import { flatten as _flatten } from "lodash-es";
import { getDrillthroughColumns } from "./getDrillthroughColumns.js";
import { _getFunctionNodesOfDrillthroughColumn } from "./internal/_getFunctionNodesOfDrillthroughColumn.js";
/**
 * Returns a new {@link MdxDrillthrough} corresponding to `mdx` where the column identified by
 * `drillthroughColumnUniqueName` has been moved to `index`. Does not mutate `mdx`.
 */ export function moveDrillthroughColumn(mdx, { drillthroughColumnUniqueName , index  }) {
    const columns = getDrillthroughColumns(mdx);
    if (!columns.includes(drillthroughColumnUniqueName)) {
        return mdx;
    }
    const fromIndex = columns.indexOf(drillthroughColumnUniqueName);
    if (fromIndex === index || index > columns.length) {
        return mdx;
    }
    const arraySliceBefore = columns.slice(0, fromIndex);
    const arraySliceAfter = columns.slice(fromIndex + 1);
    const newColumns = index < fromIndex ? [
        ...arraySliceBefore.slice(0, index),
        drillthroughColumnUniqueName,
        ...arraySliceBefore.slice(index),
        ...arraySliceAfter
    ] : [
        ...arraySliceBefore,
        ...arraySliceAfter.slice(0, index - arraySliceBefore.length - 1),
        drillthroughColumnUniqueName,
        ...arraySliceAfter.slice(index - arraySliceBefore.length - 1)
    ];
    return {
        ...mdx,
        columns: _flatten(newColumns.map((newDrillthroughColumnUniqueName)=>_getFunctionNodesOfDrillthroughColumn(newDrillthroughColumnUniqueName)))
    };
}
