import { getMeasuresAxisName } from "../getMeasuresAxisName.js";
import { quote } from "../quote.js";
import { _addAlphabeticalSort } from "./_addAlphabeticalSort.js";
import { _addNumericalSort } from "./_addNumericalSort.js";
/**
 * Returns a new {@link MdxSelect} corresponding to `mdx`, sorted as specified.
 * Does not mutate mdx.
 */ export const _addSort = (mdx, { cube , levelCoordinates , orderMode , tupleCoordinates  })=>{
    if (levelCoordinates && tupleCoordinates) {
        const { dimensionName , hierarchyName , levelName  } = levelCoordinates;
        throw new Error(`_addSort was called with both a level: "${quote(dimensionName, hierarchyName, levelName)}" and tuple: "${tupleCoordinates.map((tupleCoordinate)=>quote(tupleCoordinate.dimensionName, tupleCoordinate.hierarchyName, ...tupleCoordinate.namePath)).join(",")}". A sort can only be added on a measure (using the "tuple" argument) or a level, not both simultaneously.`);
    }
    if (levelCoordinates) {
        return _addAlphabeticalSort(mdx, {
            levelCoordinates,
            orderMode
        }, cube);
    } else if (tupleCoordinates) {
        // When sorting on a measure, the "Order" function should be added to the axis that does not contain measures.
        // The first thing to do it to find this axis.
        const measureAxis = getMeasuresAxisName(mdx);
        const targetAxisIndex = measureAxis ? mdx.axes.findIndex((axis)=>axis.name !== measureAxis) : mdx.axes.findIndex((axis)=>axis.name === "ROWS");
        return _addNumericalSort(mdx, {
            tupleCoordinates,
            orderMode,
            axisIndex: targetAxisIndex,
            cube
        });
    }
    throw new Error(`_addSort was called with no level or tuple. One of the two should be defined`);
};
