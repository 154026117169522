import { uniqWith as _uniqWith } from "lodash-es";
import { areHierarchiesEqual, getDimension } from "@activeviam/data-model";
import { getSpecificCompoundIdentifier } from "./getSpecificCompoundIdentifier.js";
import { isMdxCompoundIdentifier } from "./isMdxCompoundIdentifier.js";
import { stringify } from "./stringify.js";
import { traverseMdx } from "./traverseMdx.js";
/**
 * Returns the coordinates of the hierarchies expressed in the given Mdx.
 * Returns the hierarchies of matched hierarchy compounds, but also drilled hierarchies, levels and members.
 */ export const getHierarchies = (mdx, { cube  })=>{
    if (mdx === undefined || mdx === null) {
        return [];
    }
    const accumulator = [];
    traverseMdx(mdx, (mdxNode)=>{
        if (isMdxCompoundIdentifier(mdxNode)) {
            const specificCompoundIdentifier = getSpecificCompoundIdentifier(mdxNode, {
                cube
            });
            switch(specificCompoundIdentifier.type){
                case "hierarchy":
                case "level":
                case "member":
                    {
                        accumulator.push({
                            dimensionName: specificCompoundIdentifier.dimensionName,
                            hierarchyName: specificCompoundIdentifier.hierarchyName
                        });
                        break;
                    }
                case "measure":
                    {
                        accumulator.push({
                            dimensionName: "Measures",
                            hierarchyName: "Measures"
                        });
                        break;
                    }
                case "dimension":
                    {
                        const dimensionName = specificCompoundIdentifier.dimensionName;
                        const dimension = getDimension(dimensionName, cube);
                        accumulator.push({
                            dimensionName,
                            hierarchyName: dimension.defaultHierarchy
                        });
                        break;
                    }
                case "namedSet":
                    {
                        break;
                    }
                default:
                    {
                        throw new Error(`Encountered an Mdx compound identifier with the unknown type: "${// If none of the above types were found, then this type is incorrect.
                        // eslint-disable-next-line
                        mdxNode.type}" while getting the hierarchies in the following mdx:\n${stringify(mdx, {
                            indent: true
                        })}`);
                    }
            }
        }
    });
    return _uniqWith(accumulator, areHierarchiesEqual);
};
