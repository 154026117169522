import { getHierarchy } from "@activeviam/data-model";
import { getLevelName } from "./getLevelName.js";
/**
 * Returns the {@link MdxMemberCompoundIdentifier} corresponding to `memberCoordinates`.
 *
 * @remarks
 * Throws if `memberCoordinates` represents a measure.
 */ export const createMemberCompoundIdentifier = ({ dimensionName , hierarchyName , namePath  }, cube)=>{
    if (dimensionName === "Measures") {
        throw new Error("'createMemberCompoundIdentifier' was called on a measure. Did you mean to use 'createMeasureCompoundIdentifier' ?");
    }
    const levelIndex = namePath.length - 1;
    const hierarchy = getHierarchy({
        dimensionName,
        hierarchyName
    }, cube);
    const firstLevelName = getLevelName(hierarchy, 0);
    const levelName = getLevelName(hierarchy, levelIndex);
    return {
        elementType: "CompoundIdentifier",
        type: "member",
        identifiers: [
            dimensionName,
            hierarchyName,
            firstLevelName,
            ...namePath
        ].map((value)=>({
                elementType: "Identifier",
                quoting: "QUOTED",
                value
            })),
        dimensionName,
        hierarchyName,
        levelName,
        path: namePath
    };
};
