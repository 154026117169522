import { produce } from "immer";
import { createCalculatedMemberCompoundIdentifier } from "../createCalculatedMemberCompoundIdentifier.js";
/**
 * Returns a new {@link MdxSelect} corresponding to `mdx` where the given calculated member definition was added to the with clause.
 * Does not mutate mdx.
 */ export const _addCalculatedMemberDefinition = (mdx, { dimensionName , hierarchyName , calculatedMemberName , expression , cube , properties =[]  })=>{
    return produce(mdx, (draft)=>{
        if (draft.withClause === undefined) {
            draft.withClause = [];
        }
        const name = createCalculatedMemberCompoundIdentifier({
            dimensionName,
            hierarchyName,
            calculatedMemberName
        });
        draft.withClause.push({
            elementType: "Formula",
            expression,
            inlined: false,
            properties,
            name,
            type: "MEMBER"
        });
    });
};
