import { traverseMdx } from "../traverseMdx.js";
import { _getHighestParentRepresentingTheSameHierarchies } from "./_getHighestParentRepresentingTheSameHierarchies.js";
import { _isAMeasureThatContributesToAxisDimensionality } from "./_isAMeasureThatContributesToAxisDimensionality.js";
import { _isMdxAxis } from "./_isMdxAxis.js";
import { _isMdxSelect } from "./_isMdxSelect.js";
/**
 * Returns an array containing the object paths to the different measure sets (if any) in `mdx`.
 */ export const _getPathsToMeasures = (mdx)=>{
    const pathsToMeasures = [];
    if (_isMdxSelect(mdx)) {
        for(let axisIndex = 0; axisIndex < mdx.axes.length; axisIndex++){
            const pathsToMeasuresWithinAxis = _getPathsToMeasures(mdx.axes[axisIndex]);
            if (pathsToMeasuresWithinAxis.length > 0) {
                // A given hierarchy can contribute to the dimensionality of at most one axis.
                // So if there are matches on a given axis, there is no need to explore other axes.
                return pathsToMeasuresWithinAxis.map((pathWithinAxis)=>[
                        "axes",
                        axisIndex,
                        ...pathWithinAxis
                    ]);
            }
        }
    }
    if (_isMdxAxis(mdx)) {
        const axis = mdx;
        traverseMdx(axis, (_mdx, _path)=>{
            if (_isAMeasureThatContributesToAxisDimensionality(axis, _mdx, _path)) {
                // Two measures might have the same parent: in most cases a set.
                // In that case, don't count the node twice.
                const doesAlreadyIncludeAParent = pathsToMeasures.some((path)=>path.every((pathElement, pathElementIndex)=>_path[pathElementIndex] === pathElement));
                if (!doesAlreadyIncludeAParent) {
                    const { path: pathToHighestParentWithMeasuresOnlyInDimensionality  } = _getHighestParentRepresentingTheSameHierarchies(axis, _path);
                    pathsToMeasures.push(pathToHighestParentWithMeasuresOnlyInDimensionality);
                }
            }
        });
    }
    return pathsToMeasures;
};
