import { produce } from "immer";
import { range as _range } from "lodash-es";
import { getHierarchy } from "@activeviam/data-model";
import { addRowIndexToMapping } from "./addRowIndexToMapping.js";
import { axisIds } from "./axisIds.js";
import { fillMissingChunkAbove } from "./fillMissingChunkAbove.js";
import { fillMissingChunkBelow } from "./fillMissingChunkBelow.js";
import { getMissingTotals } from "./getMissingTotals.js";
import { getRowIndexOnScreen } from "./getRowIndexOnScreen.js";
/**
 * Returns an equivalent of `cellSet`, with additional rows for missing totals.
 * Totals can be missing from `cellSet` in the following cases:
 *   - hidden totals
 *   - measures are on rows
 *   - a slicing hierarchy is on rows
 * This is fine for the pivot table but it disallows the display of a tree table, which calls for having a parent for each of its nodes.
 * The tree table can be displayed using the cellSet returned by this function.
 * The added rows contain row headers but no values.
 *
 * Does not mutate `cellSet`.
 * Mutates `rowIndexMapping`.
 */ export function addMissingTotalRows(cellSet, { cube , rowIndexMapping  }) {
    if (!cube) {
        return cellSet;
    }
    return produce(cellSet, (draft)=>{
        const rowsAxis = draft.axes.find(({ id  })=>id === axisIds.rows);
        if (!rowsAxis) {
            return;
        }
        const indexInDataOfFirstRowInChunk = rowsAxis.range?.from ?? 0;
        const indexOnScreenOfFirstRowInChunk = getRowIndexOnScreen(indexInDataOfFirstRowInChunk, rowIndexMapping);
        let numberOfAddedRowsAbove = indexOnScreenOfFirstRowInChunk - indexInDataOfFirstRowInChunk;
        if (rowsAxis.range && indexOnScreenOfFirstRowInChunk > 0) {
            rowsAxis.range.from += numberOfAddedRowsAbove;
            if (rowIndexMapping && rowIndexMapping.length < rowsAxis.range.from - 1) {
                fillMissingChunkAbove(rowIndexMapping, rowsAxis.range.from);
            }
        }
        const hierarchies = rowsAxis.hierarchies.map(({ dimension , hierarchy  })=>dimension === "Measures" ? null : getHierarchy({
                dimensionName: dimension,
                hierarchyName: hierarchy
            }, cube));
        /** The indices of the added totals in the rows positions array. */ const addedRowIndices = [];
        let positionIndex = 0;
        let positionIndexInData = 0;
        while(positionIndex < rowsAxis.positions.length){
            // The cast below is safe: only `previousTuple` can be undefined (when `positionIndex === 0`).
            // `tuple` can never be undefined.
            // eslint-disable-next-line atoti-ui/no-as
            const [previousTuple, tuple] = [
                positionIndex - 1,
                positionIndex
            ].map((index)=>{
                if (index === -1) {
                    return undefined;
                }
                return rowsAxis.positions[index].map((member, hierarchyIndex)=>{
                    const { dimension: dimensionName , hierarchy: hierarchyName  } = rowsAxis.hierarchies[hierarchyIndex];
                    return {
                        dimensionName,
                        hierarchyName,
                        ...member
                    };
                });
            });
            // Add the missing totals to the positions on rows.
            const missingTotals = // Do NOT add missing totals above the first row of the chunk, unless it is at the top of the table.
            // Avoids breaking the visual consistency when the user scrolls past a chunk and a new one is loaded.
            rowsAxis.range && rowsAxis.range.from > 0 && positionIndex === 0 ? [] : getMissingTotals(previousTuple, tuple, {
                cube,
                hierarchies,
                maxLevelPerHierarchy: rowsAxis.maxLevelPerHierarchy
            });
            rowsAxis.positions.splice(positionIndex, 0, ...missingTotals);
            addRowIndexToMapping(rowIndexMapping, {
                rowIndexInData: indexInDataOfFirstRowInChunk + positionIndexInData,
                rowIndexOnScreen: indexOnScreenOfFirstRowInChunk + positionIndex,
                numberOfMissingTotalRowsAbove: missingTotals.length
            });
            if (missingTotals.length > 0) {
                const from = rowsAxis.range ? rowsAxis.range.from : 0;
                addedRowIndices.push(..._range(from + positionIndex, from + positionIndex + missingTotals.length));
            }
            positionIndex += missingTotals.length + 1;
            positionIndexInData += 1;
        }
        fillMissingChunkBelow(rowIndexMapping, indexOnScreenOfFirstRowInChunk + positionIndex - 1);
        const columnsAxis = cellSet.axes.find(({ id  })=>id === axisIds.columns);
        const numberOfColumns = columnsAxis ? columnsAxis.positions.length : 0;
        draft.cells.forEach((cell)=>{
            const rowIndex = Math.floor(cell.ordinal / numberOfColumns);
            while(addedRowIndices.length > 0 && rowIndex + numberOfAddedRowsAbove >= addedRowIndices[0]){
                numberOfAddedRowsAbove += 1;
                addedRowIndices.shift();
            }
            // Each cell is pushed back by the number of added rows * the number of cells in a row.
            cell.ordinal += numberOfAddedRowsAbove * numberOfColumns;
        });
        if (rowsAxis.range) {
            rowsAxis.range.to += numberOfAddedRowsAbove;
            rowsAxis.range.axisLength += numberOfAddedRowsAbove;
        }
    });
}
