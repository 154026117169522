import { isEqual as _isEqual, last as _last } from "lodash-es";
import { areLevelsEqual } from "@activeviam/data-model";
import { getSpecificCompoundIdentifier } from "../getSpecificCompoundIdentifier.js";
import { stringify } from "../stringify.js";
/** Returns whether the 2 given Mdxs are compound identifiers representing the same member.  */ export const _isSameMember = (nodeA, nodeB, cube)=>{
    const [specificCompoundIdentifierA, specificCompoundIdentifierB] = [
        nodeA,
        nodeB
    ].map((compoundIdentifier)=>getSpecificCompoundIdentifier(compoundIdentifier, {
            cube
        }));
    const invalidCompoundIdentifier = [
        specificCompoundIdentifierA,
        specificCompoundIdentifierB
    ].find(({ type  })=>![
            "measure",
            "member"
        ].includes(type));
    if (invalidCompoundIdentifier !== undefined) {
        throw new Error(`The compound identifier "${stringify(invalidCompoundIdentifier, {
            indent: true
        })}" does not represent a member.`);
    }
    if (specificCompoundIdentifierA.type === "measure" && specificCompoundIdentifierB.type === "measure") {
        return specificCompoundIdentifierA.measureName === specificCompoundIdentifierB.measureName;
    }
    if (specificCompoundIdentifierA.type === "member" && specificCompoundIdentifierB.type === "member") {
        if (!areLevelsEqual(specificCompoundIdentifierA, specificCompoundIdentifierB)) {
            return false;
        }
        return specificCompoundIdentifierA.path.length === specificCompoundIdentifierB.path.length ? // [Currency].[Currency].[AllMember].[EUR]
        // [Currency].[Currency].[ALL].[GBP]
        // [Currency].[USD]
        // => check that the full paths match
        // Not a react related operation, deep equality testing is required.
        // eslint-disable-next-line atoti-ui/no-lodash-isequal
        _isEqual(specificCompoundIdentifierA, specificCompoundIdentifierB) : // [Booking].[Desk].[AllMember].[LegalEntityA].[BusinessUnitA].[DeskA]
        // [Booking].[Desk].[Desk].[DeskA]
        // [Booking].[Desk].[BusinessUnit].[BusinessUnitA].[DeskA]
        // => check that last identifiers match
        //
        // This is less precise than the full member path equality, but still sound.
        // Indeed, any syntax without the full member path will throw on the server side
        // if the ambiguity cannot be resolved.
        // In that case, it does not matter whether the client side inference was good or not anyway.
        //
        // For instance, suppose the two following members have the name "DeskA" on the Desk level:
        // [Booking].[Desk].[AllMember].[LegalEntityA].[BusinessUnitA].[DeskA]
        // [Booking].[Desk].[AllMember].[LegalEntityB].[BusinessUnitA].[DeskA]
        // If [Booking].[Desk].[Desk].[DeskA] is expressed in the MDX, it it impossible to
        // infer which of these two members the expression points to.
        //
        // On the client side, the list of members is not known, so by convention it is considered that
        // [Booking].[Desk].[Desk].[DeskA] and [Booking].[Desk].[AllMember].[LegalEntityA].[BusinessUnitA].[DeskA]
        // represent the same member, for convenience.
        // Since in fact the ambiguity cannot be resolved when the query involving [Booking].[Desk].[Desk].[DeskA]
        // reaches the server, an error will be thrown.
        _last(specificCompoundIdentifierA.path) === _last(specificCompoundIdentifierB.path);
    }
    return false;
};
