import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { CSSProperties, FC, useMemo } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

import {
  getContentRecordAtPath,
  usePermission,
  useTree,
} from "@activeviam/activeui-sdk";
import { Tooltip } from "@activeviam/utils-react";

interface NewDashboardButtonProps {
  pathToParentFolder: string[];
  style?: CSSProperties;
}

/**
 * Displays a button that creates a new dashboard inside `pathToParentFolder`.
 */
export const NewDashboardButton: FC<NewDashboardButtonProps> = (props) => {
  const { formatMessage } = useIntl();
  const dashboardsTree = useTree("dashboard");
  const navigate = useNavigate();

  const folderRecord = useMemo(
    () =>
      getContentRecordAtPath({
        tree: dashboardsTree,
        path: props.pathToParentFolder,
      }),
    [dashboardsTree, props.pathToParentFolder],
  );

  const [canManageContent] = usePermission("canManageContent");

  if (!folderRecord) {
    return null;
  }

  const handleNewDashboardButtonClicked = () => {
    navigate(["/dashboard", ...props.pathToParentFolder, "unsaved"].join("/"));
  };

  const isCreateDashboardButtonDisabled = !folderRecord.entry.canWrite;

  return (
    <Tooltip
      trigger={isCreateDashboardButtonDisabled ? ["hover"] : []}
      placement="bottom"
      title={formatMessage({
        id: "notAllowedToCreateDashboard",
      })}
    >
      <Button
        type={"primary"}
        disabled={isCreateDashboardButtonDisabled}
        style={props.style}
        onClick={handleNewDashboardButtonClicked}
      >
        {canManageContent && <PlusOutlined />}
        {formatMessage({
          id: canManageContent ? "createDashboard" : "startExploring",
        })}
      </Button>
    </Tooltip>
  );
};
