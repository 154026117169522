import { stringify } from "../stringify.js";
/**
 * Thrown when an {@link MdxSelect}, an {@link MdxSubSelect} or an {@link MdxDrillthrough} does not specify the name of its target {@link Cube}.
 */ export class CubelessQueryError extends Error {
    constructor(mdx){
        super(`Couldn't find a cube name in Mdx:\n "${stringify(mdx, {
            indent: true
        })}")`);
    }
}
