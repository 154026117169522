import { produce } from "immer";
import { findLevels } from "./findLevels.js";
import { _cleanupMdx } from "./internal/_cleanupMdx.js";
import { _collapseMembersOfLevel } from "./internal/_collapseMembersOfLevel.js";
import { _getIndexOfAxisContainingHierarchy } from "./internal/_getIndexOfAxisContainingHierarchy.js";
import { _nullifyNodeAtPath } from "./internal/_nullifyNodeAtPath.js";
/**
 * Returns a new {@link MdxSelect} corresponding to `mdx` where the given level was removed.
 * Does not mutate mdx
 */ export function removeLevel(mdx, { cube , dimensionName , hierarchyName , levelName  }) {
    const axisIndex = _getIndexOfAxisContainingHierarchy(mdx, {
        dimensionName,
        hierarchyName,
        cube
    });
    if (axisIndex === -1) {
        return mdx;
    }
    const axis = mdx.axes[axisIndex];
    // Removing a level before other expressions on the axis breaks the identity of the potentially expanded tuples in those expressions.
    // => Collapse these potential expanded tuples.
    // For instance, consider an axis representing currencies and legal entities where USD/LegalEntityA is expanded.
    // Now suppose the user Removes currencies. USD/LegalEntityA does not exist anymore on this axis, so it should be collapsed.
    const collapsedAxis = _collapseMembersOfLevel(axis, {
        dimensionName,
        hierarchyName,
        levelName
    }, cube);
    const cleanAxis = produce(collapsedAxis, (draft)=>{
        let nextOccurrenceOfLevel;
        while(nextOccurrenceOfLevel = findLevels(draft, cube, [
            {
                dimensionName,
                hierarchyName,
                levelName
            }
        ])[0]){
            _nullifyNodeAtPath(draft, nextOccurrenceOfLevel.path);
            _cleanupMdx(draft, nextOccurrenceOfLevel.path, cube);
        }
    });
    return produce(mdx, (draft)=>{
        draft.axes[axisIndex] = cleanAxis;
        _cleanupMdx(draft, [
            "axes",
            axisIndex
        ], cube);
    });
}
