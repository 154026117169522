import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useState,
} from "react";

const IsExportingToPDFContext = createContext<
  [boolean, (isExportingToPDF: boolean) => void] | null
>(null);

/**
 * React Context Provider, allowing descendant components to know whether a dashboard is being exported as a PDF, and to trigger/stop an export.
 */
export const IsExportingToPDFProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const isExportingToPDFState = useState(false);
  return (
    <IsExportingToPDFContext.Provider value={isExportingToPDFState}>
      {children}
    </IsExportingToPDFContext.Provider>
  );
};

/**
 * React hook returning whether a dashboard is being exported as a PDF, as well as a handle to trigger/stop an export.
 */
export function useIsExportingToPDF(): [
  boolean,
  (isExportingToPDF: boolean) => void,
] {
  const isExportingToPDFState = useContext(IsExportingToPDFContext);
  if (isExportingToPDFState === null) {
    throw new Error(
      "IsExportingToPDF not found. Did you forget to provide it with <IsExportingToPDFProvider /> ?",
    );
  }
  return isExportingToPDFState;
}
