import { createHierarchyCompoundIdentifier } from "../createHierarchyCompoundIdentifier.js";
import { createLevelCompoundIdentifier } from "../createLevelCompoundIdentifier.js";
/**
 * Creates the expression of the formula required for sorting on a level.
 */ export const _getExpressionOfFormulaToSortOnLevel = (levelCoordinates, isLevelExpressedOnAxis)=>{
    const levelCompoundIdentifier = createLevelCompoundIdentifier(levelCoordinates);
    const hierarchyCompoundIdentifier = createHierarchyCompoundIdentifier(levelCoordinates);
    const hierarchyMember = {
        elementType: "Function",
        name: "CurrentMember",
        syntax: "Property",
        arguments: [
            hierarchyCompoundIdentifier
        ]
    };
    let expression;
    // If levels is empty, it means we are sorting on a level that is not expressed on the query.
    if (!isLevelExpressedOnAxis) {
        expression = {
            elementType: "Function",
            name: "IIf",
            syntax: "Function",
            arguments: [
                {
                    elementType: "Function",
                    name: ">",
                    syntax: "Infix",
                    arguments: [
                        {
                            elementType: "Function",
                            name: "Ordinal",
                            syntax: "Property",
                            arguments: [
                                {
                                    elementType: "Function",
                                    name: "Level",
                                    syntax: "Property",
                                    arguments: [
                                        hierarchyMember
                                    ]
                                }
                            ]
                        },
                        {
                            elementType: "Function",
                            name: "Ordinal",
                            syntax: "Property",
                            arguments: [
                                levelCompoundIdentifier
                            ]
                        }
                    ]
                },
                {
                    elementType: "Function",
                    name: "MEMBER_VALUE",
                    syntax: "Property",
                    arguments: [
                        {
                            elementType: "Function",
                            name: "Ancestor",
                            syntax: "Function",
                            arguments: [
                                hierarchyMember,
                                levelCompoundIdentifier
                            ]
                        }
                    ]
                },
                {
                    elementType: "Function",
                    name: "MEMBER_VALUE",
                    syntax: "Property",
                    arguments: [
                        hierarchyMember
                    ]
                }
            ]
        };
    } else {
        expression = {
            elementType: "Function",
            name: "MEMBER_VALUE",
            syntax: "Property",
            arguments: [
                hierarchyMember
            ]
        };
    }
    return expression;
};
