import { produce } from "immer";
import { findDescendant } from "./findDescendant.js";
import { _cleanupMdx } from "./internal/_cleanupMdx.js";
import { _nullifyNodeAtPath } from "./internal/_nullifyNodeAtPath.js";
import { isMdxNamedSetCompoundIdentifier } from "./isMdxNamedSetCompoundIdentifier.js";
/**
 * Returns a new {@link MdxSelect} corresponding to `mdx` where the given named set was removed.
 * Does not mutate mdx.
 */ export function removeNamedSet(mdx, { setName , cube  }) {
    return produce(mdx, (draft)=>{
        let nextOccurrence;
        while(nextOccurrence = findDescendant(draft, (node)=>isMdxNamedSetCompoundIdentifier(node, {
                setName,
                cube
            }))){
            const { path  } = nextOccurrence;
            _nullifyNodeAtPath(draft, path);
            _cleanupMdx(draft, path, cube);
        }
    });
}
