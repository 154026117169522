import { _nullifyNodeAtPath } from "./_nullifyNodeAtPath.js";
/**
 * Nullify `functionNode` at `path` in `mdx` if it does not have enough arguments.
 * Returns whether `functionNode` was nullified.
 * Mutates `mdx`.
 *
 * @example
 * AddCalculatedMembers(null) => null
 */ export function _nullifyIfNotEnoughArgs({ mdx , path , functionNode , minNumberOfArgs  }) {
    if (functionNode.arguments.length < minNumberOfArgs) {
        _nullifyNodeAtPath(mdx, path);
        return true;
    }
    return false;
}
