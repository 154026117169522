import { get as _get, initial as _initial } from "lodash-es";
import { isACompoundIdentifierRepresentingAMeasure } from "../isACompoundIdentifierRepresentingAMeasure.js";
import { isMdxFunction } from "../isMdxFunction.js";
import { _isMdxAxis } from "./_isMdxAxis.js";
// TODO [GENERIC-MDX-SUPPORT] it would be better to really reason in terms of dimensionality, rather than assuming that being in any set or crossjoin is equivalent to contributing to the axis dimensionality.
/**
 * Returns whether `mdx`, at `path` in `axis`, is a {@link MdxMeasureCompoundIdentifier} and falls into one of the following cases:
 * - it is at the root of `axis`
 * - it is expressed in a Crossjoin
 * - it is expressed in a set of measures
 */ export const _isAMeasureThatContributesToAxisDimensionality = (axis, mdx, path)=>{
    if (!isACompoundIdentifierRepresentingAMeasure(mdx)) {
        return false;
    }
    const parentPath = _initial(path);
    const grandParentPath = _initial(parentPath);
    // Forced to use `get` because the path is dynamic.
    // eslint-disable-next-line atoti-ui/no-lodash-get
    const parent = parentPath.length > 0 ? _get(axis, parentPath) : axis;
    const grandParent = // Forced to use `get` because the path is dynamic.
    // eslint-disable-next-line atoti-ui/no-lodash-get
    grandParentPath.length > 0 ? _get(axis, grandParentPath) : undefined;
    return _isMdxAxis(parent) || isMdxFunction(grandParent) && (grandParent.name === "{}" || grandParent.name.toLowerCase() === "crossjoin");
};
