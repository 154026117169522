/**
 * Returns an {@link MdxUnknownCompoundIdentifier} based on the given `identifiers`.
 */ const createUnknownCompoundIdentifier = (identifiers)=>{
    return {
        elementType: "CompoundIdentifier",
        type: "unknown",
        identifiers: identifiers.map((value)=>({
                elementType: "Identifier",
                value,
                quoting: "QUOTED"
            }))
    };
};
/**
 * Returns the pair of {@link MdxFunction} that must be added to an {@link MdxDrillthrough}
 * in order to request the {@link DrillthroughColumn} named `drillthroughColumnUniqueName` from ActivePivot.
 */ export function _getFunctionNodesOfDrillthroughColumn(drillthroughColumnUniqueName) {
    return [
        {
            elementType: "Function",
            name: "MemberValue",
            syntax: "Function",
            arguments: [
                createUnknownCompoundIdentifier([
                    drillthroughColumnUniqueName
                ])
            ]
        },
        {
            elementType: "Function",
            name: "Caption",
            syntax: "Function",
            arguments: [
                createUnknownCompoundIdentifier([
                    drillthroughColumnUniqueName
                ])
            ]
        }
    ];
}
