import { produce } from "immer";
import { update as _update } from "lodash-es";
import { createMemberOrMeasureCompoundIdentifier } from "../createMemberOrMeasureCompoundIdentifier.js";
import { isMdxLiteral } from "../isMdxLiteral.js";
import { _findDeepestOrderNode } from "./_findDeepestOrderNode.js";
import { _findFirstOrderFunction } from "./_findFirstOrderFunction.js";
/**
 * Returns a new MdxExpression corresponding to the given `expression` wrapped into an "Order" function
 * @example
 * expression: [Currency].[Currency].[Currency].Members, measureName: "foo", orderMode: ASC
 * output: Order([Currency].[Currency].[Currency].Members, [Measures].[foo], ASC)
 */ const wrapExpressionIntoOrder = (expression, { orderMode , tupleCoordinates , cube  })=>{
    const identifiers = tupleCoordinates.map(({ dimensionName , hierarchyName , namePath  })=>{
        return createMemberOrMeasureCompoundIdentifier({
            dimensionName,
            hierarchyName,
            namePath
        }, cube);
    });
    return {
        name: "Order",
        syntax: "Function",
        elementType: "Function",
        arguments: [
            expression,
            tupleCoordinates.filter((member)=>member.dimensionName !== "Measures").length >= 1 ? {
                elementType: "Function",
                name: "()",
                syntax: "Parentheses",
                arguments: identifiers
            } : identifiers[0],
            {
                elementType: "Literal",
                type: "KEYWORD",
                value: orderMode
            }
        ]
    };
};
/**
 * Returns a new {@link MdxSelect} corresponding to `mdx`, sorted numerically on the given tuple.
 * If no measure is provided in `tuple`, then the default measure will be used.
 * Does not mutate mdx.
 */ export const _addNumericalSort = (mdx, { orderMode , axisIndex , tupleCoordinates , cube  })=>produce(mdx, (draft)=>{
        const measureMember = tupleCoordinates.find(({ dimensionName , hierarchyName  })=>dimensionName === "Measures" && hierarchyName === "Measures");
        const measureName = measureMember && measureMember.namePath[0];
        const preExistingOrderFunction = _findFirstOrderFunction(draft, {
            measureName
        });
        if (preExistingOrderFunction) {
            if (!isMdxLiteral(preExistingOrderFunction.match.arguments[2])) {
                throw new Error(`Cannot add a numerical sort, because the previous sort was set on an MDX expression of type '${preExistingOrderFunction.match.arguments[2].elementType}' which is not supported.
          The only supported elementType is 'Literal'.`);
            }
            // If `mdx` is already sorted based on the given measure, just update the orderMode.
            preExistingOrderFunction.match.arguments[2].value = orderMode;
        } else {
            // In Mdx, the outside "Order" has priority over the inside ones, when there are several "Order" functions.
            // The added sort needs to only sort the positions with an equal rank according to the potential existing sorts.
            // => It goes inside the deepest "Order" function if it exists. Or at the top of the axis otherwise.
            const deepestOrderNode = _findDeepestOrderNode(draft);
            const pathToWrapInOrder = deepestOrderNode ? [
                ...deepestOrderNode.path,
                "arguments",
                0
            ] : [
                "axes",
                axisIndex,
                "expression"
            ];
            _update(draft, pathToWrapInOrder, (expression)=>wrapExpressionIntoOrder(expression, {
                    orderMode,
                    tupleCoordinates,
                    cube
                }));
        }
    });
