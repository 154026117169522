/**
 * Opens the native print window with `innerHtml` in the preview area.
 */
export const triggerNativePrint = (
  innerHtml: string,
  iframeName: string,
  onAfterPrint?: () => void,
): void => {
  // Get the iframe to print it.
  // An Iframe with the given `iframeName` must exist in the component which calls this function.
  // eslint-disable-next-line
  const iframe = window.frames[iframeName as any];

  // Get all the css present in the document.
  const styleSheets = document.styleSheets;
  let mainCSSText = "";
  Object.keys(styleSheets).forEach((styleSheetKey) => {
    Object.keys(styleSheets[parseInt(styleSheetKey)].cssRules).forEach(
      (cssRuleKey) => {
        mainCSSText +=
          styleSheets[parseInt(styleSheetKey)].cssRules[parseInt(cssRuleKey)]
            .cssText + "\n";
      },
    );
  });

  // Paste the main document's style into the iframe.
  iframe.document.head.innerHTML = `<style type="text/css">${mainCSSText}</style>`;
  iframe.document.body.innerHTML = `<div class="ant-root">${innerHtml}</div>`;

  // This pops the browser's print window to allow user to save or cancel the pdf.
  iframe.window.print();

  setTimeout(() => {
    if (onAfterPrint) {
      onAfterPrint();
    }
  });
};
