import { addCalculatedMeasureExpressionToWithClause } from "./addCalculatedMeasureExpressionToWithClause.js";
import { createHierarchyCompoundIdentifier } from "./createHierarchyCompoundIdentifier.js";
import { smartFilter } from "./smartFilter.js";
const filteredCaptionMeasureName = "FilteredCaption";
/**
 * Returns the {@link MdxSelect} that searches the members of the given hierarchy that match `searchValue`.
 * The retrieved list of members is smart filtered (see {@link smartFilter}).
 */ export function getMdxToFetchMembersMatchingCaption({ dimensionName , hierarchyName , searchValue , cube , mdxInView , filters , isSmartFilteringEnabled , hierarchiesToIgnoreInSmartFilteringContext , nonEmptyEvaluationMeasureName  }) {
    /**
   * The filtering is done through a calculated measure that returns NULL for members whose caption does not match the search value.
   * It uses the dedicated MEMBER-CAPTION-SEARCH post-processor through an ActiveMeasure.
   *
   * @see
   * - ActiveMeasure documentation: https://support.activeviam.com/confluence/display/AP5/Active+Measures+and+MDX+compilation
   * - MEMBER-CAPTION-SEARCH post-processor code: https://github.com/activeviam/activepivot/blob/a61a6bb44d2395c67d21cea2ff62365ed1c80d38/pivot/core/ext/src/main/java/com/quartetfs/biz/pivot/postprocessing/impl/FilteringPostProcessorOnTheFlyPattern.java
   */ const filteredCaptionMeasureExpression = {
        elementType: "Function",
        name: "ActiveMeasure",
        syntax: "Function",
        arguments: [
            {
                elementType: "Literal",
                type: "STRING",
                value: "MEMBER-CAPTION-SEARCH"
            },
            {
                elementType: "Literal",
                type: "STRING",
                value: "pattern"
            },
            {
                elementType: "Literal",
                type: "STRING",
                value: searchValue
            },
            {
                elementType: "Literal",
                type: "STRING",
                value: "maxNumberOfResults"
            },
            {
                elementType: "Literal",
                type: "STRING",
                value: "15"
            },
            {
                elementType: "Literal",
                type: "STRING",
                value: "filteredHierarchy"
            },
            {
                elementType: "Literal",
                type: "STRING",
                value: `${hierarchyName}@${dimensionName}`
            },
            {
                elementType: "Literal",
                type: "STRING",
                value: "measure"
            },
            {
                elementType: "Literal",
                type: "STRING",
                value: nonEmptyEvaluationMeasureName ?? "contributors.COUNT"
            }
        ]
    };
    const hierarchyMembers = {
        elementType: "Function",
        name: "Members",
        syntax: "Property",
        arguments: [
            createHierarchyCompoundIdentifier({
                dimensionName,
                hierarchyName
            })
        ]
    };
    const selectMembersMdx = {
        axes: [
            {
                elementType: "Axis",
                name: "ROWS",
                nonEmpty: false,
                properties: [],
                expression: hierarchyMembers
            }
        ],
        elementType: "Select",
        from: {
            cubeName: cube.name,
            elementType: "From"
        },
        withClause: []
    };
    const smartFilteredMdx = smartFilter(selectMembersMdx, {
        targetHierarchyCoordinates: {
            dimensionName,
            hierarchyName
        },
        // The non empty evaluation measure is the filtered caption measure: members whose caption does not match the search value are filtered out.
        // Notice that the `nonEmptyEvaluationMeasureName` received as argument (and usually coming from settings) is still taken into account as it is used in the definition of the `filteredCaptionMeasure`.
        nonEmptyEvaluationMeasureName: filteredCaptionMeasureName,
        cube,
        mdxInView,
        filters,
        isSmartFilteringEnabled,
        ignoredHierarchies: hierarchiesToIgnoreInSmartFilteringContext
    });
    return addCalculatedMeasureExpressionToWithClause(smartFilteredMdx, {
        calculatedMeasureName: filteredCaptionMeasureName,
        expression: filteredCaptionMeasureExpression
    });
}
