import { lastIndexOf as _lastIndexOf } from "lodash-es";
import { doesRepresentATuple } from "./doesRepresentATuple.js";
import { findDescendant } from "./findDescendant.js";
import { getMeasureName } from "./getMeasureName.js";
import { getTupleCoordinates } from "./getTupleCoordinates.js";
import { isACompoundIdentifierRepresentingAMeasure } from "./isACompoundIdentifierRepresentingAMeasure.js";
import { isMdxFunction } from "./isMdxFunction.js";
import { unquote } from "./unquote.js";
/**
 * When sorting alphabetically on the members of a level,
 * a calculated measure is used to compare the member names.
 * By convention, the name of the measure includes the level name.
 * This function extracts the level name from the sorting measure name,
 * and returns the corresponding {@link LevelCoordinates}.
 */ const _getLevelCoordinates = (sortingMeasureName)=>{
    const levelUniqueName = sortingMeasureName.slice(0, _lastIndexOf(sortingMeasureName, "]") + 1);
    const [dimensionName, hierarchyName, levelName] = unquote(levelUniqueName);
    return {
        dimensionName,
        hierarchyName,
        levelName
    };
};
/**
 * Returns whether `numericExpression` could be used for the purpose of
 * sorting alphabetically on the members of a hierarchy in MDX.
 *
 * @remarks
 * Returns true if the following conditions are met:
 * - `numericExpression` is a calculated measure whose definition can be found in `withClause`
 * - its expression is based on `[SomeDim].[SomeHier].CurrentMember.MEMBER_VALUE` (or MEMBER_CAPTION)
 */ const _isAlphabeticalSort = (numericExpression, withClause)=>{
    if (!withClause || !isACompoundIdentifierRepresentingAMeasure(numericExpression)) {
        return false;
    }
    const measureName = getMeasureName(numericExpression);
    const matchingFormula = withClause.find((formula)=>isACompoundIdentifierRepresentingAMeasure(formula.name, {
            measureName
        }));
    if (!matchingFormula) {
        return false;
    }
    return isMdxFunction(matchingFormula.expression) && // When sorting alphabetically on the members of a level, the iif function
    // can be used to differentiate the members of the target level from members
    // of deeper levels in the same hierarchy.
    // See example in the test file.
    [
        "member_value",
        "member_caption",
        "iif"
    ].includes(matchingFormula.expression.name.toLowerCase());
};
/**
 * Returns the {@link OrderMode} defining the sort
 * and the {@link LevelCoordinates} if it is alphabetical
 * or the {@link TupleCoordinates} if it is numerical.
 * Returns `undefined` if `mdx` is not sorted.
 */ export function getSort(mdx, { cube  }) {
    const orderFunctionLookup = findDescendant(mdx, (node)=>isMdxFunction(node, "order"));
    if (orderFunctionLookup === undefined) {
        return undefined;
    }
    // @ts-expect-error The fact that findDescendant returns a defined value ensures that the match is an MdxOrderFunction.
    const orderFunction = orderFunctionLookup.match;
    const numericExpression = orderFunction.arguments[1];
    const orderMode = orderFunction.arguments[2] ? orderFunction.arguments[2].value : "ASC";
    if (_isAlphabeticalSort(numericExpression, mdx.withClause)) {
        const sortingMeasureName = getMeasureName(numericExpression);
        const levelCoordinates = _getLevelCoordinates(sortingMeasureName);
        return {
            orderMode,
            levelCoordinates
        };
    }
    if (doesRepresentATuple(numericExpression)) {
        const tupleCoordinates = getTupleCoordinates(numericExpression, cube);
        return {
            orderMode,
            tupleCoordinates
        };
    }
    return undefined;
}
