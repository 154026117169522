import { produce } from "immer";
import { last as _last } from "lodash-es";
import { areHierarchiesEqual, getHierarchy } from "@activeviam/data-model";
import { _expandInAxis } from "./expand.js";
import { getExpandedTuples } from "./getExpandedTuples.js";
import { getLevelName } from "./getLevelName.js";
import { _cleanupMdx } from "./internal/_cleanupMdx.js";
import { _collapseMembersOfLevel } from "./internal/_collapseMembersOfLevel.js";
import { _getIndexOfAxisContainingHierarchy } from "./internal/_getIndexOfAxisContainingHierarchy.js";
/**
 * Returns a new {@link MdxSelect} corresponding to `mdx` where the given hierarchy was removed from the expansion chain it was in.
 *
 * The expanded tuples that would not exist after the hierarchy removal anymore are collapsed.
 * Those that would still exist after the removal are preserved.
 * In particular, the tuples that were expanded down to the first level of the hierarchy being removed are still expanded after the removal, but now down to the first level of the next hierarchy in the expansion chain, if any.
 *
 * Does not mutate mdx.
 */ export const removeHierarchyFromExpansionChain = (mdx, { cube , hierarchyCoordinates  })=>{
    const axisIndex = _getIndexOfAxisContainingHierarchy(mdx, {
        ...hierarchyCoordinates,
        cube
    });
    if (axisIndex === -1) {
        return mdx;
    }
    const axis = mdx.axes[axisIndex];
    const expandedTuples = getExpandedTuples(axis, cube);
    // Finds the tuples that were previously expanded down to the first level of the hierarchy being removed.
    const tuplesToReExpand = expandedTuples.filter(({ toLevel  })=>areHierarchiesEqual(toLevel, hierarchyCoordinates)).map(({ tupleCoordinates  })=>tupleCoordinates);
    // Collapse all tuples involving members from the level right before the removed hierarchy.
    if (tuplesToReExpand.length === 0) {
        // The hierarchy is not part of an expansion chain.
        // Nothing to do.
        return mdx;
    }
    const hierarchyBefore = {
        dimensionName: _last(tuplesToReExpand[0])?.dimensionName,
        hierarchyName: _last(tuplesToReExpand[0])?.hierarchyName
    };
    const indexOfLevelBefore = _last(tuplesToReExpand[0]).namePath.length - 1;
    const levelBefore = {
        ...hierarchyBefore,
        levelName: getLevelName(getHierarchy(hierarchyBefore, cube), indexOfLevelBefore)
    };
    const collapsedAxis = _collapseMembersOfLevel(axis, levelBefore, cube);
    // Then re-expand the tuples down to the first level of the hierarchy right after the one being removed, if any.
    const firstLevelOfHierarchyAfter = expandedTuples.find(({ tupleCoordinates , toLevel  })=>areHierarchiesEqual(_last(tupleCoordinates), hierarchyCoordinates) && !areHierarchiesEqual(toLevel, hierarchyCoordinates))?.toLevel;
    const reExpandedAxis = firstLevelOfHierarchyAfter ? tuplesToReExpand.reduce((newMdx, tupleToReExpand)=>{
        return _expandInAxis(newMdx, {
            cube,
            tupleCoordinates: tupleToReExpand,
            toLevel: firstLevelOfHierarchyAfter
        });
    }, collapsedAxis) : collapsedAxis;
    return produce(mdx, (draft)=>{
        draft.axes[axisIndex] = reExpandedAxis;
        _cleanupMdx(draft, [
            "axes",
            axisIndex
        ], cube);
    });
};
