import { produce } from "immer";
import { createCalculatedMemberCompoundIdentifier } from "./createCalculatedMemberCompoundIdentifier.js";
import { _areCompoundIdentifiersEqual } from "./internal/_areCompoundIdentifiersEqual.js";
import { _cleanupMdx } from "./internal/_cleanupMdx.js";
/**
 * Returns a new {@link MdxSelect} corresponding to `mdx` from which the definition of the given calculated member was removed.
 * Does not mutate mdx.
 */ export const removeCalculatedMemberDefinition = (mdx, { dimensionName , hierarchyName , calculatedMemberName , cube  })=>{
    return produce(mdx, (draft)=>{
        if (draft.withClause === undefined) {
            return;
        }
        const memberToRemove = createCalculatedMemberCompoundIdentifier({
            dimensionName,
            hierarchyName,
            calculatedMemberName
        });
        draft.withClause = draft.withClause.filter((calculatedMember)=>!_areCompoundIdentifiersEqual(memberToRemove, calculatedMember.name, cube));
        _cleanupMdx(draft, [
            "withClause"
        ], cube);
    });
};
