import { areHierarchiesEqual, getLevelIndex } from "@activeviam/data-model";
import { _collapseInAxis } from "../collapse.js";
import { getExpandedTuples } from "../getExpandedTuples.js";
import { _getHierarchiesOnAxis } from "./_getHierarchiesOnAxis.js";
/**
 * Collapses every expanded member of the level identified by `levelCoordinates`.
 * Does not mutate `mdx`.
 */ export const _collapseMembersOfLevel = (axis, levelCoordinates, cube)=>{
    const hierarchies = _getHierarchiesOnAxis(axis, {
        cube
    });
    const hierarchyIndex = hierarchies.findIndex((coordinates)=>areHierarchiesEqual(coordinates, levelCoordinates));
    if (hierarchyIndex === -1) {
        return axis;
    }
    const expandedTuples = getExpandedTuples(axis, cube);
    const levelIndex = getLevelIndex({
        cube,
        ...levelCoordinates
    });
    let collapsedAxis = axis;
    expandedTuples.forEach(({ tupleCoordinates  })=>{
        const doesTupleContainHierarchy = tupleCoordinates.some((coordinate)=>areHierarchiesEqual(coordinate, levelCoordinates));
        if (doesTupleContainHierarchy && tupleCoordinates[hierarchyIndex].namePath.length === levelIndex + 1) {
            collapsedAxis = _collapseInAxis(collapsedAxis, {
                tupleCoordinates,
                cube
            });
        }
    });
    return collapsedAxis;
};
