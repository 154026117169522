import { CloudDownloadOutlined } from "@ant-design/icons";
import { useIntl } from "react-intl";

import type { ApplicationMenuItem } from "@activeviam/activeui-sdk";

import { useIsExportingToPDF } from "../../IsExportingToPDFContext.js";

/**
 * {@link ApplicationMenuItem} used to export the file as a PDF.
 */
export const filePDFExportMenuItem: ApplicationMenuItem = {
  key: "pdf-export",
  useMenuItem: () => {
    const { formatMessage } = useIntl();
    const [isExportingToPDF, setIsExportingToPDF] = useIsExportingToPDF();

    return {
      icon: <CloudDownloadOutlined />,
      disabled: isExportingToPDF,
      label: formatMessage({ id: "exportToPDF" }),
      onClick: () => {
        if (!isExportingToPDF) {
          setIsExportingToPDF(true);
        }
      },
    };
  },
};
