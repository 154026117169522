import { addMeasure } from "./addMeasure.js";
import { removeMeasure } from "./removeMeasure.js";
/**
 * Returns a new {@link MdxSelect} corresponding to `mdx` where the given measure was moved to `measureIndex`.
 * Does not mutate mdx.
 */ export function moveMeasure(mdx, options) {
    const cleanMdx = removeMeasure(mdx, {
        ...options,
        shouldRemoveCalculatedMeasureExpression: false
    });
    return addMeasure(cleanMdx, options);
}
