import { _getPathsToMeasures } from "./internal/_getPathsToMeasures.js";
/**
 * Returns the position of measures within the dimensionality of `axis`.
 * Returns -1 if no measures are expressed on `axis`.
 */ export function getMeasuresPositionOnAxis(axis) {
    const pathsToAllMeasures = _getPathsToMeasures(axis);
    if (pathsToAllMeasures.length === 0) {
        return -1;
    }
    // If there are other levels on the axis, they are necessarily linked to the measures node through a function,
    // e.g. through a tuple, a set or a crossjoin.
    // Such a function node would be of the following form {arguments: [levelNode1, measuresNode, levelNode2]}.
    // In that case, pathsToAllMeasures[0] necessarily ends with [..., "arguments", <positionOfAllMeasuresWithinAxis>]
    const doesAxisContainOnlyMeasures = pathsToAllMeasures[0].length < 2;
    if (doesAxisContainOnlyMeasures) {
        return 0;
    }
    const lastTwoElementsInPath = pathsToAllMeasures[0].slice(pathsToAllMeasures[0].length - 2);
    // @ts-expect-error if an element in the path is the arguments array of a function,
    // then the next element in the path is the index within that array, so it is necessarily a number.
    return lastTwoElementsInPath[0] === "arguments" ? lastTwoElementsInPath[1] : 0;
}
