import { ContentNotFound } from "./components/ContentNotFound.js";
import { EditableDashboard } from "./components/EditableDashboard.js";
import { Home } from "./components/home/Home.js";

const homeRoutes = ["/", "recent", "folder/*"];
const dashboardRoutes = ["dashboard/:id", "dashboard/*"];

/**
 * The different pages of the application and the paths at which they can be accessed.
 */
export const routes = [
  ...homeRoutes.map((path) => ({
    path,
    element: <Home />,
  })),
  ...dashboardRoutes.map((path) => ({
    path,
    element: <EditableDashboard />,
  })),
  {
    path: "*",
    element: <ContentNotFound />,
  },
];
