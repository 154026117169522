import { UserOutlined } from "@ant-design/icons";

import type { ApplicationSubMenu } from "@activeviam/activeui-sdk";
import { useUser } from "@activeviam/activeui-sdk";

import { settingsMenuItem } from "./settingsMenuItem.js";
import { userLogoutMenuItem } from "./userLogoutMenuItem.js";

/**
 * {@link ApplicationSubMenu} containing {@link ApplicationMenuItem}s corresponding to the current user, such as logout.
 */
export const userSubMenu: ApplicationSubMenu = {
  key: "user-submenu",
  useMenuItem: () => {
    const { username } = useUser();
    return {
      icon: <UserOutlined />,
      label: username,
    };
  },
  children: [settingsMenuItem, userLogoutMenuItem],
};
