import { produce } from "immer";
import { get as _get, set as _set } from "lodash-es";
import { createMeasureCompoundIdentifier } from "./createMeasureCompoundIdentifier.js";
import { _addExpressionToAxis } from "./internal/_addExpressionToAxis.js";
import { _getPathsToMeasures } from "./internal/_getPathsToMeasures.js";
import { isACompoundIdentifierRepresentingAMeasure } from "./isACompoundIdentifierRepresentingAMeasure.js";
import { isMdxFunction } from "./isMdxFunction.js";
const defaultAxis = "COLUMNS";
/**
 * Returns a new {@link MdxSelect} corresponding to `mdx` where the given measure was added.
 * Does not mutate mdx.
 */ export function addMeasure(mdx, { cube , measureName , measureIndex , shouldCreateNonEmptyAxis , axisName  }) {
    const measureCompoundIdentifier = createMeasureCompoundIdentifier(measureName);
    const pathsToMeasures = _getPathsToMeasures(mdx);
    if (pathsToMeasures.length === 0) {
        const addedExpression = {
            arguments: [
                measureCompoundIdentifier
            ],
            name: "{}",
            elementType: "Function",
            syntax: "Braces"
        };
        return _addExpressionToAxis(mdx, {
            addedExpression,
            shouldCreateNonEmptyAxis,
            axisName: axisName ?? defaultAxis,
            cube
        });
    }
    return produce(mdx, (draft)=>{
        pathsToMeasures.forEach((path)=>{
            // Forced to use `get` because the path is dynamic.
            // eslint-disable-next-line atoti-ui/no-lodash-get
            const nodeHoldingMeasures = _get(draft, path);
            if (isMdxFunction(nodeHoldingMeasures, "{}")) {
                const position = measureIndex ?? nodeHoldingMeasures.arguments.length;
                // Add to the set if it already exists.
                nodeHoldingMeasures.arguments.splice(position, 0, measureCompoundIdentifier);
            } else if (isACompoundIdentifierRepresentingAMeasure(nodeHoldingMeasures)) {
                // Create a set otherwise.
                _set(draft, path, {
                    arguments: !measureIndex || measureIndex > 0 ? [
                        nodeHoldingMeasures,
                        measureCompoundIdentifier
                    ] : [
                        measureCompoundIdentifier,
                        nodeHoldingMeasures
                    ],
                    name: "{}",
                    elementType: "Function",
                    syntax: "Braces"
                });
            } else {
                throw new Error(`Cannot add measure at path ${JSON.stringify(path)}. This is likely an Atoti UI bug.`);
            }
        });
    });
}
