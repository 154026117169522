import { last as _last } from "lodash-es";
import { DimensionNotFoundError, HierarchyNotFoundError } from "@activeviam/data-model";
/**
 * Returns the specific {@link MdxCompoundIdentifier} type corresponding to `unknownCompoundIdentifier`.
 *
 * This is useful to get a more precise and standardized information on a compound identifier, that is
 * abstracted away from the compound identifier's syntax.
 *
 * Known limits:
 * Ideally, the `path` for a MdxMemberCompoundIdentifier would be the same as the `namePath` that
 * is given for members in a cellset, e.g. the full member path such as [AllMember, LegalEntityA, BusinessUnitA].
 * However, on the client side it is impossible to infer the full path with some syntaxes.
 *
 * E.g. [Currency].[EUR] or [Booking].[Desk].[BusinessUnit].[BusinessUnitA] are valid syntaxes
 * (as long as there is a unique member with that name on that level), but it is impossible to infer
 * the full path since the parent members of BusinessUnitA are not known.
 */ export function getSpecificCompoundIdentifier(compoundIdentifier, { cube , shouldThrowOnMissingHierarchy  }) {
    if (compoundIdentifier.type !== "unknown") {
        // This is already a specific type of compound identifier.
        // Do not do any extra work.
        return compoundIdentifier;
    }
    const identifierValues = compoundIdentifier.identifiers.map((identifier)=>identifier.value);
    let dimension = Object.values(cube.dimensions).find(({ name  })=>name.toLowerCase() === identifierValues[0].toLowerCase());
    if (identifierValues.length === 1) {
        if (dimension) {
            // CompoundIdentifier with the following form: [MyDimension]
            return {
                ...compoundIdentifier,
                type: "dimension",
                dimensionName: dimension.name
            };
        }
        // CompoundIdentifier with the following form: [MyNamedSet]
        return {
            ...compoundIdentifier,
            type: "namedSet",
            setName: identifierValues[0]
        };
    }
    if (identifierValues[0].toLowerCase() === "measures") {
        // CompoundIdentifier with one of the following forms:
        // - [Measures].[myMeasure]
        // - [Measures].[Measures].[myMeasure]
        // - [Measures].[Measures].[Measures].[myMeasure]
        return {
            ...compoundIdentifier,
            type: "measure",
            // The parsing of an empty string in a CompoundIdentifier, e.g. [Measures].[], would throw an error.
            // So all identifier values are necessary defined.
            // That and the check on the number of identifiers ensure that there is at least another defined identifier.
            measureName: _last(identifierValues)
        };
    }
    let numberOfMatchedIdentifiers = 0;
    let hierarchy = undefined;
    let level = undefined;
    // 1. Try to infer the hierarchy from the first identifier.
    if (dimension) {
        // The first identifier was matched with a dimension.
        // So try to match the second identifier with one of the hierarchies of that dimension.
        // This will be successful when the CompoundIdentifier starts with [MyDim].[MyHier].[...].
        numberOfMatchedIdentifiers++;
        hierarchy = Object.values(dimension.hierarchies).find(({ name  })=>name.toLowerCase() === identifierValues[1].toLowerCase());
    } else {
        // The first identifier could not be matched with a dimension.
        // However, the following syntax is supported: [MyHier].[...].
        // So try to match the first identifier with a hierarchy.
        for (const cubeDimension of Object.values(cube.dimensions)){
            for (const cubeHierarchy of Object.values(cubeDimension.hierarchies)){
                if (cubeHierarchy.name.toLowerCase() === identifierValues[0].toLowerCase()) {
                    dimension = cubeDimension;
                    hierarchy = cubeHierarchy;
                }
            }
        }
    }
    if (hierarchy) {
        numberOfMatchedIdentifiers++;
    }
    if (!dimension && !hierarchy) {
        throw new DimensionNotFoundError(identifierValues[0], cube.name);
    }
    if (dimension && hierarchy && numberOfMatchedIdentifiers === 2 && identifierValues.length === 2) {
        // CompoundIdentifier with the following form: [MyDimension].[MyHierarchy]
        return {
            ...compoundIdentifier,
            type: "hierarchy",
            dimensionName: dimension.name,
            hierarchyName: hierarchy.name
        };
    }
    // 2. Try to infer the level from the first identifiers.
    if (hierarchy && dimension && numberOfMatchedIdentifiers === 2) {
        // The first two identifiers were matched respectively with a dimension and a hierarchy.
        // Try to match the third identifier with a level of that hierarchy.
        level = Object.values(hierarchy.levels).find(({ name  })=>name.toLowerCase() === identifierValues[2].toLowerCase());
    } else if (dimension && !hierarchy) {
        // The first identifier was matched with a dimension, but the second one
        // could not be matched with a hierarchy.
        // Since the syntax [DimensionName].[LevelName].[...] is supported,
        // try to match the second identifier with a level.
        for (const cubeHierarchy of Object.values(dimension.hierarchies)){
            for (const cubeLevel of Object.values(cubeHierarchy.levels)){
                if (cubeLevel.name.toLowerCase() === identifierValues[1].toLowerCase()) {
                    hierarchy = cubeHierarchy;
                    level = cubeLevel;
                }
            }
        }
    } else if (hierarchy && numberOfMatchedIdentifiers === 1) {
        // The first identifier could not be matched with a dimension,
        // but was matched with a hierarchy.
        // Since the syntax [HierarchyName].[LevelName].[...] is supported,
        // try to match the second identifier with a level.
        level = Object.values(hierarchy.levels).find(({ name  })=>name.toLowerCase() === identifierValues[1].toLowerCase());
    }
    if (level) {
        numberOfMatchedIdentifiers++;
    }
    if (dimension && hierarchy && level && numberOfMatchedIdentifiers === identifierValues.length) {
        // CompoundIdentifier with one of the following forms:
        // - [MyDimension].[MyHierarchy].[MyLevel]
        // - [MyDimension].[MyLevel]
        // - [MyHierarchy].[MyLevel]
        return {
            ...compoundIdentifier,
            type: "level",
            dimensionName: dimension.name,
            hierarchyName: hierarchy.name,
            levelName: level.name
        };
    }
    // 3. At this stage, the CompoundIdentifier represents a member.
    // Infer the level from the member path, if necessary.
    if (dimension && !hierarchy) {
        // In cases where [dimension].[hierarchy]... syntax is known, throw here to indicate the hierarchy is not found on the cube.
        if (shouldThrowOnMissingHierarchy) {
            throw new HierarchyNotFoundError({
                dimensionName: dimension.name,
                hierarchyName: identifierValues[1]
            }, cube.name);
        }
        const defaultHierarchyName = dimension.defaultHierarchy;
        const defaultHierarchy = dimension.hierarchies[defaultHierarchyName];
        hierarchy = defaultHierarchy;
    }
    const memberPath = identifierValues.slice(numberOfMatchedIdentifiers);
    let effectiveLevelIndex = memberPath.length - 1;
    if (level) {
        // The following syntax is supported:
        // [Booking].[Desk].[BusinessUnit].[BusinessUnitA].[DeskA].[0]
        // In that case, at this stage "BusinessUnit" has been matched with `level`.
        // However, the effective level is BookId.
        const matchedLevelIndex = hierarchy.levels[level.name].index;
        effectiveLevelIndex += matchedLevelIndex;
    }
    const levelNames = Object.keys(hierarchy.levels);
    effectiveLevelIndex = Math.max(0, effectiveLevelIndex);
    effectiveLevelIndex = Math.min(effectiveLevelIndex, levelNames.length - 1);
    const effectiveLevelName = levelNames[effectiveLevelIndex];
    return {
        ...compoundIdentifier,
        type: "member",
        dimensionName: dimension.name,
        hierarchyName: hierarchy.name,
        levelName: effectiveLevelName,
        path: memberPath
    };
}
