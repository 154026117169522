import { set as _set } from "lodash-es";
// TODO: unit test
/**
 * Replaces the node at `path` in `mdx` by null.
 * {@link _cleanupMdx} should be called after this function, in order to propagate the effects of removing the node.
 *
 * @remarks
 * Mutates mdx: this function is made to be called from within a call to {produce} from "immer".
 */ export const _nullifyNodeAtPath = (mdx, path)=>{
    _set(mdx, path, null);
};
