import { getHierarchy } from "@activeviam/data-model";
import { getLevels } from "./getLevels.js";
import { getMemberCoordinates } from "./getMemberCoordinates.js";
import { getSpecificCompoundIdentifier } from "./getSpecificCompoundIdentifier.js";
import { isMdxCompoundIdentifier } from "./isMdxCompoundIdentifier.js";
import { isMdxFunction } from "./isMdxFunction.js";
import { traverseMdx } from "./traverseMdx.js";
/**
 * If `mdx` represents a level, returns its coordinates.
 * Returns `undefined` otherwise.
 */ function getLevelCoordinates(mdx, cube) {
    if (!mdx || !isMdxCompoundIdentifier(mdx)) {
        return undefined;
    }
    const specificCompoundIdentifier = getSpecificCompoundIdentifier(mdx, {
        cube
    });
    if (specificCompoundIdentifier.type !== "level") {
        return undefined;
    }
    return {
        dimensionName: specificCompoundIdentifier.dimensionName,
        hierarchyName: specificCompoundIdentifier.hierarchyName,
        levelName: specificCompoundIdentifier.levelName
    };
}
/**
 * If `descendants` represents a member expanded down to a deeper level within its hierarchy through a Descendants function, returns its coordinates.
 * Returns `undefined` otherwise.
 */ function getMemberExpandedThroughDescendants(descendants, cube) {
    const [firstArgument, secondArgument] = descendants.arguments;
    const singleMemberNode = isMdxFunction(firstArgument, "{}") && firstArgument.arguments.length === 1 ? firstArgument.arguments[0] : firstArgument;
    const memberCoordinates = getMemberCoordinates(singleMemberNode, {
        cube,
        shouldThrowOnInvalidMember: false
    });
    const toLevel = getLevelCoordinates(secondArgument, cube);
    if (memberCoordinates && toLevel) {
        return {
            memberCoordinates,
            toLevel
        };
    }
    return undefined;
}
/**
 *
 * If `crossjoin` represents a tuple expanded through a Crossjoin function, returns its coordinates.
 * Returns `undefined` otherwise.
 */ function getTupleExpandedThroughCrossjoin(crossjoin, cube) {
    const tupleCoordinates = [];
    const crossjoinArgs = crossjoin.arguments;
    let indexInCrossjoin = 0;
    for(; indexInCrossjoin < crossjoinArgs.length; indexInCrossjoin++){
        const crossjoinArg = crossjoinArgs[indexInCrossjoin];
        const memberCoordinates = getMemberCoordinates(crossjoinArg, {
            cube,
            shouldThrowOnInvalidMember: false
        });
        if (!memberCoordinates) {
            break;
        }
        tupleCoordinates.push(memberCoordinates);
    }
    if (indexInCrossjoin === 0) {
        // There are no explicit members in the crossjoin.
        // It does not represent an expanded tuple.
        return undefined;
    }
    const _nextCrossjoinArgAfterExplicitMembers = crossjoinArgs[indexInCrossjoin];
    const nextCrossjoinArgAfterExplicitMembers = isMdxFunction(_nextCrossjoinArgAfterExplicitMembers, "AddCalculatedMembers") ? _nextCrossjoinArgAfterExplicitMembers.arguments[0] : _nextCrossjoinArgAfterExplicitMembers;
    if (isMdxFunction(nextCrossjoinArgAfterExplicitMembers, "Descendants")) {
        const expandedMember = getMemberExpandedThroughDescendants(nextCrossjoinArgAfterExplicitMembers, cube);
        if (expandedMember) {
            tupleCoordinates.push(expandedMember.memberCoordinates);
            return {
                tupleCoordinates,
                toLevel: expandedMember.toLevel
            };
        }
    }
    const toLevel = getLevels(nextCrossjoinArgAfterExplicitMembers, {
        cube
    })[0];
    return toLevel ? {
        tupleCoordinates,
        toLevel
    } : undefined;
}
/**
 * If a tuple includes a total it is not counted as an expanded tuple.
 */ function doesTupleIncludeATotal(tupleCoordinates, cube) {
    return tupleCoordinates.some((memberCoordinates)=>memberCoordinates.dimensionName !== "Measures" && !getHierarchy(memberCoordinates, cube).slicing && memberCoordinates.namePath.length === 1 && memberCoordinates.namePath[0] === "AllMember");
}
/**
 * Evaluates `callback` on each node representing an expanded tuple in `mdx`.
 * If `callback` returns `false`, then the iteration is stopped.
 */ export function forEachExpandedTuple(mdx, cube, callback) {
    traverseMdx(mdx, (node, path)=>{
        if (!isMdxFunction(node, "union")) {
            return;
        }
        for(let indexInUnion = 0; indexInUnion < node.arguments.length; indexInUnion++){
            const _unionArg = node.arguments[indexInUnion];
            const unionArg = isMdxFunction(_unionArg, "AddCalculatedMembers") ? _unionArg.arguments[0] : _unionArg;
            let expandedTuple;
            if (isMdxFunction(unionArg, "Descendants")) {
                // Represents an expanded member of a single (non-measure) hierarchy, e.g. "LegalEntityA"
                const expandedMember = getMemberExpandedThroughDescendants(unionArg, cube);
                if (expandedMember) {
                    expandedTuple = {
                        tupleCoordinates: [
                            expandedMember.memberCoordinates
                        ],
                        toLevel: expandedMember.toLevel
                    };
                }
            }
            if (isMdxFunction(unionArg, "Crossjoin")) {
                // Represents an expanded tuple of several hierarchies (e.g. "USD/LegalEntityA")
                expandedTuple = getTupleExpandedThroughCrossjoin(unionArg, cube);
            }
            if (expandedTuple && !doesTupleIncludeATotal(expandedTuple.tupleCoordinates, cube)) {
                const shouldBreak = callback({
                    ...expandedTuple,
                    path: [
                        ...path,
                        "arguments",
                        indexInUnion
                    ]
                }) === false;
                if (shouldBreak) {
                    return false;
                }
            }
        }
        return;
    });
}
