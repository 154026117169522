import { get as _get } from "lodash-es";
import { isMdxFunction } from "../isMdxFunction.js";
import { _mdxFunctionsMetaData } from "./_mdxFunctionsMetaData.js";
/**
 * A set-like expression in MDX can be characterized by the hierarchies its underlying members belong to, also referred to as "dimensionality".
 * Some MDX functions change this dimensionality.
 * For instance, Crossjoin(Currency.Members, Desk.Members) will yield tuples of [Currency, Desk] (for instance USD/DeskA).
 * Whereas its individual arguments would have respectively yielded members of [Currency] and [Desk].
 */ function doesFunctionRepresentTheSameHierarchiesAsItsNthArgument(functionName, argumentIndex) {
    const functionMetaData = _mdxFunctionsMetaData[functionName.toLowerCase()];
    if (!functionMetaData) {
        return false;
    }
    switch(functionMetaData.dimensionality){
        case "common":
            {
                return true;
            }
        case "of-parsed-arg":
        case "of-first-arg":
            {
                return argumentIndex === 0;
            }
        case "of-second-arg":
            {
                return argumentIndex === 1;
            }
        case "joined":
        case "none":
        default:
            {
                return false;
            }
    }
}
/**
 * Returns the highest parent node representing the same set of hierarchies as the node at `path` in `mdx`.
 */ export const _getHighestParentRepresentingTheSameHierarchies = (mdx, path, { shouldStopAtUnion  } = {
    shouldStopAtUnion: false
})=>{
    // Forced to use `get` because path is dynamic.
    const currentNode = path.length === 0 ? mdx : _get(mdx, path);
    const currentNodeAsMatch = {
        match: currentNode,
        path
    };
    if (path.length === 0) {
        return currentNodeAsMatch;
    }
    const pathToGrandParent = path.slice(0, -2);
    // Forced to use `get` because pathToGrandParent is dynamic.
    // eslint-disable-next-line atoti-ui/no-lodash-get
    const grandParent = pathToGrandParent.length === 0 ? mdx : _get(mdx, pathToGrandParent);
    if (!isMdxFunction(grandParent)) {
        return currentNodeAsMatch;
    }
    const functionName = grandParent.name;
    if (shouldStopAtUnion && functionName.toLowerCase() === "union") {
        return currentNodeAsMatch;
    }
    const argumentIndex = Number(path[path.length - 1]);
    if (!doesFunctionRepresentTheSameHierarchiesAsItsNthArgument(functionName, argumentIndex)) {
        return currentNodeAsMatch;
    }
    return _getHighestParentRepresentingTheSameHierarchies(mdx, pathToGrandParent, {
        shouldStopAtUnion
    });
};
