import { DoubleLeftOutlined, DoubleRightOutlined } from "@ant-design/icons";
import { CSSProperties, FC, Fragment, PropsWithChildren } from "react";

import { useTheme } from "@activeviam/activeui-sdk";

interface CollapsibleProps {
  /**
   * The horizontal title, visible when the panel is expanded.
   */
  expandedTitle: JSX.Element | string;
  /**
   * The vertical title, visible when the panel is collapsed.
   */
  collapsedTitle: JSX.Element | string;
  isExpanded: boolean | undefined;
  isDisabled?: boolean;
  onToggled: (isExpanded: boolean) => void;
  style?: CSSProperties;
}

const titleHeight = 20; // px

/**
 * A component that wraps a collapsible component, allowing to toggle between collapsed and expanded.
 */
export const Collapsible: FC<PropsWithChildren<CollapsibleProps>> = ({
  children,
  isDisabled,
  isExpanded,
  expandedTitle,
  collapsedTitle,
  onToggled,
  style,
}): JSX.Element => {
  const theme = useTheme();

  return isExpanded ? (
    <Fragment>
      <div
        onClick={() => onToggled(false)}
        style={{
          backgroundColor: theme.alternateBackgroundColor,
          borderBottom: `1px solid ${theme.grayScale[5]}`,
          display: "flex",
          justifyContent: "space-between",
          padding: 8,
          alignItems: "center",
          height: titleHeight,
          cursor: "pointer",
          color: theme.textColor,
          ...style,
        }}
      >
        <div
          style={{
            color: theme.textColor,
            textTransform: "uppercase",
            fontSize: "11px",
            width: "100%",
          }}
        >
          {expandedTitle}
        </div>
        <div
          style={{
            color: theme.textColor,
          }}
        >
          {!isDisabled && <DoubleLeftOutlined />}
        </div>
      </div>
      <div style={{ height: `calc(100% - ${titleHeight}px)` }}>{children}</div>
    </Fragment>
  ) : (
    <div onClick={() => onToggled(true)} style={{ height: "100%" }}>
      <div
        style={{
          backgroundColor: theme.alternateBackgroundColor,
          borderBottom: "1px solid",
          borderRight: "1px solid",
          borderColor: theme.cellBorderColor,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: 20,
          color: theme.textColor,
        }}
      >
        <div style={{ color: theme.textColor, cursor: "pointer" }}>
          <DoubleRightOutlined />
        </div>
      </div>
      <div
        style={{
          borderRight: `1px solid ${theme.cellBorderColor}`,
          borderColor: theme.cellBorderColor,
          backgroundColor: theme.alternateBackgroundColor,
          height: "100%",
          whiteSpace: "nowrap",
          textAlign: "center",
          cursor: "pointer",
        }}
      >
        {collapsedTitle}
      </div>
    </div>
  );
};
