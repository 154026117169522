import { omit as _omit } from "lodash-es";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  DashboardContent,
  DashboardMetaData,
  DashboardState,
  UserName,
  UserRole,
  serializeDashboardState,
  useContentClient,
  useTree,
} from "@activeviam/activeui-sdk";
import { useNotifyStatus } from "@activeviam/application-state";

import { useIsSavingDashboardAs } from "../IsSavingDashboardAsContext.js";
import { getDashboardThumbnailDataUrl } from "../utils/getDashboardThumbnailDataUrl.js";

/**
 * React Hook returning a function allowing to "save as" the currently loaded dashboard.
 * The function redirects the user to the new dashboard, if it succeeded.
 */
export function useSaveDashboardAs(): ({
  dashboard,
  name,
  pathToParentFolder,
  owners,
  readers,
}: {
  dashboard: DashboardState;
  name: string;
  pathToParentFolder: string[];
  owners: (UserName | UserRole)[];
  readers: (UserName | UserRole)[];
}) => Promise<string | undefined> {
  const dashboardsTree = useTree("dashboard");
  const navigate = useNavigate();
  const contentClient = useContentClient();
  const dispatch = useDispatch();
  const notifyStatus = useNotifyStatus();
  const [, setIsSavingDashboardAs] = useIsSavingDashboardAs();

  return async ({
    dashboard,
    name,
    pathToParentFolder,
    owners = [],
    readers = [],
  }: {
    dashboard: DashboardState;
    name: string;
    pathToParentFolder: string[];
    owners: (UserName | UserRole)[];
    readers: (UserName | UserRole)[];
  }): Promise<string | undefined> => {
    if (dashboardsTree) {
      // Disable the "Unsaved changes" popup momentarily.
      setIsSavingDashboardAs(true);
      const dryDashboard = serializeDashboardState(dashboard);
      const thumbnailDataUrl = await getDashboardThumbnailDataUrl();
      const promise = contentClient.createFile<
        DashboardContent,
        DashboardMetaData
      >({
        content: _omit(dryDashboard, "name"),
        metaData: { name },
        owners,
        pathToParentFolder,
        readers,
        type: "dashboard",
        thumbnailDataUrl,
      });
      await notifyStatus({ action: "saving", promise });

      const dashboardId = await promise;
      await contentClient.loadTree("dashboard");

      // Keep track of the state of the dashboard that was just saved.
      // Prevents the "Unsaved changes" popup from opening if the user leaves the page after saving, unless she makes new changes.
      dispatch({ type: "dashboardSaved", dashboardState: dashboard });
      // Navigate to the URL of the dashboard that was just saved.
      // Allows the user to share or bookmark this URL.
      // Must be done BEFORE the call to setIsSavingDashboardAs(false);
      // Otherwise, the dashboard would be unnecessarily unloaded and reloaded.
      navigate(`/dashboard/${dashboardId}`);
      // Re-enable the "Unsaved changes" popup.
      setIsSavingDashboardAs(false);

      return dashboardId;
    }

    return undefined;
  };
}
