import { getHierarchy } from "@activeviam/data-model";
import { isMdxCompoundIdentifier } from "./isMdxCompoundIdentifier.js";
import { isMdxLiteral } from "./isMdxLiteral.js";
/**
 * Returns the index of the deepest level yielded by a Descendants node.
 */ export function getIndexOfDeepestLevelExpressedInDescendantsNode({ descendantsNode , dimensionName , hierarchyName , indexOfDeepestLevelExpressedInInputSet , cube  }) {
    // See the multiple possible combinations: https://learn.microsoft.com/en-us/sql/mdx/descendants-mdx?view=sql-server-ver16
    const [, levelOrDistance, scopeFlag] = descendantsNode.arguments;
    const isLevelOrDistanceSpecified = levelOrDistance && !(isMdxLiteral(levelOrDistance) && levelOrDistance.type === "PLACE_HOLDER");
    const lowerCaseScopeFlagValue = scopeFlag && isMdxLiteral(scopeFlag) ? scopeFlag.value.toLowerCase() : undefined;
    const hierarchy = getHierarchy({
        dimensionName,
        hierarchyName
    }, cube);
    const levelNames = Object.keys(hierarchy.levels);
    if (lowerCaseScopeFlagValue && lowerCaseScopeFlagValue.includes("after")) {
        return levelNames.length - 1;
    }
    if (!isLevelOrDistanceSpecified && !lowerCaseScopeFlagValue) {
        return levelNames.length - 1;
    }
    if (!isLevelOrDistanceSpecified && lowerCaseScopeFlagValue === "leaves") {
        // The LEAVES flag yields leaf members when no level or distance is specified.
        return levelNames.length - 1;
    }
    let effectiveLevelIndex = indexOfDeepestLevelExpressedInInputSet;
    if (levelOrDistance) {
        if (isMdxCompoundIdentifier(levelOrDistance)) {
            const levelNameInFunction = levelOrDistance.identifiers[2].value;
            effectiveLevelIndex = levelNames.findIndex((levelName)=>levelName === levelNameInFunction);
        } else {
            if (!isMdxLiteral(levelOrDistance)) {
                throw new Error(`Unsupported elementType "${levelOrDistance.elementType}". The second argument of a Descendants function is expected to be a literal (elementType="Literal").`);
            }
            effectiveLevelIndex += parseInt(levelOrDistance.value, 10);
        }
    }
    if (lowerCaseScopeFlagValue === "before") {
        effectiveLevelIndex--;
    }
    effectiveLevelIndex = Math.max(0, indexOfDeepestLevelExpressedInInputSet, effectiveLevelIndex);
    effectiveLevelIndex = Math.min(effectiveLevelIndex, levelNames.length - 1);
    return effectiveLevelIndex;
}
