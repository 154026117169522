/**
 * Returns the {@link DrillthroughColumn}s for the given cube.
 * Fetches them from ActivePivot if they were not already fetched previously.
 */ export function getCubeDrillthroughColumns(activePivotClient, cubeName, { signal  } = {}) {
    const { drillthroughColumns , isLoading  } = activePivotClient.drillthroughColumns[cubeName] ?? {};
    if (!isLoading && drillthroughColumns) {
        return Promise.resolve(drillthroughColumns);
    }
    return new Promise((resolve, reject)=>{
        const listener = (drillthroughColumns, metaData)=>{
            if (!metaData.isLoading) {
                activePivotClient.removeDrillthroughColumnsListener(cubeName, listener);
                if (metaData.error) {
                    reject(metaData.error);
                } else {
                    resolve(drillthroughColumns);
                }
            }
        };
        activePivotClient.addDrillthroughColumnsListener(cubeName, listener);
        void activePivotClient.loadDrillthroughColumns(cubeName, {
            signal
        });
        if (signal) {
            signal.onabort = ()=>{
                activePivotClient.removeDrillthroughColumnsListener(cubeName, listener);
                reject(signal.reason);
            };
        }
    });
}
