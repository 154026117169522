import { areHierarchiesEqual } from "@activeviam/data-model";
import { getHierarchies } from "../getHierarchies.js";
/**
 * Returns the index of the axis in `mdx` which contains the hierarchies expressed in `tupleCoordinates`.
 * Returns -1 if no such axis is found.
 */ export function _getIndexOfAxisContainingHierarchiesExpressedInTuple(mdx, { cube , tupleCoordinates  }) {
    return mdx.axes.findIndex((axis)=>{
        // TODO https://activeviam.atlassian.net/browse/UI-8798
        // This is dangerous until getHierarchies returns only the ones contributing to the dimensionality of the input MDX.
        const hierarchiesInAxis = getHierarchies(axis, {
            cube
        });
        return tupleCoordinates.every((memberCoordinates, indexOfHierarchyInAxis)=>areHierarchiesEqual(memberCoordinates, hierarchiesInAxis[indexOfHierarchyInAxis]));
    });
}
