import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import {
  DashboardContent,
  DashboardMetaData,
  DashboardState,
  getContentRecordAtPath,
  getDashboardState,
  getHasUnsavedChanges,
  getOwnersAndReadersForNewFile,
  serializeDashboardState,
  useContentClient,
  usePermission,
  useTree,
  useUser,
} from "@activeviam/activeui-sdk";
import { useNotifyStatus } from "@activeviam/application-state";

import { usePathToParentFolder } from "../PathToParentFolderContext.js";
import { useIsDashboardSaved } from "../components/useIsDashboardSaved.js";
import { getDashboardThumbnailDataUrl } from "../utils/getDashboardThumbnailDataUrl.js";
import { useSaveDashboardAs } from "./useSaveDashboardAs.js";

/**
 * React Hook returning a function allowing to save the currently loaded dashboard
 */
export function useSaveDashboard() {
  const dashboardsTree = useTree("dashboard");
  const dashboardFromState = useSelector(getDashboardState);
  const hasUnsavedChanges = useSelector(getHasUnsavedChanges);
  const contentClient = useContentClient();
  const pathToParentFolder = usePathToParentFolder();
  const notifyStatus = useNotifyStatus();
  const dispatch = useDispatch();
  const isDashboardSaved = useIsDashboardSaved();
  const saveDashboardAs = useSaveDashboardAs();
  const { username } = useUser();
  const [canShare] = usePermission("canShare");
  const { formatMessage } = useIntl();

  const { id } = useParams<{ id?: string }>();

  const isReadOnly =
    id &&
    !getContentRecordAtPath({
      tree: dashboardsTree,
      path: [...(pathToParentFolder ?? []), id],
    })?.entry.canWrite;

  const [canManageContent] = usePermission("canManageContent");

  return async (dashboard?: DashboardState) => {
    // It's handy to not have to provide `dashboard` when calling this function, but there is one exception. See Header.tsx
    const _dashboard = dashboard === undefined ? dashboardFromState : dashboard;

    if (!_dashboard) {
      return;
    }

    if (isDashboardSaved) {
      if (
        id &&
        dashboardsTree &&
        (hasUnsavedChanges || dashboard !== undefined) &&
        !isReadOnly &&
        canManageContent
      ) {
        const { name, ...dryDashboard } = serializeDashboardState(_dashboard);
        if (pathToParentFolder) {
          const thumbnailDataUrl = await getDashboardThumbnailDataUrl();

          const promise = contentClient.updateFile<
            DashboardContent,
            DashboardMetaData
          >({
            content: dryDashboard,
            id,
            metaData: {
              name: name || formatMessage({ id: "aui.defaultDashboardName" }),
            },
            pathToParentFolder,
            type: "dashboard",
            thumbnailDataUrl,
          });
          dispatch({ type: "dashboardSaved", dashboardState: _dashboard });
          await notifyStatus({ action: "saving", promise });
          await contentClient.loadTree("dashboard");
        }
      }
    } else {
      // Saving a dashboard for the first time.
      const folderRecord = pathToParentFolder
        ? getContentRecordAtPath({
            tree: dashboardsTree,
            path: pathToParentFolder,
          })
        : dashboardsTree;

      if (!pathToParentFolder || !folderRecord) {
        return;
      }

      const {
        owners: parentFolderOwners,
        readers: parentFolderReaders,
      } = folderRecord.entry;

      const { owners, readers } = getOwnersAndReadersForNewFile({
        currentUserName: username,
        parentFolderOwners,
        parentFolderReaders,
        isParentFolderRoot: pathToParentFolder.length === 0,
        canShare,
      });

      await saveDashboardAs({
        dashboard: _dashboard,
        name:
          _dashboard.name || formatMessage({ id: "aui.defaultDashboardName" }),
        pathToParentFolder,
        owners,
        readers,
      });
    }
  };
}
