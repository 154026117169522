import { LogoutOutlined } from "@ant-design/icons";
import { App as AntdApp, ConfigProvider } from "antd";
import { map as _map, uniq as _uniq } from "lodash-es";
import { useContext } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import {
  ApplicationMenuItem,
  getHasUnsavedChanges,
  getPrefixedLocalStorageKey,
  useActivePivotClients,
  useContentClient,
  useLogout,
  usePermission,
  useSetting,
  useUser,
} from "@activeviam/activeui-sdk";

/**
 * {@link ApplicationMenuItem} allowing a user to log out.
 */
export const userLogoutMenuItem: ApplicationMenuItem = {
  key: "logout",
  useMenuItem: () => {
    const { formatMessage } = useIntl();
    const { modal } = AntdApp.useApp();
    const dispatch = useDispatch();
    const { username } = useUser();
    const activePivotClients = useActivePivotClients();
    const contentClient = useContentClient();
    const [isUnsavedChangesPopupEnabled] = useSetting(
      "unsavedChangesPopup.isEnabled",
    );
    const hasUnsavedChanges = useSelector(getHasUnsavedChanges);
    const [canManageContent] = usePermission("canManageContent");

    const { getPopupContainer } = useContext(ConfigProvider.ConfigContext);

    const logout = useLogout();

    // TODO 5.2.0 https://activeviam.atlassian.net/browse/UI-8575
    // Replace the legacy default logout logic by the one from @activeviam/authenticated-clients-and-user.
    const defaultLogout = async () => {
      // Remove the authentication token on the client side to trigger the login popup when the page is reloaded.
      localStorage.removeItem(getPrefixedLocalStorageKey("token"));

      // Notify each server that the user is logging out, so that they can clear their corresponding stateful session.
      const serverUrls = _uniq([
        contentClient.url,
        ..._map(activePivotClients, "url"),
      ]);
      await Promise.allSettled(
        serverUrls.map((url) =>
          fetch(`${url}/logout`, {
            // The `credentials` property is set to `"include"` so that the user credentials info are forwarded to the server even for a cross-origin call.
            // This allows the server to know which user it must logout.
            credentials: "include",
          }),
        ),
      );

      // Reload the page to ensure that any client side state is flushed.
      window.location.reload();
    };

    const unloadDashboardAndLogout = async () => {
      // Clear the dashboard past state so that a second "Unsaved changes" popup does not appear.
      dispatch({ type: "dashboardUnloaded" });
      await (logout ?? defaultLogout)();
    };

    const handleClicked = async () => {
      if (
        hasUnsavedChanges &&
        isUnsavedChangesPopupEnabled &&
        canManageContent
      ) {
        modal.confirm({
          title: `Logout ${username}`,
          content: `Logging out ‘${username}’ will lose any unsaved work.`,
          onOk: unloadDashboardAndLogout,
          getContainer: getPopupContainer,
        });
      } else {
        await unloadDashboardAndLogout();
      }
    };

    return {
      icon: <LogoutOutlined />,
      label: formatMessage({ id: "logout" }),
      onClick: handleClicked,
    };
  },
};
