import { css } from "@emotion/react";
import { FC, useEffect } from "react";
import { useAsyncAbortable } from "react-async-hook";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

import {
  DataModelClickListenersProvider,
  IsPresentingProvider,
  LoadingBackground,
  NoResponseError,
  PluginsProvider,
  getDashboardState,
  getIsPresenting,
  useActivePivotClients,
  useContentClient,
  useTree,
  useUser,
} from "@activeviam/activeui-sdk";
import {
  getIsSettingsPopupVisible,
  useNotifyStatus,
} from "@activeviam/application-state";
import { NotifyLoadingStatusProvider } from "@activeviam/status-notifications";
import { WidgetLoadingOverlayProvider } from "@activeviam/widget";

import { ConfigurationContext } from "../ConfigurationContext.js";
import { IsExportingToPDFProvider } from "../IsExportingToPDFContext.js";
import { IsSavingDashboardAsProvider } from "../IsSavingDashboardAsContext.js";
import { PathToParentFolderProvider } from "../PathToParentFolderContext.js";
import { useConfigurationFilteredBasedOnPermissions } from "../hooks/useConfigurationFilteredBasedOnPermissions.js";
import { useIsLocationDashboard } from "../hooks/useIsLocationDashboard.js";
import { ConnectionLostBanner } from "./ConnectionLostBanner.js";
import Header from "./header/Header.js";
import { SettingsPopup } from "./header/SettingsPopup.js";

const App: FC = () => {
  const { username, userRoles } = useUser();
  const contentClient = useContentClient();
  const activePivotClients = useActivePivotClients();
  const isPresenting = useSelector(getIsPresenting);
  const dashboardState = useSelector(getDashboardState);
  const dashboardsTree = useTree("dashboard");
  const isLocationDashboard = useIsLocationDashboard();
  const notifyStatus = useNotifyStatus();

  // Fetch:
  // - the data model
  // - the saved dashboards and widgets
  // - the user activity and settings
  useAsyncAbortable(
    async (signal) => {
      const initTrees = async () => {
        await Promise.all(
          (["dashboard", "widget", "filter"] as const).map((contentType) =>
            contentClient.loadTree(contentType, { signal }),
          ),
        );
      };

      const initDataModels = async () => {
        await Promise.all(
          Object.entries(activePivotClients).map(
            async ([serverKey, activePivotClient]) => {
              try {
                await activePivotClient.loadDataModel({ signal });
              } catch (error) {
                if (error instanceof NoResponseError) {
                  // This can happen if the server is down.
                  // This situation is safely handled by children components.
                  // This error is swallowed in order to let the user go on even if one server is down.
                } else {
                  throw error;
                }
              }
            },
          ),
        );
      };

      const initSettings = async () => {
        await contentClient.loadSettings(username, { signal });
      };

      const initActivity = async () => {
        await contentClient.loadActivity(username, { signal });
      };

      await Promise.all([
        initTrees(),
        initDataModels(),
        initActivity(),
        initSettings(),
      ]);
    },
    [username, contentClient, activePivotClients],
  );

  const { loading: arePermissionsLoading } = useAsyncAbortable(
    (signal) => contentClient.loadPermissions(username, userRoles, { signal }),
    [contentClient, username, userRoles],
  );

  const filteredConfiguration = useConfigurationFilteredBasedOnPermissions();

  const dashboardName = dashboardState?.name;
  useEffect(() => {
    document.title = dashboardName ?? filteredConfiguration.applicationName;
  }, [dashboardName, filteredConfiguration.applicationName]);

  const isSettingsPopupVisible = useSelector(getIsSettingsPopupVisible);

  // A loading background is displayed until the permissions are loaded to ensure users don't see controls they shouldn't have access to.
  if (arePermissionsLoading) {
    return <LoadingBackground />;
  }

  return (
    /* Override the full configuration coming from extension registrations with the version of it only including what the user is allowed to see. */
    <ConfigurationContext.Provider value={filteredConfiguration}>
      <WidgetLoadingOverlayProvider
        value={filteredConfiguration.widgetLoadingOverlay}
      >
        <PluginsProvider value={filteredConfiguration.pluginRegistry}>
          <IsPresentingProvider value={isPresenting}>
            <IsExportingToPDFProvider>
              <IsSavingDashboardAsProvider>
                <DataModelClickListenersProvider>
                  <PathToParentFolderProvider dashboardsTree={dashboardsTree}>
                    <NotifyLoadingStatusProvider value={notifyStatus}>
                      <div
                        css={css`
                          position: absolute;
                          margin: auto;
                          left: 0;
                          right: 0;
                          width: fit-content;
                          z-index: 1;
                        `}
                      >
                        <ConnectionLostBanner />
                      </div>
                      <div
                        css={css`
                          position: absolute;
                          height: 100%;
                          width: 100%;
                        `}
                      >
                        {/*
                         * The number of menu item hooks is not the same in the home page and in a dashboard.
                         * To avoid errors linked to the rule of hooks, the header is unmounted/remounted when the user switches between these pages.
                         */}
                        <Header key={isLocationDashboard ? 0 : 1} />
                        {/* Either the home page or a dashboard. */}
                        <SettingsPopup isVisible={isSettingsPopupVisible} />
                        <Outlet />
                      </div>
                    </NotifyLoadingStatusProvider>
                  </PathToParentFolderProvider>
                </DataModelClickListenersProvider>
              </IsSavingDashboardAsProvider>
            </IsExportingToPDFProvider>
          </IsPresentingProvider>
        </PluginsProvider>
      </WidgetLoadingOverlayProvider>
    </ConfigurationContext.Provider>
  );
};

export default App;
