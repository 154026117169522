import { getHierarchy } from "@activeviam/data-model";
import { createLevelCompoundIdentifier } from "./createLevelCompoundIdentifier.js";
import { _addExpressionToAxis } from "./internal/_addExpressionToAxis.js";
import { _getDescendantsFunction } from "./internal/_getDescendantsFunction.js";
import { quote } from "./quote.js";
const _getMembersFunction = (dimensionName, hierarchyName, levelName)=>({
        arguments: [
            createLevelCompoundIdentifier({
                dimensionName,
                hierarchyName,
                levelName
            })
        ],
        elementType: "Function",
        name: "Members",
        syntax: "Property"
    });
/**
 * Returns the MdxExpression representing the level.
 * If the hierarchy is slicing, returns a .Members function.
 * Otherwise, returns a Descendants() function, in order to yield subtotals.
 */ export const getLevelExpression = ({ dimensionName , hierarchyName , levelName  }, cube, doesIncludeCalculatedMembers)=>{
    const hierarchy = getHierarchy({
        dimensionName,
        hierarchyName
    }, cube);
    const levelIndex = hierarchy.levels[levelName].index;
    if (levelIndex === -1) {
        throw new Error(`Could not find level "${levelName}" in hierarchy "${quote(dimensionName, hierarchyName)}" in cube "${cube.name}"`);
    }
    if (hierarchy.slicing) {
        const membersFunction = _getMembersFunction(dimensionName, hierarchyName, levelName);
        return doesIncludeCalculatedMembers ? {
            elementType: "Function",
            syntax: "Function",
            name: "AddCalculatedMembers",
            arguments: [
                membersFunction
            ]
        } : membersFunction;
    } else {
        return _getDescendantsFunction({
            dimensionName,
            hierarchyName,
            levelIndex,
            doesIncludeCalculatedMembers,
            cube
        });
    }
};
/**
 * Returns a new {@link MdxSelect} corresponding to `mdx` where the given level was added.
 */ export function addLevel(mdx, { cube , dimensionName , hierarchyName , levelName , axisName , indexOnAxis , shouldCreateNonEmptyAxis , doesIncludeCalculatedMembers  }) {
    const addedExpression = getLevelExpression({
        dimensionName,
        hierarchyName,
        levelName
    }, cube, doesIncludeCalculatedMembers);
    return _addExpressionToAxis(mdx, {
        axisName,
        cube,
        indexOnAxis,
        shouldCreateNonEmptyAxis,
        addedExpression
    });
}
