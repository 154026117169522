import { FilterOutlined } from "@ant-design/icons";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";

import type { ApplicationMenuItem } from "@activeviam/activeui-sdk";

import { MenuItemLabel } from "./MenuItemLabel.js";

const filtersKey = "filters";
/**
 * {@link ApplicationMenuItem} used to open a filters section to apply saved filters.
 */
export const insertSavedFiltersMenuItem: ApplicationMenuItem = {
  key: "saved-filters",
  useMenuItem: () => {
    const dispatch = useDispatch();
    const { formatMessage } = useIntl();

    return {
      icon: <FilterOutlined />,
      label: (
        <MenuItemLabel
          title={formatMessage({
            id: "aui.dataVisualization.insertSavedFilters",
          })}
          shortcutKey={filtersKey}
        />
      ),
      onClick: () => {
        dispatch({
          type: "resourcesPanelChanged",
          resourcesPanelKey: filtersKey,
        });
      },
    };
  },
};
