import { map as _map, orderBy as _orderBy } from "lodash-es";
import { FC, Fragment, useCallback, useEffect, useRef, useState } from "react";
import { useAsyncAbortable } from "react-async-hook";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { usePrevious } from "react-use";
import { ActionCreators } from "redux-undo";

import {
  CenteredTitle,
  Dashboard,
  DashboardMetaData,
  DashboardState,
  deserializeDashboardState,
  findContentRecords,
  getActivePageKey,
  getDashboardState,
  getIsDeferred,
  getSelectedLeafKey,
  useActivity,
  useContentClient,
  useTree,
} from "@activeviam/activeui-sdk";
import { isBrowserOnMacOS } from "@activeviam/utils";

import { useConfiguration } from "../ConfigurationContext.js";
import { useIsExportingToPDF } from "../IsExportingToPDFContext.js";
import { useIsSavingDashboardAs } from "../IsSavingDashboardAsContext.js";
import { usePathToParentFolder } from "../PathToParentFolderContext.js";
import { useIsDashboardReadOnly } from "../hooks/useIsDashboardReadOnly.js";
import { triggerNativePrint } from "../utils/triggerNativePrint.js";
import { ContentNotFound } from "./ContentNotFound.js";
import { useInitialDashboardState } from "./useInitialDashboardState.js";
import { useIsDashboardSaved } from "./useIsDashboardSaved.js";

const iframeName = "printIframe";

const maximumNumberOfRecentlyOpenedDashboard = 10;

interface DashboardBasedOnIdProps {
  id?: string;
  /**
   * The number of times that the user created a new unsaved dashboard since they connected to the application.
   * Allows to unload their previous dashboard and load a new one when the user creates a new dashboard while being on an unsaved dashboard.
   */
  unsavedDashboardCounter?: string;
}

/*
 * Renders a dashboard with an initial state corresponding to the saved dashboard with the given id.
 */
export const DashboardBasedOnId: FC<DashboardBasedOnIdProps> = ({
  id,
  unsavedDashboardCounter,
}) => {
  const previousId = usePrevious(id);
  const contentClient = useContentClient();
  const containerRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const dashboardsTree = useTree("dashboard");
  const [
    recentlyOpenedDashboards,
    updateRecentlyOpenedDashboards,
  ] = useActivity("recentlyOpenedDashboards");
  const { formatMessage } = useIntl();
  const pathToParentFolder = usePathToParentFolder();

  const isDashboardSaved = useIsDashboardSaved();

  // The name of the dashboard is stored under /structure on the Content Server.
  // In order to fetch it, the path to the dashboard's parent folder is required.
  // If the dashboards tree was loaded but no folder was found containing the target dashboard, then the dashboard cannot be loaded.
  const isDashboardContentMissing =
    isDashboardSaved &&
    dashboardsTree !== null &&
    pathToParentFolder === undefined;

  const isDeferred = useSelector(getIsDeferred);
  const activePageKey = useSelector(getActivePageKey);
  const selectedLeafKey = useSelector(getSelectedLeafKey);
  const dashboardState = useSelector(getDashboardState);
  const [isExportingToPDF, setIsExportingToPDF] = useIsExportingToPDF();
  const {
    isBrandSignatureVisible,
    initialDashboardPageState,
  } = useConfiguration();
  const [isDashboardLoaded, setIsDashboardLoaded] = useState(false);

  const isDashboardReadOnly = useIsDashboardReadOnly();
  useEffect(() => {
    if (isDashboardReadOnly) {
      dispatch({
        type: "isPresentingChanged",
        isPresenting: true,
      });
    }
  }, [dispatch, isDashboardReadOnly]);

  useEffect(() => {
    if (isExportingToPDF && isDashboardLoaded) {
      requestAnimationFrame(() => {
        setTimeout(() => {
          triggerNativePrint(
            containerRef.current?.innerHTML || "",
            iframeName,
            () => setIsExportingToPDF(false),
          );
          // This has to be done so that React children are rerendered and a new frame is painted before the export is triggered.
          // We cannot omit the setTimeout's 2nd argument, because widgets using `useDebouncedComponentSize` are displayed asynchronously after the page becomes visible.
          // TODO Find a better solution to ensure the widgets are resized before the export starts.
          // See https://github.com/orgs/activeviam/teams/activeui/discussions/34.
        }, 150);
      });
    }
  }, [isExportingToPDF, isDashboardLoaded, setIsExportingToPDF]);

  // Undo/Redo on (Ctrl/Cmd) + Z/Y
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event[isBrowserOnMacOS ? "metaKey" : "ctrlKey"]) {
        if (event.key === "z") {
          // Only fire the first event. "event.repeat" returns true if the given key is being held down.
          // https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/repeat
          if (!event.repeat) {
            dispatch(ActionCreators.undo());
          }
        } else if (event.key === "y") {
          // On macOS chrome, Cmd + Y also opens up the history tab.
          if (isBrowserOnMacOS) {
            event.preventDefault();
          }
          if (!event.repeat) {
            dispatch(ActionCreators.redo());
          }
        }
      }
    };
    document.body.addEventListener("keydown", handleKeyDown);
    return () => {
      document.body.removeEventListener("keydown", handleKeyDown);
    };
  }, [dispatch]);

  const initialDashboardState = useInitialDashboardState();

  // Fetch the dashboard.
  useAsyncAbortable(
    async (signal) => {
      let loadedDashboardState = initialDashboardState;

      if (isDashboardSaved) {
        if (!pathToParentFolder || !id) {
          return;
        }

        const dashboardState = await contentClient.fetchFile<
          DashboardState<"serialized">,
          DashboardMetaData
        >(
          {
            id,
            pathToParentFolder,
            type: "dashboard",
          },
          { signal },
        );

        loadedDashboardState = deserializeDashboardState(dashboardState);
      }
      dispatch({
        type: "dashboardLoaded",
        dashboardState: loadedDashboardState,
      });
    },
    [
      id,
      contentClient,
      dispatch,
      pathToParentFolder,
      initialDashboardState,
      isDashboardSaved,
    ],
  );

  // Load initial dashboard state if a new unsaved dashboard is created.
  useEffect(() => {
    if (unsavedDashboardCounter) {
      dispatch({
        type: "dashboardLoaded",
        dashboardState: initialDashboardState,
      });
    }
  }, [initialDashboardState, unsavedDashboardCounter, dispatch]);

  const [isSavingDashboardAs] = useIsSavingDashboardAs();

  /**
   * The hook below runs its logic in a clean up.
   * It must rely on a ref for isSavingDashboard.
   * Otherwise this information would be outdated in its scope.
   * Making it consider the dashboard as not in the process of being saved when in fact it is.
   * Leading it to get mistakenly unloaded.
   */
  const isSavingDashboardAsRef = useRef(isSavingDashboardAs);
  isSavingDashboardAsRef.current = isSavingDashboardAs;

  useEffect(
    () => () => {
      if (!isSavingDashboardAsRef.current) {
        dispatch({ type: "dashboardUnloaded" });
      }
    },
    // `id` and `unsavedDashboardCounter` must be in the dependencies list, even though unused in this hook.
    // Indeed, when the user navigates from dashboard A to dashboard B or unsaved/1 to unsaved/2, then dashboard A or unsaved/1 must be unloaded.
    // Otherwise, there are bugs such as:
    // - https://activeviam.atlassian.net/browse/UI-8124
    // - https://activeviam.atlassian.net/browse/UI-8276
    [id, isSavingDashboardAsRef, unsavedDashboardCounter, dispatch],
  );

  // When the user opens a dashboard: update the recently opened dashboards in the user activity.
  useEffect(() => {
    if (
      dashboardsTree &&
      id &&
      id !== previousId &&
      !isDashboardContentMissing
    ) {
      const accessibleDashboards = findContentRecords(
        dashboardsTree,
        _map(recentlyOpenedDashboards, "id"),
      );

      const updatedRecentlyOpenedDashboards = _orderBy(
        [
          ...(recentlyOpenedDashboards || []).filter(
            (accessLog) =>
              accessLog.id !== id &&
              accessibleDashboards[accessLog.id] !== undefined &&
              accessibleDashboards[accessLog.id].node.entry.canRead,
          ),
          {
            id,
            lastOpened: Date.now(),
          },
        ],
        "lastOpened",
        "desc",
      );

      updateRecentlyOpenedDashboards(
        updatedRecentlyOpenedDashboards.slice(
          0,
          maximumNumberOfRecentlyOpenedDashboard,
        ),
      );
    }
  }, [
    id,
    dispatch,
    previousId,
    recentlyOpenedDashboards,
    updateRecentlyOpenedDashboards,
    dashboardsTree,
    isDashboardContentMissing,
  ]);

  const handleDashboardChanged = useCallback(
    (newDashboardState: DashboardState) => {
      dispatch({
        type: "dashboardUpdated",
        dashboardState: newDashboardState,
      });
    },
    [dispatch],
  );

  const handleActivePageChange = useCallback(
    (newActivePageKey: string) => {
      dispatch({ type: "activePageChanged", pageKey: newActivePageKey });
    },
    [dispatch],
  );

  const handleIsDeferredChanged = useCallback(
    (newIsDeferred: boolean) => {
      dispatch({ type: "isDeferredChanged", isDeferred: newIsDeferred });
    },
    [dispatch],
  );

  const handleSelectedWidgetChange = useCallback(
    (newSelectedLeafKey: string) => {
      if (activePageKey !== undefined) {
        dispatch({
          type: "widgetSelected",
          pageKey: activePageKey,
          leafKey: newSelectedLeafKey,
        });
      }
    },
    [dispatch, activePageKey],
  );

  const handleDashboardLoaded = () => {
    setIsDashboardLoaded(true);
  };

  if (isDashboardContentMissing && !isSavingDashboardAs) {
    return (
      <ContentNotFound
        message={formatMessage(
          { id: "error.dashboardNotFound" },
          {
            id,
          },
        )}
      />
    );
  }

  if (!dashboardState) {
    return (
      <CenteredTitle level={2}>
        {formatMessage({ id: "loadingYourDashboard" })}
      </CenteredTitle>
    );
  }

  return (
    <Fragment>
      <Dashboard
        ref={containerRef}
        activePageKey={activePageKey}
        state={dashboardState}
        isExportingToPDF={isExportingToPDF}
        onActivePageChange={handleActivePageChange}
        onChange={handleDashboardChanged}
        onWidgetSelected={handleSelectedWidgetChange}
        isBrandSignatureVisible={isBrandSignatureVisible}
        selectedLeafKey={selectedLeafKey}
        style={{ height: "100%" }}
        initialPageState={initialDashboardPageState}
        isDeferred={isDeferred}
        onIsDeferredChange={handleIsDeferredChanged}
        onLoaded={handleDashboardLoaded}
      />
      {isExportingToPDF && (
        <iframe
          width="0"
          height="0"
          name={iframeName}
          title={iframeName}
        ></iframe>
      )}
    </Fragment>
  );
};
