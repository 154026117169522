// See https://docs.activeviam.com/products/atoti/server/5.11.3/docs/mdx/mdx_functions/#mdx-functions
export const functionKeywords = [
    "Abs",
    "ActiveMeasure",
    "AddCalculatedMembers",
    "Aggregate",
    "AllMembers",
    "Ancestor",
    "Ancestors",
    "Ascendants",
    "Avg",
    "BottomCount",
    "BottomPercent",
    "BottomSum",
    "CalculationCurrentPass",
    "CalculationPassValue",
    "Caption",
    "CASE Statement",
    "CDate",
    "Children",
    "CInt",
    "CLng",
    "ClosingPeriod",
    "CoalesceEmpty",
    "Correlation",
    "Count",
    "Cousin",
    "Covariance",
    "CovarianceN",
    "Crossjoin",
    "CStr",
    "Current",
    "CurrentMember",
    "CurrentOrdinal",
    "DataMember",
    "DateAdd",
    "DateDiff",
    "DatePart",
    "Day",
    "DefaultMember",
    "Descendants",
    "Dimension",
    "Dimensions",
    "Distinct",
    "DistinctCount",
    "Divide",
    "DrilldownLevel",
    "DrilldownLevelBottom",
    "DrilldownLevelTop",
    "DrilldownMember",
    "DrilldownMemberBottom",
    "DrilldownMemberTop",
    "DrillupLevel",
    "DrillupMember",
    "Error",
    "Except",
    "Exists",
    "Extract",
    "Filter",
    "FirstChild",
    "FirstSibling",
    "Generate",
    "Head",
    "Hierarchize",
    "Hierarchy",
    "IIf",
    "Instr",
    "Intersect",
    "Is_DataMember",
    "Is_UnknownMember",
    "IsAncestor",
    "IsDate",
    "IsEmpty",
    "IsLeaf",
    "IsNull",
    "IsSibling",
    "Item",
    "Lag",
    "LastChild",
    "LastPeriods",
    "LastSibling",
    "LCase",
    "Lead",
    "Left",
    "Level",
    "Levels",
    "LinRegIntercept",
    "LinRegPoint",
    "LinRegR2",
    "LinRegSlope",
    "LinRegVariance",
    "LookupCube",
    "Max",
    "MeasureGroupMeasures",
    "Median",
    "Members",
    "MemberValue",
    "Min",
    "Month",
    "Mtd",
    "Name",
    "NextMember",
    "NonEmpty",
    "Now",
    "OpeningPeriod",
    "Order",
    "Ordinal",
    "ParallelPeriod",
    "Parent",
    "PeriodsToDate",
    "PrevMember",
    "Properties",
    "Qtd",
    "Rank",
    "Rgb",
    "Right",
    "Round",
    "Siblings",
    "Stdev",
    "StdevP",
    "Stdev",
    "StdevP",
    "StripCalculatedMembers",
    "StrToMember",
    "Subset",
    "Sum",
    "Tail",
    "TopCount",
    "TopPercent",
    "TopSum",
    "UCase",
    "Union",
    "UniqueName",
    "Unorder",
    "Value",
    "Var",
    "Variance",
    "VarianceP",
    "VarP",
    "VisualTotals",
    "Weekday",
    "Wtd",
    "Year",
    "Ytd"
];
export const kpiKeywords = [
    "KPIGoal",
    "KPIStatus",
    "KPITrend",
    "KPIValue"
];
export const argumentKeywords = [
    "ALL",
    "DIMENSIONS",
    "IGNORE",
    "END",
    "ASC",
    "DESC",
    "BASC",
    "BDESC",
    "POST",
    "AFTER",
    "BEFORE",
    "BEFORE_AND_AFTER",
    "LEAVES",
    "SELF",
    "SELF_AND_AFTER",
    "SELF_AND_BEFORE",
    "SELF_BEFORE_AFTER",
    "EXCLUDEEMPTY",
    "INCLUDEEMPTY",
    "RECURSIVE",
    "INCLUDE_CALC_MEMBERS",
    "CONSTRAINED",
    "TYPED"
];
export const memberPropertyKeywords = [
    "ANCESTOR_CAPTION",
    "CATALOG_NAME",
    "CHILDREN_CARDINALITY",
    "CUBE_NAME",
    "DESCRIPTION",
    "DISPLAY_INFO",
    "DIMENSION_UNIQUE_NAME",
    "HIERARCHY_UNIQUE_NAME",
    "IS_DATAMEMBER",
    "IS_PLACEHOLDERMEMBER",
    "IS_UNKNOWNMEMBER",
    "LEVEL_NUMBER",
    "LEVEL_UNIQUE_NAME",
    "MEMBER_CAPTION",
    "MEMBER_GUID",
    "MEMBER_KEY",
    "MEMBER_NAME",
    "MEMBER_ORDINAL",
    "MEMBER_TYPE",
    "MEMBER_VALUE",
    "MEMBER_UNIQUE_NAME",
    "PARENT_COUNT",
    "PARENT_LEVEL",
    "PARENT_UNIQUE_NAME",
    "SCHEMA_NAME",
    "SKIPPED_LEVELS",
    "UNARY_OPERATOR",
    "UNIQUE_NAME"
];
export const axisKeywords = [
    "CHAPTERS",
    "COLUMNS",
    "PAGES",
    "ROWS",
    "SECTIONS",
    "SLICER"
];
export const cellPropertyKeywords = [
    "BACK_COLOR",
    "CELL_ORDINAL",
    "FONT_FLAGS",
    "FONT_NAME",
    "FONT_SIZE",
    "FORE_COLOR",
    "FORMAT",
    "FORMAT_STRING",
    "FORMATTED_VALUE",
    "VALUE"
];
export const booleanOperatorKeywords = [
    "AND",
    "IS",
    "NOT",
    "OR",
    "XOR"
];
export const booleanKeywords = [
    "FALSE",
    "NULL",
    "TRUE"
];
export const structuralKeywords = [
    "AS",
    "AXIS",
    "CASE",
    "CELL",
    "DIMENSION",
    "DRILLTHROUGH",
    "ELSE",
    "END",
    "EXISTING",
    "EMPTY",
    "FIRSTROW",
    "FROM",
    "MAXROWS",
    "MEMBER",
    "NON",
    "ON",
    "PROPERTIES",
    "RETURN",
    "SELECT",
    "SET",
    "THEN",
    "VISUAL",
    "WHEN",
    "WHERE",
    "WITH"
];
