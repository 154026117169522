import { CSSProperties, FC, MouseEvent } from "react";

import { useTheme } from "@activeviam/activeui-sdk";

interface LogoProps {
  onClick: (event: MouseEvent<HTMLDivElement>) => void;
  css?: CSSProperties;
}

const Logo: FC<LogoProps> = (props) => {
  const theme = useTheme();
  const logo = theme.isDark ? "logo-dark-theme" : "logo";

  return (
    <img
      onClick={props.onClick}
      style={{
        cursor: "pointer",
        ...props.css,
      }}
      alt="logo"
      src={`${logo}.svg`}
    />
  );
};

export default Logo;
