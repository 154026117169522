import { produce } from "immer";
import { areHierarchiesEqual } from "@activeviam/data-model";
import { _collapseInAxis } from "../collapse.js";
import { getExpandedTuples } from "../getExpandedTuples.js";
import { _cleanupMdx } from "./_cleanupMdx.js";
import { _getIndexOfAxisContainingHierarchy } from "./_getIndexOfAxisContainingHierarchy.js";
/**
 * Collapses every expanded member of the hierarchy given by `hierarchyCoordinates`.
 * Does not mutate `mdx`.
 */ export const _collapseMembersOfHierarchy = (mdx, hierarchyCoordinates, cube)=>{
    const axisIndex = _getIndexOfAxisContainingHierarchy(mdx, {
        ...hierarchyCoordinates,
        cube
    });
    if (axisIndex === -1) {
        return mdx;
    }
    const axis = mdx.axes[axisIndex];
    const expandedTuples = getExpandedTuples(axis, cube);
    let collapsedAxis = axis;
    expandedTuples.forEach(({ tupleCoordinates  })=>{
        const doesTupleContainHierarchy = tupleCoordinates.some((coordinate)=>areHierarchiesEqual(coordinate, hierarchyCoordinates));
        if (doesTupleContainHierarchy) {
            collapsedAxis = _collapseInAxis(collapsedAxis, {
                tupleCoordinates,
                cube
            });
        }
    });
    return produce(mdx, (draft)=>{
        draft.axes[axisIndex] = collapsedAxis;
        _cleanupMdx(draft, [
            "axes",
            axisIndex
        ], cube);
    });
};
