import { ClockCircleOutlined, FolderOutlined } from "@ant-design/icons";
import { css } from "@emotion/react";
import { FC, useMemo } from "react";
import { useIntl } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";

import {
  getContentRecordAtPath,
  useTheme,
  useTree,
} from "@activeviam/activeui-sdk";

import { useConfiguration } from "../../ConfigurationContext.js";
import { ContentNotFound } from "../ContentNotFound.js";
import { DashboardExplorer } from "./DashboardExplorer.js";
import { DashboardFoldersTree } from "./DashboardFoldersTree.js";
import { LoadingHomePageSkeleton } from "./LoadingHomePageSkeleton.js";
import { RecentlyOpened } from "./RecentlyOpened.js";

/**
 * Displays the application's homepage.
 */
export const Home: FC = () => {
  const { pathToHome } = useConfiguration();
  const dashboardsTree = useTree("dashboard");
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const pathToSelectedFolder = useMemo(() => {
    return pathname !== pathToHome && pathname !== "/recent"
      ? pathname
          .substring(1)
          .split("/")
          .filter((subpath) => subpath !== "folder")
      : [];
  }, [pathname, pathToHome]);

  const folder = useMemo(
    () =>
      getContentRecordAtPath({
        tree: dashboardsTree,
        path: pathToSelectedFolder,
      }),

    [dashboardsTree, pathToSelectedFolder],
  );

  const handlePathChanged = (newPath: string[]) => {
    const urlFolderPath = newPath.reduce((acc, current) => {
      return `${acc}/${current}`;
    }, "");
    navigate(`/folder${urlFolderPath}`);
  };

  const selectedStyle = {
    backgroundColor: theme.selectionColor,
    borderRight: `3px solid ${theme.primaryColor}`,
  };

  return (
    <div
      css={css`
        height: 100%;
        overflow: auto;
      `}
    >
      <div
        // The height of the space allocated to the content of the folder is 100% minus the height of the breadcrumb.
        css={css`
          height: calc(100% - 40px);
          display: flex;
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            height: 100%;
            width: 280px;
            flex-shrink: 0;
            background-color: ${theme.backgroundColor};
            filter: drop-shadow(0px 2px 8px ${theme.black}1A);
          `}
        >
          <div
            css={{
              height: 30,
              marginTop: 14,
              paddingRight: 16,
              paddingLeft: 6,
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              flexShrink: 0,
              ":hover": {
                color: theme.hoverColor,
              },
              ...(pathname === "/folder" ? selectedStyle : {}),
              color:
                pathname !== "/recent" ? theme.primaryColor : theme.textColor,
            }}
            onClick={() => navigate("/folder")}
          >
            <FolderOutlined style={{ padding: 5 }} />
            {formatMessage({ id: "aui.contentTree.folders" })}
          </div>
          <div
            css={css`
              flex-grow: 1;
              padding-left: 6px;
              overflow: auto;
            `}
          >
            <DashboardFoldersTree
              onPathToSelectedFolderChanged={(
                pathToSelectedFolder: string[],
              ) => {
                const urlFolderPath = pathToSelectedFolder.reduce(
                  (acc, current) => {
                    return `${acc}/${current}`;
                  },
                  "",
                );
                navigate(`/folder${urlFolderPath}`);
              }}
              pathToSelectedFolder={
                pathname !== "/recent" ? pathToSelectedFolder : undefined
              }
            />
          </div>
          <div
            style={{
              height: 30,
              flexShrink: 0,
              justifySelf: "flex-end",
              borderTop: `solid 1px ${theme.grayScale[3]}`,
              paddingRight: 16,
              paddingLeft: 6,
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
            css={{
              ":hover": {
                color: theme.hoverColor,
              },
              ...(pathname === "/recent" ? selectedStyle : {}),
              color:
                pathname === "/recent" ? theme.primaryColor : theme.textColor,
            }}
            onClick={() => navigate("/recent")}
          >
            <ClockCircleOutlined style={{ padding: 5 }} />
            {formatMessage({ id: "recentlyOpened" })}
          </div>
        </div>
        <div
          css={css`
            height: 100%;
            flex-grow: 1;
          `}
        >
          {!dashboardsTree ? (
            <LoadingHomePageSkeleton />
          ) : pathname === "/recent" ? (
            <RecentlyOpened />
          ) : folder && folder.entry.canRead ? (
            <DashboardExplorer
              pathToParentFolder={pathToSelectedFolder}
              // If `dashboardsTree` is defined, then the folder is defined and valid
              // since the path has been validated with `getValidPathOfIds`.
              folder={folder!}
              onPathToFolderChanged={handlePathChanged}
            />
          ) : (
            <ContentNotFound
              message={formatMessage(
                { id: "error.folderNotFound" },
                { path: pathToSelectedFolder.join("/") },
              )}
            />
          )}
        </div>
      </div>
    </div>
  );
};
