import { useIntl } from "react-intl";

import type { ApplicationSubMenu } from "@activeviam/activeui-sdk";

import { useIsDashboardReadOnly } from "../../hooks/useIsDashboardReadOnly.js";
import { useIsLocationDashboard } from "../../hooks/useIsLocationDashboard.js";
import { insertSavedFiltersMenuItem } from "./insertSavedFiltersMenuItem.js";
import { insertWidgetsMenuItem } from "./insertWidgetsMenuItem.js";

/**
 * {@link ApplicationSubMenu} containing menu items for insert actions, such as insert saved widgets/filters.
 */
export const insertSubMenu: ApplicationSubMenu = {
  key: "insert-submenu",
  useMenuItem: () => {
    const { formatMessage } = useIntl();
    const isLocationDashboard = useIsLocationDashboard();
    const isDashboardReadOnly = useIsDashboardReadOnly();

    if (!isLocationDashboard || isDashboardReadOnly) {
      return undefined;
    }
    return {
      label: formatMessage({ id: "insert" }),
    };
  },
  children: [insertWidgetsMenuItem, insertSavedFiltersMenuItem],
};
