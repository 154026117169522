import { fetchAndThrowIfNeeded } from "@activeviam/client";
/**
 * Asynchronously returns the list of {@link DrillthroughColumn} available for each cube on the server at `serverUrl`.
 */ export async function fetchDrillthroughColumnsV8({ url , pivotServiceVersion , cubeName , requestInit  }) {
    const { restPath  } = pivotServiceVersion;
    const response = await fetchAndThrowIfNeeded(`${url}${restPath}/cube/query/mdx/drillthroughheaders`, {
        ...requestInit,
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            ...requestInit?.headers
        },
        body: `{"mdx": "DRILLTHROUGH SELECT FROM [${cubeName}]"}`
    });
    return response.json();
}
