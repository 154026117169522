import { findDescendant } from "../findDescendant.js";
import { isMdxFunction } from "../isMdxFunction.js";
import { _isNumericExpression } from "./_isNumericExpression.js";
/**
 * Returns a reference to the first order function encountered in `mdx`.
 * Only matches an Order based on `measureName`, if given.
 */ export const _findFirstOrderFunction = (mdx, { measureName , kpiName  } = {})=>{
    const isSortNumeric = Boolean(measureName);
    // `match` is an MdxFunction because findDescendant is called with matchFn = `_isMdxFunction`.
    // eslint-disable-next-line
    return findDescendant(mdx, (descendant)=>{
        if (!isMdxFunction(descendant, "order")) {
            return false;
        }
        const isSecondArgumentOfOrderANumericExpression = _isNumericExpression(descendant.arguments[1], {
            measureName
        });
        return !isSortNumeric || isSecondArgumentOfOrderANumericExpression;
    });
};
