import { areHierarchiesEqual } from "@activeviam/data-model";
import { getHierarchies } from "../getHierarchies.js";
import { isMdxFunction } from "../isMdxFunction.js";
import { traverseMdx } from "../traverseMdx.js";
/**
 * Returns the Crossjoin functions in `mdx`. Only matches those containing `hierarchies` (exclusively or not), if given.
 */ export const _findCrossjoins = (mdx, { cube , hierarchyCoordinates  })=>{
    const crossjoins = [];
    traverseMdx(mdx, (node, path)=>{
        if (isMdxFunction(node, "crossjoin")) {
            if (!hierarchyCoordinates || hierarchyCoordinates.length === 0) {
                crossjoins.push({
                    match: node,
                    path
                });
            } else {
                const hierarchiesInCrossjoin = getHierarchies(node, {
                    cube
                });
                if (hierarchiesInCrossjoin.length >= hierarchyCoordinates.length && hierarchyCoordinates.every(({ dimensionName , hierarchyName  }, i)=>areHierarchiesEqual(hierarchiesInCrossjoin[i], {
                        dimensionName,
                        hierarchyName
                    }))) {
                    crossjoins.push({
                        match: node,
                        path
                    });
                }
            }
        }
    });
    return crossjoins;
};
