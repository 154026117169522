import { get as _get } from "lodash-es";
import { areHierarchiesEqual, getHierarchy } from "@activeviam/data-model";
import { getIndexOfDeepestLevelExpressedInDescendantsNode } from "./getIndexOfDeepestLevelExpressedInDescendantsNode.js";
import { getLevelName } from "./getLevelName.js";
import { getSpecificCompoundIdentifier } from "./getSpecificCompoundIdentifier.js";
import { _findCompoundIdentifiers } from "./internal/_findCompoundIdentifiers.js";
import { _getHighestParentRepresentingTheSameHierarchies } from "./internal/_getHighestParentRepresentingTheSameHierarchies.js";
import { _isContributingToNodeDimensionality } from "./internal/_isContributingToNodeDimensionality.js";
import { isMdxCompoundIdentifier } from "./isMdxCompoundIdentifier.js";
import { isMdxFunction } from "./isMdxFunction.js";
import { isMdxLiteral } from "./isMdxLiteral.js";
/**
 * Returns the coordinates of every level used in `mdx`.
 * Only returns the levels matching `coordinates`, if given.
 */ export const findLevels = (mdx, cube, onlyMatchCoordinates)=>{
    const levels = [];
    const compoundIdentifiersSearchResults = _findCompoundIdentifiers(mdx, {
        cube
    });
    compoundIdentifiersSearchResults.forEach(({ match , path  })=>{
        const specificCompoundIdentifier = getSpecificCompoundIdentifier(match, {
            cube
        });
        if (specificCompoundIdentifier.type === "level" || specificCompoundIdentifier.type === "member" || specificCompoundIdentifier.type === "hierarchy") {
            const { dimensionName , hierarchyName  } = specificCompoundIdentifier;
            const hierarchy = getHierarchy({
                dimensionName,
                hierarchyName
            }, cube);
            let levelIndex = 0;
            if (specificCompoundIdentifier.type === "level" || specificCompoundIdentifier.type === "member") {
                const levelNameInCompoundIdentifier = specificCompoundIdentifier.levelName;
                levelIndex = hierarchy.levels[levelNameInCompoundIdentifier].index;
            }
            const { path: pathToHighestParentRepresentingTheSameHierarchy  } = _getHighestParentRepresentingTheSameHierarchies(mdx, path, {
                shouldStopAtUnion: true
            });
            // The parents of the compound identifiers can change the deepest target level requested by the query, for this hierarchy.
            for(let i = pathToHighestParentRepresentingTheSameHierarchy.length - 1; i < path.length; i++){
                // Forced to use `get` because path is dynamic.
                const parentPath = path.slice(0, i);
                // eslint-disable-next-line atoti-ui/no-lodash-get
                const parentNode = parentPath.length === 0 ? mdx : _get(mdx, parentPath);
                if (isMdxFunction(parentNode)) {
                    switch(parentNode.name.toLowerCase()){
                        case "currentmember":
                            // The 'CurrentMember' function is not taken into account as it might represent a member of any level.
                            return;
                        case "drilldownlevel":
                        case "drilldownlevelbottom":
                        case "drilldownleveltop":
                        case "drilldownmember":
                        case "drilldownmemberbottom":
                        case "drilldownmembertop":
                        case "children":
                        case "firstchild":
                        case "lastchild":
                            levelIndex++;
                            break;
                        case "drilluplevel":
                        case "parent":
                            levelIndex--;
                            break;
                        case "ancestor":
                        case "ancestors":
                            {
                                const secondArgument = parentNode.arguments[1];
                                if (isMdxCompoundIdentifier(secondArgument)) {
                                    const levelNameInFunction = secondArgument.identifiers[2].value;
                                    levelIndex = hierarchy.levels[levelNameInFunction].index;
                                } else {
                                    if (!isMdxLiteral(secondArgument)) {
                                        throw new Error(`Unsupported elementType "${secondArgument.elementType}". The second argument of an "${parentNode.name}" function is expected to be a literal (elementType="Literal").`);
                                    }
                                    levelIndex -= parseInt(secondArgument.value, 10);
                                }
                            }
                            break;
                        case "descendants":
                            levelIndex = getIndexOfDeepestLevelExpressedInDescendantsNode({
                                descendantsNode: parentNode,
                                dimensionName,
                                hierarchyName,
                                indexOfDeepestLevelExpressedInInputSet: levelIndex,
                                cube
                            });
                            break;
                        case "allmembers":
                        case "members":
                            levelIndex = specificCompoundIdentifier.type === "hierarchy" ? Object.values(hierarchy.levels).length - 1 : levelIndex;
                            break;
                        default:
                            break;
                    }
                }
            }
            levelIndex = Math.max(0, levelIndex);
            levelIndex = Math.min(levelIndex, Object.keys(hierarchy.levels).length - 1);
            const levelName = getLevelName(hierarchy, levelIndex);
            levels.push({
                dimensionName,
                hierarchyName,
                levelName,
                // Forced to use `get` because pathToHighestParentRepresentingTheSameLevel is dynamic.
                // eslint-disable-next-line atoti-ui/no-lodash-get
                match: _get(mdx, pathToHighestParentRepresentingTheSameHierarchy),
                path: pathToHighestParentRepresentingTheSameHierarchy
            });
        }
    });
    const levelsExplicitlyContributingToNodeDimensionality = levels.filter(({ match , path  })=>_isContributingToNodeDimensionality(mdx, path) && !isMdxFunction(match, "DefaultMember"));
    return onlyMatchCoordinates ? levelsExplicitlyContributingToNodeDimensionality.filter(({ dimensionName , hierarchyName , levelName  })=>onlyMatchCoordinates.some((coordinates)=>areHierarchiesEqual({
                dimensionName,
                hierarchyName
            }, coordinates) && (!("levelName" in coordinates) || levelName === coordinates.levelName))) : levelsExplicitlyContributingToNodeDimensionality;
};
