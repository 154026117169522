import { css } from "@emotion/react";
import {
  ConfigProvider as AntdConfigProvider,
  Tabs,
  theme as antdTheme,
} from "antd";
import { FC, Fragment, useEffect } from "react";
import { useIntl } from "react-intl";

import { useTheme } from "@activeviam/activeui-sdk";

import { useConfiguration } from "../../ConfigurationContext.js";
import { collapsedPanelWidth } from "../ResourcesAndToolsPanels.js";

const { useToken } = antdTheme;

type TabPosition = "left" | "top";

interface ToolsPanelProps {
  tabsPosition: TabPosition;
  onActiveToolKeyChanged: (activeToolKey: string) => void;
  activeToolKey?: string;
}

/**
 * Displays a menu with an icon per available tool (such as the fields or filters editors).
 * Allows users to switch from one tool to another.
 */
export const ToolsPanel: FC<ToolsPanelProps> = ({
  tabsPosition,
  onActiveToolKeyChanged,
  activeToolKey,
}) => {
  const { formatMessage } = useIntl();
  const { tools } = useConfiguration();
  const theme = useTheme();
  const { token: antdThemeTokens } = useToken();
  const shortcuts: { [key: string]: string } = tools.reduce((acc, tool) => {
    return tool.shortcut ? { ...acc, [tool.shortcut]: tool.key } : acc;
  }, {});

  useEffect(() => {
    const handleKeydown = (event: KeyboardEvent) => {
      const targetToolKey = shortcuts[event.key];
      if (event.altKey && targetToolKey && targetToolKey !== activeToolKey) {
        onActiveToolKeyChanged(targetToolKey);
      }
    };

    document.body.addEventListener("keydown", handleKeydown);
    return () => {
      document.body.removeEventListener("keydown", handleKeydown);
    };
  }, [activeToolKey, onActiveToolKeyChanged, shortcuts]);

  const items = tools.map((tool) => ({
    key: tool.key,
    label: (
      <Fragment>
        <span
          {...(tabsPosition === "top"
            ? { className: "anticon" }
            : {
                css: css`
                  border: 1px solid ${theme.grayScale[6]};
                  border-radius: ${antdThemeTokens.borderRadius}px;
                  padding: 5px 6px;
                  margin: 4px;
                `,
              })}
        >
          <tool.icon />
        </span>
        <span
          className="tab-item-label"
          css={css`
            padding-left: ${antdThemeTokens.padding}px;
          `}
        >
          {formatMessage({
            id: `aui.tool.${tool.key}.name`,
          })}
        </span>
      </Fragment>
    ),
  }));

  return (
    <AntdConfigProvider
      theme={{
        components: {
          Tabs: {
            margin: 4,
            marginSM: 0,
            paddingLG: 0,
          },
        },
      }}
    >
      <Tabs
        css={css`
          .ant-tabs-nav-operations {
            margin: 0 !important;
            padding: 0 !important;
            .ant-tabs-nav-more {
              display: flex;
              align-items: center;
            }
          }
          div.ant-tabs-tab-btn span.tab-item-label {
            display: none;
          }
        `}
        activeKey={activeToolKey}
        tabPosition={tabsPosition}
        items={items}
        onTabClick={onActiveToolKeyChanged}
        type={tabsPosition === "top" ? "card" : "line"}
        tabBarStyle={
          tabsPosition === "top"
            ? { marginTop: 6, paddingLeft: 8, height: 20 }
            : { marginTop: 6, minWidth: collapsedPanelWidth }
        }
      />
    </AntdConfigProvider>
  );
};
