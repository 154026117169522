import { useMemo } from "react";

import { DashboardPageState, DashboardState } from "@activeviam/activeui-sdk";

import { useConfiguration } from "../ConfigurationContext.js";

const defaultInitialDashboardPageState: Omit<DashboardPageState, "name"> = {
  content: {},
  layout: {
    children: [
      {
        leafKey: "0",
        size: 1,
      },
    ],
    direction: "row",
  },
};

/**
 * React hook returning the state of the dashboard used when the user creates a new dashboard.
 */
export function useInitialDashboardState(): DashboardState {
  const initialDashboardPageState =
    useConfiguration()?.initialDashboardPageState ??
    defaultInitialDashboardPageState;

  const initialDashboardState = useMemo((): DashboardState => {
    return {
      pages: {
        "p-0": { ...initialDashboardPageState, name: "Page 1" },
      },
      pagesOrder: ["p-0"],
      filters: [],
    };
  }, [initialDashboardPageState]);

  return initialDashboardState;
}
