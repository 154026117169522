import { LayoutFilled } from "@ant-design/icons";
import { css } from "@emotion/react";
import { Card } from "antd";
import { FC, MouseEvent } from "react";

import { DashboardMetaData, useTheme } from "@activeviam/activeui-sdk";
import { OnAfterSubmit } from "@activeviam/file-list";

import {
  fileCardCoverHeight,
  fileCardCoverWidth,
} from "../../utils/getDashboardThumbnailDataUrl.js";
import { FileCardContextMenuIcon } from "./FileCardContextMenuIcon.js";

export interface FileCardProps {
  id: string;
  metaData: DashboardMetaData;
  pathToParentFolder: string[];
  description: string;
  thumbnail?: string;
  onFileSelected?: (event: MouseEvent) => void;
  isSelected?: boolean;
  onFileDoubleClicked: () => void;
  onAfterSubmit: OnAfterSubmit;
}

/**
 * Clickable card used for accessing dashboards.
 */
export const FileCard: FC<FileCardProps> = (props) => {
  const theme = useTheme();
  const {
    description,
    id,
    metaData,
    pathToParentFolder,
    onFileDoubleClicked,
    onAfterSubmit,
  } = props;

  const caption = metaData.name;

  return (
    <div
      css={css`
        :hover {
          .context-menu-icon {
            opacity: 0.5;
          }
        }
        .context-menu-icon:hover {
          opacity: 1;
        }
      `}
    >
      <Card
        key={id}
        onClick={(event) => {
          if (props.onFileSelected) {
            props.onFileSelected(event);
          }
        }}
        onContextMenu={(event) => {
          if (props.onFileSelected && !props.isSelected) {
            props.onFileSelected(event);
          }
        }}
        onDoubleClick={onFileDoubleClicked}
        css={{
          ...(props.isSelected
            ? {
                backgroundColor: theme.selectionColor,
              }
            : {
                ":hover": {
                  backgroundColor: theme.grayScale[3],
                },
              }),
        }}
        style={{
          height: 220,
          width: 240,
          margin: "12px 12px 0 0",
          transition: "background-color 0.5s",
          cursor: "pointer",
        }}
        bodyStyle={{
          padding: 12,
          borderTop: `1px solid ${theme.cellBorderColor}`,
        }}
        cover={
          <div>
            <FileCardContextMenuIcon
              id={id}
              pathToParentFolder={pathToParentFolder}
              onAfterSubmit={onAfterSubmit}
            />
            {props.thumbnail ? (
              <img
                style={{
                  width: fileCardCoverWidth,
                  height: fileCardCoverHeight,
                  margin: 5,
                  marginBottom: 0,
                }}
                src={props.thumbnail}
                alt={`Thumbnail of dashboard '${caption}'`}
              />
            ) : (
              <div
                css={css`
                  display: flex !important;
                  align-items: center;
                  justify-content: center;
                  height: 150px;
                  font-size: 48px;
                  color: ${theme.grayScale[5]};
                `}
              >
                <LayoutFilled />
              </div>
            )}
          </div>
        }
      >
        <Card.Meta
          title={
            <span css={{ color: theme.textColor }} title={caption}>
              {caption}
            </span>
          }
          description={description}
        />
      </Card>
    </div>
  );
};
