import { useIntl } from "react-intl";

import type { ApplicationSubMenu } from "@activeviam/activeui-sdk";

import { useIsLocationDashboard } from "../../hooks/useIsLocationDashboard.js";
import { editRedoMenuItem } from "./editRedoMenuItem.js";
import { editResetMenuItem } from "./editResetMenuItem.js";
import { editUndoMenuItem } from "./editUndoMenuItem.js";

/**
 * {@link ApplicationSubMenu} containing menu items useful to edit the current dashboard, such as undo/redo.
 */
export const editSubMenu: ApplicationSubMenu = {
  key: "edit-submenu",
  useMenuItem: () => {
    const { formatMessage } = useIntl();
    const isLocationDashboard = useIsLocationDashboard();

    if (!isLocationDashboard) {
      return undefined;
    }
    return {
      label: formatMessage({ id: "edit" }),
    };
  },
  children: [editUndoMenuItem, editRedoMenuItem, editResetMenuItem],
};
