import {
  AMetaData,
  ContentRecord,
  getMetaData,
} from "@activeviam/content-client";

/**
 * Returns the captions of all parent folders of a dashboard whose direct parent folder is at the given `pathToParentFolder`.
 */
export function getCaptionsOfFolders(
  pathToParentFolder: string[],
  dashboardsTree: ContentRecord<AMetaData> | null,
): { caption?: string; id: string }[] {
  let node: ContentRecord<AMetaData> | null | undefined = dashboardsTree;
  const items: { caption?: string; id: string }[] = [];

  if (!node) {
    return items;
  }

  for (const id of pathToParentFolder) {
    node = node.children?.[id];

    // A node part of the `pathToParentFolder` can be missing when a folder that is part of the current path has been deleted.
    // This component can rerender, as a reaction to the dashboardsTree being updated by the deletion,
    // before the <Home/> component redirects to the first existing ancestor.
    if (!node) {
      return items;
    }
    const metaData = getMetaData(node, id);

    items.push({
      caption: metaData.name,
      id,
    });
  }
  return items;
}
