import { ShareAltOutlined } from "@ant-design/icons";
import { Fragment, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import type { ApplicationMenuItem } from "@activeviam/activeui-sdk";
import {
  getContentRecordAtPath,
  getDashboardState,
  useIsModalMounted,
  usePermission,
  useTree,
} from "@activeviam/activeui-sdk";
import { ShareModal } from "@activeviam/permissions";
import { Tooltip } from "@activeviam/utils-react";

import { usePathToParentFolder } from "../../PathToParentFolderContext.js";

/**
 * {@link ApplicationMenuItem} that allows a user to share the dashboard.
 */
export const shareMenuItem: ApplicationMenuItem = {
  key: "share-dashboard",
  useMenuItem: () => {
    const { formatMessage } = useIntl();
    const [isShareModalVisible, setIsShareModalVisible] = useState(false);
    const isShareModalMounted = useIsModalMounted(isShareModalVisible);
    const { id: dashboardId } = useParams<{ id: string }>();
    const dashboardTree = useTree("dashboard");
    const pathToParentFolder = usePathToParentFolder();
    const dashboard = useSelector(getDashboardState);
    const [canShare] = usePermission("canShare");

    const entry = dashboardId
      ? getContentRecordAtPath({
          tree: dashboardTree,
          path: [...(pathToParentFolder ?? []), dashboardId],
        })?.entry
      : undefined;

    if (!dashboard || !canShare) {
      return undefined;
    }

    const handleCancel = () => {
      setIsShareModalVisible(false);
    };

    const handleClick = () => {
      if (!isDashboardReadOnly) {
        setIsShareModalVisible(true);
      }
    };

    const handleSubmit = () => {
      setIsShareModalVisible(false);
    };

    const isDashboardReadOnly = entry ? !entry.canWrite : true;

    const isDisabled = isDashboardReadOnly || !dashboardId || !entry;
    return {
      icon: <ShareAltOutlined />,
      disabled: isDisabled,
      label: (
        <Fragment>
          {
            // Unmount the modal when it's not displayed to reset its state.
            isShareModalMounted &&
              pathToParentFolder &&
              dashboardId &&
              entry && (
                <ShareModal
                  id={dashboardId}
                  key={entry.timestamp}
                  entry={entry}
                  isVisible={isShareModalVisible}
                  onSubmit={handleSubmit}
                  onCancel={handleCancel}
                  pathToParentFolder={pathToParentFolder}
                  contentType={"dashboard"}
                  name={dashboard.name}
                />
              )
          }
          <Tooltip
            trigger={isDisabled ? ["hover"] : []}
            placement="bottom"
            title={formatMessage(
              {
                id: isDashboardReadOnly
                  ? "aui.contentTree.notAllowedToPerformAction"
                  : "aui.contentTree.notAllowedToShareBecauseUnsavedDashboard",
              },
              { itemType: "dashboard", action: "share" },
            )}
          >
            {formatMessage({ id: "aui.share" })}
          </Tooltip>
        </Fragment>
      ),
      onClick: () => {
        if (!isShareModalVisible) {
          handleClick();
        }
      },
    };
  },
};
