import { FolderOutlined } from "@ant-design/icons";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";

import type { ApplicationMenuItem } from "@activeviam/activeui-sdk";

import { MenuItemLabel } from "./MenuItemLabel.js";

const openKey = "open";

/**
 * {@link ApplicationMenuItem} to open a dashboard without having to navigate to the home page.
 */
export const fileOpenDashboardPopupMenuItem: ApplicationMenuItem = {
  key: "open-dashboard",
  useMenuItem: () => {
    const dispatch = useDispatch();
    const { formatMessage } = useIntl();

    return {
      icon: <FolderOutlined />,
      label: (
        <MenuItemLabel
          title={formatMessage({ id: openKey })}
          shortcutKey={openKey}
        />
      ),
      onClick: () => {
        dispatch({
          type: "openDashboardPopupOpened",
        });
      },
    };
  },
};
