import { Card } from "antd";
import { flatMap as _flatMap } from "lodash-es";
import { FC, MouseEvent, useMemo, useState } from "react";
import { useIntl } from "react-intl";

import {
  AMetaData,
  ContentRecord,
  DashboardMetaData,
  useContentClient,
  useTheme,
} from "@activeviam/activeui-sdk";
import {
  FileOrFolderInExplorer,
  OnAfterSubmit,
  getSortedFilesAndFolders,
  useFileListContextMenuItems,
} from "@activeviam/file-list";
import { Dropdown } from "@activeviam/utils-react";

import { FileCards } from "./FileCards.js";
import { FolderCards } from "./FolderCards.js";

interface DashboardGridProps {
  pathToParentFolder: string[];
  onFolderDoubleClicked: (pathToParentFolder: string[]) => void;
  selectedRanges: { from: number; to: number }[];
  onItemSelected: (index: number, event: MouseEvent) => void;
  onAfterSubmit: OnAfterSubmit;
  clearSelection: () => void;
  onFileDoubleClicked: (id: string) => void;
  folder: ContentRecord;
  folders: FileOrFolderInExplorer[];
  files: FileOrFolderInExplorer[];
  folderMetaData?: AMetaData;
  selectedFileRanges: { from: number; to: number }[];
  selectedFolderRanges: { from: number; to: number }[];
}

/**
 * Displays the folders and files contained in a folder as a grid of cards.
 */
export const DashboardGrid: FC<DashboardGridProps> = (props) => {
  const { formatMessage } = useIntl();
  const {
    clearSelection,
    selectedFileRanges,
    selectedFolderRanges,
    onAfterSubmit,
    onItemSelected,
  } = props;
  const [isDropdownMenuOpen, setIsDropdownMenuOpen] = useState(false);

  const theme = useTheme();
  const contentClient = useContentClient();

  const [idOfFolderBeingRenamed, setIdOfFolderBeingRenamed] = useState<
    string | undefined
  >(undefined);

  const handleFolderRenamingCanceled = () => {
    setIdOfFolderBeingRenamed(undefined);
  };

  const handleFolderRenamingEnded = async (
    pathToParentFolder: string[],
    metaData: DashboardMetaData,
  ) => {
    setIdOfFolderBeingRenamed(undefined);
    await contentClient.updateFolder({
      type: "dashboard",
      pathToParentFolder,
      metaData,
    });

    await contentClient.loadTree("dashboard");
  };

  const defaultDashboardName = formatMessage({
    id: "aui.defaultDashboardName",
  });

  const { files, folders } = useMemo(() => {
    return getSortedFilesAndFolders({
      folder: props.folder,
      defaultName: defaultDashboardName,
    });
  }, [defaultDashboardName, props.folder]);

  const selectedFilesAndFolders = useMemo(
    () => [
      ..._flatMap(selectedFolderRanges, (range) =>
        folders.slice(range.from, range.to + 1),
      ),
      ..._flatMap(selectedFileRanges, (range) =>
        files.slice(range.from, range.to + 1),
      ),
    ],
    [files, folders, selectedFileRanges, selectedFolderRanges],
  );

  const titleStyle = {
    fontSize: 12,
    color: theme.textColor,
    fontWeight: 600,
  };

  const cardStyle = {
    boxShadow: `0px 2px 8px 0px ${theme.black}1A`,
  };

  const cardHeadHeight = "39px";

  const cardProps = {
    headStyle: {
      ...titleStyle,
      backgroundColor: theme.grayScale[1],
      height: cardHeadHeight,
    },
    bordered: false,
    bodyStyle: {
      paddingTop: 0,
      paddingBottom: 0,
      height: `calc(100% - ${cardHeadHeight})`,
      // Setting a minimum height so that the Folders card has the same height when it is empty or when there is one row of sub-folders.
      minHeight: 68,
    },
  };

  const contextMenuItems = useFileListContextMenuItems({
    selectedFilesAndFolders,
    pathToParentFolder: props.pathToParentFolder,
    onFolderRenamingStarted: setIdOfFolderBeingRenamed,
    onAfterSubmit,
  });

  return (
    <Dropdown
      trigger={["contextMenu"]}
      open={isDropdownMenuOpen}
      onOpenChange={setIsDropdownMenuOpen}
      menu={{
        items: contextMenuItems,
        style: { maxWidth: 600 },
        onClick: ({ domEvent }) => {
          // A menu item can decide to opt out from the default behavior by calling `event.domEvent.preventDefault()`.
          // The default behavior is to close the menu on a click event.
          if (!domEvent.isDefaultPrevented()) {
            setIsDropdownMenuOpen(false);
          }
        },
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Card
          title={formatMessage({ id: "aui.contentTree.folders" })}
          {...cardProps}
          style={cardStyle}
        >
          <FolderCards
            folder={props.folder}
            folderMetaData={props.folderMetaData}
            subfolders={props.folders}
            pathToParentFolder={props.pathToParentFolder}
            onSubfolderDoubleClicked={(subfolderPath) => {
              clearSelection();
              props.onFolderDoubleClicked(subfolderPath);
            }}
            selectedFolderRanges={selectedFolderRanges}
            onFolderSelected={onItemSelected}
            onEditEnded={handleFolderRenamingEnded}
            onEditCanceled={handleFolderRenamingCanceled}
            idOfFolderBeingRenamed={idOfFolderBeingRenamed}
          />
        </Card>
        <Card
          title={formatMessage({ id: "aui.contentTree.dashboards" })}
          {...cardProps}
          style={{ ...cardStyle, marginTop: 12, flexGrow: 1 }}
        >
          <FileCards
            files={props.files}
            pathToParentFolder={props.pathToParentFolder}
            selectedDashboardRanges={selectedFileRanges}
            onFileSelected={(index, event) => {
              onItemSelected(index + props.folders.length, event);
            }}
            onFileDoubleClicked={props.onFileDoubleClicked}
            onAfterSubmit={onAfterSubmit}
          />
        </Card>
      </div>
    </Dropdown>
  );
};
