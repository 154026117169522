import { produce } from "immer";
import { find as _find, last as _last } from "lodash-es";
import { areLevelsEqual, getHierarchy } from "@activeviam/data-model";
import { _collapseInAxis } from "./collapse.js";
import { getExpandedTuples } from "./getExpandedTuples.js";
import { getLevelName } from "./getLevelName.js";
import { _collapseMembersOfLevel } from "./internal/_collapseMembersOfLevel.js";
import { _expandTupleOntoDeeperLevelOfTheSameHierarchy } from "./internal/_expandTupleOntoDeeperLevelOfTheSameHierarchy.js";
import { _expandTupleOntoDifferentHierarchy } from "./internal/_expandTupleOntoDifferentHierarchy.js";
import { _getIndexOfAxisContainingHierarchiesExpressedInTuple } from "./internal/_getIndexOfAxisContainingLevelsExpressedInTuple.js";
/**
 * Checks whether two given MemberCoordinates are on the same level.
 */ const areOnSameLevel = (memberA, memberB)=>memberA?.dimensionName === memberB.dimensionName && memberA?.hierarchyName === memberB.hierarchyName && memberA?.namePath.length === memberB.namePath.length;
/**
 * Collapses the expanded tuples that would conflict with expanding the tuple identified by `tupleCoordinates` down to the level identified by `toLevel`.
 * The potential conflicts are the following:
 * - some cousin tuples are expanded down to a different level than `toLevel`
 * - some descendant tuples are expanded
 */ const collapseConflictingExpansions = ({ axis , cube , lastCoordinateInTuple , toLevel , tupleCoordinates  })=>{
    const expandedTuples = getExpandedTuples(axis, cube);
    const firstExpandedCousin = _find(expandedTuples, (expandedTuple)=>{
        const lastMemberCoordinates = _last(expandedTuple.tupleCoordinates);
        return areOnSameLevel(lastMemberCoordinates, lastCoordinateInTuple);
    });
    const hasCousinsExpandedDownToADifferentLevel = firstExpandedCousin ? !areLevelsEqual(firstExpandedCousin.toLevel, toLevel) : false;
    if (hasCousinsExpandedDownToADifferentLevel) {
        const levelIndex = lastCoordinateInTuple.namePath.length - 1;
        const hierarchy = getHierarchy({
            dimensionName: lastCoordinateInTuple?.dimensionName,
            hierarchyName: lastCoordinateInTuple?.hierarchyName
        }, cube);
        return _collapseMembersOfLevel(axis, {
            hierarchyName: lastCoordinateInTuple.hierarchyName,
            dimensionName: lastCoordinateInTuple.dimensionName,
            levelName: getLevelName(hierarchy, levelIndex)
        }, cube);
    }
    return _collapseInAxis(axis, {
        cube,
        tupleCoordinates
    });
};
/**
 * Returns a new {@link MdxAxis} corresponding to `axis` where the given tuple was expanded.
 * Does not mutate mdx.
 */ export function _expandInAxis(axis, { cube , tupleCoordinates , toLevel , doesIncludeCalculatedMembers  }) {
    if (tupleCoordinates.length === 0) {
        return axis;
    }
    // lastCoordinateInTuple is defined as per the check above
    const lastCoordinateInTuple = _last(tupleCoordinates);
    const axisWithoutConflictingExpansions = collapseConflictingExpansions({
        axis,
        cube,
        lastCoordinateInTuple,
        toLevel,
        tupleCoordinates
    });
    if (toLevel.dimensionName === lastCoordinateInTuple.dimensionName && toLevel.hierarchyName === lastCoordinateInTuple.hierarchyName) {
        return _expandTupleOntoDeeperLevelOfTheSameHierarchy(axisWithoutConflictingExpansions, {
            tupleCoordinates,
            toLevel,
            doesIncludeCalculatedMembers,
            cube
        });
    } else {
        return _expandTupleOntoDifferentHierarchy(axisWithoutConflictingExpansions, {
            cube,
            tupleCoordinates,
            toLevel,
            doesIncludeCalculatedMembers
        });
    }
}
/**
 * Returns a new {@link MdxSelect} corresponding to `mdx` where the given tuple was expanded.
 * Does not mutate mdx.
 */ export function expand(mdx, { cube , tupleCoordinates , toLevel , doesIncludeCalculatedMembers  }) {
    const axisIndex = _getIndexOfAxisContainingHierarchiesExpressedInTuple(mdx, {
        cube,
        tupleCoordinates
    });
    if (axisIndex === -1) {
        return mdx;
    }
    const axisWithExpandedTuple = _expandInAxis(mdx.axes[axisIndex], {
        cube,
        tupleCoordinates,
        toLevel,
        doesIncludeCalculatedMembers
    });
    return produce(mdx, (draft)=>{
        draft.axes[axisIndex] = axisWithExpandedTuple;
    });
}
