import { FC } from "react";

import { NewDashboardButton } from "./NewDashboardButton.js";

interface NewDashboardCardProps {
  pathToParentFolder: string[];
}

/**
 * Displays a button shaped like a `<Card />` that creates a new dashboard inside `pathToParentFolder`.
 */
export const NewDashboardCard: FC<NewDashboardCardProps> = (props) => {
  return (
    <NewDashboardButton
      style={{
        width: 240,
        height: 220,
        marginRight: 12,
        marginTop: 12,
        fontSize: 14,
        fontWeight: 400,
      }}
      pathToParentFolder={props.pathToParentFolder}
    />
  );
};
