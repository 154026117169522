import { set as _set } from "lodash-es";
import { getSpecificCompoundIdentifier } from "../getSpecificCompoundIdentifier.js";
import { isMdxCompoundIdentifier } from "../isMdxCompoundIdentifier.js";
import { isMdxFunction } from "../isMdxFunction.js";
import { cleanupIdempotentFunction, cleanupLikeCrossjoin, cleanupLikeExcept, cleanupLikeUnion, composeCleanupFunctions, defaultCleanup } from "./_functionCleanup.js";
import { _nullifyIfAllArgsAreDefaultMembers } from "./_nullifyIfAllArgsAreDefaultMembers.js";
import { _nullifyIfNotEnoughArgs } from "./_nullifyIfNotEnoughArgs.js";
import { _replaceWithOnlyRemainingArg } from "./_replaceWithOnlyRemainingArg.js";
// Disabling spell checking for the following lines as they contain lower cased MDX function names that are not correct English.
/* cSpell:disable */ /**
 * Contains, for each {@link MdxFunction}, information on:
 * - how this function is cleaned up, after one of its argument was removed.
 * - how this function's dimensionality (the list of hierarchies it requests from ActivePivot) is calculated, based on the dimensionalities of its arguments.
 */ export const _mdxFunctionsMetaData = {
    // CUSTOM ACTIVEVIAM FUNCTIONS
    // See https://github.com/activeviam/activepivot/blob/2ab38870fd01bf8414838ea6b59e1e7f02f3fe5e/pivot/mdx/intf/src/main/java/com/quartetfs/pivot/mdx/statement/IFunction.java#L21 for the names,
    // and for the implementations: https://github.com/activeviam/activepivot/tree/2ab38870fd01bf8414838ea6b59e1e7f02f3fe5e/pivot/mdx/impl/src/main/java/com/quartetfs/pivot/mdx/func/custom/impl
    activemeasure: {
        minNumberOfArgs: 1
    },
    _as_value: {
        minNumberOfArgs: 1
    },
    _currentepoch: {
        minNumberOfArgs: 0
    },
    _currentlocation: {
        minNumberOfArgs: 0
    },
    _currentmeasure: {
        minNumberOfArgs: 0
    },
    dimtohier: {
        minNumberOfArgs: 1
    },
    distinctvalue: {
        minNumberOfArgs: 1
    },
    _isrealtime: {
        minNumberOfArgs: 0
    },
    is_unknownmember: // https://github.com/activeviam/activepivot/blob/2ab38870fd01bf8414838ea6b59e1e7f02f3fe5e/pivot/mdx/impl/src/main/java/com/quartetfs/pivot/mdx/func/impl/IsUnknownMemberFunction.java
    {
        minNumberOfArgs: 1
    },
    membersnamesearch: {
        minNumberOfArgs: 2,
        dimensionality: "of-first-arg"
    },
    memberscaptionsearch: {
        minNumberOfArgs: 2,
        dimensionality: "of-first-arg"
    },
    // VISUAL BASIC FUNCTIONS
    // Only the VB functions supported by ActivePivot are handled here.
    // This subset is defined at https://github.com/activeviam/activepivot/blob/2ab38870fd01bf8414838ea6b59e1e7f02f3fe5e/pivot/mdx/intf/src/main/java/com/quartetfs/pivot/mdx/statement/IFunction.java#L214-L234 and https://support.activeviam.com/confluence/display/AP5/MDX+Functions
    // For most of the functions, the implementation can be found here: https://github.com/activeviam/activepivot/tree/2ab38870fd01bf8414838ea6b59e1e7f02f3fe5e/pivot/mdx/impl/src/main/java/com/quartetfs/pivot/mdx/func/vb/impl
    //
    // For the full list of VB functions supported in MDX, see: https://docs.microsoft.com/en-us/sql/mdx/vba-functions-in-mdx-and-dax?view=sql-server-ver15
    // Their reference can be found here: https://docs.microsoft.com/en-us/dotnet/visual-basic/language-reference/functions/
    cint: {
        minNumberOfArgs: 1
    },
    clng: {
        minNumberOfArgs: 1
    },
    cdate: {
        minNumberOfArgs: 1
    },
    cstr: {
        minNumberOfArgs: 1
    },
    dateadd: {
        minNumberOfArgs: 3
    },
    datediff: {
        minNumberOfArgs: 3
    },
    datepart: {
        minNumberOfArgs: 2
    },
    day: {
        minNumberOfArgs: 1
    },
    isdate: {
        minNumberOfArgs: 1
    },
    isnull: {
        minNumberOfArgs: 1
    },
    isnumeric: {
        minNumberOfArgs: 1
    },
    lcase: {
        minNumberOfArgs: 1
    },
    left: {
        minNumberOfArgs: 2
    },
    log: {
        minNumberOfArgs: 2
    },
    month: {
        minNumberOfArgs: 1
    },
    now: {
        minNumberOfArgs: 0
    },
    right: {
        minNumberOfArgs: 2
    },
    round: {
        minNumberOfArgs: 1
    },
    ucase: {
        minNumberOfArgs: 1
    },
    weekday: {
        minNumberOfArgs: 1
    },
    year: {
        minNumberOfArgs: 1
    },
    // OPERATORS
    "=": {
        minNumberOfArgs: 2
    },
    ">=": {
        minNumberOfArgs: 2
    },
    ">": {
        minNumberOfArgs: 2
    },
    "<=": {
        minNumberOfArgs: 2
    },
    "<": {
        minNumberOfArgs: 2
    },
    "<>": {
        minNumberOfArgs: 2
    },
    "^": {
        minNumberOfArgs: 2
    },
    "/": {
        minNumberOfArgs: 2
    },
    ":": {
        minNumberOfArgs: 2,
        dimensionality: "common"
    },
    // This does not take into account the "Positive" case.
    "+": {
        minNumberOfArgs: 2,
        // The MdxFunctionMetaData interface declares that the cleanup attribute is of type CleanupFunction.
        // TypeScript correctly understands that, but ESLint wrongly complains that the function is not typed.
        // eslint-disable-next-line
        cleanup: (cleanupArgs)=>cleanupArgs.functionNode.syntax === "Prefix" ? defaultCleanup(cleanupArgs) : cleanupLikeUnion(cleanupArgs),
        dimensionality: "common"
    },
    // This does not take into account the "Negative" case.
    "-": {
        minNumberOfArgs: 2,
        // The MdxFunctionMetaData interface declares that the cleanup attribute is of type CleanupFunction.
        // TypeScript correctly understands that, but ESLint wrongly complains that the function is not typed.
        // eslint-disable-next-line
        cleanup: (cleanupArgs)=>cleanupArgs.functionNode.syntax === "Prefix" ? defaultCleanup(cleanupArgs) : cleanupLikeExcept(cleanupArgs),
        dimensionality: "common"
    },
    "*": {
        minNumberOfArgs: 2,
        cleanup: cleanupLikeCrossjoin,
        dimensionality: "joined"
    },
    and: {
        minNumberOfArgs: 2,
        cleanup: cleanupLikeUnion
    },
    is: {
        minNumberOfArgs: 2
    },
    not: {
        minNumberOfArgs: 1
    },
    or: {
        minNumberOfArgs: 2,
        cleanup: cleanupLikeUnion
    },
    xor: {
        minNumberOfArgs: 2,
        cleanup: composeCleanupFunctions(_replaceWithOnlyRemainingArg, _nullifyIfNotEnoughArgs)
    },
    // Note that both () and {} should NOT be replaced with their first argument,
    // even if it is the only one remaining.
    // Indeed, it would not work in some cases, e.g.:
    // - https://docs.microsoft.com/en-us/sql/mdx/count-tuple-mdx?view=sql-server-ver15
    // - https://docs.microsoft.com/en-us/sql/mdx/item-member-mdx?view=sql-server-ver15
    // - https://docs.microsoft.com/en-us/sql/mdx/item-tuple-mdx?view=sql-server-ver15
    "()": {
        minNumberOfArgs: 1,
        dimensionality: "joined",
        cleanup: _nullifyIfNotEnoughArgs
    },
    "{}": {
        minNumberOfArgs: 1,
        dimensionality: "common",
        cleanup: _nullifyIfNotEnoughArgs
    },
    // FUNCTIONS
    // See https://docs.microsoft.com/en-us/sql/mdx/mdx-function-reference-mdx?view=sql-server-ver15
    abs: {
        minNumberOfArgs: 1,
        cleanup: cleanupIdempotentFunction
    },
    addcalculatedmembers: {
        minNumberOfArgs: 1,
        cleanup: cleanupIdempotentFunction,
        dimensionality: "of-first-arg"
    },
    aggregate: {
        minNumberOfArgs: 1
    },
    allmembers: {
        minNumberOfArgs: 1,
        dimensionality: "of-first-arg"
    },
    ancestor: {
        minNumberOfArgs: 2,
        dimensionality: "of-first-arg"
    },
    ancestors: {
        minNumberOfArgs: 2,
        dimensionality: "of-first-arg"
    },
    ascendants: {
        minNumberOfArgs: 1,
        dimensionality: "of-first-arg"
    },
    avg: {
        minNumberOfArgs: 1
    },
    axis: {
        minNumberOfArgs: 1
    },
    bottomcount: {
        minNumberOfArgs: 2,
        cleanup: cleanupLikeExcept,
        dimensionality: "of-first-arg"
    },
    bottompercent: {
        minNumberOfArgs: 3,
        cleanup: cleanupLikeExcept,
        dimensionality: "of-first-arg"
    },
    bottomsum: {
        minNumberOfArgs: 3,
        cleanup: cleanupLikeExcept,
        dimensionality: "of-first-arg"
    },
    calculationcurrentpass: {
        minNumberOfArgs: 0
    },
    calculationpassvalue: {
        minNumberOfArgs: 2
    },
    children: {
        minNumberOfArgs: 1,
        dimensionality: "of-first-arg"
    },
    closingperiod: {
        minNumberOfArgs: 0,
        dimensionality: "of-first-arg"
    },
    coalesceempty: {
        minNumberOfArgs: 2
    },
    correlation: {
        minNumberOfArgs: 2
    },
    count: {
        minNumberOfArgs: 1
    },
    cousin: {
        minNumberOfArgs: 2,
        dimensionality: "of-first-arg"
    },
    covariance: {
        minNumberOfArgs: 2
    },
    covariancen: {
        minNumberOfArgs: 2
    },
    crossjoin: {
        minNumberOfArgs: 2,
        cleanup: cleanupLikeCrossjoin,
        dimensionality: "joined"
    },
    current: {
        minNumberOfArgs: 1,
        dimensionality: "of-first-arg"
    },
    currentmember: {
        minNumberOfArgs: 1,
        dimensionality: "of-first-arg"
    },
    currentordinal: {
        minNumberOfArgs: 1
    },
    customdata: {
        minNumberOfArgs: 0
    },
    datamember: {
        minNumberOfArgs: 1
    },
    defaultmember: {
        minNumberOfArgs: 1,
        dimensionality: "of-first-arg"
    },
    descendants: {
        minNumberOfArgs: 1,
        dimensionality: "of-first-arg"
    },
    dimension: {
        minNumberOfArgs: 1,
        dimensionality: "of-first-arg"
    },
    dimensions: {
        minNumberOfArgs: 1
    },
    distinct: {
        minNumberOfArgs: 1,
        cleanup: cleanupIdempotentFunction,
        dimensionality: "of-first-arg"
    },
    distinctcount: {
        minNumberOfArgs: 1
    },
    divide: {
        minNumberOfArgs: 2
    },
    drilldownlevel: {
        minNumberOfArgs: 1,
        dimensionality: "of-first-arg"
    },
    drilldownlevelbottom: {
        minNumberOfArgs: 2,
        dimensionality: "of-first-arg"
    },
    drilldownleveltop: {
        minNumberOfArgs: 2,
        dimensionality: "of-first-arg"
    },
    drilldownmember: {
        minNumberOfArgs: 2,
        dimensionality: "of-first-arg"
    },
    drilldownmemberbottom: {
        minNumberOfArgs: 3,
        dimensionality: "of-first-arg"
    },
    drilldownmembertop: {
        minNumberOfArgs: 3,
        dimensionality: "of-first-arg"
    },
    drilluplevel: {
        minNumberOfArgs: 1,
        dimensionality: "of-first-arg"
    },
    drillupmember: {
        minNumberOfArgs: 2,
        dimensionality: "of-first-arg"
    },
    error: {
        minNumberOfArgs: 0
    },
    except: {
        minNumberOfArgs: 2,
        cleanup: cleanupLikeExcept,
        dimensionality: "common"
    },
    exists: {
        minNumberOfArgs: 2,
        cleanup: cleanupLikeExcept,
        dimensionality: "of-first-arg"
    },
    extract: {
        minNumberOfArgs: 2,
        cleanup: cleanupLikeExcept
    },
    filter: {
        minNumberOfArgs: 2,
        cleanup: cleanupLikeExcept,
        dimensionality: "of-first-arg"
    },
    firstchild: {
        minNumberOfArgs: 1,
        dimensionality: "of-first-arg"
    },
    firstsibling: {
        minNumberOfArgs: 1,
        dimensionality: "of-first-arg"
    },
    generate: {
        minNumberOfArgs: 2,
        dimensionality: "of-second-arg"
    },
    head: {
        minNumberOfArgs: 1,
        dimensionality: "of-first-arg"
    },
    hierarchize: {
        minNumberOfArgs: 1,
        cleanup: composeCleanupFunctions(cleanupIdempotentFunction, ({ mdx , path , functionNode , cube  })=>{
            // Calling Hierarchize on different members from the same level is useless.
            // The check below will remove the wrapping Hierarchize in calls of the form:
            // Hierarchize([myDimension].[myHierarchy].[myLevel].Members).
            // TODO make it more generic by checking for any expression representing members from a single level,
            // instead of checking "Members" only.
            // E.g. the same would apply to Hierarchize([myDimension].[myHierarchy].[path].[to].[myMember].Children)
            if (functionNode.arguments.length === 1 && isMdxFunction(functionNode.arguments[0], "members") && isMdxCompoundIdentifier(functionNode.arguments[0].arguments[0]) && getSpecificCompoundIdentifier(functionNode.arguments[0].arguments[0], {
                cube
            }).type === "level") {
                _set(mdx, path, functionNode.arguments[0]);
                return true;
            }
            return false;
        }),
        dimensionality: "of-first-arg"
    },
    hierarchy: {
        minNumberOfArgs: 1,
        dimensionality: "of-first-arg"
    },
    iif: {
        minNumberOfArgs: 3,
        dimensionality: "of-second-arg"
    },
    instr: {
        minNumberOfArgs: 2
    },
    intersect: {
        minNumberOfArgs: 2,
        cleanup: cleanupLikeUnion,
        dimensionality: "common"
    },
    isancestor: {
        minNumberOfArgs: 2
    },
    isempty: {
        minNumberOfArgs: 1
    },
    isgeneration: {
        minNumberOfArgs: 2
    },
    isleaf: {
        minNumberOfArgs: 1
    },
    issibling: {
        minNumberOfArgs: 2
    },
    item: {
        minNumberOfArgs: 2
    },
    kpigoal: {
        minNumberOfArgs: 1
    },
    kpistatus: {
        minNumberOfArgs: 1
    },
    kpitrend: {
        minNumberOfArgs: 1
    },
    kpiweight: {
        minNumberOfArgs: 1
    },
    kpicurrenttimemember: {
        minNumberOfArgs: 1
    },
    kpivalue: {
        minNumberOfArgs: 1
    },
    lag: {
        minNumberOfArgs: 2,
        dimensionality: "of-first-arg"
    },
    lastchild: {
        minNumberOfArgs: 1,
        dimensionality: "of-first-arg"
    },
    lastperiods: {
        minNumberOfArgs: 1,
        dimensionality: "of-first-arg"
    },
    lastsibling: {
        minNumberOfArgs: 1,
        dimensionality: "of-first-arg"
    },
    lead: {
        minNumberOfArgs: 2,
        dimensionality: "of-first-arg"
    },
    leaves: {
        minNumberOfArgs: 0,
        dimensionality: "of-first-arg"
    },
    level: {
        minNumberOfArgs: 1,
        dimensionality: "of-first-arg"
    },
    levels: {
        minNumberOfArgs: 2,
        dimensionality: "of-first-arg"
    },
    linkmember: {
        minNumberOfArgs: 2,
        dimensionality: "of-second-arg"
    },
    linregintercept: {
        minNumberOfArgs: 2
    },
    linregpoint: {
        minNumberOfArgs: 3
    },
    linregr2: {
        minNumberOfArgs: 2
    },
    linregslope: {
        minNumberOfArgs: 2
    },
    linregvariance: {
        minNumberOfArgs: 2
    },
    lookupcube: {
        minNumberOfArgs: 2
    },
    max: {
        minNumberOfArgs: 1
    },
    measuregroupmeasures: {
        minNumberOfArgs: 1
    },
    median: {
        minNumberOfArgs: 1
    },
    members: {
        minNumberOfArgs: 1,
        dimensionality: "of-first-arg"
    },
    membertostr: {
        minNumberOfArgs: 1,
        dimensionality: "of-first-arg"
    },
    membervalue: {
        minNumberOfArgs: 1
    },
    min: {
        minNumberOfArgs: 1
    },
    mtd: {
        minNumberOfArgs: 0
    },
    name: {
        minNumberOfArgs: 1
    },
    nametoset: {
        minNumberOfArgs: 1,
        dimensionality: "of-parsed-arg"
    },
    nextmember: {
        minNumberOfArgs: 1,
        dimensionality: "of-first-arg"
    },
    nonempty: {
        minNumberOfArgs: 1,
        cleanup: cleanupIdempotentFunction,
        dimensionality: "of-first-arg"
    },
    nonemptycrossjoin: {
        minNumberOfArgs: 1,
        dimensionality: "joined",
        cleanup: composeCleanupFunctions(_nullifyIfNotEnoughArgs, _nullifyIfAllArgsAreDefaultMembers)
    },
    openingperiod: {
        minNumberOfArgs: 0,
        dimensionality: "of-first-arg"
    },
    order: {
        minNumberOfArgs: 2,
        cleanup: cleanupLikeExcept,
        dimensionality: "of-first-arg"
    },
    ordinal: {
        minNumberOfArgs: 1
    },
    parallelperiod: {
        minNumberOfArgs: 0,
        dimensionality: "of-first-arg"
    },
    parent: {
        minNumberOfArgs: 1,
        dimensionality: "of-first-arg"
    },
    periodstodate: {
        minNumberOfArgs: 0,
        dimensionality: "of-first-arg"
    },
    predict: {
        minNumberOfArgs: 2
    },
    prevmember: {
        minNumberOfArgs: 1,
        dimensionality: "of-first-arg"
    },
    properties: {
        minNumberOfArgs: 2
    },
    qtd: {
        minNumberOfArgs: 0,
        dimensionality: "of-first-arg"
    },
    rank: {
        minNumberOfArgs: 2
    },
    rgb: {
        minNumberOfArgs: 3
    },
    rollupchildren: {
        minNumberOfArgs: 2
    },
    root: {
        minNumberOfArgs: 0
    },
    settoarray: {
        minNumberOfArgs: 1
    },
    settostr: {
        minNumberOfArgs: 1,
        dimensionality: "of-first-arg"
    },
    siblings: {
        minNumberOfArgs: 1,
        dimensionality: "of-first-arg"
    },
    stddev: {
        minNumberOfArgs: 1
    },
    stddevp: {
        minNumberOfArgs: 1
    },
    stdev: {
        minNumberOfArgs: 1
    },
    stdevp: {
        minNumberOfArgs: 1
    },
    stripcalculatedmembers: {
        minNumberOfArgs: 1,
        cleanup: cleanupIdempotentFunction,
        dimensionality: "of-first-arg"
    },
    strtomember: {
        minNumberOfArgs: 1,
        dimensionality: "of-parsed-arg"
    },
    strtoset: {
        minNumberOfArgs: 1,
        dimensionality: "of-parsed-arg"
    },
    strtotuple: {
        minNumberOfArgs: 1,
        dimensionality: "of-parsed-arg"
    },
    strtovalue: {
        minNumberOfArgs: 1
    },
    subset: {
        minNumberOfArgs: 2,
        cleanup: cleanupLikeExcept,
        dimensionality: "of-first-arg"
    },
    sum: {
        minNumberOfArgs: 1
    },
    tail: {
        minNumberOfArgs: 1,
        dimensionality: "of-first-arg"
    },
    toggledrillstate: {
        minNumberOfArgs: 2,
        dimensionality: "of-first-arg"
    },
    topcount: {
        minNumberOfArgs: 2,
        cleanup: cleanupLikeExcept,
        dimensionality: "of-first-arg"
    },
    toppercent: {
        minNumberOfArgs: 3,
        cleanup: cleanupLikeExcept,
        dimensionality: "of-first-arg"
    },
    topsum: {
        minNumberOfArgs: 3,
        cleanup: cleanupLikeExcept,
        dimensionality: "of-first-arg"
    },
    tupletostr: {
        minNumberOfArgs: 1,
        dimensionality: "of-first-arg"
    },
    union: {
        minNumberOfArgs: 2,
        cleanup: cleanupLikeUnion,
        dimensionality: "common"
    },
    uniquename: {
        minNumberOfArgs: 1
    },
    unknownmember: {
        minNumberOfArgs: 1,
        dimensionality: "of-first-arg"
    },
    unorder: {
        minNumberOfArgs: 1,
        cleanup: cleanupIdempotentFunction,
        dimensionality: "of-first-arg"
    },
    username: {
        minNumberOfArgs: 0
    },
    validmeasure: {
        minNumberOfArgs: 1
    },
    value: {
        minNumberOfArgs: 1
    },
    var: {
        minNumberOfArgs: 1
    },
    variance: {
        minNumberOfArgs: 1
    },
    variancep: {
        minNumberOfArgs: 1
    },
    varp: {
        minNumberOfArgs: 1
    },
    visualtotals: {
        minNumberOfArgs: 1,
        cleanup: cleanupIdempotentFunction,
        dimensionality: "of-first-arg"
    },
    wtd: {
        minNumberOfArgs: 0,
        dimensionality: "of-first-arg"
    },
    ytd: {
        minNumberOfArgs: 0,
        dimensionality: "of-first-arg"
    }
}; /* cSpell:enable */ 
