import { produce } from "immer";
/**
 * Returns an {@link MdxFormula} corresponding to `formula` where the name is prefixed with `cubeName`, if it was not the case already.
 * Does not mutate `formula`.
 *
 * Useful for CREATE/DROP/UPDATE statements, to uniquely identify an entity across all cubes.
 * See https://learn.microsoft.com/en-us/sql/mdx/mdx-data-definition-create-member for example.
 */ export function prefixFormulaNameWithCubeName(formula, cubeName) {
    const firstIdentifier = formula.name.identifiers[0];
    return firstIdentifier.value === cubeName ? formula : produce(formula, (draft)=>{
        draft.name.identifiers.unshift({
            elementType: "Identifier",
            quoting: firstIdentifier.quoting,
            value: cubeName
        });
    });
}
