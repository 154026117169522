/**
 * Returns the calculated members for the given cube.
 * Fetches them from the Atoti Server if they were not already fetched previously.
 */ export function getCubeCalculatedMembers(activePivotClient, cubeName, { signal  } = {}) {
    const { calculatedMembers , isLoading  } = activePivotClient.calculatedMembers[cubeName] ?? {};
    if (!isLoading && calculatedMembers) {
        return Promise.resolve(calculatedMembers);
    }
    return new Promise((resolve, reject)=>{
        const listener = (calculatedMembers, metaData)=>{
            if (!metaData.isLoading) {
                activePivotClient.removeCalculatedMembersListener(cubeName, listener);
                if (metaData.error) {
                    reject(metaData.error);
                } else {
                    resolve(calculatedMembers);
                }
            }
        };
        activePivotClient.addCalculatedMembersListener(cubeName, listener);
        void activePivotClient.loadCalculatedMembers(cubeName, {
            signal
        });
        if (signal) {
            signal.onabort = ()=>{
                activePivotClient.removeCalculatedMembersListener(cubeName, listener);
                reject(signal.reason);
            };
        }
    });
}
