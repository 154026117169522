import { areHierarchiesEqual } from "@activeviam/data-model";
import { getHierarchies } from "../getHierarchies.js";
import { isMdxCompoundIdentifier } from "../isMdxCompoundIdentifier.js";
import { traverseMdx } from "../traverseMdx.js";
/**
 * Returns the compound identifiers in `mdx`. Only matches those corresponding to `hierarchyCoordinates`, if given.
 */ export const _findCompoundIdentifiers = (mdx, { hierarchyCoordinates , cube  })=>{
    const compoundIdentifiers = [];
    traverseMdx(mdx, (node, path)=>{
        if (isMdxCompoundIdentifier(node)) {
            if (!hierarchyCoordinates) {
                compoundIdentifiers.push({
                    match: node,
                    path
                });
            } else {
                const [hierarchyCoordinatesOfCompoundIdentifier] = getHierarchies(node, {
                    cube
                });
                if (areHierarchiesEqual(hierarchyCoordinates, hierarchyCoordinatesOfCompoundIdentifier)) {
                    compoundIdentifiers.push({
                        match: node,
                        path
                    });
                }
            }
        }
    });
    return compoundIdentifiers;
};
