import { EllipsisOutlined } from "@ant-design/icons";
import { css } from "@emotion/react";
import { useState } from "react";

import {
  FileListMenuItemProps,
  getContentRecordAtPath,
  opacitize,
  useMenuItemMakeCopyOfFile,
  useMenuItemMoveFilesAndFolders,
  useMenuItemShareFileOrFolder,
  useTheme,
  useTree,
} from "@activeviam/activeui-sdk";
import { OnAfterSubmit, useMenuItemDeleteFile } from "@activeviam/file-list";
import { Dropdown } from "@activeviam/utils-react";

interface FileCardContextMenuIconProps {
  id: string;
  pathToParentFolder: string[];
  onAfterSubmit: OnAfterSubmit;
}

/**
 * Icon used by `FileCard` to signify the existence of a context menu action.
 */
export const FileCardContextMenuIcon = ({
  id,
  pathToParentFolder,
  onAfterSubmit,
}: FileCardContextMenuIconProps) => {
  const theme = useTheme();
  const dashboardsTree = useTree("dashboard");
  const [isDropdownMenuOpen, setIsDropdownMenuOpen] = useState(false);

  const record = getContentRecordAtPath({
    tree: dashboardsTree,
    path: [...pathToParentFolder, id],
  });

  const menuItemProps: FileListMenuItemProps = {
    pathToParentFolder,
    contentTree: dashboardsTree ?? undefined,
    contentType: "dashboard",
    selectedFilesAndFolders: record ? [{ id, record }] : [],
    onAfterSubmit,
  };

  const menuItemShareFileOrFolder = useMenuItemShareFileOrFolder(menuItemProps);
  const menuItemMoveFilesAndFolders = useMenuItemMoveFilesAndFolders(
    menuItemProps,
  );
  const menuItemMakeCopyOfFile = useMenuItemMakeCopyOfFile(menuItemProps);
  const menuItemDeleteFile = useMenuItemDeleteFile(menuItemProps);

  if (!dashboardsTree || !record) {
    return null;
  }

  return (
    <div
      className={"context-menu-icon"}
      css={css`
        display: flex;
        position: absolute;
        width: 100%;
        justify-content: flex-end;
        font-size: 24px;
        opacity: 0;
        transition: opacity 0.5s;
        z-index: 10;
      `}
      onClick={(event) => {
        // Prevents the click event on the icon to bubble to the file card and mistakenly trigger a selection.
        event.stopPropagation();
      }}
    >
      <Dropdown
        placement={"bottomRight"}
        trigger={["click"]}
        open={isDropdownMenuOpen}
        onOpenChange={setIsDropdownMenuOpen}
        menu={{
          items: [
            menuItemShareFileOrFolder,
            menuItemMoveFilesAndFolders,
            menuItemMakeCopyOfFile,
            menuItemDeleteFile,
          ],
          onClick: ({ domEvent }) => {
            // A menu item can decide to opt out from the default behavior by calling `event.domEvent.preventDefault()`.
            // The default behavior is to close the menu on a click event.
            if (!domEvent.isDefaultPrevented()) {
              setIsDropdownMenuOpen(false);
            }
          },
        }}
      >
        <EllipsisOutlined
          css={css`
            z-index: 10;
            border-radius: 5px;
            margin: 5px;
            background-color: ${opacitize(theme.grayScale[5], 0.5)};
          `}
        />
      </Dropdown>
    </div>
  );
};
