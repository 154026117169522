import { AppstoreOutlined, UnorderedListOutlined } from "@ant-design/icons";
import { groupBy as _groupBy, last as _last } from "lodash-es";
import {
  FC,
  Fragment,
  KeyboardEvent,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

import {
  AMetaData,
  ContentRecord,
  IconEmptyFolder,
  Title,
  getMetaData,
  usePermission,
  useTheme,
} from "@activeviam/activeui-sdk";
import {
  FileList,
  getSortedFilesAndFolders,
  useOnAfterSubmit,
} from "@activeviam/file-list";
import { Tooltip, useMultiSelection } from "@activeviam/utils-react";

import { useSettingWithLocalStorageDefault } from "../../hooks/useSettingWithLocalStorageDefault.js";
import { BrandSignatureFooter } from "./BrandSignatureFooter.js";
import { DashboardGrid } from "./DashboardGrid.js";
import { FolderBreadcrumb } from "./FolderBreadcrumb.js";
import { NewDashboardButton } from "./NewDashboardButton.js";
import { NewFolderButton } from "./NewFolderButton.js";
import { getSelectedRangesOfFoldersAndFiles } from "./getSelectedRangesOfFoldersAndFiles.js";

export interface DashboardExplorerProps {
  pathToParentFolder: string[];
  onPathToFolderChanged: (newPath: string[]) => void;
  folder: ContentRecord<AMetaData>;
}

const breadcrumbHeight = 28;
const breadcrumbMargin = 8;

/**
 * Displays the content of a dashboard folder.
 * Allows the user to browse up the path using the breadcrumbs component.
 */
export const DashboardExplorer: FC<DashboardExplorerProps> = (props) => {
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const navigate = useNavigate();

  const [canManageContent] = usePermission("canManageContent");

  const [layout, setLayout] = useSettingWithLocalStorageDefault(
    "homePageLayout",
  );

  const defaultDashboardName = formatMessage({
    id: "aui.defaultDashboardName",
  });

  const folderMetaData = useMemo(() => {
    const folderId = _last(props.pathToParentFolder);
    return props.folder && folderId
      ? getMetaData<AMetaData>(props.folder, folderId)
      : undefined;
  }, [props.folder, props.pathToParentFolder]);

  const { files, folders } = useMemo(() => {
    return getSortedFilesAndFolders({
      folder: props.folder,
      defaultName: defaultDashboardName,
    });
  }, [defaultDashboardName, props.folder]);

  const hasAccessToSomeDashboards =
    props.pathToParentFolder.length !== 0 ||
    folders.length !== 0 ||
    files.length !== 0;

  const {
    selectedRanges,
    onItemSelected,
    clearSelection,
    setSelectedRanges,
  } = useMultiSelection();

  const onAfterSubmit = useOnAfterSubmit({
    clearSelection,
    setSelectedRanges,
    pathToParentFolder: props.pathToParentFolder,
  });

  const handleItemSelected = useCallback(
    (index: number, event: MouseEvent<unknown, unknown> | KeyboardEvent) => {
      onItemSelected({
        index,
        isExtending: event.shiftKey,
        isResetting: !event.shiftKey && !event.ctrlKey,
      });
    },
    [onItemSelected],
  );

  const handleDashboardOpened = useCallback(
    (dashboardId: string) => {
      navigate(`/dashboard/${dashboardId}`);
    },
    [navigate],
  );

  const { selectedFolderRanges, selectedFileRanges } = useMemo(
    () =>
      getSelectedRangesOfFoldersAndFiles({
        selectedRanges,
        numberOfFolders: folders.length,
        layout,
      }),
    [selectedRanges, folders, layout],
  );

  // Clears the selection whenever the user changes the layout or navigates to a different folder.
  useEffect(() => {
    clearSelection();
  }, [props.pathToParentFolder, clearSelection, layout]);

  return (
    <div
      // A tabIndex is required for the div to be a focusable component able to capture keyboard events.
      // Using -1 as a value will prevent the div from being visibly focused on screen.
      tabIndex={-1}
      onKeyUp={(event) => {
        if (event.key === "Escape") {
          clearSelection();
        }
      }}
      style={{
        backgroundColor: theme.backgroundColor,
        display: "flex",
        height: "100%",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: breadcrumbMargin,
          marginBottom: breadcrumbMargin,
          height: breadcrumbHeight,
          paddingRight: 24,
        }}
      >
        <div
          style={{
            flexGrow: 1,
            display: "flex",
            alignItems: "baseline",
            paddingLeft: 12,
          }}
        >
          <FolderBreadcrumb
            pathToParentFolder={props.pathToParentFolder}
            onPathToFolderChanged={props.onPathToFolderChanged}
          />
        </div>
        {hasAccessToSomeDashboards &&
          (layout === "grid" ? (
            <Tooltip
              placement={"bottom"}
              title={formatMessage({ id: "fileList.switchToList" })}
            >
              <UnorderedListOutlined
                style={{ fontSize: 18 }}
                onClick={() => {
                  setLayout("list");
                }}
              />
            </Tooltip>
          ) : (
            <Fragment>
              <NewFolderButton
                style={{ marginRight: 16 }}
                pathToParentFolder={props.pathToParentFolder}
                parentFolderMetaData={folderMetaData}
                parentFolder={props.folder}
              />
              <NewDashboardButton
                pathToParentFolder={props.pathToParentFolder}
                style={{ marginRight: 16 }}
              />
              <Tooltip
                placement={"bottom"}
                title={formatMessage({ id: "fileList.switchToGrid" })}
              >
                <AppstoreOutlined
                  style={{ fontSize: 18 }}
                  onClick={() => {
                    setLayout("grid");
                  }}
                />
              </Tooltip>
            </Fragment>
          ))}
      </div>
      <div
        style={{
          ...(layout === "grid" && { overflow: "auto" }),
          flexGrow: 1,
          width: "100%",
          backgroundColor: theme.grayScale[2],
          padding: 12,
          paddingBottom: 0,
        }}
      >
        {!hasAccessToSomeDashboards ? (
          <div
            style={{
              backgroundColor: theme.backgroundColor,
              boxShadow: `0px 2px 8px 0px ${theme.black}1A`,
              flexGrow: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              flexDirection: "column",
            }}
          >
            <IconEmptyFolder style={{ fontSize: 164 }} />
            <div style={{ marginTop: 20, marginBottom: 30 }}>
              <Title level={2}>
                {formatMessage({
                  id: canManageContent
                    ? "createFirstDashboard"
                    : "requestAccessToDashboards",
                })}
              </Title>
            </div>
            <div>
              <NewFolderButton
                parentFolder={props.folder}
                parentFolderMetaData={folderMetaData}
                pathToParentFolder={props.pathToParentFolder}
                style={{
                  height: 44,
                  marginRight: 16,
                  width: 183,
                }}
              />
              <NewDashboardButton
                pathToParentFolder={props.pathToParentFolder}
                style={{
                  height: 44,
                  width: 183,
                }}
              />
            </div>
          </div>
        ) : (
          <div css={{ height: `100%` }}>
            {layout === "grid" ? (
              <DashboardGrid
                pathToParentFolder={props.pathToParentFolder}
                onFolderDoubleClicked={props.onPathToFolderChanged}
                selectedRanges={selectedRanges}
                onItemSelected={handleItemSelected}
                clearSelection={clearSelection}
                onFileDoubleClicked={handleDashboardOpened}
                folder={props.folder}
                folderMetaData={folderMetaData}
                selectedFileRanges={selectedFileRanges}
                selectedFolderRanges={selectedFolderRanges}
                folders={folders}
                files={files}
                onAfterSubmit={onAfterSubmit}
              />
            ) : (
              <FileList
                contentType="dashboard"
                hasContextMenu={true}
                style={{ boxShadow: `0px -2px 8px 0px ${theme.black}1A` }}
                emptyHint={
                  canManageContent
                    ? ` ${formatMessage({ id: "emptyHint" })}`
                    : undefined
                }
                onFileOpened={handleDashboardOpened}
                pathToParentFolder={props.pathToParentFolder}
                onPathToFolderChanged={props.onPathToFolderChanged}
                mode={"explorer"}
                isHome={true}
              />
            )}
          </div>
        )}
      </div>
      <BrandSignatureFooter />
    </div>
  );
};
