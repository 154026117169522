import { _flattenIdempotentCall } from "./_flattenIdempotentCall.js";
import { _flattenNestedCall } from "./_flattenNestedCall.js";
import { _nullifyIfAllArgsAreDefaultMembers } from "./_nullifyIfAllArgsAreDefaultMembers.js";
import { _nullifyIfMandatoryArgRemoved } from "./_nullifyIfMandatoryArgRemoved.js";
import { _nullifyIfNotEnoughArgs } from "./_nullifyIfNotEnoughArgs.js";
import { _replaceWithFirstArgIfMandatoryArgRemoved } from "./_replaceWithFirstArgIfMandatoryArgRemoved.js";
import { _replaceWithOnlyRemainingArg } from "./_replaceWithOnlyRemainingArg.js";
/**
 * Returns a function that calls the given `cleanupFunctions` in order,
 * stopping whenever one of them returns `true`, meaning that it did clean up the function node.
 *
 * This allows to perform some cleanup steps only if the function has not already been cleaned up.
 * For example, if a function has been replaced by its first argument, it should not be nullified afterwards.
 */ export function composeCleanupFunctions(...cleanupFunctions) {
    return function(cleanupArgs) {
        let shouldStop = false;
        for (const cleanupFunction of cleanupFunctions){
            shouldStop = cleanupFunction(cleanupArgs);
            if (shouldStop) {
                break;
            }
        }
        return shouldStop;
    };
}
/**
 * The default way to cleanup a function node.
 * It nullifies the node if at least one of the two following conditions is met:
 * - It does not have enough arguments.
 * - One of its mandatory arguments has been removed.
 */ export const defaultCleanup = composeCleanupFunctions(_nullifyIfNotEnoughArgs, _nullifyIfMandatoryArgRemoved);
/**
 * Cleans up a function in the same way as a Crossjoin would be cleaned.
 * This is mostly for factorization, and it does not necessarily mean that
 * the function is equivalent to a Crossjoin.
 */ export const cleanupLikeCrossjoin = composeCleanupFunctions(_flattenNestedCall, _nullifyIfAllArgsAreDefaultMembers, _replaceWithOnlyRemainingArg, _nullifyIfNotEnoughArgs);
/**
 * Cleans up a function in the same way as a Union would be cleaned.
 * This is mostly for factorization, and it does not necessarily mean that
 * the function is equivalent to a Union.
 */ export const cleanupLikeUnion = composeCleanupFunctions(_flattenNestedCall, _replaceWithOnlyRemainingArg, _nullifyIfNotEnoughArgs);
/**
 * Cleans up a function in the same way as an Except would be cleaned.
 * This is mostly for factorization, and it does not necessarily mean that
 * the function is equivalent to an Except.
 */ export const cleanupLikeExcept = composeCleanupFunctions(_replaceWithFirstArgIfMandatoryArgRemoved, _nullifyIfNotEnoughArgs);
/**
 * Cleans up an idempotent function node, e.g. `AddCalculatedMembers`.
 */ export const cleanupIdempotentFunction = composeCleanupFunctions(_flattenIdempotentCall, defaultCleanup);
