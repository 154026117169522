import { produce } from "immer";
import { _isMdxSubSelect } from "./internal/_isMdxSubSelect.js";
import { isMdxDrillthrough } from "./isMdxDrillthrough.js";
function getFromClause(subSelect) {
    let fromClause = subSelect.from;
    while(_isMdxSubSelect(fromClause)){
        fromClause = fromClause.from;
    }
    return fromClause;
}
export function setCubeName(mdx, { cubeName  }) {
    if (isMdxDrillthrough(mdx)) {
        return {
            ...mdx,
            select: setCubeName(mdx.select, {
                cubeName
            })
        };
    }
    return produce(mdx, (draft)=>{
        if (_isMdxSubSelect(draft.from)) {
            getFromClause(draft.from).cubeName = cubeName;
        } else {
            draft.from.cubeName = cubeName;
        }
    });
}
