import { findLastIndex as _findLastIndex } from "lodash-es";
import { getHierarchy } from "@activeviam/data-model";
import { isTotal } from "./isTotal.js";
/**
 * Returns `tupleCoordinates` without its potential trailing totals.
 * Does not mutate `tupleCoordinates`.
 */ export function trimTrailingTotals(tupleCoordinates, cube) {
    const lastNonTotalIndex = _findLastIndex(tupleCoordinates, (tupleCoordinate)=>{
        if (tupleCoordinate.dimensionName === "Measures") {
            return true;
        }
        const hierarchy = getHierarchy(tupleCoordinate, cube);
        return !isTotal(hierarchy, tupleCoordinate);
    });
    return tupleCoordinates.slice(0, lastNonTotalIndex + 1);
}
