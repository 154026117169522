import lexer from "@activeviam/mdx-lexer";
import parser from "@activeviam/mdx-parser";
import * as definitions from "./parsing/definitions.js";
import * as nodeCreators from "./parsing/nodeCreators.js";
import * as parsingUtils from "./parsing/parsingUtils.js";
import { MdxError } from "./parsing/MdxError.js";
// This function is given as input to jison.
// We are not free to choose its signature.
// eslint-disable-next-line max-params
const parseError = function(realStack, input, err, hash) {
    const error = new MdxError(err.message, input, hash);
    // if we use the current stack, we just have info about where in the lexer it failed, which we don't care.
    // real stack provides info on the callers, much more interesting
    error.stack = realStack;
    throw error;
};
// This is required to provide the lexer to the parser
parser.lexer = lexer;
// make create functions available to parser
parser.yy.lib = nodeCreators;
parser.yy.utils = parsingUtils;
parser.yy.def = {
    ...definitions,
    SlicerAxisName: "SLICER"
};
// to add information about current parsing MDX
const originalParse = parser.parse.bind(parser);
/**
 * Wraps the Jison-generated MDX parser with error handling
 * @param inputMdx - MDX query to parse
 * Returns the parsed MDX query
 * @throws {MdxError} if the MDX query is invalid
 */ parser.parse = function(inputMdx) {
    // Handle parse errors and recover from ASI
    // @ts-expect-error: Types Error and string do not match
    parser.yy.parseError = parseError.bind(this, new Error().stack, inputMdx);
    try {
        return originalParse(inputMdx);
    } finally{
        parser.yy.parseError = undefined;
    }
};
/**
 * Returns the Mdx abstract syntax tree corresponding to `mdxString`
 * @throws {@link MdxError} when `mdxString` does not represent a valid Mdx query.
 */ export function parse(mdxString) {
    const mdx = parser.parse(mdxString);
    return mdx;
}
