/**
 * Returns the {@link MdxHierarchyCompoundIdentifier} corresponding to the given hierarchy coordinates.
 */ export function createHierarchyCompoundIdentifier({ dimensionName , hierarchyName  }) {
    return {
        elementType: "CompoundIdentifier",
        type: "hierarchy",
        identifiers: [
            dimensionName,
            hierarchyName
        ].map((value)=>({
                elementType: "Identifier",
                quoting: "QUOTED",
                value
            })),
        dimensionName,
        hierarchyName
    };
}
