import { produce } from "immer";
import { findDescendant } from "./findDescendant.js";
import { _cleanupMdx } from "./internal/_cleanupMdx.js";
import { _nullifyNodeAtPath } from "./internal/_nullifyNodeAtPath.js";
import { isMdxCompoundIdentifier } from "./isMdxCompoundIdentifier.js";
const _isCompoundIdentifierOfHierarchy = (mdx, { dimensionName: targetDimension , hierarchyName: targetHierarchy  })=>{
    if (!isMdxCompoundIdentifier(mdx)) {
        return false;
    }
    const hasTargetDimensionName = mdx.identifiers[0].value === targetDimension;
    const hasTargetHierarchyName = mdx.identifiers[1].value === targetHierarchy;
    return hasTargetDimensionName && hasTargetHierarchyName;
};
/**
 * Returns a new {@link Mdx} where all identifiers corresponding to the given hierarchy are removed.
 * This is unsafe because the removed identifiers are not interpreted with regards to a specific cube: it is not precise and will not work with all syntaxes.
 * For example, it would not remove identifier `[Geography].[AllMember].[City]` because the hierarchy name is omitted.
 *
 * Resorting to this function is justified only when the hierarchy to remove cannot be found in the cube it is supposed to be in.
 * This can occur when the cube structure has changed on the server side but the content depending on it has not been updated on the client side.
 *
 * Does not mutate mdx.
 */ export const unsafeRemoveHierarchy = (mdx, hierarchyCoordinates, cube)=>{
    return produce(mdx, (draft)=>{
        let nextNode;
        while(nextNode = findDescendant(draft, (node)=>_isCompoundIdentifierOfHierarchy(node, hierarchyCoordinates))){
            _nullifyNodeAtPath(draft, nextNode.path);
            _cleanupMdx(draft, nextNode.path, cube);
        }
    });
};
