import { getSpecificCompoundIdentifier } from "./getSpecificCompoundIdentifier.js";
import { isMdxCompoundIdentifier } from "./isMdxCompoundIdentifier.js";
/**
 * Returns whether `mdx` is a {@link MdxNamedSetCompoundIdentifier}, with the given name (if any).
 */ export function isMdxNamedSetCompoundIdentifier(mdx, { setName , cube  }) {
    if (!isMdxCompoundIdentifier(mdx)) {
        return false;
    }
    const specificCompoundIdentifier = getSpecificCompoundIdentifier(mdx, {
        cube
    });
    return specificCompoundIdentifier.type === "namedSet" && (!setName || specificCompoundIdentifier.setName === setName);
}
