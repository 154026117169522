import { set as _set } from "lodash-es";
/**
 * Replaces `functionNode` at `path` in `mdx` by its first argument if a mandatory argument has been removed.
 * In particular, this means that `functionNode` will be nullified if its first argument has been removed.
 * Returns whether `functionNode` was replaced.
 * Mutates `mdx`.
 *
 * @example
 * Order([MySet], null, BDESC) => [MySet]
 * Order(null, count, BDESC) => null
 */ export function _replaceWithFirstArgIfMandatoryArgRemoved({ mdx , path , functionNode , minNumberOfArgs , indexOfFirstRemovedArg  }) {
    if (indexOfFirstRemovedArg === 0) {
        _set(mdx, path, null);
        return true;
    }
    if (indexOfFirstRemovedArg !== -1 && indexOfFirstRemovedArg < minNumberOfArgs) {
        _set(mdx, path, functionNode.arguments[0]);
        return true;
    }
    return false;
}
