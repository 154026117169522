import { useParams } from "react-router-dom";

import { useTree } from "@activeviam/activeui-sdk";
import { getContentRecordAtPath } from "@activeviam/content-client";

import { usePathToParentFolder } from "../PathToParentFolderContext.js";

/**
 * React hook that returns whether the current dashboard is read-only.
 */
export function useIsDashboardReadOnly(): boolean {
  const dashboardsTree = useTree("dashboard");
  const pathToParentFolder = usePathToParentFolder();
  const { id } = useParams<{
    id?: string;
  }>();

  const contentRecord =
    id && id !== "unsaved"
      ? getContentRecordAtPath({
          tree: dashboardsTree,
          path: [...(pathToParentFolder ?? []), id],
        })
      : undefined;

  return Boolean(contentRecord && !contentRecord?.entry.canWrite);
}
