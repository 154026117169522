import { FC, useMemo } from "react";

import {
  HighlightedSearchResult,
  MissingMetaDataError,
  useTheme,
  useTree,
} from "@activeviam/activeui-sdk";

import { getCaptionsOfFolders } from "../../utils/getCaptionsOfFolders.js";

interface HomeSearchResultProps {
  path: string[];
  dashboardName: string;
  searchValue: string;
}

/**
 * Displays the name of the given dashboard and the names of all its parent folders starting from the root of the tree.
 * Adds an ellipsis at the end of the dashboard name if it is too long.
 * Adds an ellipsis at the beginning of the folder path if it is too long.
 */
export const HomeSearchResult: FC<HomeSearchResultProps> = ({
  path,
  dashboardName,
  searchValue,
}) => {
  const dashboardsTree = useTree("dashboard");
  const theme = useTheme();

  const captionsOfParentFolders = useMemo(() => {
    try {
      return getCaptionsOfFolders(path.slice(0, -1), dashboardsTree);
    } catch (error) {
      if (error instanceof MissingMetaDataError) {
        // When the user has access to a dashboard but not to its parent folder, then the path is not shown.
        return [];
      }
      throw error;
    }
  }, [dashboardsTree, path]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: 555,
      }}
    >
      <div
        style={{
          flexShrink: 0,
          maxWidth: 350,
        }}
      >
        <HighlightedSearchResult
          caption={dashboardName}
          searchValue={searchValue}
          css={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        />
      </div>
      <div
        style={{
          color: theme.grayScale[7],
          fontSize: 10,
          overflow: "hidden",
          whiteSpace: "nowrap",
          direction: "rtl",
          textAlign: "right",
          textOverflow: "ellipsis",
          marginLeft: 25,
        }}
      >
        {captionsOfParentFolders.map(({ caption }) => caption).join("/")}
      </div>
    </div>
  );
};
