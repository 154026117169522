import { produce } from "immer";
import { last as _last } from "lodash-es";
import { areHierarchiesEqual, getHierarchy, getLevelIndex } from "@activeviam/data-model";
import { _expandInAxis } from "./expand.js";
import { getExpandedTuples } from "./getExpandedTuples.js";
import { getLevelName } from "./getLevelName.js";
import { _collapseMembersOfLevel } from "./internal/_collapseMembersOfLevel.js";
import { _getIndexOfAxisContainingHierarchy } from "./internal/_getIndexOfAxisContainingHierarchy.js";
/**
 * Adds a hierarchy after the level identified by `coordinatesOfLevelBefore` in the corresponding expansion chain.
 *
 * The members of `coordinatesOfLevelBefore` that were already expanded are collapsed, then re-expanded down to the first level of the given hierarchy.
 * Has no effect if `coordinatesOfLevelBefore` is not part of an expansion chain (i.e. if no members of `coordinatesOfLevelBefore` are expanded).
 *
 * Does not mutate `mdx`.
 */ export function addHierarchyToExpansionChain(mdx, { cube , hierarchyCoordinates , coordinatesOfLevelBefore  }) {
    const axisIndex = _getIndexOfAxisContainingHierarchy(mdx, {
        ...coordinatesOfLevelBefore,
        cube
    });
    if (axisIndex === -1) {
        return mdx;
    }
    const axis = mdx.axes[axisIndex];
    const expandedTuples = getExpandedTuples(axis, cube);
    const indexOfLevelBefore = getLevelIndex({
        cube,
        ...coordinatesOfLevelBefore
    });
    // Finds the expanded tuples that end with a member from the target parent level.
    // They will be collapsed, then re-expanded on the first level of the hierarchy being added.
    const tuplesToReExpand = expandedTuples.filter(({ tupleCoordinates  })=>{
        const lastMemberCoordinates = _last(tupleCoordinates);
        return areHierarchiesEqual(lastMemberCoordinates, coordinatesOfLevelBefore) && indexOfLevelBefore === lastMemberCoordinates.namePath.length - 1;
    }).map(({ tupleCoordinates  })=>tupleCoordinates);
    if (tuplesToReExpand.length === 0) {
        return mdx;
    }
    const collapsedAxis = _collapseMembersOfLevel(axis, coordinatesOfLevelBefore, cube);
    const hierarchyToAdd = getHierarchy(hierarchyCoordinates, cube);
    const levelName = getLevelName(hierarchyToAdd, hierarchyToAdd.slicing ? 0 : 1);
    const reExpandedAxis = tuplesToReExpand.reduce((newMdx, tupleToReExpand)=>{
        return _expandInAxis(newMdx, {
            cube,
            tupleCoordinates: tupleToReExpand,
            toLevel: {
                ...hierarchyCoordinates,
                levelName
            }
        });
    }, collapsedAxis);
    return produce(mdx, (draft)=>{
        draft.axes[axisIndex] = reExpandedAxis;
    });
}
