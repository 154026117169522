import { composeWithDevToolsDevelopmentOnly } from "@redux-devtools/extension";
import { FC, PropsWithChildren, useMemo } from "react";
import { Provider as ReduxProvider } from "react-redux";
import { StoreEnhancer, createStore } from "redux";

import { reducer as applicationShellReducer } from "@activeviam/application-state";

import { useConfiguration } from "../ConfigurationContext.js";

/**
 * Returns the Atoti UI Redux store, based on the application shell state and possibly enhanced by extensions.
 */
function createEnhancedStore(storeEnhancers: StoreEnhancer[]) {
  /**
   * Returns a Redux store enhancer obtained by composing the input enhancers.
   *
   * In dev mode, the Redux devtools enhancer is added.
   * A special way of composing is needed for it: see https://github.com/reduxjs/redux-devtools/tree/main/extension#13-use-redux-devtoolsextension-package-from-npm.
   * TODO https://activeviam.atlassian.net/browse/UI-7043 Make the Redux devtools accessible when using the starter, and not just when using the application shell as a standalone.
   */
  const composeEnhancers = composeWithDevToolsDevelopmentOnly;

  const storeEnhancer = composeEnhancers(...storeEnhancers);

  /**
   * An extension developer might forget to forward the preloaded state when defining a store enhancer.
   *
   * E.g.:
   * ```
   * const enhancerThatDoesNotForwardPreloadedState = createStore => (reducer, preloadedState) => {
   *   const enhancedReducer = (state, action) => {
   *     // Some custom reducer logic...
   *   }
   *   // Note how the preloaded state is not forwarded.
   *   return createStore(enhancedReducer);
   * }
   * ```
   *
   * TypeScript will not warn against this usage since `preloadedState` is legitimately an optional argument of `createStore`.
   * However, this is dangerous when store enhancers are composed.
   * The example enhancer above would remove any preloaded state defined in the application shell or another extension.
   *
   * To avoid this kind of hard-to-debug issues, a dummy preloaded state is passed when creating the store.
   * If it is not found once the store is created, a warning is output in the console.
   */
  const safeguardPreloadedState = {
    __SAFE_ENHANCER_USAGE_FLAG__: true,
  };

  const store = createStore(
    applicationShellReducer,
    safeguardPreloadedState,
    storeEnhancer,
  );

  if (!store.getState().__SAFE_ENHANCER_USAGE_FLAG__) {
    // eslint-disable-next-line no-console
    console.warn(
      "The preloaded state of the Atoti UI Redux store does not have the expected shape. This means that you're using at least one Atoti UI extension defining a store enhancer that does not forward the preloaded state properly. You should fix the store enhancer implementations in your own extensions if applicable, and contact the maintainers of any external faulty extension.",
    );
  }

  return store;
}

/**
 * Component that creates the Redux store and provides it to its children.
 */
export const EnhancedReduxProvider: FC<PropsWithChildren> = ({ children }) => {
  const { storeEnhancers } = useConfiguration();

  const store = useMemo(() => createEnhancedStore(storeEnhancers), [
    storeEnhancers,
  ]);

  return <ReduxProvider store={store}>{children}</ReduxProvider>;
};
