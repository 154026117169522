import { css } from "@emotion/react";
import { MouseEvent } from "react";
import { useIntl } from "react-intl";

import {
  AMetaData,
  ContentRecord,
  DashboardMetaData,
  getMetaData,
  usePermission,
} from "@activeviam/activeui-sdk";
import { FileOrFolderInExplorer } from "@activeviam/file-list";
import { Text } from "@activeviam/utils-react";

import { FolderCard } from "./FolderCard.js";
import { NewFolderCard } from "./NewFolderCard.js";

interface FolderCardsProps {
  pathToParentFolder: string[];
  subfolders: FileOrFolderInExplorer[];
  folder: ContentRecord;
  onSubfolderDoubleClicked: (pathToSubFolder: string[]) => void;
  selectedFolderRanges: { from: number; to: number }[];
  onFolderSelected: (id: number, event: MouseEvent) => void;
  folderMetaData?: AMetaData;
  onEditEnded: (
    pathToParentFolder: string[],
    metaData: DashboardMetaData,
  ) => Promise<void>;
  onEditCanceled: () => void;
  idOfFolderBeingRenamed?: string;
}

/**
 * Displays the folders contained in `folder` as cards.
 */
export function FolderCards(props: FolderCardsProps): JSX.Element {
  const [canManageContent] = usePermission("canManageContent");
  const { formatMessage } = useIntl();

  if (!canManageContent && props.subfolders.length === 0) {
    return (
      <Text
        small={true}
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {formatMessage({ id: "folderWithNoSubfolders" })}
      </Text>
    );
  }

  return (
    <div
      css={css`
        width: 100%;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 12px;
        -webkit-user-select: none;
        -moz-user-select: none;
      `}
    >
      {canManageContent && (
        <NewFolderCard
          parentFolder={props.folder}
          pathToParentFolder={props.pathToParentFolder}
          parentFolderMetaData={props.folderMetaData}
        />
      )}
      {props.subfolders.map(({ record, id }, index) => {
        return (
          <FolderCard
            key={id}
            metaData={getMetaData(record, id)}
            id={id}
            onFolderDoubleClicked={() => {
              props.onSubfolderDoubleClicked([...props.pathToParentFolder, id]);
            }}
            pathToParentFolder={props.pathToParentFolder}
            isSelected={props.selectedFolderRanges.some(
              (range) => range.from <= index && index <= range.to,
            )}
            onFolderSelected={(event) => props.onFolderSelected(index, event)}
            onEditEnded={props.onEditEnded}
            onEditCanceled={props.onEditCanceled}
            isRenaming={props.idOfFolderBeingRenamed === id}
          />
        );
      })}
    </div>
  );
}
