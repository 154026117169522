import { createMeasureCompoundIdentifier } from "./createMeasureCompoundIdentifier.js";
import { createMemberCompoundIdentifier } from "./createMemberCompoundIdentifier.js";
/**
 * Returns the {@link MdxCompoundIdentifier} corresponding to `memberCoordinates`.
 * More specifically:
 * - It returns an {@link MdxMeasureCompoundIdentifier} if the coordinates represent a measure.
 * - It returns an {@link MdxMemberCompoundIdentifier} otherwise.
 */ export function createMemberOrMeasureCompoundIdentifier(memberCoordinates, cube) {
    if (memberCoordinates.dimensionName === "Measures") {
        const measureName = memberCoordinates.namePath[0];
        return createMeasureCompoundIdentifier(measureName);
    }
    return createMemberCompoundIdentifier(memberCoordinates, cube);
}
