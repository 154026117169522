import { useLocation } from "react-router-dom";

const unsavedDashboardRegex = /unsaved\/\d+$/;

/**
 * React hook returning the unsaved dashboard counter, if any.
 * This counter is the last part of the URL of an unsaved dashboard.
 *
 * @example
 *
 * - if URL ends with /dashboard/unsaved, then useUnsavedDashboardCounter() -> undefined
 * - if URL ends with /dashboard/unsaved/2, then useUnsavedDashboardCounter() -> 2
 * - if URL ends with /dashboard/unsaved/3, then useUnsavedDashboardCounter() -> 3
 */
export function useUnsavedDashboardCounter(): string | undefined {
  const { pathname } = useLocation();
  const dashboardPathParts = pathname.split("/").splice(-2);
  // Check to see if the dashboard is unsaved with a counter.
  const isUnsavedDashboardWithCounter = unsavedDashboardRegex.test(
    dashboardPathParts.join("/"),
  );
  if (!isUnsavedDashboardWithCounter) {
    return undefined;
  }
  return dashboardPathParts[1];
}
