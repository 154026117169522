import { _addSort } from "./internal/_addSort.js";
import { removeSort } from "./removeSort.js";
/**
 * Returns a new {@link MdxSelect} corresponding to `mdx`, sorted as specified.
 * Does not mutate mdx.
 *
 * @param levelCoordinates - should be specified in order to sort alphabetically based on the names of the members of a level.
 * @param tupleCoordinates - should be specified in order to sort numerically based on the value of a measure.
 */ export function setSort(mdx, { cube , levelCoordinates , orderMode , tupleCoordinates  }) {
    return _addSort(removeSort(mdx), {
        cube,
        tupleCoordinates,
        levelCoordinates,
        orderMode
    });
}
