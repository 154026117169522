const extractErrorDetails = function(input, hash) {
    let firstPosition = 0;
    let lastPosition = 0;
    if (hash.loc) {
        firstPosition = hash.loc.first_column;
        lastPosition = hash.loc.last_column;
        if (hash.text.length > 0) {
            if (hash.line === hash.loc.last_line - 1) {
                firstPosition = hash.loc.last_column + 1;
            } else {
                // in order to find the text we have to go back to the original text
                // because sometimes last_column refers to the last character of the previous line
                const lines = input.split("\n");
                firstPosition = lines[lines.length - 1].indexOf(hash.text);
            }
            lastPosition = firstPosition + hash.text.length;
        }
    }
    return {
        text: hash.text,
        expected: hash.expected,
        from: {
            line: hash.line,
            ch: firstPosition
        },
        to: {
            line: hash.line,
            ch: lastPosition
        }
    };
};
/**
 * Thrown when parsing an invalid Mdx query.
 */ export class MdxError extends Error {
    static type = "MdxError";
    constructor(message, input, hash){
        super(message);
        this.details = extractErrorDetails(input, hash);
    }
}
