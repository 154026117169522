import { last as _last } from "lodash-es";
import { getHierarchy } from "@activeviam/data-model";
import { isTotal } from "./isTotal.js";
import { trimTrailingTotals } from "./trimTrailingTotals.js";
/**
 * Returns the hierarchy and level index of the left-most different member between `previousTuple` and `tuple`.
 */ const getHierarchyIndexAndLevelIndexOfLeftMostDifferentMember = (previousTuple, tuple, maxLevelPerHierarchy)=>{
    if (!previousTuple) {
        return [
            0,
            0
        ];
    }
    for(let hierarchyIndex = 0; hierarchyIndex < maxLevelPerHierarchy.length; hierarchyIndex++){
        for(let levelIndex = 0; levelIndex < maxLevelPerHierarchy[hierarchyIndex]; levelIndex++){
            const previousMember = previousTuple[hierarchyIndex];
            const member = tuple[hierarchyIndex];
            if (member.namePath[levelIndex] !== previousMember.namePath[levelIndex]) {
                return [
                    hierarchyIndex,
                    levelIndex
                ];
            }
        }
    }
    return [
        maxLevelPerHierarchy.length - 1,
        _last(maxLevelPerHierarchy) ?? 0
    ];
};
const getHierarchyIndexOfRightMostNonTotalMember = (tuple, cube)=>{
    const tupleWithoutTrailingTotals = trimTrailingTotals(tuple, cube);
    return tupleWithoutTrailingTotals.length;
};
/**
 * Returns the totals that are missing in between `previousTuple` and `tuple`.
 */ export const getMissingTotals = (previousTuple, tuple, { hierarchies , maxLevelPerHierarchy , cube  })=>{
    const missingTotals = [];
    let [hierarchyIndex, levelIndex] = getHierarchyIndexAndLevelIndexOfLeftMostDifferentMember(previousTuple, tuple, maxLevelPerHierarchy);
    const maxHierarchyIndex = getHierarchyIndexOfRightMostNonTotalMember(tuple, cube);
    // Iterating across hierarchies and levels appearing in `tuple` but not `previousTuple`.
    while(hierarchyIndex < maxHierarchyIndex){
        const member = tuple[hierarchyIndex];
        while(levelIndex < member.namePath.length){
            if (hierarchyIndex === maxHierarchyIndex - 1 && levelIndex === member.namePath.length - 1) {
                break;
            }
            // New missing totals are added, ensuring that each position is exactly 1 level deeper than the previous one.
            const addedTuple = [
                ...tuple.slice(0, hierarchyIndex),
                {
                    ...member,
                    namePath: member.namePath.slice(0, levelIndex + 1),
                    captionPath: member.captionPath.slice(0, levelIndex + 1)
                }
            ];
            const hierarchy = member.dimensionName === "Measures" ? null : getHierarchy(member, cube);
            if (hierarchy && addedTuple.every((member)=>isTotal(hierarchy, member))) {
            // Don't add the grand total.
            } else {
                missingTotals.push(addedTuple);
            }
            levelIndex++;
        }
        hierarchyIndex++;
        if (hierarchyIndex < hierarchies.length) {
            const hierarchy = hierarchies[hierarchyIndex];
            // hierarchy === null means that this is the "Measures" columns.
            levelIndex = hierarchy === null || hierarchy.slicing ? 0 : 1;
        }
    }
    return missingTotals;
};
