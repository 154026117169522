import { isMdxCompoundIdentifier } from "./isMdxCompoundIdentifier.js";
import { isMdxFunction } from "./isMdxFunction.js";
/**
 * Returns a new {@link MdxDrillthrough} corresponding to `mdx` where the column identified
 * by `drillthroughColumnUniqueName` was removed. Does not mutate `mdx`.
 */ export function removeDrillthroughColumn(mdx, { drillthroughColumnUniqueName  }) {
    if (!mdx.columns) {
        return mdx;
    }
    const newColumns = mdx.columns.filter((columnExpression)=>{
        return (isMdxFunction(columnExpression, "membervalue") || isMdxFunction(columnExpression, "caption")) && isMdxCompoundIdentifier(columnExpression.arguments[0]) && columnExpression.arguments[0].identifiers[0].value !== drillthroughColumnUniqueName;
    });
    if (newColumns.length === mdx.columns.length) {
        // no column was removed
        return mdx;
    }
    return {
        ...mdx,
        columns: newColumns
    };
}
