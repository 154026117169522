import { css } from "@emotion/react";
import { Card } from "antd";
import { fill as _fill } from "lodash-es";
import { CSSProperties } from "react";
import { useIntl } from "react-intl";

import { useTheme } from "@activeviam/activeui-sdk";

// With the dummy button, this places 5 rows of 6 dashboards each.
const cards = _fill(new Array(29), null);

interface DashboardsSkeletonProps {
  cardStyle: CSSProperties;
  cardProps: {
    headStyle: CSSProperties;
    bordered: boolean;
    bodyStyle: CSSProperties;
  };
}

/**
 * Displays gray placeholder rectangles for dashboards while waiting for the home page to load.
 * Also includes a faded blue placeholder rectangle for the "Create new dashboard" button.
 */
export const DashboardsSkeleton = (props: DashboardsSkeletonProps) => {
  const { cardStyle, cardProps } = props;
  const { formatMessage } = useIntl();
  const theme = useTheme();

  return (
    <Card
      title={formatMessage({ id: "aui.contentTree.dashboards" })}
      {...cardProps}
      style={{ ...cardStyle, flexGrow: 1 }}
    >
      <div
        css={css`
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          padding-bottom: 16px;
          -webkit-user-select: none;
          -moz-user-select: none;
        `}
      >
        <div
          css={css`
            border-radius: 2px;
            height: 150px;
            background: ${theme.selectionColor};
            height: 220px;
            width: 240px;
            margin: 12px 12px 0 0;
          `}
        />
        {cards.map((item, index) => (
          <div
            key={index}
            css={css`
              border-radius: 2px;
              height: 150px;
              background: ${theme.grayScale[4]};
              height: 220px;
              width: 240px;
              margin: 12px 12px 0 0;
            `}
          />
        ))}
      </div>
    </Card>
  );
};
