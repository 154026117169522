import { set as _set } from "lodash-es";
/**
 * Replaces `functionNode` at `path` in `mdx` by its first argument if it is the only one remaining.
 * Returns whether `functionNode` was replaced.
 * Mutates `mdx`.
 *
 * @example
 * Crossjoin({EUR, GBP}, null) => {EUR, GBP}
 */ export function _replaceWithOnlyRemainingArg({ mdx , path , functionNode  }) {
    if (functionNode.arguments.length === 1) {
        _set(mdx, path, functionNode.arguments[0]);
        return true;
    }
    return false;
}
