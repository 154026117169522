import { useSelector } from "react-redux";

import type { ApplicationMenuItem } from "@activeviam/activeui-sdk";
import { getStatusNotifications } from "@activeviam/activeui-sdk";

import { StatusNotificationIconAndLabel } from "./StatusNotificationIconAndLabel.js";

/**
 * {@link ApplicationMenuItem} displaying an icon and some text indicating that some asynchronous action is taking place.
 */
export const statusNotificationMenuItem: ApplicationMenuItem = {
  key: "status-notification",
  useMenuItem: () => {
    const statusNotifications = useSelector(getStatusNotifications);
    const lastNotification =
      statusNotifications[statusNotifications.length - 1];

    if (!lastNotification) {
      return undefined;
    }

    const { action, status } = lastNotification;

    return StatusNotificationIconAndLabel(action, status);
  },
};
