import { getMeasureName } from "./getMeasureName.js";
import { isMdxCompoundIdentifier } from "./isMdxCompoundIdentifier.js";
/**
 * Returns whether `mdx` represents a {@link Measure}.
 */ export const isACompoundIdentifierRepresentingAMeasure = (mdx, { measureName  } = {})=>{
    const isMeasure = isMdxCompoundIdentifier(mdx) && mdx.identifiers[0].value.toLowerCase() === "measures" && mdx.identifiers[1] !== undefined;
    if (!isMeasure) {
        return false;
    }
    // @ts-expect-error The check above guarantees that at this stage `mdx` is MdxUnknownCompoundIdentifier | MdxMeasureCompoundIdentifier
    if (measureName !== undefined && getMeasureName(mdx) !== measureName) {
        return false;
    }
    return true;
};
