import { SaveOutlined } from "@ant-design/icons";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import {
  ApplicationMenuItem,
  getContentRecordAtPath,
  getHasUnsavedChanges,
  useTree,
} from "@activeviam/activeui-sdk";

import { usePathToParentFolder } from "../../PathToParentFolderContext.js";
import { useSaveDashboard } from "../../hooks/useSaveDashboard.js";
import { useIsDashboardSaved } from "../useIsDashboardSaved.js";
import { MenuItemLabel } from "./MenuItemLabel.js";

const saveKey = "save";

/**
 * {@link ApplicationMenuItem} to save a dashboard with a different name.
 */
export const fileSaveMenuItem: ApplicationMenuItem = {
  key: "save-dashboard",
  useMenuItem: () => {
    const { formatMessage } = useIntl();
    const saveDashboard = useSaveDashboard();
    const dashboardsTree = useTree("dashboard");
    const pathToParentFolder = usePathToParentFolder();
    const { id: dashboardId } = useParams<{ id: string }>();
    const isDashboardSaved = useIsDashboardSaved();

    const hasUnsavedChanges = useSelector(getHasUnsavedChanges);

    const isDashboardReadOnly =
      dashboardId !== undefined &&
      isDashboardSaved &&
      !getContentRecordAtPath({
        tree: dashboardsTree,
        path: [...(pathToParentFolder ?? []), dashboardId],
      })?.entry.canWrite;

    const handleClicked = async () => {
      await saveDashboard();
    };

    const disabled = !hasUnsavedChanges || isDashboardReadOnly;

    return {
      icon: <SaveOutlined />,
      disabled,
      label: (
        <MenuItemLabel
          title={formatMessage({ id: "aui.save" })}
          shortcutKey={saveKey}
          disabled={disabled}
          tooltipTitle={
            isDashboardReadOnly
              ? formatMessage(
                  {
                    id: "aui.contentTree.notAllowedToPerformAction",
                  },
                  {
                    itemType: "dashboard",
                    action: saveKey,
                  },
                )
              : undefined
          }
        />
      ),
      onClick: handleClicked,
    };
  },
};
