/**
 * Fills up `rowIndexMapping` with approximations for the indices in the data of the rows below `rowIndexOnScreen` and above the next known row.
 * This is required when the user:
 * - lands on a lazy-loaded tree table
 * - scrolls to the bottom of it
 * - then scroll back halfway up
 *
 * In this situation, `rowIndexMapping` was filled up with approximations for the middle of the table when the chunk at the bottom of it was loaded.
 * See fillMissingChunkAbove.
 * Then the current row was filled up when the chunk in the middle was loaded.
 * See addMissingTotalRows.
 * This can create a hole right beneath the chunk in the middle of the table.
 *
 * This function fills up this potential hole.
 *
 * Mutates `rowIndexMapping`.
 */ export function fillMissingChunkBelow(rowIndexMapping, rowIndexOnScreen) {
    if (!rowIndexMapping || rowIndexOnScreen >= rowIndexMapping.length - 1) {
        return;
    }
    let i = rowIndexOnScreen;
    while(rowIndexMapping[i + 1] > rowIndexMapping[i] + 1){
        rowIndexMapping.splice(i + 1, 0, rowIndexMapping[i] + 1);
        i++;
    }
}
