import { _addExpressionToAxis } from "./internal/_addExpressionToAxis.js";
/**
 * Returns a new {@link MdxSelect} corresponding to `mdx` where the given set was added.
 */ export function addNamedSet(mdx, { cube , setName , axisName , indexOnAxis , shouldCreateNonEmptyAxis  }) {
    const addedExpression = {
        elementType: "CompoundIdentifier",
        identifiers: [
            {
                elementType: "Identifier",
                quoting: "QUOTED",
                value: setName
            }
        ],
        type: "namedSet",
        setName
    };
    return _addExpressionToAxis(mdx, {
        axisName,
        cube,
        indexOnAxis,
        shouldCreateNonEmptyAxis,
        addedExpression
    });
}
