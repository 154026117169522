import { InvalidMemberError } from "./errors/InvalidMemberError.js";
import { getSpecificCompoundIdentifier } from "./getSpecificCompoundIdentifier.js";
import { isMdxCompoundIdentifier } from "./isMdxCompoundIdentifier.js";
/**
 * Returns the {@link MemberCoordinates} corresponding to the given {@link MdxExpression}.
 * Throws {@link InvalidMemberError} if the expression does not represent a member.
 */ export function getMemberCoordinates(expression, options) {
    try {
        if (!isMdxCompoundIdentifier(expression)) {
            throw new InvalidMemberError(expression, options.cube.name);
        }
        const specificCompoundIdentifier = getSpecificCompoundIdentifier(expression, options);
        if (specificCompoundIdentifier.type === "measure") {
            return {
                dimensionName: "Measures",
                hierarchyName: "Measures",
                namePath: [
                    specificCompoundIdentifier.measureName
                ]
            };
        }
        if (specificCompoundIdentifier.type === "member") {
            return {
                dimensionName: specificCompoundIdentifier.dimensionName,
                hierarchyName: specificCompoundIdentifier.hierarchyName,
                namePath: specificCompoundIdentifier.path
            };
        }
        throw new InvalidMemberError(expression, options.cube.name);
    } catch (e) {
        if (e instanceof InvalidMemberError && options.shouldThrowOnInvalidMember === false) {
            return undefined;
        }
        throw e;
    }
}
