import { isEqual as _isEqual } from "lodash-es";
import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useMemo,
  useRef,
} from "react";
import { useLocation } from "react-router-dom";

import { ContentRecord, DashboardMetaData } from "@activeviam/activeui-sdk";
import { getPathToParentFolder } from "@activeviam/content-client";

import { useIsDashboardSaved } from "./components/useIsDashboardSaved.js";

/**
 * The path (of ids) to the current dashboard's parent folder.
 */
export type PathToParentFolder = string[];

/**
 * React Context that carries the provided PathToParentFolder.
 */
const PathToParentFolderContext = createContext<
  PathToParentFolder | undefined | null
>(null);

type PathToParentFolderProviderProps = {
  dashboardsTree: ContentRecord<DashboardMetaData> | null;
};

/**
 * React Context Provider, useful to provide the {@link PathToParentFolder} of a dashboard.
 * Allows underlying components to access the dashboard's PathToParentFolder.
 */
export const PathToParentFolderProvider: FC<PropsWithChildren<
  PathToParentFolderProviderProps
>> = ({ dashboardsTree, children }) => {
  const { pathname } = useLocation();
  const pathToParentFolderOrId = pathname
    .replaceAll(/\/dashboard/g, "")
    .replaceAll(/\/unsaved(?:\/\d+)?/g, "")
    .split("/")
    // "Empty" paths are filtered out, as they do not comprise real paths to a parent folder or id.
    .filter((path) => path !== "");

  const isDashboardSaved = useIsDashboardSaved();
  const pathToParentFolder = useMemo(() => {
    if (!isDashboardSaved) {
      return pathToParentFolderOrId;
    }
    return dashboardsTree
      ? getPathToParentFolder(dashboardsTree, pathToParentFolderOrId[0])
      : null;
  }, [dashboardsTree, isDashboardSaved, pathToParentFolderOrId]);

  // Update the array reference only if the array content has changed.
  const stablePathToParentFolderRef = useRef<string[] | undefined | null>(
    pathToParentFolder,
  );
  // `_isEqual` is used here to compare 2 small arrays of strings: not a performance threat.
  // eslint-disable-next-line atoti-ui/no-lodash-isequal
  if (!_isEqual(stablePathToParentFolderRef.current, pathToParentFolder)) {
    stablePathToParentFolderRef.current = pathToParentFolder;
  }

  return (
    <PathToParentFolderContext.Provider
      value={stablePathToParentFolderRef.current}
    >
      {children}
    </PathToParentFolderContext.Provider>
  );
};

/**
 * React hook returning the provided PathToParentFolder from context.
 * Returns `null` if the dashboard tree is not yet loaded.
 * Returns `undefined` if the dashboard does not exist or if the user does not have access to it.
 */
export function usePathToParentFolder(): PathToParentFolder | undefined | null {
  return useContext(PathToParentFolderContext);
}
