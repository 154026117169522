import { characterToEscape, separator } from "./quote.js";
const SEPARATOR_LENGTH = separator.length;
// In MDX, the character to escape is escaped by duplicating it.
const escapedCharacter = "]]";
// When we unescape, we remove the duplicated characters
const ESCAPE_FACTOR = escapedCharacter.length / characterToEscape.length;
const startAndEndWithBracketsRegex = /^\[.*\]$/;
const escapedCharacterRegExp = new RegExp(escapedCharacter, "g");
/**
 * Identify if the separator was in the source or created by the quote step
 */ const isSeparatorFromSource = (str, cursor)=>{
    let counter = 0;
    while(str.slice(cursor - counter - 1, cursor - counter) === characterToEscape){
        ++counter;
    }
    return counter % ESCAPE_FACTOR === 0;
};
function unescape(s) {
    return s.replace(escapedCharacterRegExp, characterToEscape);
}
/**
 * Converts a stringified Mdx compound identifier into the corresponding array of strings.
 *
 * @example
 * ```tsx
 * unquote('[a].[b]') // === ['a', 'b']
 * ```
 */ export function unquote(str) {
    let cursorStart = 0;
    let cursorEnd = 0;
    const result = [];
    // Check if the string needs unquoting
    if (!startAndEndWithBracketsRegex.test(str)) {
        return [
            str
        ];
    }
    // Remove `[` at the beginning and `]` at the end
    const strippedS = str.substr(1, str.length - 2);
    let end = false;
    while(!end){
        cursorEnd = strippedS.indexOf(separator, cursorEnd);
        if (cursorEnd !== -1) {
            if (isSeparatorFromSource(strippedS, cursorEnd)) {
                result.push(unescape(strippedS.slice(cursorStart, cursorEnd)));
                cursorStart = cursorEnd + SEPARATOR_LENGTH;
                cursorEnd += SEPARATOR_LENGTH;
            } else {
                ++cursorEnd;
            }
        } else {
            result.push(unescape(strippedS.slice(cursorStart)));
            end = true;
        }
    }
    return result;
}
