import { quote } from "@activeviam/mdx";
/**
 * For each hierarchy present in the CellSet, returns the index of the axis containing it in `cellSet.axes` and the index where this hierarchy can be found in `axis.hierarchies`.
 * Only gathers hierarchy indices in the axes corresponding to `axisNames`, if provided.
 * @example
 * If the CellSet has a single axis, which only contains members of the hierarchy "[Currency].[Currency]", this function will return:
 * {
 *   "[Currency].[Currency]": {axisIndex: 0, hierarchyIndex: 0}
 * }
 */ export const getHierarchyIndicesInCellSet = (data, options)=>{
    const hierarchyIndices = {};
    data.axes.forEach((axis, axisIndex)=>{
        if (// The hierarchies in the slicer should not be taken into account.
        axis.id !== -1 && (options === undefined || axis.id === 0 && options.axisNames.includes("COLUMNS") || axis.id === 1 && options.axisNames.includes("ROWS"))) {
            axis.hierarchies.forEach(({ dimension , hierarchy  }, hierarchyIndex)=>{
                hierarchyIndices[quote(dimension, hierarchy)] = {
                    dimensionName: dimension,
                    hierarchyName: hierarchy,
                    axisIndex,
                    hierarchyIndex
                };
            });
        }
    });
    return hierarchyIndices;
};
