import { fetchAndThrowIfNeeded } from "@activeviam/client";
/**
 * Asynchronously creates/drops/edits a member/kpi given the corresponding MDX statement.
 *
 * For the API, see:
 * - https://support.activeviam.com/confluence/display/AP5/Dynamically+adding+MDX+elements
 * - https://artifacts.activeviam.com/documentation/rest/6.0.0/activepivot.html#cube_configuration_execute_post
 *
 * For the supported calculated member/kpi properties, see:
 * - https://github.com/activeviam/activepivot/blob/876981bef9a65acbb228f97c53825a356de59382/pivot/core/impl/src/main/java/com/quartetfs/biz/pivot/definitions/impl/CalculatedMemberDescription.java
 * - https://github.com/activeviam/activepivot/blob/876981bef9a65acbb228f97c53825a356de59382/pivot/core/impl/src/main/java/com/quartetfs/biz/pivot/definitions/impl/KpiDescription.java
 */ export async function executeMdxDefinitionStatement({ url , pivotServiceVersion , requestInit , mdxDefinitionStatement , owners , readers  }) {
    await fetchAndThrowIfNeeded(`${url}${pivotServiceVersion.restPath}/cube/configuration/execute`, {
        ...requestInit,
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            ...requestInit?.headers
        },
        body: JSON.stringify({
            statement: mdxDefinitionStatement,
            owners,
            readers
        })
    });
}
