import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useState,
} from "react";

const IsSavingDashboardAsContext = createContext<
  [boolean, (isSavingDashboardAs: boolean) => void] | null
>(null);

/**
 * React Context Provider, allowing descendant components to know whether the current dashboard is being saved as.
 */
export const IsSavingDashboardAsProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const isSavingDashboardAsState = useState(false);
  return (
    <IsSavingDashboardAsContext.Provider value={isSavingDashboardAsState}>
      {children}
    </IsSavingDashboardAsContext.Provider>
  );
};

/**
 * React hook returning a reference to whether the current dashboard is being saved as.
 */
export function useIsSavingDashboardAs(): [
  boolean,
  (isSavingDashboardAs: boolean) => void,
] {
  const isSavingDashboardAsState = useContext(IsSavingDashboardAsContext);
  if (isSavingDashboardAsState === null) {
    throw new Error(
      "IsSavingDashboardAs not found. Did you forget to provide it with <IsSavingDashboardAsProvider /> ?",
    );
  }
  return isSavingDashboardAsState;
}
