import { activeuiVersion } from "@activeviam/activeui-version";
import { AWebSocketClientImpl, NoResponseError } from "@activeviam/client";
import { _lowerCaseKpiPropertyNames, getIndexedDataModel } from "@activeviam/data-model";
import { quote, stringify, unquote } from "@activeviam/mdx";
import { ensureError } from "@activeviam/utils";
import { activePivotClientReducer, clientInitialState } from "./activePivotClientReducer.js";
import { executeMdxDefinitionStatement } from "./executeMdxDefinitionStatement.js";
import { fetchCalculatedMembers } from "./fetchCalculatedMembers.js";
import { fetchDataModel } from "./fetchDataModel.js";
import { fetchDrillthroughColumns } from "./fetchDrillthroughColumns.js";
import { prefixFormulaNameWithCubeName } from "./utils/prefixFormulaNameWithCubeName.js";
/**
 * Class implementing the {@link ActivePivotClient} interface.
 * All the public methods are documented in the interface.
 */ class ActivePivotClientImpl extends AWebSocketClientImpl {
    /**
   * The state of this client.
   */ state = clientInitialState;
    // Private constructor to enforce calling the static `create` method.
    constructor({ url , serverVersion , serviceVersion , requestInit , pingPeriod  }){
        super({
            url,
            serverVersion,
            serviceVersion,
            requestInit: {
                ...requestInit,
                headers: {
                    ...requestInit?.headers,
                    "x-activeui-version": activeuiVersion
                }
            },
            pingPeriod
        });
    }
    static create({ url , serviceVersion , serverVersion , requestInit , pingPeriod  }) {
        const activePivotClient = new ActivePivotClientImpl({
            url,
            serverVersion,
            serviceVersion,
            requestInit,
            pingPeriod
        });
        void activePivotClient.connect();
        return activePivotClient;
    }
    async connect() {
        if (this.connectionStatus === "connected") {
            return;
        }
        const connectionPromise = new Promise((resolve)=>{
            const connectionStatusListener = (connectionStatus)=>{
                if (connectionStatus === "connected") {
                    resolve();
                    this.removeConnectionStatusListener(connectionStatusListener);
                }
            };
            this.addConnectionStatusListener(connectionStatusListener);
        });
        if (this.connectionStatus === "disconnected") {
            this.dispatch({
                type: "webSocketOpening"
            });
            try {
                await this.openWebSocket();
                this.webSocket.addEventListener("open", ()=>{
                    this.dispatch({
                        type: "webSocketOpened"
                    });
                });
                this.webSocket.addEventListener("message", (e)=>{
                    const data = JSON.parse(e.data);
                    this.dispatch({
                        type: "webSocketMessageReceived",
                        message: data
                    });
                });
                this.webSocket.addEventListener("close", ()=>{
                    this.disconnect();
                });
                this.webSocket.addEventListener("error", ()=>{
                    this.dispatch({
                        type: "webSocketErrored"
                    });
                });
            } catch (error) {
                this.disconnect();
                if (error instanceof NoResponseError) {
                    // This can happen if the server is down.
                    // TODO do not swallow the `NoResponseError`.
                    // This implies making ActivePivotClient.connect asynchronous (which is a breaking change).
                    // See https://activeviam.atlassian.net/browse/UI-6381
                    return;
                } else {
                    throw error;
                }
            }
        }
        return connectionPromise;
    }
    disconnect() {
        if (this.connectionStatus !== "disconnected") {
            this.closeWebSocket();
            this.dispatch({
                type: "webSocketClosed"
            });
        }
    }
    /**
   * None of the methods on this class do anything directly.
   * Instead they call this dispatch method.
   * Dispatch returns the next state of the client including an array of side effects
   * that should be executed.
   * Structuring things like this allows us to unit test most of the functionality
   * very easily, since it's a pure function (the reducer).
   */ dispatch(event) {
        const [nextState, sideEffects] = activePivotClientReducer(this.state, event);
        // We now have a sort of reducer for side effects here.
        // We try to keep the number of them low.
        sideEffects.forEach((sideEffect)=>{
            switch(sideEffect.type){
                case "webSocketSendMessage":
                    if (nextState.connectionStatus === "connected") {
                        this.webSocket.send(JSON.stringify(sideEffect.message));
                    } else {
                    // Do not queue any messages while the client is not connected.
                    // Once the WebSocket connection is successfully established, each query will automatically be registered, with its latest state if it changes on the client side.
                    // See ActivePivotClient.connect() and the handling of the "webSocketOpened" action in the reducer for implementation details.
                    }
                    break;
                default:
                    throw new Error(`Unexpected ActivePivot client sideEffect type: ${sideEffect.type}. Valid types are ["webSocketSendMessage"]`);
            }
        });
        const previousMap = this.state._map;
        const previousConnectionStatus = this.connectionStatus;
        const previousDataModel = this.dataModel;
        const previousCalculatedMembers = this.state.calculatedMembers;
        const previousDrillthroughColumns = this.state.drillthroughColumns;
        this.state = nextState;
        // Notify listeners if queries or query results have changed.
        for(const queryId in nextState._map){
            const { query , queryResult , queryListeners , queryResultListeners  } = this.state._map[queryId] || {};
            const { query: previousQuery , queryResult: previousQueryResult  } = previousMap[queryId] || {};
            if (query !== previousQuery) {
                queryListeners?.forEach((queryListener)=>{
                    queryListener(query);
                });
            }
            if (queryResult !== previousQueryResult) {
                queryResultListeners?.forEach((queryResultListener)=>{
                    queryResultListener(queryResult);
                });
            }
        }
        // Notify listeners if the connectionStatus has changed.
        if (this.connectionStatus !== previousConnectionStatus) {
            nextState.connectionStatusListeners.forEach((connectionStatusListener)=>{
                connectionStatusListener(this.connectionStatus);
            });
        }
        // Notify listeners if the data model has changed.
        if (this.dataModel !== previousDataModel) {
            nextState.dataModelListeners?.forEach((dataModelListener)=>{
                // The data model is undefined only in the initial state.
                dataModelListener(this.dataModel);
            });
        }
        for(const cubeName in this.state.drillthroughColumns){
            const drillthroughState = this.state.drillthroughColumns[cubeName];
            const previousDrillthroughState = previousDrillthroughColumns[cubeName];
            const { drillthroughColumns , isLoading , error  } = drillthroughState;
            if (drillthroughColumns !== previousDrillthroughState?.drillthroughColumns || isLoading !== previousDrillthroughState?.isLoading) {
                drillthroughState.listeners?.forEach((listener)=>{
                    // The drillthrough columns are undefined only in the initial state.
                    listener(drillthroughColumns, {
                        error,
                        isLoading
                    });
                });
            }
        }
        for(const cubeName in this.state.calculatedMembers){
            const calculatedMembersState = this.state.calculatedMembers[cubeName];
            const previousCalculatedMembersState = previousCalculatedMembers[cubeName];
            const { calculatedMembers , isLoading , error  } = calculatedMembersState;
            if (calculatedMembers !== previousCalculatedMembersState?.calculatedMembers || isLoading !== previousCalculatedMembersState?.isLoading) {
                calculatedMembersState.listeners?.forEach((listener)=>{
                    listener(calculatedMembers, {
                        error,
                        isLoading
                    });
                });
            }
        }
    }
    /**
   * Registers a query on the corresponding server. This
   * creates a dedicated stream identified by `queryId`.
   *
   * If a query is already registered with `queryId`, it will throw.
   */ register(queryId, query) {
        this.dispatch({
            type: "queryRegistered",
            id: queryId,
            ...query
        });
    }
    /**
   * Updates the {@link Query} identified by `queryId`.
   * Marks the query as loading and sends a message to the server.
   */ update(queryId, query) {
        this.dispatch({
            type: "queryUpdated",
            id: queryId,
            ...query
        });
    }
    setQuery(queryId, query) {
        const isQueryRegistered = this.state._map[queryId]?.query !== undefined;
        if (isQueryRegistered) {
            this.update(queryId, query);
        } else {
            this.register(queryId, query);
        }
    }
    get connectionStatus() {
        return this.state.connectionStatus;
    }
    addConnectionStatusListener(listener) {
        this.dispatch({
            type: "connectionStatusListenerAdded",
            listener
        });
    }
    removeConnectionStatusListener(listener) {
        this.dispatch({
            type: "connectionStatusListenerRemoved",
            listener
        });
    }
    getQuery(queryId) {
        return this.state._map[queryId]?.query;
    }
    addQueryListener(queryId, listener) {
        this.dispatch({
            type: "queryListenerAdded",
            id: queryId,
            listener
        });
    }
    removeQueryListener(queryId, listener) {
        this.dispatch({
            type: "queryListenerRemoved",
            id: queryId,
            listener
        });
    }
    getQueryResult(queryId) {
        return this.state._map[queryId]?.queryResult;
    }
    addQueryResultListener(queryId, listener) {
        this.dispatch({
            type: "queryResultListenerAdded",
            id: queryId,
            listener
        });
    }
    removeQueryResultListener(queryId, listener) {
        this.dispatch({
            type: "queryResultListenerRemoved",
            id: queryId,
            listener
        });
        if (this.state._map[queryId].queryResultListeners.length === 0) {
            this.dispatch({
                type: "queryUnregistered",
                id: queryId
            });
        }
    }
    refreshQuery(queryId) {
        this.dispatch({
            type: "queryRefreshed",
            id: queryId
        });
    }
    async loadDataModel({ signal  } = {}) {
        this.dispatch({
            type: "dataModelLoadingStarted"
        });
        const dataModel = await fetchDataModel({
            serverUrl: this.url,
            pivotServiceVersion: this.serviceVersion,
            options: {
                ...this.requestInit,
                signal
            }
        });
        const indexedDataModel = getIndexedDataModel(dataModel);
        this.__UNSAFE_setDataModel__(indexedDataModel);
    }
    get dataModel() {
        return this.state.dataModel;
    }
    get isDataModelLoading() {
        return this.state.isDataModelLoading;
    }
    get drillthroughColumns() {
        return this.state.drillthroughColumns;
    }
    get calculatedMembers() {
        return this.state.calculatedMembers;
    }
    // atoti uses this: it has a custom WebSocket notifying of data model changes.
    // See https://activeviam.atlassian.net/browse/UI-6015.
    __UNSAFE_setDataModel__(dataModel, error) {
        this.dispatch({
            type: "dataModelLoaded",
            dataModel,
            error
        });
    }
    addDataModelListener(listener) {
        this.dispatch({
            type: "dataModelListenerAdded",
            listener
        });
    }
    removeDataModelListener(listener) {
        this.dispatch({
            type: "dataModelListenerRemoved",
            listener
        });
    }
    async loadDrillthroughColumns(cubeName, { signal  } = {}) {
        const drillthroughState = this.state.drillthroughColumns[cubeName];
        const isLoading = drillthroughState && drillthroughState.isLoading;
        if (isLoading) {
            // If the client is called several times concurrently to load columns, only load them once.
            return;
        }
        this.dispatch({
            type: "drillthroughColumnsLoading",
            cubeName
        });
        let error;
        let drillthroughColumns;
        try {
            drillthroughColumns = await fetchDrillthroughColumns({
                url: this.url,
                pivotServiceVersion: this.serviceVersion,
                cubeName,
                requestInit: {
                    ...this.requestInit,
                    signal
                }
            });
        } catch (_error) {
            error = ensureError(_error);
            // Logging this error exceptionally in order to not make it visible to developers.
            // It is also stored in the client state in order to be presented to users looking for drillthrough columns.
            // eslint-disable-next-line no-console
            console.error(error);
        }
        this.dispatch({
            type: "drillthroughColumnsLoaded",
            cubeName,
            drillthroughColumns,
            error
        });
    }
    addDrillthroughColumnsListener(cubeName, listener) {
        this.dispatch({
            type: "drillthroughColumnsListenerAdded",
            cubeName,
            listener
        });
    }
    removeDrillthroughColumnsListener(cubeName, listener) {
        this.dispatch({
            type: "drillthroughColumnsListenerRemoved",
            cubeName,
            listener
        });
    }
    async loadCalculatedMembers(cubeName, { signal  } = {}) {
        const calculatedMembersState = this.state.calculatedMembers[cubeName];
        const isLoading = calculatedMembersState && calculatedMembersState.isLoading;
        if (isLoading) {
            // If the client is called several times concurrently to load columns, only load them once.
            return;
        }
        this.dispatch({
            type: "calculatedMembersLoading",
            cubeName
        });
        let error;
        let calculatedMembers;
        try {
            calculatedMembers = await fetchCalculatedMembers({
                cubeName,
                serverUrl: this.url,
                pivotServiceVersion: this.serviceVersion,
                options: {
                    ...this.requestInit,
                    signal
                }
            });
        } catch (_error) {
            error = ensureError(_error);
            // Logging this error exceptionally in order to not make it visible to developers.
            // It is also stored in the client state in order to be presented to users looking for calculated members.
            // eslint-disable-next-line no-console
            console.error(error);
        }
        this.dispatch({
            type: "calculatedMembersLoaded",
            cubeName,
            calculatedMembers,
            error
        });
    }
    addCalculatedMembersListener(cubeName, listener) {
        this.dispatch({
            type: "calculatedMembersListenerAdded",
            cubeName,
            listener
        });
    }
    removeCalculatedMembersListener(cubeName, listener) {
        this.dispatch({
            type: "calculatedMembersListenerRemoved",
            cubeName,
            listener
        });
    }
    async createCalculatedMember({ formula , cubeName , owners , readers  }) {
        const cubeSpecificFormula = prefixFormulaNameWithCubeName(formula, cubeName);
        const createStatement = `CREATE ${stringify(cubeSpecificFormula)}`;
        await executeMdxDefinitionStatement({
            url: this.url,
            pivotServiceVersion: this.serviceVersion,
            requestInit: this.requestInit,
            mdxDefinitionStatement: createStatement,
            owners,
            readers
        });
    }
    async deleteCalculatedMember({ memberUniqueName , cubeName  }) {
        const identifiers = unquote(memberUniqueName);
        const memberUniqueNameAcrossAllCubes = identifiers[0] === cubeName ? memberUniqueName : quote(cubeName, ...identifiers);
        const dropStatement = `DROP MEMBER ${memberUniqueNameAcrossAllCubes}`;
        await executeMdxDefinitionStatement({
            url: this.url,
            pivotServiceVersion: this.serviceVersion,
            requestInit: this.requestInit,
            mdxDefinitionStatement: dropStatement
        });
    }
    async updateCalculatedMember({ formula , cubeName , owners , readers  }) {
        const cubeSpecificFormula = prefixFormulaNameWithCubeName(formula, cubeName);
        const createStatement = `UPDATE ${stringify(cubeSpecificFormula)}`;
        await executeMdxDefinitionStatement({
            url: this.url,
            pivotServiceVersion: this.serviceVersion,
            requestInit: this.requestInit,
            mdxDefinitionStatement: createStatement,
            owners,
            readers
        });
        await this.loadCalculatedMembers(cubeName);
    }
    async createKpi({ kpi , cubeName , owners , readers  }) {
        const kpiUniqueName = quote(cubeName, kpi.name);
        let createStatement = `CREATE KPI ${kpiUniqueName} AS ${kpi.value}`;
        _lowerCaseKpiPropertyNames// For example: kpistatus => status
        .map((kpiPropertyName)=>kpiPropertyName.slice(3)).forEach((_kpiPropertyName)=>{
            // TypeScript does not recognize that the property names obtained at runtime match the keys of the Kpi interface.
            // eslint-disable-next-line atoti-ui/no-as
            const kpiPropertyName = _kpiPropertyName;
            if (kpi[kpiPropertyName]) {
                createStatement = `${createStatement}, ${kpiPropertyName.toUpperCase()}=${kpi[kpiPropertyName]}`;
            }
        });
        await executeMdxDefinitionStatement({
            url: this.url,
            pivotServiceVersion: this.serviceVersion,
            requestInit: this.requestInit,
            mdxDefinitionStatement: createStatement,
            owners,
            readers
        });
    }
    async deleteKpi({ kpiName , cubeName  }) {
        const kpiUniqueName = quote(cubeName, kpiName);
        const dropStatement = `DROP KPI ${kpiUniqueName}`;
        await executeMdxDefinitionStatement({
            url: this.url,
            pivotServiceVersion: this.serviceVersion,
            requestInit: this.requestInit,
            mdxDefinitionStatement: dropStatement
        });
    }
}
/**
 * Returns a new {@link ActivePivotClient} instance.
 */ export function createActivePivotClient({ url , serverVersion , serviceVersion , requestInit , pingPeriod  }) {
    return ActivePivotClientImpl.create({
        url,
        serverVersion,
        serviceVersion,
        requestInit,
        pingPeriod
    });
}
