import { CloseOutlined, RollbackOutlined } from "@ant-design/icons";
import { FC, PropsWithChildren } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import {
  Resizable,
  getActiveToolKey,
  getPrefixedLocalStorageKey,
  getResourcesPanelKey,
  useIsPresenting,
  usePersisted,
  useTheme,
} from "@activeviam/activeui-sdk";

import { Collapsible } from "./Collapsible.js";
import { ResourcesPanel } from "./ResourcesPanel.js";
import { headerHeight } from "./header/Header.js";
import { ActiveTool } from "./tools/ActiveTool.js";
import { ToolsPanel } from "./tools/ToolsPanel.js";

export const collapsedPanelWidth = 34;
export const minPanelWidth = 110;
const defaultPanelWidth = 240;

/**
 * Displays, from left to right:
 * - the data model tree, widgets, or saved filters (resizable and collapsible)
 * - the tools (fields, filters, style, ...) (resizable and collapsible)
 * - its React child
 */
export const ResourcesAndToolsPanels: FC<PropsWithChildren> = ({
  children,
}) => {
  const theme = useTheme();
  const { formatMessage } = useIntl();
  const isPresenting = useIsPresenting();
  const dispatch = useDispatch();
  const [isDataModelTreeExpanded, setIsDataModelTreeExpanded] = usePersisted(
    getPrefixedLocalStorageKey("aui-data-model"),
    true,
  );
  const [areToolsExpanded, setAreToolsExpanded] = usePersisted(
    getPrefixedLocalStorageKey("aui-tools"),
    true,
  );

  const [toolsWidth, setToolsWidth] = usePersisted<number>(
    getPrefixedLocalStorageKey("tool-width"),
    defaultPanelWidth,
  );

  const [dataModelWidth, setDataModelWidth] = usePersisted<number>(
    getPrefixedLocalStorageKey("data-model-widths"),
    defaultPanelWidth,
  );

  const resourcesPanelKey = useSelector(getResourcesPanelKey);
  const activeToolKey = useSelector(getActiveToolKey);

  const dataModelTitle =
    resourcesPanelKey === "data-model" ? (
      formatMessage({ id: "aui.dataModel" })
    ) : (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          whiteSpace: "nowrap",
        }}
      >
        <RollbackOutlined style={{ marginRight: 3 }} />
        <div
          style={{
            flexGrow: 1,
            whiteSpace: "nowrap",
            color: theme.textColor,
          }}
        >
          {formatMessage({ id: "aui.backToDataModel" })}
        </div>
        <CloseOutlined />
      </div>
    );

  const handleActiveToolKeyChanged = (toolKey: string) => {
    dispatch({ type: "activeToolChanged", toolKey });
    setAreToolsExpanded(true);
  };

  return (
    <Resizable
      style={{
        height: `calc(100% - ${headerHeight}px)`,
        background: theme.grayScale[1],
      }}
      minSize={isDataModelTreeExpanded ? minPanelWidth : collapsedPanelWidth}
      size={
        isPresenting
          ? 0
          : isDataModelTreeExpanded || resourcesPanelKey !== "data-model"
          ? dataModelWidth ?? defaultPanelWidth
          : collapsedPanelWidth
      }
      isResizingDisabled={
        !isDataModelTreeExpanded && resourcesPanelKey === "data-model"
      }
      firstChildStyle={
        resourcesPanelKey === "data-model"
          ? {}
          : {
              boxShadow:
                "0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05)",
            }
      }
      onResizeEnd={setDataModelWidth}
    >
      {!isPresenting && (
        <Collapsible
          collapsedTitle={
            <div
              style={{
                display: "inline-block",
                justifyContent: "center",
                width: 15,
                alignItems: "center",
                marginTop: 70,
                transform: "rotate(-90deg)",
                color: theme.textColor,
                textTransform: "uppercase",
              }}
            >
              {dataModelTitle}
            </div>
          }
          expandedTitle={dataModelTitle}
          isDisabled={resourcesPanelKey !== "data-model"}
          isExpanded={
            isDataModelTreeExpanded ||
            // Only the data model tree has the ability to be collapsed.
            resourcesPanelKey !== "data-model"
          }
          onToggled={() => {
            if (resourcesPanelKey === "data-model") {
              setIsDataModelTreeExpanded(!isDataModelTreeExpanded);
            } else {
              // When closing the saved filters or widgets panel, the user will return to an expanded data model tree.
              setIsDataModelTreeExpanded(true);
              dispatch({
                type: "resourcesPanelChanged",
                resourcesPanelKey: "data-model",
              });
            }
          }}
        >
          <ResourcesPanel />
        </Collapsible>
      )}
      <Resizable
        minSize={areToolsExpanded ? minPanelWidth : collapsedPanelWidth}
        size={
          isPresenting
            ? 0
            : areToolsExpanded
            ? toolsWidth ?? defaultPanelWidth
            : collapsedPanelWidth
        }
        isResizingDisabled={!areToolsExpanded}
        onResizeEnd={setToolsWidth}
      >
        {isPresenting ? null : (
          <Collapsible
            collapsedTitle={
              <ToolsPanel
                tabsPosition="left"
                activeToolKey={activeToolKey}
                onActiveToolKeyChanged={handleActiveToolKeyChanged}
              />
            }
            expandedTitle={formatMessage({ id: "aui.tools" })}
            isExpanded={areToolsExpanded}
            onToggled={setAreToolsExpanded}
          >
            <ToolsPanel
              tabsPosition="top"
              activeToolKey={activeToolKey}
              onActiveToolKeyChanged={handleActiveToolKeyChanged}
            />
            <ActiveTool width={toolsWidth ?? defaultPanelWidth} />
          </Collapsible>
        )}
        {children}
      </Resizable>
    </Resizable>
  );
};
