/**
 * Fills up `rowIndexMapping` with approximations for the indices in the data of all the rows above `rowIndexInData`.
 * Adds more elements until `rowIndexMapping` reaches `rowIndexInData`.
 * These elements have the same difference between their index on screen and their index in the data as the last element in `rowIndexMapping` does before the operation.
 * In practice, it means that no total rows are considered missing in the missing chunk, until this chunk is loaded.
 *
 * Mutates `rowIndexMapping`.
 */ export function fillMissingChunkAbove(rowIndexMapping, rowIndexInData) {
    const lastIndexOnScreen = rowIndexMapping.length - 1;
    const lastIndexInData = rowIndexMapping[lastIndexOnScreen];
    const numberOfAddedRows = lastIndexOnScreen - lastIndexInData;
    let i = rowIndexMapping.length - numberOfAddedRows;
    while(rowIndexMapping.length < rowIndexInData + numberOfAddedRows){
        rowIndexMapping.push(i);
        i++;
    }
}
