export const Quoting = {
    QUOTED: "QUOTED",
    UNQUOTED: "UNQUOTED",
    AMP_QUOTED: "AMP_QUOTED"
};
export const ElementType = {
    DRILLTHROUGH: "Drillthrough",
    SELECT: "Select",
    SUB_SELECT: "SubSelect",
    AXIS: "Axis",
    CASE_EXPRESSION: "CaseExpression",
    COMPOUND_IDENTIFIER: "CompoundIdentifier",
    FORMULA: "Formula",
    FUNCTION: "Function",
    FROM: "From",
    IDENTIFIER: "Identifier",
    LITERAL: "Literal",
    MEMBER_PROPERTY_DEFINITION: "MemberPropertyDefinition",
    WHEN_CLAUSE: "When"
};
export const CompoundIdType = {
    UNKNOWN: "unknown",
    DIMENSION: "dimension",
    HIERARCHY: "hierarchy",
    LEVEL: "level",
    MEMBER: "member",
    NAMED_SET: "namedSet"
};
export const Syntax = {
    PROPERTY: "Property",
    FUNCTION: "Function",
    INFIX: "Infix",
    PREFIX: "Prefix",
    PARENTHESES: "Parentheses",
    BRACES: "Braces",
    METHOD: "Method"
};
export const FormulaType = {
    SET: "SET",
    MEMBER: "MEMBER"
};
export const Argument = {
    PLACE_HOLDER: "PLACE_HOLDER",
    SCALAR: "SCALAR",
    KEYWORD: "KEYWORD",
    STRING: "STRING"
};
