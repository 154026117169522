import { forEach as _forEach } from "lodash-es";
import { getHierarchies } from "../getHierarchies.js";
import { getSpecificCompoundIdentifier } from "../getSpecificCompoundIdentifier.js";
import { isMdxCompoundIdentifier } from "../isMdxCompoundIdentifier.js";
import { quote } from "../quote.js";
import { unquote } from "../unquote.js";
import { _findCompoundIdentifiers } from "./_findCompoundIdentifiers.js";
import { _isContributingToNodeDimensionality } from "./_isContributingToNodeDimensionality.js";
/**
 * Returns the filter classes that should be used when setting the respective `filters` in `mdx`.
 *
 * A filter is classified as a slicer filter if:
 * - there is no other filter on the same hierarchy
 * - it represents a single member
 * - the hierarchy is not already expressed on an axis
 * Otherwise, it is classified as a subselect.
 */ export function _getFilterClasses({ mdx , filters , cube  }) {
    const filterIndexesByHierarchy = {};
    filters.forEach((filter, index)=>{
        const { dimensionName , hierarchyName  } = getHierarchies(filter, {
            cube
        })[0];
        const hierarchyUniqueName = quote(dimensionName, hierarchyName);
        filterIndexesByHierarchy[hierarchyUniqueName] = [
            ...filterIndexesByHierarchy[hierarchyUniqueName] || [],
            index
        ];
    });
    const filterClasses = new Array(filters.length).fill("subselect");
    _forEach(filterIndexesByHierarchy, (filterIndexes, hierarchyUniqueName)=>{
        if (filterIndexes.length > 1) {
            // There is more than one filter on this hierarchy.
            // They cannot all be added in the slicer axis, because the hierarchy would be expressed multiple times in the slicer tuple.
            // They cannot be added for some as slicer filters and for some others as subselects, because the hierarchy would contribute to the dimensionality of both the slicer axis and another axis.
            // So they must all be added as subselects.
            return;
        }
        const singleFilterIndex = filterIndexes[0];
        const filter = filters[singleFilterIndex];
        if (!(isMdxCompoundIdentifier(filter) && [
            "measure",
            "member"
        ].includes(getSpecificCompoundIdentifier(filter, {
            cube
        }).type))) {
            // Only a tuple is allowed as the slicer axis expression, with exactly one member per hierarchy, each corresponding to a filter.
            // Since there is more than one member in the filter expression, it needs to be added as a subselect.
            return;
        }
        const [dimensionName, hierarchyName] = unquote(hierarchyUniqueName);
        const isHierarchyContributingToTheDimensionalityOfAnAxis = mdx.axes.some((axis)=>_findCompoundIdentifiers(axis, {
                hierarchyCoordinates: {
                    dimensionName,
                    hierarchyName
                },
                cube
            }).some(({ path  })=>_isContributingToNodeDimensionality(axis, path)));
        if (isHierarchyContributingToTheDimensionalityOfAnAxis) {
            // The hierarchy is already expressed on an axis.
            // Since expressing a hierarchy on multiple axes at a time (including the slicer) is not allowed, the filter must be added as a subselect.
            return;
        }
        filterClasses[singleFilterIndex] = "slicer";
    });
    return filterClasses;
}
