import { getSpecificCompoundIdentifier } from "./getSpecificCompoundIdentifier.js";
import { _areCompoundIdentifiersEqual } from "./internal/_areCompoundIdentifiersEqual.js";
import { _isMdxSubSelect } from "./internal/_isMdxSubSelect.js";
import { isMdxCompoundIdentifier } from "./isMdxCompoundIdentifier.js";
import { isMdxDrillthrough } from "./isMdxDrillthrough.js";
import { isMdxFunction } from "./isMdxFunction.js";
/** Returns the filters present on the WHERE clause of a SELECT statement. These filters always are Compound Identifiers */ export const _getSlicerFilters = (slicer)=>{
    if (!slicer) {
        return [];
    }
    if (isMdxFunction(slicer.expression) && slicer.expression.syntax === "Parentheses") {
        return slicer.expression.arguments.map((argument, i)=>({
                // `argument` is an MdxCompoundIdentifier because the syntax is "Parentheses".
                // eslint-disable-next-line
                mdx: argument,
                path: [
                    "expression",
                    "arguments",
                    i
                ]
            }));
    } else {
        return [
            {
                // `slicer.expression` is an MdxCompoundIdentifier because the syntax is not "Parentheses".
                // eslint-disable-next-line
                mdx: slicer.expression,
                path: [
                    "expression"
                ]
            }
        ];
    }
};
/** Returns whether the given slicer filter is also present in the subSelect */ const _findIndexInSubSelect = (slicerFilter, subSelectFilters, cube)=>{
    const specificCompoundIdentifierFromSlicer = getSpecificCompoundIdentifier(slicerFilter.mdx, {
        cube
    });
    return subSelectFilters.findIndex((subSelectFilter)=>{
        if (!isMdxCompoundIdentifier(subSelectFilter.mdx)) {
            return false;
        }
        return _areCompoundIdentifiersEqual(specificCompoundIdentifierFromSlicer, subSelectFilter.mdx, cube);
    });
};
/** Returns the filters expressed in `mdx`. */ export function getFilters(mdx, { cube  }) {
    if (isMdxDrillthrough(mdx)) {
        return getFilters(mdx.select, {
            cube
        }).map(({ mdx , paths  })=>({
                mdx,
                paths: paths.map((path)=>[
                        "select",
                        ...path
                    ])
            }));
    }
    /** The filters represented on the COLUMNS axis of the given mdx, if it is a subSelect */ const ownFilters = [];
    if (_isMdxSubSelect(mdx)) {
        const columnAxisIndex = (mdx.axes || []).findIndex((axis)=>axis.name === "COLUMNS");
        if (columnAxisIndex !== -1) {
            const columnAxis = mdx.axes[columnAxisIndex];
            ownFilters.push({
                mdx: columnAxis.expression,
                paths: [
                    [
                        "axes",
                        columnAxisIndex,
                        "expression"
                    ]
                ]
            });
        }
    }
    const subSelectFilters = mdx.from && _isMdxSubSelect(mdx.from) ? getFilters(mdx.from, {
        cube
    }).map(({ mdx , paths  })=>({
            mdx,
            paths: paths.map((path)=>[
                    "from",
                    ...path
                ])
        })) : [];
    const slicerFilters = mdx.slicerAxis ? _getSlicerFilters(mdx.slicerAxis).map(({ mdx , path  })=>({
            mdx,
            path: [
                "slicerAxis",
                ...path
            ]
        })) : [];
    const slicerFiltersAbsentFromSubSelect = [];
    let i = 0;
    while(i + slicerFiltersAbsentFromSubSelect.length < slicerFilters.length){
        const slicerFilter = slicerFilters[i + slicerFiltersAbsentFromSubSelect.length];
        const indexInSubSelect = _findIndexInSubSelect(slicerFilter, subSelectFilters, cube);
        if (indexInSubSelect !== -1) {
            subSelectFilters[indexInSubSelect].paths.push(slicerFilter.path);
            i++;
        } else {
            slicerFiltersAbsentFromSubSelect.push({
                mdx: slicerFilter.mdx,
                paths: [
                    slicerFilter.path
                ]
            });
        }
    }
    return [
        ...subSelectFilters,
        ...slicerFiltersAbsentFromSubSelect,
        ...ownFilters
    ];
}
