/**
 * For a given {@link Cell} in a {@link CellSet}, returns its corresponding {@link Tuple}.
 * In the case of a lazy loaded {@link CellSet}, returns null if the {@link Cell} is not yet loaded.
 */ export const getTuple = ({ columnIndex , data , hierarchyIndicesInCellSet , rowIndex  })=>{
    const tuple = [];
    // The tuple can be undefined in the case of a lazy-loaded cellset, if it is out of the currently loaded range.
    let isTupleDefined = false;
    Object.values(hierarchyIndicesInCellSet).forEach(({ dimensionName , hierarchyName , axisIndex , hierarchyIndex  })=>{
        const axis = data.axes[axisIndex];
        const offset = axis.range?.from || 0;
        const positionIndex = (axis.id === 0 ? columnIndex : rowIndex) - offset;
        if (axis.positions[positionIndex]) {
            // See https://github.com/activeviam/atoti-ui/pull/1633#discussion_r674665310.
            isTupleDefined = true;
            if (// Do not push an entry for a hierarchy with no entry at this position.
            // This can happen if the tuple was created as a missing total.
            axis.positions[positionIndex][hierarchyIndex] !== undefined) {
                tuple.push({
                    dimensionName,
                    hierarchyName,
                    ...axis.positions[positionIndex][hierarchyIndex]
                });
            }
        }
    });
    return isTupleDefined ? tuple : null;
};
