/**
 * Object mapping all the axis type names and their id.
 */ export const axisIds = {
    slicer: -1,
    columns: 0,
    rows: 1,
    pages: 2,
    chapters: 3,
    sections: 4
};
/**
 * Returns the id of an MDX axis given its name.
 */ export function getAxisId(axisName) {
    return axisIds[axisName.toLowerCase()];
}
