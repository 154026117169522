import { ApplicationMenu, Whitelist } from "@activeviam/activeui-sdk";

import { isPluginAllowedBasedOnContentManagementPermission } from "./isPluginAllowedBasedOnContentManagementPermission.js";

/**
 * Returns a copy of `applicationMenus` and their descendants, except those that the user is not allowed to see.
 * Does not mutate `applicationMenus.`
 */
export function filterApplicationMenusBasedOnPermissions(
  applicationMenusFromConfiguration: ApplicationMenu[],
  options: {
    allowedKeys: Whitelist;
    areAllowedKeysSet: boolean;
    canManageContent: boolean;
    isCanManageContentSet: boolean;
  },
) {
  const filteredApplicationMenus: ApplicationMenu[] = [];

  applicationMenusFromConfiguration.forEach((applicationMenu) => {
    if (
      isPluginAllowedBasedOnContentManagementPermission(applicationMenu.key, {
        ...options,
        keysGrantedByContentManagement: ["save-dashboard-as", "save-dashboard"],
      })
    ) {
      filteredApplicationMenus.push({
        ...applicationMenu,
        ...("children" in applicationMenu && {
          children: filterApplicationMenusBasedOnPermissions(
            applicationMenu.children,
            options,
          ),
        }),
      });
    }
  });

  return filteredApplicationMenus;
}
