import { traverseMdx } from "./traverseMdx.js";
/**
 * Returns a reference to the first descendant matching the given condition in `mdx`.
 */ export const findDescendant = (mdx, matchFn, path = [])=>{
    let match = undefined;
    traverseMdx(mdx, (_mdx, _path)=>{
        const isMatch = matchFn(_mdx, _path);
        if (isMatch) {
            match = {
                match: _mdx,
                path: _path
            };
            return false;
        }
        return;
    }, path);
    return match;
};
