import { FolderAddOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { CSSProperties, FC, Fragment, useState } from "react";
import { useIntl } from "react-intl";

import {
  AMetaData,
  ContentRecord,
  CreateFolderModal,
  useIsModalMounted,
  usePermission,
} from "@activeviam/activeui-sdk";
import { Tooltip } from "@activeviam/utils-react";

export interface NewFolderButtonProps {
  parentFolderMetaData?: AMetaData;
  pathToParentFolder: string[];
  parentFolder: ContentRecord<AMetaData>;
  style?: CSSProperties;
}

/**
 * Displays a button that creates a new folder inside `pathToParentFolder`.
 */
export const NewFolderButton: FC<NewFolderButtonProps> = ({
  parentFolderMetaData,
  parentFolder,
  pathToParentFolder,
  style,
}) => {
  const { formatMessage } = useIntl();
  const [isCreateFolderModalVisible, setIsCreateFolderModalVisible] = useState(
    false,
  );
  const isCreateFolderModalMounted = useIsModalMounted(
    isCreateFolderModalVisible,
  );
  const isCreateFolderButtonDisabled = !parentFolder.entry.canWrite;

  const [canManageContent] = usePermission("canManageContent");
  if (!canManageContent) {
    return null;
  }

  return (
    <Fragment>
      {isCreateFolderModalMounted && (
        <CreateFolderModal
          isVisible={isCreateFolderModalVisible}
          onCancel={() => setIsCreateFolderModalVisible(false)}
          onSubmit={() => {
            setIsCreateFolderModalVisible(false);
          }}
          title={formatMessage(
            { id: "aui.contentTree.createFolder" },
            {
              parentFolderName:
                parentFolderMetaData?.name ||
                formatMessage({
                  id: "aui.contentTree.folders",
                }),
            },
          )}
          parentFolder={parentFolder.entry}
          pathToParentFolder={pathToParentFolder}
          contentType={"dashboard"}
        />
      )}
      <Tooltip
        trigger={isCreateFolderButtonDisabled ? ["hover"] : []}
        placement={"bottom"}
        title={formatMessage({
          id: "notAllowedToCreateFolder",
        })}
      >
        <Button
          style={style}
          onClick={() => setIsCreateFolderModalVisible(true)}
          disabled={isCreateFolderButtonDisabled}
          type="default"
        >
          <FolderAddOutlined />
          &nbsp;
          {formatMessage({
            id: "aui.contentTree.createNewFolder",
          })}
        </Button>
      </Tooltip>
    </Fragment>
  );
};
