import { isEqual as _isEqual, uniqWith as _uniqWith } from "lodash-es";
import { findLevels } from "./findLevels.js";
/**
 * Returns the coordinates of every level used in `mdx`.
 */ export function getLevels(mdx, { cube  }) {
    const levels = findLevels(mdx, cube);
    return _uniqWith(levels.map(({ dimensionName , hierarchyName , levelName  })=>({
            dimensionName,
            hierarchyName,
            levelName
        })), // The items in the array are created just above and they are each referentially unique.
    // Only a deep comparison can allow to return a list with unique values.
    // This is not a performance threat: these objects only have 3 string attributes to compare.
    // eslint-disable-next-line atoti-ui/no-lodash-isequal
    _isEqual);
}
