// These functions are used to configure jison in order to parse Mdx.
// Refactoring it in order to respect our signature guidelines is too costly.
/* eslint-disable max-params */ import { isMdxCompoundIdentifier } from "../isMdxCompoundIdentifier.js";
import { argumentKeywords } from "./keywords.js";
/* Warning: don't reorder keys in the objects returned from these functions.
 * The parser depends on the order of the keys in those object literals, even though
 * the iteration order of object keys is undefined in JavaScript.
 * Tracking issue: UI-844
 */ /**
 * Checks to see if the value matches any keyword in array
 */ const isValueInArray = (values, value)=>{
    const testValue = value.toUpperCase();
    return values.some((element)=>element === testValue);
};
/**
 * Checks to see if the value is being used as a function keyword
 */ const isValueAKeyword = (MdxIdentifier)=>MdxIdentifier.elementType === "Identifier" && MdxIdentifier.quoting === "UNQUOTED" && isValueInArray(argumentKeywords, MdxIdentifier.value);
const isMdxIdentifier = (node)=>node.elementType === "Identifier";
/**
 * Create an an MDX axis.
 *
 * @param name
 * @param nonEmpty
 * @param expression
 * @param properties
 * Returns
 *
 * @memberof MdxCreateApi
 */ function createAxis(name, nonEmpty, expression, properties = []) {
    return {
        elementType: "Axis",
        name,
        nonEmpty,
        expression,
        properties
    };
}
/**
 * Note: The `elseExp` argument is the else expression MDX for what is inside the else.
 *       For example for `ELSE [Measures].[A]` one should just send `parse('[Measures].[A]')`.
 */ function createCaseExpression(match, cases, elseExp) {
    return {
        elementType: "CaseExpression",
        match,
        cases,
        elseExp
    };
}
function createLiteral(type, value) {
    return {
        elementType: "Literal",
        value,
        type
    };
}
function createCompoundIdentifier(id) {
    return {
        elementType: "CompoundIdentifier",
        // @ts-expect-error: MdxIdentifier is not assignable to ConcatArray<never>
        identifiers: [].concat(id),
        type: "unknown"
    };
}
function createFromClause(cubeName) {
    return {
        elementType: "From",
        cubeName
    };
}
/**
 * Warning: "formula" here currently means "any calculated member", rather than the usual meaning of
 * "a calculated member in the Measures hierarchy; i.e. a calculated measure".
 */ function createFormula(type, name, expression, properties, inlined) {
    let effectiveName = name;
    if (type === "SET") {
        effectiveName = {
            ...name,
            type: "namedSet",
            setName: name.identifiers[0].value
        };
    }
    return {
        elementType: "Formula",
        name: effectiveName,
        type,
        properties,
        inlined: Boolean(inlined),
        expression
    };
}
function createSelectStatement(axes, slicerAxis, from, withClause, cellProps) {
    return {
        elementType: "Select",
        withClause,
        axes,
        slicerAxis,
        from,
        cellProps
    };
}
function createSubSelect(nonVisual, axes, slicerAxis, from) {
    return {
        elementType: "SubSelect",
        axes,
        slicerAxis,
        from,
        nonVisual
    };
}
function createIdentifier(value, quoting) {
    let returnValue = value;
    if (quoting === "QUOTED" || quoting === "AMP_QUOTED") {
        returnValue = value.replace(/\]\]/g, "]");
    }
    return {
        elementType: "Identifier",
        value: returnValue,
        quoting
    };
}
function createMdxFunction(name, syntax, args) {
    const transformIfFunctionKeyword = (argumentsArray)=>{
        const lastIndex = argumentsArray.length - 1;
        return argumentsArray.map((value, index)=>{
            if (index === lastIndex) {
                if (isMdxIdentifier(value) && isValueAKeyword(value)) {
                    return createLiteral("KEYWORD", value.value);
                } else if (isMdxCompoundIdentifier(value) && value.identifiers.length === 1 && isValueAKeyword(value.identifiers[0])) {
                    return createLiteral("KEYWORD", value.identifiers[0].value);
                }
            }
            return value;
        });
    };
    return {
        elementType: "Function",
        name,
        syntax,
        // TODO do we want to do this outside of the parser?
        arguments: transformIfFunctionKeyword(args)
    };
}
function createMemberPropertyDefinition(memberProperty, valueExpression) {
    return {
        elementType: "MemberPropertyDefinition",
        name: memberProperty,
        expression: valueExpression
    };
}
function createDrillthroughStatement(maxRows, firstRow, select, columns) {
    return {
        elementType: "Drillthrough",
        maxRows,
        firstRow,
        select,
        columns
    };
}
function createWhenClause(when, then) {
    return {
        elementType: "When",
        when,
        then
    };
}
export { createAxis, createCaseExpression, createCompoundIdentifier, createDrillthroughStatement, createFromClause, createFormula, createIdentifier, createLiteral, createMdxFunction, createMemberPropertyDefinition, createSelectStatement, createSubSelect, createWhenClause };
