import { produce } from "immer";
import { _cleanUpUnusedFormulas } from "./internal/_cleanUpUnusedFormulas.js";
import { _cleanupMdx } from "./internal/_cleanupMdx.js";
import { _collapseMembersOfHierarchy } from "./internal/_collapseMembersOfHierarchy.js";
import { _getPathsToMeasures } from "./internal/_getPathsToMeasures.js";
import { _nullifyNodeAtPath } from "./internal/_nullifyNodeAtPath.js";
/**
 * Returns a new {@link MdxSelect} corresponding to `mdx` where all measures were removed.
 * Does not mutate `mdx`.
 */ export function removeAllMeasures(mdx, { cube , shouldRemoveCalculatedMeasureExpression  }) {
    const cleanMdx = _collapseMembersOfHierarchy(mdx, {
        dimensionName: "Measures",
        hierarchyName: "Measures"
    }, cube);
    const withMeasuresRemoved = produce(cleanMdx, (draft)=>{
        let pathToMeasures;
        while(pathToMeasures = _getPathsToMeasures(draft)?.[0]){
            _nullifyNodeAtPath(draft, pathToMeasures);
            _cleanupMdx(draft, pathToMeasures, cube);
        }
    });
    if (shouldRemoveCalculatedMeasureExpression === false) {
        return withMeasuresRemoved;
    }
    return _cleanUpUnusedFormulas(withMeasuresRemoved);
}
