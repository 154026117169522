import { addCalculatedMeasureExpressionToWithClause } from "./addCalculatedMeasureExpressionToWithClause.js";
import { addMeasure } from "./addMeasure.js";
/**
 * Returns a new {@link MdxSelect} corresponding to `mdx` where the given calculated measure was added.
 * Does not mutate mdx.
 */ export function addCalculatedMeasure(mdx, { calculatedMeasureName , expression , measureIndex , properties  }, cube) {
    const mdxWithDefinition = addCalculatedMeasureExpressionToWithClause(mdx, {
        calculatedMeasureName,
        expression,
        properties
    });
    return addMeasure(mdxWithDefinition, {
        cube,
        measureName: calculatedMeasureName,
        measureIndex
    });
}
