/**
 * The mapping between the index of a row on screen and its index in the cellset received from the server.
 * These indices can be different when missing total rows are added to the table on the client side.
 * This only happens in the tree-table widget.
 *
 * The index in the `rowIndexMapping` represents the index on screen.
 * The value at this index represents the index in the cellset received from the server.
 *
 * The elements in this array are contiguous positive integers, sorted in ascending order.
 * This array cannot contain any "hole": an element cannot be greater than its previous element + 1.
 * An element can appear several times in a row.
 * Indeed, missing total rows are added on the client side and are considered to share the same index in the data as the row below them.
 */ /**
 * Returns the index on screen corresponding to `rowIndexInData`.
 * This index is `rowIndexInData` + the number of added rows representing missing totals above this row.
 */ export function getRowIndexOnScreen(rowIndexInData, rowIndexMapping) {
    if (!rowIndexMapping || rowIndexInData === 0) {
        return 0;
    }
    // Iterate over `rowIndexMapping` from right to left.
    let index = rowIndexMapping.length - 1;
    let value = rowIndexMapping[index];
    // Look for an element that's lower than or equal to `rowIndexInData`.
    const goal = rowIndexInData;
    while(index >= 0 && (value = rowIndexMapping[index]) && value > goal){
        // As the values grow slower than the indices,
        // i can be safely decremented by the difference between the current value and the goal (and not just by 1).
        index -= value - goal;
    }
    // This offsetting must be done in case `rowIndexInData` is greater than the last element in the array.
    // In practice, this happens when the user scrolls below the furthest chunk she previously loaded.
    //
    // Consider the following example:
    //
    // rowIndexMapping = [0, 1, 1, 1, 2, 3, 4, 4]
    //
    // Suppose that we are looking for the index on screen of `rowIndexInData` = 6.
    //
    // The last row whose index on screen is known is the 4th one in the data.
    // Its index on screen is 7 (last element in the array).
    // The best approximation of getRowIndexOnScreen(6) is 7 + (6 - 4).
    return index + (rowIndexInData - value);
}
