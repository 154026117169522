import { produce } from "immer";
import { merge as _merge } from "lodash-es";
import { ReactNode, createContext, useContext } from "react";
import { useAsync } from "react-async-hook";

import { LoadingBackground } from "@activeviam/activeui-sdk";
import {
  Configuration,
  ConfigurationNotFoundError,
  activateExtensions,
  loadExtensions,
} from "@activeviam/module-federation";

import { initialConfiguration } from "./initialConfiguration.js";

/**
 * React Context for the activated extensions.
 */
export const ConfigurationContext = createContext<Configuration | null>(null);

function ConfigurationProvider({ children }: { children: ReactNode }) {
  const { result: configuration } = useAsync(async () => {
    const extensions = await loadExtensions("extensions.json");
    return produce(initialConfiguration, (draft) =>
      activateExtensions(extensions, draft),
    );
  }, []);

  if (!configuration) {
    return <LoadingBackground />;
  }

  return (
    <ConfigurationContext.Provider value={configuration}>
      {children}
    </ConfigurationContext.Provider>
  );
}

function useConfiguration(): Configuration {
  const context = useContext(ConfigurationContext);
  if (!context) {
    throw new ConfigurationNotFoundError();
  }
  return context;
}

export { ConfigurationProvider, useConfiguration };
