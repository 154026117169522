import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";

import { IconPuzzle } from "@activeviam/activeui-sdk";
import type { ApplicationMenuItem } from "@activeviam/activeui-sdk";

import { MenuItemLabel } from "./MenuItemLabel.js";

const widgetsKey = "widgets";

/**
 * {@link ApplicationMenuItem} used to open a widgets section to insert native or saved widgets into a dashboard.
 */
export const insertWidgetsMenuItem: ApplicationMenuItem = {
  key: widgetsKey,
  useMenuItem: () => {
    const dispatch = useDispatch();
    const { formatMessage } = useIntl();

    return {
      icon: <IconPuzzle />,
      label: (
        <MenuItemLabel
          title={formatMessage({
            id: "aui.contentTree.widgets",
          })}
          shortcutKey={widgetsKey}
        />
      ),
      onClick: () => {
        dispatch({
          type: "resourcesPanelChanged",
          resourcesPanelKey: widgetsKey,
        });
      },
    };
  },
};
