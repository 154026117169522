import { FC, ReactNode } from "react";

import { useTheme } from "@activeviam/activeui-sdk";
import { Tooltip } from "@activeviam/utils-react";

import { keyboardShortcuts } from "../../keyboardShortcuts.js";

interface MenuItemLabelProps {
  title: ReactNode;
  shortcutKey?: string;
  disabled?: boolean;
  tooltipTitle?: ReactNode;
}

/**
 * Label for an Ant Design menu item.
 */
export const MenuItemLabel: FC<MenuItemLabelProps> = (props) => {
  const theme = useTheme();

  return (
    <Tooltip
      trigger={props.tooltipTitle ? ["hover"] : []}
      placement="right"
      title={props.tooltipTitle}
    >
      <span style={{ display: "flex" }}>
        <span
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            flexGrow: 1,
          }}
        >
          {props.title}
        </span>
        {props.shortcutKey ? (
          <span
            style={{
              color: props.disabled
                ? theme.disabledTextColor
                : theme.grayScale[7],
              justifySelf: "flex-end",
            }}
          >
            ({keyboardShortcuts[props.shortcutKey]})
          </span>
        ) : null}
      </span>
    </Tooltip>
  );
};
