// TODO update doc link to the corresponding section in the Atoti UI 5 documentation when available.

/**
 * The ID of the DOM element used as the container for all Ant Design overlays.
 * This element should be under <ThemeProvider />.
 */
export const overlayRootId = "overlay-root";

/**
 * Returns the DOM element used as the container for all Ant Design overlays.
 * The application must provide this element as part of its DOM.
 * For more information, see https://docs.activeviam.com/products/atoti/ui/4.3.15/dev/guides/custom-components.html/#inheriting-ant-design-style.
 */
export function getOverlayRoot() {
  const element = document.getElementById(overlayRootId);
  if (!element) {
    throw new Error(`Missing element with \`id="${overlayRootId}"\` in page.`);
  }
  return element;
}
