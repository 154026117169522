import { FolderOutlined } from "@ant-design/icons";
import { Input } from "antd";
import type { ItemType } from "antd/es/menu/hooks/useItems.js";
import {
  isEqual as _isEqual,
  last as _last,
  unionWith as _unionWith,
} from "lodash-es";
import { MouseEvent } from "react";

import { ContentNode, TreeObject } from "@activeviam/activeui-sdk";
import { Text } from "@activeviam/utils-react";

export interface GetDashboardFolderNodeProps {
  node: TreeObject<ContentNode>;
  path: string[];
  pathToSelectedFolder?: string[];
  pathToEditedFolder?: string[];
  onSelect: (path: string[]) => void;
  onContextMenu: (path: string[]) => void;
  onEditionEnded: (path: string[], caption: string) => void;
}

/**
 *  Returns a dashboard folder node.
 *  This node is a menu item, because the dashboard folders tree technically is a menu.
 */
export function getDashboardFolderNode({
  node,
  path,
  pathToSelectedFolder,
  pathToEditedFolder,
  onSelect,
  onContextMenu,
  onEditionEnded,
}: GetDashboardFolderNodeProps): ItemType {
  // `_isEqual` is used here to compare 2 small arrays of strings: not a performance threat.
  /* eslint-disable  atoti-ui/no-lodash-isequal */
  const isSelected = _isEqual(path, pathToSelectedFolder);
  const isBeingEdited = _isEqual(path, pathToEditedFolder);
  /* eslint-enable  atoti-ui/no-lodash-isequal */

  return {
    // A folder id is only unique among its siblings, whereas a menu item key needs to be unique across the entire tree.
    key: path.join("/"),
    icon: <FolderOutlined />,
    className: isSelected ? "folder-selected" : undefined,
    label: (
      <div
        onClick={(event) => {
          // Clicking on a non empty folder caption selects the folder without expending or collapsing it.
          // Clicking the right arrow or the left icon expends/collapses the folder without navigating to it.
          if (node.children?.length) {
            event.stopPropagation();
            onSelect(path);
          }
        }}
      >
        {isBeingEdited ? (
          <Input
            bordered={false}
            style={{ padding: 0, color: "inherit" }}
            defaultValue={node.caption}
            autoFocus
            onFocus={(event) => event.target.select()}
            onBlur={(event) => {
              const inputValue = event.target.value.trim();
              onEditionEnded(path, inputValue);
            }}
            onPressEnter={(event) => {
              // Prevent the folder from expending/collapsing.
              event.stopPropagation();
              event.currentTarget.blur();
            }}
            onKeyUp={(event) => {
              if (event.key === "Escape") {
                const originalCaption = node?.caption ?? "";
                onEditionEnded(path, originalCaption);
              }
            }}
          />
        ) : (
          <Text
            style={{
              verticalAlign: "middle",
              paddingBottom: "4px",
              color: "inherit",
            }}
            small={true}
            ellipsis={{
              tooltip: {
                title: node.caption,
                placement: "right",
                mouseLeaveDelay: 0,
              },
            }}
          >
            {node.caption}
          </Text>
        )}
      </div>
    ),
    // @ts-expect-error the Ant Design MenuItemType interface misses its `onContextMenu` attribute, even though the Menu supports it during runtime.
    // See https://github.com/ant-design/ant-design/issues/38370.
    onContextMenu: (event: MouseEvent<HTMLDivElement, MouseEvent>) => {
      // The context menu event bubbles up to the parent folder.
      // In order to prevent the parent folder from opening its own context menu, the target folder prevents the default behavior of the event.
      // `event.stopPropagation()` can not be used because it would prevent the Dropdown component from opening the menu.
      if (event.isDefaultPrevented()) {
        return;
      }
      event.preventDefault();
      onContextMenu(path);
    },
  };
}
