import { useEffect } from "react";

import {
  Settings,
  getPrefixedLocalStorageKey,
  useSetting,
} from "@activeviam/activeui-sdk";
import { defaultSettings } from "@activeviam/content-client";

/**
 * React Hook returning a setting that is stored as a string.
 * While loading, it defaults to a setting in the local storage or a the setting's default value.
 * Once loaded, it updates the local storage to the value from the Content Server.
 */
export function useSettingWithLocalStorageDefault<K extends keyof Settings>(
  settingKey: K,
): [Settings[K], (value: Settings[K]) => void] {
  const localStorageKey = getPrefixedLocalStorageKey(`settings-${settingKey}`);
  const localStorageSetting =
    // Since the local storage setting is set as a variable of type Settings[K], it necessarily must also be Settings[K].
    // eslint-disable-next-line atoti-ui/no-as
    (window.localStorage.getItem(localStorageKey) as Settings[K]) ??
    defaultSettings[settingKey];
  const [
    settingValue,
    setSettingValue,
    { isLoading: isSettingLoading },
  ] = useSetting(settingKey);

  useEffect(() => {
    if (!isSettingLoading && typeof settingValue === "string") {
      window.localStorage.setItem(localStorageKey, settingValue);
    }
  }, [
    localStorageSetting,
    isSettingLoading,
    settingKey,
    settingValue,
    localStorageKey,
  ]);

  const setting: Settings[K] = isSettingLoading
    ? localStorageSetting
    : settingValue;

  return [setting, setSettingValue];
}
