import { produce } from "immer";
import { get as _get, isEqual as _isEqual, last as _last, uniqWith as _uniqWith, update as _update } from "lodash-es";
import { createLevelCompoundIdentifier } from "../createLevelCompoundIdentifier.js";
import { createMemberCompoundIdentifier } from "../createMemberCompoundIdentifier.js";
import { createMemberOrMeasureCompoundIdentifier } from "../createMemberOrMeasureCompoundIdentifier.js";
import { findDescendant } from "../findDescendant.js";
import { isMdxCompoundIdentifier } from "../isMdxCompoundIdentifier.js";
import { isMdxFunction } from "../isMdxFunction.js";
import { _findCompoundIdentifiers } from "./_findCompoundIdentifiers.js";
import { _findCrossjoins } from "./_findCrossjoins.js";
import { _getHighestParentRepresentingTheSameHierarchies } from "./_getHighestParentRepresentingTheSameHierarchies.js";
/**
 * Returns the nodes representing the set of hierarchies defined by `hierarchyCoordinates`.
 */ const _getNodesRepresentingTargetHierarchies = (mdx, { cube , hierarchyCoordinates  })=>{
    if (hierarchyCoordinates.length === 0) {
        return [];
    }
    if (hierarchyCoordinates.length === 1) {
        return _findCompoundIdentifiers(mdx, {
            hierarchyCoordinates: hierarchyCoordinates[0],
            cube
        });
    }
    return _findCrossjoins(mdx, {
        hierarchyCoordinates,
        cube
    });
};
/**
 * Returns the nodes that should be wrapped in a Descendant function.
 * They are the outermost nodes of the same dimensionality as `hierarchyCoordinates`.
 */ export const getNodesToExpand = (mdx, { hierarchyCoordinates , cube  })=>{
    const nodesToExpand = _uniqWith(_getNodesRepresentingTargetHierarchies(mdx, {
        hierarchyCoordinates,
        cube
    }).map(({ path  })=>_getHighestParentRepresentingTheSameHierarchies(mdx, path)).filter(({ match  })=>!isMdxCompoundIdentifier(match)), // Not a performance threat: paths are small arrays of strings or numbers.
    // eslint-disable-next-line atoti-ui/no-lodash-isequal
    ({ path: path1  }, { path: path2  })=>_isEqual(path1, path2));
    return nodesToExpand.map(({ match , path  })=>{
        let isAlreadyHierarchized = false;
        let _path = path;
        let node = match;
        while(isMdxFunction(node)){
            if (!functionsAboveUnion.includes(node.name.toLowerCase())) {
                break;
            }
            if (node.name.toLowerCase() === "hierarchize") {
                isAlreadyHierarchized = true;
            }
            _path = [
                ..._path,
                "arguments",
                0
            ];
            // Forced to use `get` because the path is dynamic.
            // eslint-disable-next-line atoti-ui/no-lodash-get
            node = _get(mdx, _path);
        }
        return {
            match: node,
            path: _path,
            isAlreadyHierarchized
        };
    });
};
// These functions should remain above the added Union
const functionsAboveUnion = [
    "hierarchize",
    "order"
];
/**
 * Returns the MDX node that should be inserted in the Union in order to request the descendants of the expanded node.
 */ const _getDescendantsNode = ({ originalNode , tupleCoordinates , toLevel , doesIncludeCalculatedMembers , cube  })=>{
    const lastMember = _last(tupleCoordinates);
    let descendants = {
        arguments: [
            {
                arguments: [
                    createMemberCompoundIdentifier(lastMember, cube)
                ],
                elementType: "Function",
                name: "{}",
                syntax: "Braces"
            },
            createLevelCompoundIdentifier(toLevel)
        ],
        elementType: "Function",
        name: "Descendants",
        syntax: "Function"
    };
    if (doesIncludeCalculatedMembers) {
        descendants = {
            elementType: "Function",
            syntax: "Function",
            name: "AddCalculatedMembers",
            arguments: [
                descendants
            ]
        };
    }
    if (tupleCoordinates.length === 1) {
        return descendants;
    }
    const crossjoin = findDescendant(originalNode, (node)=>isMdxFunction(node, "crossjoin"));
    if (!crossjoin) {
        throw new Error(`Cannot expand tuple, because it contains hierarchies that don't exist on the target axis.\nTupleCoordinates:\n${JSON.stringify(tupleCoordinates, undefined, 2)}\nMdx:\n${JSON.stringify(originalNode, undefined, 2)}.`);
    }
    return {
        // Several hierarchies are involved in the expansion: the original node has to contain a Crossjoin.
        arguments: [
            // `crossjoin.match` is an MdxFunction because findDescendant takes in `isMdxFunction` is called with matchFn = isMdxCompoundIdentifier.
            // eslint-disable-next-line atoti-ui/no-as
            ...crossjoin.match.arguments.map((arg, i)=>{
                if (i < tupleCoordinates.length - 1) {
                    return createMemberOrMeasureCompoundIdentifier(tupleCoordinates[i], cube);
                }
                if (i === tupleCoordinates.length - 1) {
                    return descendants;
                }
                return arg;
            })
        ],
        elementType: "Function",
        name: "Crossjoin",
        syntax: "Function"
    };
};
/**
 * Expands `tupleCoordinates` down onto `toLevel`.
 * Wraps the new level expression with `AddCalculatedMembers` if `doesIncludeCalculatedMembers` is `true`.
 * Assumes that `toLevel` belongs to the same hierarchy as the last member in `tupleCoordinates` does.
 * Does not mutate `mdx`.
 */ export const _expandTupleOntoDeeperLevelOfTheSameHierarchy = (mdx, { tupleCoordinates , toLevel , doesIncludeCalculatedMembers , cube  })=>{
    // - find the expressions with the same dimensionality as `tupleCoordinates`.
    // - surround them in an `Union` function.
    // - add as 2nd argument of the `Union` the descendants of `tupleCoordinates` onto `toLevel`.
    return produce(mdx, (draft)=>{
        const nodesToExpand = getNodesToExpand(draft, {
            hierarchyCoordinates: tupleCoordinates,
            cube
        });
        nodesToExpand.forEach(({ match , path , isAlreadyHierarchized  })=>{
            // If there is not already a Union function, then add it.
            if (!isMdxFunction(match, "union")) {
                _update(draft, path, (node)=>({
                        arguments: [
                            node
                        ],
                        elementType: "Function",
                        name: "Union",
                        syntax: "Function"
                    }));
            }
            // There is necessarily a Union function at this stage.
            // Forced to use `get` because the path is dynamic.
            // eslint-disable-next-line atoti-ui/no-lodash-get
            const union = _get(draft, path);
            union.arguments.push(_getDescendantsNode({
                originalNode: match,
                tupleCoordinates,
                toLevel,
                doesIncludeCalculatedMembers,
                cube
            }));
            if (!isAlreadyHierarchized) {
                _update(draft, path, (_node)=>({
                        arguments: [
                            _node
                        ],
                        elementType: "Function",
                        name: "Hierarchize",
                        syntax: "Function"
                    }));
            }
        });
    });
};
