import {
  isNil as _isNil,
  isObject as _isObject,
  pick as _pick,
  noop,
} from "lodash-es";
import {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useSyncExternalStore,
} from "react";
import { useAsyncCallback } from "react-async-hook";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";

import { Settings, useContentClient, useUser } from "@activeviam/activeui-sdk";
import { defaultSettings } from "@activeviam/content-client";
import { SettingsEditor } from "@activeviam/settings";
import { Modal, Tooltip } from "@activeviam/utils-react";

function isInvalidSettings(settings: Partial<Settings>) {
  return (
    Object.values(
      _pick(
        settings,
        "search.maxResults",
        "table.animations.duration",
        "table.animations.threshold",
      ),
    ).some(_isNil) ||
    (_isObject(settings["table.animations.threshold"]) &&
      (Object.values(settings["table.animations.threshold"]).some(_isNil) ||
        Object.keys(settings["table.animations.threshold"]).includes("")))
  );
}

/**
 * Displays a Popup allowing a user to edit their settings.
 */
export const SettingsPopup = ({ isVisible }: { isVisible: boolean }) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const { username } = useUser();
  const contentClient = useContentClient();

  const subscribeToOrganizationSettings = useCallback(
    (callback: () => void) =>
      // @ts-expect-error pending on https://github.com/activeviam/atoti-ui/pull/4005#pullrequestreview-1651301868.
      contentClient?.subscribeToSettings("organization", callback) ?? noop,
    [contentClient],
  );
  const organizationSettings = useSyncExternalStore(
    subscribeToOrganizationSettings,
    () =>
      // @ts-expect-error pending on https://github.com/activeviam/atoti-ui/pull/4005#pullrequestreview-1651301868.
      contentClient?.getSettings("organization"),
  );

  const subscribeToUserSettings = useCallback(
    (callback: () => void) =>
      // @ts-expect-error pending on https://github.com/activeviam/atoti-ui/pull/4005#pullrequestreview-1651301868.
      contentClient?.subscribeToSettings("user", callback) ?? noop,
    [contentClient],
  );
  const userSettings = useSyncExternalStore(subscribeToUserSettings, () =>
    // @ts-expect-error pending on https://github.com/activeviam/atoti-ui/pull/4005#pullrequestreview-1651301868.
    contentClient?.getSettings("user"),
  );

  const [userSettingsDraft, setUserSettingsDraft] = useState<Partial<Settings>>(
    {},
  );
  useEffect(() => setUserSettingsDraft(userSettings ?? {}), [userSettings]);

  const displayedSettings = useMemo(
    () => ({
      ...defaultSettings,
      ...organizationSettings,
      ...userSettingsDraft,
    }),
    [organizationSettings, userSettingsDraft],
  );

  const {
    execute: saveUserSettings,
    loading: isSavingUserSettings,
  } = useAsyncCallback(async () => {
    await contentClient.updateSettings({
      username,
      settings: userSettingsDraft,
    });
    dispatch({ type: "settingsPopupClosed" });
  });

  // Close the popup when unmounting.
  // Prevents the modal from still being open while navigating from a dashboard to the home page and back to a dashboard.
  useEffect(() => {
    return () => {
      dispatch({ type: "settingsPopupClosed" });
    };
  }, [dispatch]);

  const isDisabled = isInvalidSettings(userSettingsDraft);

  return (
    <Modal
      title={formatMessage({ id: "aui.settings" })}
      okText={
        <Tooltip
          trigger={isDisabled ? ["hover"] : []}
          placement={"right"}
          title={formatMessage({ id: "aui.settingsEditor.disabled" })}
        >
          {formatMessage({ id: "aui.save" })}
        </Tooltip>
      }
      open={isVisible}
      destroyOnClose={true}
      okButtonProps={{
        loading: isSavingUserSettings,
        disabled: isDisabled,
      }}
      onOk={saveUserSettings}
      onCancel={() => {
        setUserSettingsDraft(userSettings);
        dispatch({ type: "settingsPopupClosed" });
      }}
      width={572}
    >
      <SettingsEditor
        settings={displayedSettings}
        onChange={(key, value) => {
          setUserSettingsDraft((current) => ({ ...current, [key]: value }));
        }}
      />
    </Modal>
  );
};
