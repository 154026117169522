import { produce } from "immer";
import { set as _set } from "lodash-es";
import { _cleanUpUnusedFormulas } from "./internal/_cleanUpUnusedFormulas.js";
import { _findFirstOrderFunction } from "./internal/_findFirstOrderFunction.js";
/**
 * Returns a new {@link MdxSelect} corresponding to `mdx`, unsorted.
 * Does not mutate `mdx`.
 */ export function removeSort(mdx) {
    const firstOrderFunction = _findFirstOrderFunction(mdx);
    const mdxWithoutFirstOrder = produce(mdx, (draft)=>{
        if (firstOrderFunction) {
            _set(draft, firstOrderFunction.path, firstOrderFunction.match.arguments[0]);
        }
    });
    if (!firstOrderFunction) {
        return _cleanUpUnusedFormulas(mdxWithoutFirstOrder);
    }
    // Remove the first encountered Order function until none are left.
    return removeSort(mdxWithoutFirstOrder);
}
