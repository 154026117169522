import { isACompoundIdentifierRepresentingAMeasure } from "./isACompoundIdentifierRepresentingAMeasure.js";
import { isMdxDrillthrough } from "./isMdxDrillthrough.js";
/**
 * Returns the expression of each calculated measure defined in `mdx`.
 */ export function getCalculatedMeasures(mdx) {
    if (isMdxDrillthrough(mdx)) {
        return getCalculatedMeasures(mdx.select);
    }
    return mdx.withClause?.reduce((acc, formula)=>{
        const { identifiers  } = formula.name;
        if (!isACompoundIdentifierRepresentingAMeasure(formula.name)) {
            return acc;
        }
        return {
            ...acc,
            [identifiers[1].value]: formula
        };
    }, {});
}
