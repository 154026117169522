import {
  Theme,
  ThemeNotFoundError,
  ThemeProvider,
  darkActiveViamTheme,
  lightActiveViamTheme,
} from "@activeviam/activeui-sdk";

import { useConfiguration } from "./ConfigurationContext.js";
import { overlayRootId } from "./getOverlayRoot.js";
import { useSettingWithLocalStorageDefault } from "./hooks/useSettingWithLocalStorageDefault.js";

/**
 * HOC that provides the theme corresponding to the theme key specified in the settings.
 */
export function withStyle<P extends JSX.IntrinsicAttributes>(
  Component: React.ComponentType<P>,
): React.ComponentType<P> {
  const Wrapped = (props: P) => {
    const configuration = useConfiguration();
    const [themeKey] = useSettingWithLocalStorageDefault("theme");

    const themes: {
      [key: string]: Partial<Theme> & { primaryColor: string };
    } = {
      "light-activeviam": lightActiveViamTheme,
      "dark-activeviam": darkActiveViamTheme,
      ...configuration.themes,
    };

    const theme = themes[themeKey];

    if (!theme) {
      throw new ThemeNotFoundError(themeKey, Object.keys(themes));
    }

    return (
      <ThemeProvider value={theme}>
        <div id={overlayRootId} />
        <Component {...props} />
      </ThemeProvider>
    );
  };

  const name = Component.displayName || Component.name || "Unknown";
  Wrapped.displayName = `withStyle(${name})`;
  return Wrapped;
}
