import { UndoOutlined } from "@ant-design/icons";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { ActionCreators } from "redux-undo";

import type { ApplicationMenuItem, State } from "@activeviam/activeui-sdk";

import { MenuItemLabel } from "./MenuItemLabel.js";

const undoKey = "undo";

/**
 * {@link ApplicationMenuItem} used to revert the latest change made by a user.
 */
export const editUndoMenuItem: ApplicationMenuItem = {
  key: undoKey,
  useMenuItem: () => {
    const dispatch = useDispatch();
    const { formatMessage } = useIntl();

    const handleUndo = () => {
      dispatch(ActionCreators.undo());
    };
    const numberOfUndoableStates = useSelector(
      (state: State) => state.dashboard.past.length,
    );

    // If there is no past state then there is nothing to undo.
    const disabled = numberOfUndoableStates === 0;

    return {
      icon: <UndoOutlined />,
      disabled,
      label: (
        <MenuItemLabel
          title={formatMessage({
            id: undoKey,
          })}
          shortcutKey={undoKey}
          disabled={disabled}
        />
      ),
      onClick: handleUndo,
    };
  },
};
