import { produce } from "immer";
import { get as _get, update as _update } from "lodash-es";
import { findLevels } from "../findLevels.js";
import { isMdxFunction } from "../isMdxFunction.js";
import { _collapseMembersOfHierarchy } from "./_collapseMembersOfHierarchy.js";
import { _findCrossjoins } from "./_findCrossjoins.js";
import { _getHierarchiesOnAxis } from "./_getHierarchiesOnAxis.js";
/**
 * Returns the levels of the given hierarchy in `mdx`.
 */ const _findLevelOfSameHierarchy = (mdx, addedLevels, cube)=>{
    if (addedLevels.length !== 1) {
        // If several hierarchies are represented in the added expression, then no level is comparable to it.
        return;
    }
    const { dimensionName , hierarchyName  } = addedLevels[0];
    return findLevels(mdx, cube, [
        {
            dimensionName,
            hierarchyName
        }
    ])[0];
};
/**
 * Returns the top node of the axis. Leaves potential Order functions above it.
 */ const _findTopNode = (mdx, axisIndex)=>{
    // The target axis doesn't contain a crossjoin yet, create one.
    const pathToTopNode = [
        "axes",
        axisIndex,
        "expression"
    ];
    // Forced to use `get` because pathToTopNode is dynamic.
    // eslint-disable-next-line atoti-ui/no-lodash-get
    let topNode = _get(mdx, pathToTopNode);
    // Leave the potential "Order" functions at the root of the axis.
    while((topNode = // Forced to use `get` because pathToTopNode is dynamic.
    // eslint-disable-next-line atoti-ui/no-lodash-get
    _get(mdx, pathToTopNode)) && isMdxFunction(topNode, "order")){
        pathToTopNode.push("arguments", 0);
    }
    return pathToTopNode;
};
/**
 * Adds `addedExpression` in a Union wrapping the node at `path` in `mdx`.
 * Mutates `mdx`.
 */ const _addToUnion = (mdx, path, addedExpression)=>{
    const pathToGrandParent = path.slice(0, -2);
    // Forced to use `get` because pathToGrandParent is dynamic.
    // eslint-disable-next-line atoti-ui/no-lodash-get
    const grandParent = _get(mdx, pathToGrandParent);
    if (isMdxFunction(grandParent, "union")) {
        // The target node is already in a Union.
        // Add `addedExpression` to the Union.
        _update(mdx, pathToGrandParent, (_union)=>({
                ..._union,
                arguments: [
                    ..._union.arguments,
                    addedExpression
                ]
            }));
    } else {
        // Wrap the target node in a Union and add `addedExpression` to it.
        _update(mdx, path, (node)=>({
                arguments: [
                    node,
                    addedExpression
                ],
                elementType: "Function",
                name: "Union",
                syntax: "Function"
            }));
    }
};
/**
 * Wraps the node at `path` in `mdx` in a Crossjoin.
 * Mutates `mdx`.
 */ const _wrapInCrossjoin = (mdx, path)=>{
    _update(mdx, path, (node)=>({
            arguments: [
                node
            ],
            elementType: "Function",
            name: "Crossjoin",
            syntax: "Function"
        }));
};
/**
 * Returns a new {@link MdxSelect} corresponding to `mdx` where the given hierarchy or level was added.
 * Does not mutate mdx.
 */ export const _addExpressionToAxis = (mdx, options)=>{
    const { cube , addedExpression  } = options;
    const axisName = options.axisName !== undefined ? options.axisName : "ROWS";
    const shouldCreateNonEmptyAxis = options.shouldCreateNonEmptyAxis !== undefined ? options.shouldCreateNonEmptyAxis : true;
    const axisIndex = (mdx.axes || []).findIndex((axis)=>axis.name === axisName);
    const levelsOnAxis = findLevels(mdx.axes[axisIndex], cube);
    const hierarchiesOnAxis = _getHierarchiesOnAxis(mdx.axes[axisIndex], {
        cube
    });
    // TODO Fix the logic here.
    // There are some issues here regarding where we want to collapse/add new levels.
    // See https://activeviam.atlassian.net/browse/UI-7761 for more details.
    const indexOnAxis = options.indexOnAxis !== undefined ? options.indexOnAxis : axisIndex === -1 ? 0 : levelsOnAxis.length;
    // Adding an expression before other expressions on the axis breaks the identity of the potentially expanded tuples in those expressions.
    // => Collapse these potential expanded tuples.
    // For instance, consider an axis representing currencies and legal entities where USD/LegalEntityA is expanded.
    // Now suppose the user drags City before currencies. USD/LegalEntityA does not exist anymore on this axis, so it should be collapsed.
    const cleanMdx = _collapseMembersOfHierarchy(mdx, hierarchiesOnAxis[indexOnAxis], cube);
    return produce(cleanMdx, (draft)=>{
        if (draft.axes === undefined) {
            // `axes` is not here, create it.
            draft.axes = [];
        }
        const axisIndex = draft.axes.findIndex(({ name  })=>name === axisName);
        if (axisIndex === -1) {
            // The target axis is not here, create it.
            draft.axes.push({
                elementType: "Axis",
                name: axisName,
                nonEmpty: shouldCreateNonEmptyAxis,
                expression: addedExpression,
                properties: []
            });
            // `addedExpression` was added at the root of the new axis.
            // The job is done.
            return;
        }
        const axis = draft.axes[axisIndex];
        const addedLevels = findLevels(addedExpression, cube);
        const crossjoins = _findCrossjoins(axis, {
            cube
        });
        if (crossjoins.length === 0) {
            const pathToTopNode = _findTopNode(mdx, axisIndex);
            const levelOfSameHierarchy = _findLevelOfSameHierarchy(// Forced to use `get` because pathToTopNode is dynamic.
            // eslint-disable-next-line atoti-ui/no-lodash-get
            _get(draft, pathToTopNode), addedLevels, cube);
            if (levelOfSameHierarchy !== undefined) {
                _addToUnion(draft, [
                    ...pathToTopNode,
                    ...levelOfSameHierarchy.path
                ], addedExpression);
                // `addedExpression` was added to a union with the pre-existing level of the same hierarchy.
                // The job is done.
                return;
            }
            _wrapInCrossjoin(draft, pathToTopNode);
            crossjoins.push({
                path: pathToTopNode,
                // Forced to use `get` because pathToTopNode is dynamic.
                // eslint-disable-next-line atoti-ui/no-lodash-get
                match: _get(draft, pathToTopNode)
            });
        }
        crossjoins.forEach(({ match: crossjoin , path: pathToCrossjoin  })=>{
            const levelOfSameHierarchy = _findLevelOfSameHierarchy(crossjoin, addedLevels, cube);
            if (levelOfSameHierarchy !== undefined) {
                _addToUnion(draft, [
                    "axes",
                    axisIndex,
                    ...pathToCrossjoin,
                    ...levelOfSameHierarchy.path
                ], addedExpression);
            } else {
                // Add to Crossjoin
                crossjoin.arguments.splice(indexOnAxis, 0, addedExpression);
            }
        });
    });
};
