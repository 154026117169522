import { getServiceVersionIdAsNumber } from "@activeviam/versions";
import { fetchDrillthroughColumnsV6 } from "./fetchDrillthroughColumnsV6.js";
import { fetchDrillthroughColumnsV8 } from "./fetchDrillthroughColumnsV8.js";
/**
 * Asynchronously returns the list of {@link DrillthroughColumn} available for each cube on the server at `serverUrl`.
 */ export async function fetchDrillthroughColumns(args) {
    const serviceVersionIdAsNumber = getServiceVersionIdAsNumber(args.pivotServiceVersion.id);
    switch(serviceVersionIdAsNumber){
        case 8:
            return fetchDrillthroughColumnsV8(args);
        default:
            return fetchDrillthroughColumnsV6(args);
    }
}
