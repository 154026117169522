import { produce } from "immer";
import { isEmpty as _isEmpty, uniqWith as _uniqWith } from "lodash-es";
import { DimensionNotFoundError, HierarchyNotFoundError, areHierarchiesEqual, getHierarchy } from "@activeviam/data-model";
import { createLevelCompoundIdentifier } from "./createLevelCompoundIdentifier.js";
import { createMeasureCompoundIdentifier } from "./createMeasureCompoundIdentifier.js";
import { getHierarchies } from "./getHierarchies.js";
import { getLevelName } from "./getLevelName.js";
import { _getIndexOfAxisContainingHierarchy } from "./internal/_getIndexOfAxisContainingHierarchy.js";
import { isMdxDrillthrough } from "./isMdxDrillthrough.js";
import { quote } from "./quote.js";
import { setFilters } from "./setFilters.js";
import { stringify } from "./stringify.js";
/**
 * Returns a new {@link MdxSelect} corresponding to `mdx` where the axis expression is changed to yield only the available members(2) of a hierarchy, in order to let the user filter their view(1) on some of them.
 *
 * The initial axis expression in `mdx` is expected to represent a set of members of that hierarchy in the first place, the goal of this function being to narrow it down.
 *
 * Does not mutate its arguments.
 *
 * @remarks
 * (1)
 * "View" refers to all widgets about to be filtered.
 * It can be a single widget, a page or a whole dashboard.
 * It is represented by 2 arguments:
 * - `mdxInView`: the Mdx of each widget in the view.
 * - `filters`: the filters applied to all widgets in the view.
 *
 * (2)
 * The "available members" are the members contributing to the view.
 * They are therefore the only ones that the user can filter the view on and obtain a non-empty result.
 * "Smart filtering" refers to the retrieval of these members.
 *
 * When there are slicing hierarchies in the view, it is necessary to look for the availability of members on *all* their slices.
 * Otherwise, only the default slices would be taken into account, and some members may be missing (see example below).
 * Note however that this can hurt performance when the cross-product number of slices to evaluate grows large.
 * For example, if there are 2 slicing hierarchies in the view, each with 100 members on its first level, then 10.000 evaluations are required.
 *
 * @example
 *
 * -------------------------------------------------------
 * 1 - Data
 * Suppose that the entire raw data is the following:
 *
 * Currency  Date       City          QuantitySold
 * EUR       Today      Paris                    2
 * EUR       Today      Berlin                   4
 * EUR       Yesterday  Berlin                   4
 * EUR       Yesterday  New-York                 3
 * GBP       Today      London                   6
 * GBP       Yesterday  London                   8
 * USD       Yesterday  Paris                    2
 * USD       Yesterday  New-York                 6
 * JPY       Today      Tokyo                    7
 *
 * A cube is built on top of this data, where each non-numeric column is mapped to a hierarchy.
 * The Date hierarchy is slicing, and "Today" is its default member.
 * The default member of the Currency and City hierarchies is "AllMember".
 *
 * -------------------------------------------------------
 * 2 - View
 *
 * Suppose the user is looking at QuantitySold for EUR and USD across all dates:
 * Currency   Date      QuantitySold
 * EUR        Today                6
 * EUR        Yesterday            7
 * USD        Yesterday            8
 *
 * -------------------------------------------------------
 * 3 - Goal
 *
 * The user wants to filter the table on "New-York".
 * She drags "City" into "Widget filters".
 *
 * Question: which cities should be presented to her?
 * Answer: Paris, Berlin and New York.
 *
 * Indeed, other cities do not contribute to the records for (Currency ∈ [EUR, USD]).
 * Filtering on these other cities would therefore result in a useless, frustrating empty view.
 *
 * -------------------------------------------------------
 * 4 - Solution: naive approach
 *
 * The simple MDX query to retrieve members is:
 *
 * SELECT
 *  [Geography].[City].[City].Members ON ROWS
 *  FROM [Cube]
 *
 * But this returns *all* cities: Paris, Berlin, London, New-York, and Tokyo.
 * London and Tokyo must be excluded.
 *
 * -------------------------------------------------------
 * 5 - Exclude contributions of filtered-out members (London and Tokyo).
 *
 * This is done by applying the view's filters (Currency ∈ [EUR, USD]) to the query above.
 * Note that the "NON EMPTY" keyword has to be added for the filters to work.
 *
 * SELECT
 *  NON EMPTY [Geography].[City].[City].Members ON ROWS
 *  FROM (
 *    SELECT {
 *      [Currency].[Currency].[AllMember].[EUR],
 *      [Currency].[Currency].[AllMember].[USD]
 *    }
 *    ON ROWS FROM [Cube]
 *  )
 *
 * But this returns only Paris and Berlin.
 * New-York is excluded, even though the [New-York, Yesterday] record contributes to the view!
 *
 * Indeed, adding "NON EMPTY" means only considering the default members of slicing hierarchies (here, "Today").
 * In this example, "New York" is (unfortunately) excluded because it has no contribution for Today.
 *
 * -------------------------------------------------------
 * 6 - Restore contributions of non-default members of slicing hierarchies that are used in the view (New York).
 *
 * The contribution of "Yesterday" must be explicitly taken into account to get New-York in the results.
 * This is done by refining the "non-emptiness" requirement on cities.
 * Specifically, by requesting the cities which have at least one record for at least one date:
 *
 * SELECT
 *  NonEmpty(
 *    [Geography].[City].[City].Members,
 *    Crossjoin(
 *      [Measures].[contributors.COUNT],
 *      [Time].[Date].Levels(0).Members
 *    )
 *  ) ON ROWS
 *  FROM (
 *    SELECT {
 *      [Currency].[Currency].[AllMember].[EUR],
 *      [Currency].[Currency].[AllMember].[USD]
 *    }
 *    ON ROWS FROM [Cube]
 *  )
 *
 * This returns Paris, London and New York, as expected 🥇.
 * Good job, and thank you for reading to the end.
 *
 * @remarks
 * In some complex cases, the MDX generated on the UI side might be smart-filtered too eagerly and not pair well with the server side implementation.
 * This is typically the case when analysis hierarchies or factless hierarchies are involved in the filtering.
 *
 * To avoid empty/unexpected results, the problematic hierarchies should be ignored from the smart filtering context, i.e.:
 * - Do not do any smart filtering at all when retrieving the members of such a hierarchy
 * - Do not take filters on those hierarchies into account when smart filtering a query to retrieve members from another hierarchy
 *
 * This is controlled by the `ignoredHierarchies` argument.
 *
 *
 * @see
 * Useful reads for a deeper understanding:
 * - MDX filtering and default member behavior: https://docs.activeviam.com/products/atoti/server/5.10.0/docs/mdx/mdx_filtering.html
 * - Non-emptiness evaluation: https://mitchellpearson.com/2016/02/09/mdx-non-empty-keyword-vs-nonempty-function/
 * - Analysis hierarchies: https://docs.activeviam.com/products/atoti/server/5.10.0/docs/concepts/dimensions_and_hierarchies.html#analysis-hierarchies
 * - Factless hierarchies: https://docs.activeviam.com/products/atoti/server/5.10.0/docs/concepts/dimensions_and_hierarchies.html#factless-hierarchies
 */ export function smartFilter(mdx, { cube , targetHierarchyCoordinates , mdxInView , filters , nonEmptyEvaluationMeasureName , isSmartFilteringEnabled , ignoredHierarchies  }) {
    if (!isSmartFilteringEnabled) {
        return mdx;
    }
    const isSmartFilteringDisabledForTargetHierarchy = Boolean(ignoredHierarchies?.includes(quote(targetHierarchyCoordinates.dimensionName, targetHierarchyCoordinates.hierarchyName)));
    const filtersToApply = filters && !isSmartFilteringDisabledForTargetHierarchy ? getFiltersToApplyForSmartFiltering({
        filters,
        ignoredHierarchies,
        cube
    }) : [];
    const isTargetHierarchyVirtual = getHierarchy(targetHierarchyCoordinates, cube).virtual;
    if (isTargetHierarchyVirtual) {
        if (nonEmptyEvaluationMeasureName) {
            throw new Error(`${nonEmptyEvaluationMeasureName} cannot be used when smart filtering the query to fetch members of ${quote(targetHierarchyCoordinates.dimensionName, targetHierarchyCoordinates.hierarchyName)}, because it is a virtual hierarchy.`);
        }
        // TODO Once https://activeviam.atlassian.net/browse/PIVOT-5733 is addressed, handle the case where there are slicing hierarchies in the user's view.
        if (_isEmpty(filters)) {
            return mdx;
        }
        return setFilters(mdx, {
            filters: filtersToApply,
            cube
        });
    }
    const axisIndex = _getIndexOfAxisContainingHierarchy(mdx, {
        ...targetHierarchyCoordinates,
        cube
    });
    if (axisIndex === -1) {
        throw new Error(`The query to smart-filter must represent a set of members from a target hierarchy on an axis, but the hierarchy ${quote(targetHierarchyCoordinates.dimensionName, targetHierarchyCoordinates.hierarchyName)} was not found on any axis in:\n${stringify(mdx, {
            indent: true
        })}`);
    }
    if (isSmartFilteringDisabledForTargetHierarchy) {
        if (nonEmptyEvaluationMeasureName) {
            // There is a special measure other than contributors.COUNT to evaluate whether a member is available.
            // This is the case when the user searches for a member in the filter popover for example.
            // In this case, it must be taken into account by slicing on this measure and removing empty evaluations on the result set through the NON EMPTY keyword.
            const mdxWithNonEmpty = produce(mdx, (draft)=>{
                draft.axes[axisIndex].nonEmpty = true;
            });
            return setFilters(mdxWithNonEmpty, {
                cube,
                filters: [
                    createMeasureCompoundIdentifier(nonEmptyEvaluationMeasureName)
                ]
            });
        }
        // The target hierarchy is ignored in the smart filtering context.
        // Make sure to remove the axis NON EMPTY evaluation, if any, so that all members are retrieved.
        const mdxWithNonEmptyClauseRemoved = produce(mdx, (draft)=>{
            draft.axes[axisIndex].nonEmpty = false;
        });
        return mdxWithNonEmptyClauseRemoved;
    }
    const nonEmptyEvaluationSet = getNonEmptyEvaluationSet({
        mdxInView,
        nonEmptyEvaluationMeasureName,
        cube
    });
    const mdxWithNonEmptyEvaluation = produce(mdx, (draft)=>{
        // The non-emptiness of tuples on the ROWS axis is evaluated for each tuple of the `nonEmptyEvaluationSet`, thanks to the NonEmpty function.
        // So make sure that the axis NON EMPTY keyword is not used, to not override the NonEmpty function.
        draft.axes[axisIndex].nonEmpty = false;
        draft.axes[axisIndex].expression = {
            arguments: [
                draft.axes[axisIndex].expression,
                nonEmptyEvaluationSet
            ],
            elementType: "Function",
            name: "NonEmpty",
            syntax: "Function"
        };
    });
    if (_isEmpty(filtersToApply)) {
        return mdxWithNonEmptyEvaluation;
    }
    return setFilters(mdxWithNonEmptyEvaluation, {
        filters: filtersToApply,
        cube
    });
}
/**
 * Returns the coordinates of the first level of each slicing hierarchy encountered in the axes of a query of `mdxInView`.
 *
 * See {@link smartFilter}.
 */ function getSlicingHierarchiesInView({ mdxInView , cube  }) {
    if (!mdxInView) {
        return [];
    }
    const hierarchies = [];
    mdxInView.forEach((mdx)=>{
        const { axes  } = isMdxDrillthrough(mdx) ? mdx.select : mdx;
        axes.forEach((axis)=>{
            const hierarchiesOnAxis = getHierarchies(axis, {
                cube
            });
            hierarchies.push(...hierarchiesOnAxis.filter(({ dimensionName  })=>dimensionName !== "Measures"));
        });
    });
    const dedupedHierarchies = _uniqWith(hierarchies, areHierarchiesEqual);
    const levels = [];
    dedupedHierarchies.forEach((hierarchyCoordinates)=>{
        const hierarchy = getHierarchy(hierarchyCoordinates, cube);
        if (hierarchy.slicing) {
            levels.push({
                ...hierarchyCoordinates,
                levelName: getLevelName(hierarchy, 0)
            });
        }
    });
    return levels;
}
/**
 * Returns the set of tuples against which the non-empty evaluation is performed when fetching the list of members that is presented to the user when they create/edit a filter.
 *
 * See {@link smartFilter}.
 */ function getNonEmptyEvaluationSet({ mdxInView , nonEmptyEvaluationMeasureName , cube  }) {
    const slicingHierarchiesInView = getSlicingHierarchiesInView({
        cube,
        mdxInView
    });
    const nonEmptyEvaluationMeasure = createMeasureCompoundIdentifier(nonEmptyEvaluationMeasureName ?? "contributors.COUNT");
    if (slicingHierarchiesInView.length > 0) {
        const slicesToEvaluate = slicingHierarchiesInView.map((levelCoordinates)=>({
                elementType: "Function",
                name: "Members",
                syntax: "Property",
                arguments: [
                    createLevelCompoundIdentifier(levelCoordinates)
                ]
            }));
        return {
            elementType: "Function",
            name: "Crossjoin",
            syntax: "Function",
            arguments: [
                ...slicesToEvaluate,
                nonEmptyEvaluationMeasure
            ]
        };
    }
    return nonEmptyEvaluationMeasure;
}
/**
 * Returns the filters that should be applied when fetching the list of members that is presented to the user when they create/edit a filter.
 *
 * See {@link smartFilter}.
 */ export function getFiltersToApplyForSmartFiltering({ filters , ignoredHierarchies , cube  }) {
    return filters.filter((filter)=>{
        try {
            const hierarchies = getHierarchies(filter, {
                cube
            });
            // Ignore filters on measures, and filters on hierarchies that should not be taken into account in the smart filtering context.
            return hierarchies.every(({ dimensionName , hierarchyName  })=>dimensionName !== "Measures" && !ignoredHierarchies?.includes(quote(dimensionName, hierarchyName)));
        } catch (error) {
            if (error instanceof DimensionNotFoundError || error instanceof HierarchyNotFoundError) {
                // Ignore filters targeting hierarchies that are not in the cube, and corrupt filters.
                return false;
            } else {
                throw error;
            }
        }
    });
}
