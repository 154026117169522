import { FC } from "react";

import { BrandSignature } from "@activeviam/utils-react";

import { useConfiguration } from "../../ConfigurationContext.js";

/**
 * Displays a footer with the brand signature in the application.
 */
export const BrandSignatureFooter: FC = () => {
  const { isBrandSignatureVisible } = useConfiguration();

  return isBrandSignatureVisible ? (
    <div
      style={{
        minHeight: 28,
        display: "flex",
        alignItems: "center",
        justifyContent: "right",
      }}
    >
      <BrandSignature />
    </div>
  ) : null;
};
