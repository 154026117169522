function interleaveFn(array, sepFn) {
    const result = [];
    array.forEach((val, i)=>{
        if (i !== 0) {
            result.push(sepFn(i));
        }
        result.push(val);
    });
    return result;
}
/**
 * Interleave an item between each item of an array.
 * If array is [a,b,c,d] and sep is i, output is [a,i,b,i,c,i,d].
 * @param array the array to interleave, not mutated.
 * @param sep the separator to insert between each item of the input array.
 * Returns the output array.
 */ export function interleave(array, sep) {
    return interleaveFn(array, ()=>sep);
}
