import { flatMap as _flatMap } from "lodash-es";
import { _getFunctionNodesOfDrillthroughColumn } from "./internal/_getFunctionNodesOfDrillthroughColumn.js";
/**
 * Returns a new {@link MdxDrillthrough} corresponding to `mdx`.
 * The columns in the returned MDX are set to the drillthrough columns corresponding to `drillthroughColumnUniqueNames`.
 * Does not mutate `mdx`.
 */ export function setDrillthroughColumns(mdx, { drillthroughColumnUniqueNames  }) {
    return {
        ...mdx,
        columns: _flatMap(drillthroughColumnUniqueNames, _getFunctionNodesOfDrillthroughColumn)
    };
}
