import { CubelessQueryError } from "./errors/CubelessQueryError.js";
const getCubeNameInSelect = (select)=>getCubeName(select.from);
const getCubeNameInFromClause = (from)=>from.cubeName;
const getCubeNameInDrillthrough = (drillthrough)=>getCubeNameInSelect(drillthrough.select);
/**
 * Returns the name of the cube used in `mdx`, if any.
 */ export function getCubeName(mdx) {
    switch(mdx.elementType){
        case "Drillthrough":
            return getCubeNameInDrillthrough(mdx);
        case "Select":
        case "SubSelect":
            return getCubeNameInSelect(mdx);
        case "From":
            return getCubeNameInFromClause(mdx);
        default:
            throw new CubelessQueryError(mdx);
    }
}
