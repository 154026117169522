import {
  Configuration,
  toolBehaviorEditor,
  toolContentEditor,
  toolFiltersEditor,
  toolStyleEditor,
} from "@activeviam/activeui-sdk";
import { defaultConfiguration } from "@activeviam/module-federation";

import {
  leftApplicationMenu,
  rightApplicationMenu,
} from "./components/header/ApplicationMenus.js";

/**
 * A {@link Configuration} that does not contain any plugin.
 */
export const initialConfiguration: Configuration = {
  ...defaultConfiguration,
  rightApplicationMenu,
  leftApplicationMenu,
  tools: [
    toolContentEditor,
    toolFiltersEditor,
    toolStyleEditor,
    toolBehaviorEditor,
  ],
};
