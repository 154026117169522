import { areHierarchiesEqual } from "@activeviam/data-model";
import { findDescendant } from "../findDescendant.js";
import { getHierarchies } from "../getHierarchies.js";
import { isMdxCompoundIdentifier } from "../isMdxCompoundIdentifier.js";
/**
 * Returns the index of the axis in `mdx` containing a reference to the hierarchy identified by `dimensionName` and `hierarchyName`.
 * Returns -1 if no reference to this hierarchy exists in `mdx`.
 */ export const _getIndexOfAxisContainingHierarchy = (mdx, { dimensionName , hierarchyName , cube  })=>{
    return (mdx.axes || []).findIndex((_axis)=>findDescendant(_axis, (node)=>{
            if (!isMdxCompoundIdentifier(node)) {
                return false;
            }
            const [hierarchyCoordinates] = getHierarchies(node, {
                cube
            });
            return areHierarchiesEqual(hierarchyCoordinates, {
                dimensionName,
                hierarchyName
            });
        }));
};
