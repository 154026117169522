import { createMeasureCompoundIdentifier } from "./createMeasureCompoundIdentifier.js";
/**
 * Returns the calculated member corresponding to the given coordinates.
 */ export const createCalculatedMemberCompoundIdentifier = ({ dimensionName , hierarchyName , calculatedMemberName  })=>{
    if (dimensionName === "Measures") {
        return createMeasureCompoundIdentifier(calculatedMemberName);
    }
    return {
        elementType: "CompoundIdentifier",
        type: "unknown",
        identifiers: [
            dimensionName,
            hierarchyName,
            calculatedMemberName
        ].map((value)=>({
                elementType: "Identifier",
                quoting: "QUOTED",
                value
            }))
    };
};
