// This function is internal to this file.
// It is like the exported function, but without the "name" check sugar
const _isMdxFunctionBase = (mdx, name)=>{
    return mdx !== undefined && mdx.elementType === "Function";
};
/**
 * Returns whether `mdx` represents a function.
 * The name attribute is optional.
 * If given, it must be in all lowercase.
 */ export const isMdxFunction = (mdx, name)=>{
    if (!_isMdxFunctionBase(mdx, name)) {
        return false;
    }
    return name === undefined || name.toLowerCase() === mdx.name.toLowerCase();
};
