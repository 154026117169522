import { isMdxFunction } from "../isMdxFunction.js";
import { stringify } from "../stringify.js";
import { _cleanupMdx } from "./_cleanupMdx.js";
import { _nullifyNodeAtPath } from "./_nullifyNodeAtPath.js";
/**
 * Removes the obsolete DefaultMember functions in a Union of crossjoins, i.e. those present in all crossjoins.
 * This can occur when collapsing a tuple.
 *
 * Mutates `union`.
 *
 * @example
 * Consider the following MDX where LegalEntityA is expanded down to Currency.
 * Hierarchize(
 *   Union(
 *     Crossjoin(
 *       Descendants(
 *         {
 *           [Booking].[Desk].[AllMember]
 *         },
 *         1,
 *         SELF_AND_BEFORE
 *       ),
 *       [Currency].[Currency].DefaultMember
 *     ),
 *     Crossjoin(
 *       [Booking].[Desk].[AllMember].[LegalEntityA],
 *       Hierarchize(
 *         Descendants(
 *           {
 *             [Currency].[Currency].[AllMember]
 *           },
 *           1,
 *           SELF_AND_BEFORE
 *         )
 *       )
 *     )
 *   )
 * )
 *
 * Suppose the user collapses LegalEntityA.
 * The corresponding Crossjoin is removed:
 * Hierarchize(
 *   Union(
 *     Crossjoin(
 *       Descendants(
 *         {
 *           [Booking].[Desk].[AllMember]
 *         },
 *         1,
 *         SELF_AND_BEFORE
 *       ),
 *       [Currency].[Currency].DefaultMember
 *     )
 *   )
 * )
 *
 * Since the Currency hierarchy is not expressed anywhere else than through DefaultMember, it can be removed:
 * Hierarchize(
 *   Union(
 *     Crossjoin(
 *       Descendants(
 *         {
 *           [Booking].[Desk].[AllMember]
 *         },
 *         1,
 *         SELF_AND_BEFORE
 *       )
 *     )
 *   )
 * )
 *
 * The Crossjoin should now be cleaned up as well, since it only has one argument:
 * Hierarchize(
 *   Union(
 *     Descendants(
 *       {
 *         [Booking].[Desk].[AllMember]
 *       },
 *       1,
 *       SELF_AND_BEFORE
 *     )
 *   )
 * )
 *
 * In this case, the Union node is also left with one argument, so it should be replaced by it.
 * However, this function does not handle it.
 * It is up to the caller to do it and propagate the cleanup higher up.
 */ export function _cleanupObsoleteDefaultMembers(union, cube) {
    if (!isMdxFunction(union, "union")) {
        throw new Error(`Invalid call to _cleanupObsoleteDefaultMembers. Expected a Union node, but got:\n ${stringify(union, {
            indent: true
        })}`);
    }
    if (!union.arguments.every((unionArg)=>isMdxFunction(unionArg, "crossjoin"))) {
        // Not all arguments of the Union are crossjoins.
        // It is impossible to infer whether instances of the DefaultMember function are useless.
        // Stop here.
        return;
    }
    // The check right above ensures that all union arguments are crossjoins.
    // eslint-disable-next-line atoti-ui/no-as
    const crossjoins = union.arguments;
    const indexesOfCommonHierarchiesWithDefaultMember = [];
    const numberOfHierarchiesInCrossjoins = crossjoins[0].arguments.length;
    for(let indexInCrossjoin = 0; indexInCrossjoin < numberOfHierarchiesInCrossjoins; indexInCrossjoin++){
        if (crossjoins.every((crossjoin)=>isMdxFunction(crossjoin.arguments[indexInCrossjoin], "defaultmember"))) {
            indexesOfCommonHierarchiesWithDefaultMember.push(indexInCrossjoin);
        }
    }
    // For each crossjoin in the union, remove obsolete default members and cleanup up to the union.
    crossjoins.forEach((crossjoin, indexInUnion)=>{
        indexesOfCommonHierarchiesWithDefaultMember.forEach((indexOfObsoleteDefaultMember)=>{
            _nullifyNodeAtPath(crossjoin, [
                "arguments",
                indexOfObsoleteDefaultMember
            ]);
        });
        _cleanupMdx(union, [
            "arguments",
            indexInUnion
        ], cube);
    });
}
