import { intersectionWith as _intersectionWith } from "lodash-es";
import { areLevelsEqual } from "@activeviam/data-model";
import { getAxisTotalsMetaData, unquote } from "@activeviam/mdx";
import { getAxisId } from "./utils/axisIds.js";
/**
 * Returns the {@link QueryTotalsMetaData} of an MDX query.
 */ export function getQueryTotalsMetaData(mdx, { cube , hiddenGrandTotalsContextValue , hiddenSubTotalsContextValue  }) {
    const metaData = {};
    // The `hiddenGrandTotals` context value takes a comma-separated list of axisIds (0,1) on which to hide the totals (here columns and rows).
    // See https://docs.activeviam.com/products/atoti/server/latest/docs/cube/context_values/
    // See https://docs.activeviam.com/products/atoti/server/latest/docs/mdx/engine_configuration/#hide-grand-totals
    const idsOfAxesInHiddenGrandTotalsContextValue = hiddenGrandTotalsContextValue ? hiddenGrandTotalsContextValue.split(",") : [];
    // The `hiddensubtotals` context values takes a comma-separated list of unique level names, of which sub-totals are to be hidden.
    // See https://docs.activeviam.com/products/atoti/server/latest/docs/mdx/engine_configuration/#hide-subtotals
    const uniqueNamesOfLevelsInHiddenSubTotalsContextValue = hiddenSubTotalsContextValue ? hiddenSubTotalsContextValue.split(",") : [];
    const coordinatesOfLevelsInHiddenSubtotalsContextValue = uniqueNamesOfLevelsInHiddenSubTotalsContextValue.map((levelUniqueName)=>{
        const [dimensionName, hierarchyName, levelName] = unquote(levelUniqueName);
        return {
            dimensionName,
            hierarchyName,
            levelName
        };
    });
    mdx?.axes.forEach((axis)=>{
        const axisMetaData = getAxisTotalsMetaData(axis, cube);
        const isGrandTotalHidden = axisMetaData.isGrandTotalHidden || idsOfAxesInHiddenGrandTotalsContextValue.includes(String(getAxisId(axis.name)));
        const levelsWithHiddenTotals = _intersectionWith(axisMetaData.levelsWithToggleableTotals, [
            ...axisMetaData.levelsWithHiddenTotals,
            ...coordinatesOfLevelsInHiddenSubtotalsContextValue
        ], areLevelsEqual);
        metaData[axis.name] = {
            ...axisMetaData,
            isGrandTotalHidden,
            levelsWithHiddenTotals
        };
    });
    return metaData;
}
