import { uniq as _uniq } from "lodash-es";
import { getMeasureName } from "./getMeasureName.js";
import { getMeasuresAxisName } from "./getMeasuresAxisName.js";
import { _isAMeasureThatContributesToAxisDimensionality } from "./internal/_isAMeasureThatContributesToAxisDimensionality.js";
import { isMdxDrillthrough } from "./isMdxDrillthrough.js";
import { traverseMdx } from "./traverseMdx.js";
/**
 * Returns the names of the measures used in the axes of `mdx`.
 * Does not return duplicate measures.
 */ export function getMeasureNames(mdx) {
    if (mdx === undefined || mdx === null) {
        return [];
    }
    if (isMdxDrillthrough(mdx)) {
        return getMeasureNames(mdx.select);
    }
    const axisName = getMeasuresAxisName(mdx);
    const axis = mdx.axes.find(({ name  })=>name === axisName);
    if (!axis) {
        return [];
    }
    const measuresContributingToAxisDimensionality = [];
    traverseMdx(axis, (mdx, path)=>{
        if (_isAMeasureThatContributesToAxisDimensionality(axis, mdx, path)) {
            measuresContributingToAxisDimensionality.push(mdx);
        }
    });
    const measureNames = measuresContributingToAxisDimensionality.map(getMeasureName);
    return _uniq(measureNames);
}
