import { produce } from "immer";
import { createCalculatedMemberCompoundIdentifier } from "./createCalculatedMemberCompoundIdentifier.js";
import { createMemberCompoundIdentifier } from "./createMemberCompoundIdentifier.js";
import { getHierarchies } from "./getHierarchies.js";
import { InvalidNamePathError } from "./index.js";
import { _addCalculatedMemberDefinition } from "./internal/_addCalculatedMemberDefinition.js";
import { _addExpressionToAxis } from "./internal/_addExpressionToAxis.js";
import { isDynamicMemberNamePath } from "./isDynamicMemberNamePath.js";
import { removeCalculatedMemberDefinition } from "./removeCalculatedMemberDefinition.js";
const comparisonMemberName = "__comparison_member__";
/** Returns a name identifying the member in the MDX query */ function getMemberName(memberNamePath) {
    if (isDynamicMemberNamePath(memberNamePath)) {
        return memberNamePath.type;
    }
    if (memberNamePath.length === 0) {
        throw new InvalidNamePathError();
    }
    return memberNamePath[memberNamePath.length - 1];
}
/**
 * Sets a set of two members corresponding to the `comparison` as the PAGES axis in `mdx`.
 * If the PAGES axis does not exist yet, it is created.
 * If `comparison` is undefined, the PAGES axis is removed.
 * Does not mutate `mdx`.
 */ export const setComparison = (mdx, { cube , comparison  })=>{
    const pageAxisIndex = (mdx.axes || []).findIndex((axis)=>axis.name === "PAGES");
    const hierarchyCoordinatesOfPreviousComparison = pageAxisIndex === -1 ? undefined : getHierarchies(mdx.axes[pageAxisIndex], {
        cube
    })[0];
    const mdxWithPagesAxisRemoved = pageAxisIndex === -1 ? mdx : produce(mdx, (draft)=>{
        draft.axes.splice(pageAxisIndex, 1);
    });
    const mdxWithPreviousComparisonRemoved = hierarchyCoordinatesOfPreviousComparison ? removeCalculatedMemberDefinition(mdxWithPagesAxisRemoved, {
        ...hierarchyCoordinatesOfPreviousComparison,
        calculatedMemberName: comparisonMemberName,
        cube
    }) : mdxWithPagesAxisRemoved;
    if (!comparison) {
        return mdxWithPreviousComparisonRemoved;
    }
    const { dimensionName , hierarchyName , referenceMemberNamePath , comparedMemberNamePath  } = comparison;
    const currentMemberExpression = {
        arguments: [
            {
                elementType: "CompoundIdentifier",
                identifiers: [
                    {
                        elementType: "Identifier",
                        quoting: "QUOTED",
                        value: dimensionName
                    },
                    {
                        elementType: "Identifier",
                        quoting: "QUOTED",
                        value: hierarchyName
                    }
                ],
                dimensionName,
                hierarchyName,
                type: "hierarchy"
            }
        ],
        elementType: "Function",
        name: "CurrentMember",
        syntax: "Property"
    };
    const [referenceMemberMdx, comparedMemberMdx] = [
        referenceMemberNamePath,
        comparedMemberNamePath
    ].map((memberPath)=>isDynamicMemberNamePath(memberPath) ? memberPath.type.toLowerCase() === "currentmember" ? currentMemberExpression : {
            arguments: [
                currentMemberExpression
            ],
            elementType: "Function",
            name: memberPath.type,
            syntax: "Property"
        } : createMemberCompoundIdentifier({
            dimensionName,
            hierarchyName,
            namePath: memberPath
        }, cube));
    const differenceMemberCompoundIdentifier = createCalculatedMemberCompoundIdentifier({
        dimensionName,
        hierarchyName,
        calculatedMemberName: comparisonMemberName
    });
    const pageAxisExpression = {
        arguments: [
            referenceMemberMdx,
            comparedMemberMdx,
            differenceMemberCompoundIdentifier
        ],
        elementType: "Function",
        name: "{}",
        syntax: "Braces"
    };
    const mdxWithComparisonFormulaDefinition = _addCalculatedMemberDefinition(mdxWithPreviousComparisonRemoved, {
        dimensionName,
        hierarchyName,
        calculatedMemberName: comparisonMemberName,
        expression: {
            arguments: [
                comparedMemberMdx,
                referenceMemberMdx
            ],
            elementType: "Function",
            name: "-",
            syntax: "Infix"
        },
        cube,
        properties: [
            {
                elementType: "MemberPropertyDefinition",
                expression: {
                    elementType: "Literal",
                    type: "STRING",
                    value: `${getMemberName(comparedMemberNamePath)} - ${getMemberName(referenceMemberNamePath)}`
                },
                name: "CAPTION"
            }
        ]
    });
    return _addExpressionToAxis(mdxWithComparisonFormulaDefinition, {
        addedExpression: pageAxisExpression,
        axisName: "PAGES",
        cube,
        // Require even the members that don't have a value:
        // It allows to retrieve the caption of members that don't have an associated value.
        // It also makes the result easier to work with, as it settles the indexes on the pages axis at which the different members involved in the comparison can be found.
        shouldCreateNonEmptyAxis: false
    });
};
