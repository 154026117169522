import {
  intersection as _intersection,
  mapValues as _mapValues,
  pickBy as _pickBy,
} from "lodash-es";

import {
  PluginRegistry,
  PluginsOfType,
  Whitelist,
} from "@activeviam/activeui-sdk";

import { isPluginAllowedBasedOnContentManagementPermission } from "./isPluginAllowedBasedOnContentManagementPermission.js";

/**
 * Returns the part of the {@link PluginRegistry} that the user is allowed to see.
 */
export function filterPluginRegistryBasedOnPermissions(
  pluginRegistry: PluginRegistry,
  {
    canManageContent,
    isCanManageContentSet,
    allowedWidgets,
    allowedWidgetMenuItems,
    areAllowedWidgetMenuItemsSet,
    allowedWidgetTitleBarButtons,
    areAllowedTitleBarButtonsSet,
  }: {
    canManageContent: boolean;
    isCanManageContentSet: boolean;
    allowedWidgets: Whitelist;
    allowedWidgetMenuItems: Whitelist;
    areAllowedWidgetMenuItemsSet: boolean;
    allowedWidgetTitleBarButtons: Whitelist;
    areAllowedTitleBarButtonsSet: boolean;
  },
): PluginRegistry {
  const filterPluginsBasedOnContentManagementPermission = <PluginType>({
    plugins,
    allowedKeys,
    areAllowedKeysSet,
    keysGrantedByContentManagement = [],
  }: {
    plugins: PluginsOfType<PluginType>;
    allowedKeys: Whitelist;
    areAllowedKeysSet: boolean;
    keysGrantedByContentManagement?: string[];
  }): PluginsOfType<PluginType> => {
    const filteredPlugins: PluginsOfType<PluginType> = {};

    Object.entries(plugins).forEach(([pluginKey, plugin]) => {
      if (
        isPluginAllowedBasedOnContentManagementPermission(pluginKey, {
          allowedKeys,
          areAllowedKeysSet,
          canManageContent,
          isCanManageContentSet,
          keysGrantedByContentManagement,
        })
      ) {
        filteredPlugins[pluginKey] = plugin;
      }
    });

    return filteredPlugins;
  };

  const filteredMenuItemPlugins = filterPluginsBasedOnContentManagementPermission(
    {
      plugins: pluginRegistry["menu-item"] || {},
      allowedKeys: allowedWidgetMenuItems,
      areAllowedKeysSet: areAllowedWidgetMenuItemsSet,
      // If the user cannot write on the content server, the "save widget" and "save widget as" menu items are hidden.
      keysGrantedByContentManagement: ["save-widget", "save-widget-as"],
    },
  );

  const filteredTitleBarButtonPlugins = filterPluginsBasedOnContentManagementPermission(
    {
      plugins: pluginRegistry["titlebar-button"] || {},
      allowedKeys: allowedWidgetTitleBarButtons,
      areAllowedKeysSet: areAllowedTitleBarButtonsSet,
    },
  );

  const filteredMenuItemPluginKeys = Object.keys(filteredMenuItemPlugins);
  const filteredTitleBarButtonPluginKeys = Object.keys(
    filteredTitleBarButtonPlugins,
  );

  const filteredWidgetPlugins =
    allowedWidgets === "*"
      ? pluginRegistry.widget
      : _pickBy(pluginRegistry.widget, (_, key) =>
          allowedWidgets.includes(key),
        );

  return {
    ...pluginRegistry,
    "menu-item": filteredMenuItemPlugins,
    "titlebar-button": filteredTitleBarButtonPlugins,
    // Remove references to forbidden menu items in widget plugins.
    widget: _mapValues(filteredWidgetPlugins, (widgetPlugin) => {
      return {
        ...widgetPlugin,
        menuItems: _intersection(
          widgetPlugin.menuItems,
          filteredMenuItemPluginKeys,
        ),
        contextMenuItems: _intersection(
          widgetPlugin.contextMenuItems,
          filteredMenuItemPluginKeys,
        ),
        titleBarButtons: _intersection(
          widgetPlugin.titleBarButtons,
          filteredTitleBarButtonPluginKeys,
        ),
      };
    }),
  };
}
