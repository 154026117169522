import { get as _get } from "lodash-es";
import { _addExpressionToAxis } from "./internal/_addExpressionToAxis.js";
import { _getPathsToMeasures } from "./internal/_getPathsToMeasures.js";
import { removeAllMeasures } from "./removeAllMeasures.js";
/**
 * Returns a new {@link MdxSelect} corresponding to `mdx` where all measures were moved to `indexOnAxis` on the axis with `axisName`.
 * Does not mutate mdx.
 */ export function moveAllMeasures(mdx, { axisName , cube , indexOnAxis , shouldCreateNonEmptyAxis  }) {
    const pathsToMeasures = _getPathsToMeasures(mdx);
    if (pathsToMeasures.length === 0) {
        return mdx;
    }
    // Forced to use `get` because pathToMeasures is dynamic.
    // eslint-disable-next-line atoti-ui/no-lodash-get
    const allMeasuresNode = _get(mdx, pathsToMeasures[0]);
    const cleanMdx = removeAllMeasures(mdx, {
        cube,
        shouldRemoveCalculatedMeasureExpression: false
    });
    return _addExpressionToAxis(cleanMdx, {
        axisName: axisName || "COLUMNS",
        cube,
        indexOnAxis,
        shouldCreateNonEmptyAxis,
        addedExpression: allMeasuresNode
    });
}
