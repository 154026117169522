import { RollbackOutlined } from "@ant-design/icons";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { ActionCreators } from "redux-undo";

import type { ApplicationMenuItem, State } from "@activeviam/activeui-sdk";

/**
 * {@link ApplicationMenuItem} to reset the dashboard to its previous saved state.
 */
export const editResetMenuItem: ApplicationMenuItem = {
  key: "reset",
  useMenuItem: () => {
    const { formatMessage } = useIntl();
    const dispatch = useDispatch();

    const handleReset = () => {
      dispatch(ActionCreators.jumpToPast(0));
    };

    const numberOfUndoableStates = useSelector(
      (state: State) => state.dashboard.past.length,
    );

    return {
      icon: <RollbackOutlined />,
      // If there is no past state then there is nothing to reset.
      disabled: numberOfUndoableStates === 0,
      label: formatMessage({ id: "resetDashboard" }),
      onClick: handleReset,
    };
  },
};
