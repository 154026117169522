import { isBrowserOnMacOS } from "@activeviam/utils";

const modifierKey = isBrowserOnMacOS ? "Cmd" : "Ctrl";

/**
 * The mapping of keyboard shortcuts to their corresponding keys.
 */
export const keyboardShortcuts: { [key: string]: string } = {
  filters: "F",
  widgets: "W",
  open: `${modifierKey} + O`,
  redo: `${modifierKey} + Y`,
  save: `${modifierKey} + S`,
  undo: `${modifierKey} + Z`,
};
