import { SettingOutlined } from "@ant-design/icons";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";

import type { ApplicationMenuItem } from "@activeviam/application-menu";

/**
 * {@link ApplicationMenuItem} allowing a user to edit their settings.
 */
export const settingsMenuItem: ApplicationMenuItem = {
  key: "settings",
  useMenuItem: () => {
    const dispatch = useDispatch();
    const { formatMessage } = useIntl();

    return {
      icon: <SettingOutlined />,
      label: formatMessage({ id: "aui.settings" }),
      onClick: () => {
        dispatch({
          type: "settingsPopupOpened",
        });
      },
    };
  },
};
