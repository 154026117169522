import { produce } from "immer";
import { createMeasureCompoundIdentifier } from "./createMeasureCompoundIdentifier.js";
/**
 * Returns a new {@link MdxSelect} corresponding to `mdx` where the given calculated measure expression was added to the with clause.
 * Does not mutate mdx.
 */ export const addCalculatedMeasureExpressionToWithClause = (mdx, { calculatedMeasureName , expression , properties =[]  })=>{
    return produce(mdx, (draft)=>{
        if (draft.withClause === undefined) {
            draft.withClause = [];
        }
        const name = createMeasureCompoundIdentifier(calculatedMeasureName);
        draft.withClause.push({
            elementType: "Formula",
            expression,
            inlined: false,
            properties,
            name,
            type: "MEMBER"
        });
    });
};
