import { FolderFilled } from "@ant-design/icons";
import { Card, Input } from "antd";
import { FC, MouseEvent, useState } from "react";

import {
  AMetaData,
  DashboardMetaData,
  useTheme,
} from "@activeviam/activeui-sdk";

interface FolderCardProps {
  metaData: AMetaData;
  id: string;
  pathToParentFolder: string[];
  onFolderDoubleClicked: () => void;
  onFolderSelected: (event: MouseEvent) => void;
  isSelected: boolean;
  onEditEnded: (
    pathToParentFolder: string[],
    metaData: DashboardMetaData,
  ) => Promise<void>;
  onEditCanceled: () => void;
  isRenaming: boolean;
}

export const folderCardStyle = {
  width: 240,
  marginTop: 12,
  marginRight: 12,
  height: 45,
};

/**
 * Displays a card representing a folder. It is smaller than a `FileCard`.
 */
export const FolderCard: FC<FolderCardProps> = (props) => {
  const theme = useTheme();
  const {
    onFolderSelected,
    onFolderDoubleClicked,
    onEditEnded,
    onEditCanceled,
    isRenaming,
    isSelected,
  } = props;
  const [isUploadingNewName, setIsUploadingNewName] = useState(false);
  const [editedCaption, setEditedCaption] = useState(props.metaData.name);

  const handleEditCanceled = () => {
    setEditedCaption(props.metaData.name);
    onEditCanceled();
  };

  const handleEditEnded = async () => {
    if (!editedCaption || editedCaption.trim() === "") {
      handleEditCanceled();
    } else {
      setIsUploadingNewName(true);
      await onEditEnded([...props.pathToParentFolder, props.id], {
        ...props.metaData,
        name: editedCaption,
      });
    }
  };

  return (
    <Card
      key={props.id}
      css={{
        ...(props.isSelected
          ? {
              backgroundColor: theme.selectionColor,
            }
          : {
              ":hover": {
                backgroundColor: theme.grayScale[3],
              },
            }),
      }}
      style={{
        ...folderCardStyle,
        color: theme.textColor,
        transition: "background-color 0.5s",
        cursor: "pointer",
      }}
      onDoubleClick={onFolderDoubleClicked}
      onClick={(event) => {
        onFolderSelected(event);
      }}
      onContextMenu={(event) => {
        if (!isSelected) {
          onFolderSelected(event);
        }
      }}
    >
      <div
        css={{
          display: "flex",
        }}
      >
        <div>
          <FolderFilled
            style={{ fontSize: 18, marginRight: 10, color: theme.grayScale[7] }}
          />
        </div>
        <div
          css={{
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
          }}
        >
          {isRenaming ? (
            <Input
              value={editedCaption}
              onChange={(event) => setEditedCaption(event.target.value)}
              autoFocus
              onFocus={(event) => event.target.select()}
              onBlur={handleEditEnded}
              onKeyUp={(event) => {
                if (event.key === "Enter") {
                  void handleEditEnded();
                } else if (event.key === "Escape") {
                  handleEditCanceled();
                }
              }}
              style={{
                border: "none",
                padding: 0,
                boxShadow: "none",
                backgroundColor: "transparent",
              }}
            />
          ) : isUploadingNewName ? (
            editedCaption
          ) : (
            props.metaData.name
          )}
        </div>
      </div>
    </Card>
  );
};
