import { css } from "@emotion/react";
import { FC, MouseEvent } from "react";
import { useAsyncAbortable } from "react-async-hook";
import { useIntl } from "react-intl";

import {
  getMetaData,
  getMetaDataRecord,
  useContentClient,
  usePermission,
  useUser,
} from "@activeviam/activeui-sdk";
import {
  FileOrFolderInExplorer,
  OnAfterSubmit,
  getDurationAndTimeUnit,
} from "@activeviam/file-list";
import { Text } from "@activeviam/utils-react";

import { FileCard } from "./FileCard.js";
import { NewDashboardCard } from "./NewDashboardCard.js";

interface FileCardsProps {
  pathToParentFolder: string[];
  files: FileOrFolderInExplorer[];
  selectedDashboardRanges: { from: number; to: number }[];
  onFileSelected: (index: number, event: MouseEvent) => void;
  onFileDoubleClicked: (id: string) => void;
  onAfterSubmit: OnAfterSubmit;
}

/**
 * Displays the list of dashboard contained in the given folder as clickable cards.
 */
export const FileCards: FC<FileCardsProps> = ({
  pathToParentFolder,
  files,
  selectedDashboardRanges,
  onFileSelected,
  onFileDoubleClicked,
  onAfterSubmit,
}) => {
  const { formatMessage, formatRelativeTime } = useIntl();
  const { username } = useUser();
  const contentClient = useContentClient();
  const [canManageContent] = usePermission("canManageContent");

  const { result: thumbnails } = useAsyncAbortable(
    async (signal) =>
      files.length > 0
        ? contentClient.fetchThumbnails("dashboard", pathToParentFolder, {
            signal,
          })
        : {},
    [contentClient, pathToParentFolder, files],
  );

  if (!thumbnails && !files) {
    // Wait for the thumbnails to be resolved.
    return null;
  }

  if (!canManageContent && files.length === 0) {
    return (
      <Text
        small={true}
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {formatMessage({ id: "folderWithNoDashboards" })}
      </Text>
    );
  }

  return (
    <div
      css={css`
        width: 100%;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 16px;
        -webkit-user-select: none;
        -moz-user-select: none;
      `}
    >
      <NewDashboardCard pathToParentFolder={pathToParentFolder} />
      {files.map(({ id, record }, index) => {
        const {
          entry: { lastEditor, timestamp: lastEditedTimestamp },
        } = getMetaDataRecord(record, id);
        const { duration, timeUnit } = getDurationAndTimeUnit(
          lastEditedTimestamp,
        );
        const who =
          lastEditor === username ? formatMessage({ id: "you" }) : lastEditor;

        const description =
          duration && duration > 0
            ? `${who} ${formatMessage({
                id: "edited",
              })} ${formatRelativeTime(-duration, timeUnit, {
                numeric: "auto",
                style: "long",
              })}`
            : `${formatMessage({ id: "justEdited" }, { who })} `;

        return (
          <FileCard
            key={id}
            id={id}
            metaData={getMetaData(record, id)}
            pathToParentFolder={pathToParentFolder}
            thumbnail={thumbnails?.[id]}
            description={description}
            isSelected={selectedDashboardRanges.some(
              (range) => range.from <= index && index <= range.to,
            )}
            onFileSelected={(event) => onFileSelected(index, event)}
            onFileDoubleClicked={() => onFileDoubleClicked(id)}
            onAfterSubmit={onAfterSubmit}
          />
        );
      })}
    </div>
  );
};
