import { areHierarchiesEqual } from "@activeviam/data-model";
import { addCalculatedMeasureExpressionToWithClause } from "../addCalculatedMeasureExpressionToWithClause.js";
import { findLevels } from "../findLevels.js";
import { isACompoundIdentifierRepresentingAMeasure } from "../isACompoundIdentifierRepresentingAMeasure.js";
import { quote } from "../quote.js";
import { _addNumericalSort } from "./_addNumericalSort.js";
import { _getExpressionOfFormulaToSortOnLevel } from "./_getExpressionOfFormulaToSortOnLevel.js";
import { _getHierarchiesOnAxis } from "./_getHierarchiesOnAxis.js";
/**
 * Returns a new {@link MdxSelect} corresponding to `mdx`, sorted alphabetically on the given level.
 * Does not mutate mdx.
 */ export const _addAlphabeticalSort = (mdx, { levelCoordinates , orderMode  }, cube)=>{
    const { dimensionName , hierarchyName , levelName  } = levelCoordinates;
    const nameOfSortingMeasure = `${quote(dimensionName, hierarchyName, levelName)}_for_order`;
    const tupleCoordinates = [
        {
            dimensionName: "Measures",
            hierarchyName: "Measures",
            namePath: [
                nameOfSortingMeasure
            ]
        }
    ];
    // If the level was already sorted, the definition of the sorting measure should not be added to the withClause a second time.
    const shouldUpdateWithClause = mdx.withClause.filter((formula)=>isACompoundIdentifierRepresentingAMeasure(formula.name, {
            measureName: nameOfSortingMeasure
        })).length === 0;
    const levels = findLevels(mdx, cube, [
        levelCoordinates
    ]);
    const sortingMeasureExpression = _getExpressionOfFormulaToSortOnLevel(levelCoordinates, levels.length > 0);
    const axisIndex = mdx.axes.findIndex((axis)=>{
        const hierarchiesOnAxis = _getHierarchiesOnAxis(axis, {
            cube
        });
        return hierarchiesOnAxis.some((hierarchyCoordinates)=>areHierarchiesEqual(hierarchyCoordinates, {
                dimensionName,
                hierarchyName
            }));
    });
    if (axisIndex === -1) {
        throw new Error("Sorting a query on a hierarchy that it does not contain is not supported");
    }
    const sortedMdx = _addNumericalSort(mdx, {
        orderMode,
        axisIndex,
        tupleCoordinates,
        cube
    });
    return shouldUpdateWithClause ? addCalculatedMeasureExpressionToWithClause(sortedMdx, {
        calculatedMeasureName: nameOfSortingMeasure,
        expression: sortingMeasureExpression
    }) : sortedMdx;
};
