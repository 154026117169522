import type { ItemType, SubMenuType } from "antd/es/menu/hooks/useItems.js";

import { AMetaData, ContentNode } from "@activeviam/activeui-sdk";
import { TreeObject } from "@activeviam/tree";

import {
  GetDashboardFolderNodeProps,
  getDashboardFolderNode,
} from "./getDashboardFolderNode.js";

/**
 * Traverses `rootNode` and recursively creates the `items` passed to the Ant Design Menu.
 */
export const getDashboardFoldersTreeNodes = (
  rootNode: TreeObject<ContentNode<AMetaData>>,
  nodeProps: Omit<GetDashboardFolderNodeProps, "node" | "path">,
  parentPath: string[] = [],
): ItemType[] => {
  return (rootNode.children ?? []).map((childNode) => {
    const path = [...parentPath, childNode.id];
    const menuItem = getDashboardFolderNode({
      node: childNode,
      path,
      ...nodeProps,
    });
    if (childNode.children && childNode.children.length > 0) {
      // If the child node has children itself, then its corresponding `menuItem` is a submenu as it contains the items corresponding to these children.
      // eslint-disable-next-line atoti-ui/no-as
      (menuItem as SubMenuType).children = getDashboardFoldersTreeNodes(
        childNode,
        nodeProps,
        path,
      );
    }
    return menuItem;
  });
};
