/**
 * Returns the {@link MdxMeasureCompoundIdentifier} corresponding to `measureName`.
 */ export function createMeasureCompoundIdentifier(measureName) {
    return {
        elementType: "CompoundIdentifier",
        type: "measure",
        identifiers: [
            {
                elementType: "Identifier",
                quoting: "QUOTED",
                value: "Measures"
            },
            {
                elementType: "Identifier",
                quoting: "QUOTED",
                value: measureName
            }
        ],
        measureName
    };
}
