import { FC, useCallback, useMemo } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import {
  AWidgetState,
  DashboardState,
  getActivePageKey,
  getActiveToolKey,
  getDashboardState,
  getLayoutPath,
  getPage,
  getSelectedLeafKey,
  updateWidget,
  useSwitchedWidgetState,
} from "@activeviam/activeui-sdk";
import { ToolPlaceholder } from "@activeviam/tool";

import { useConfiguration } from "../../ConfigurationContext.js";
import { headerHeight } from "../header/Header.js";

interface ActiveToolProps {
  width: number;
}

interface ActiveToolWithoutPlaceholderProps extends ActiveToolProps {
  dashboardState: DashboardState;
  activePageKey: string;
  selectedLeafKey: string;
}

const ActiveToolWithoutPlaceholder: FC<ActiveToolWithoutPlaceholderProps> = ({
  dashboardState,
  activePageKey,
  selectedLeafKey,
}) => {
  const activeToolKey = useSelector(getActiveToolKey);
  const { tools } = useConfiguration();
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const pageState = getPage(dashboardState, activePageKey);
  const queryId = `${activePageKey}/${selectedLeafKey}`;

  const selectedWidgetState: AWidgetState =
    dashboardState.pages[activePageKey].content[selectedLeafKey];
  const switchedWidgetState = useSwitchedWidgetState(
    selectedWidgetState,
    queryId,
  );

  const handleSelectedWidgetStateChanged = useCallback(
    (newWidgetState: AWidgetState) => {
      const newDashboard = updateWidget(
        dashboardState,
        {
          pageKey: activePageKey,
          leafKey: selectedLeafKey,
        },
        () => newWidgetState,
      );
      dispatch({ type: "dashboardUpdated", dashboardState: newDashboard });
    },
    [dashboardState, activePageKey, selectedLeafKey, dispatch],
  );

  const handleDashboardChanged = useCallback(
    (newDashboardState: DashboardState) => {
      dispatch({ type: "dashboardUpdated", dashboardState: newDashboardState });
    },
    [dispatch],
  );

  const layout = pageState?.layout;
  const layoutPath = useMemo(
    () => (layout ? getLayoutPath(layout, selectedLeafKey) : undefined),
    [layout, selectedLeafKey],
  );

  const tool = tools.find((tool) => tool.key === activeToolKey);
  const Tool = tool?.component;

  return (
    <div
      style={{
        overflowY: "auto",
        height: `calc(100% - ${headerHeight}px)`,
        padding: "0 8px",
      }}
    >
      {Tool ? (
        <Tool
          widgetState={switchedWidgetState}
          onWidgetChange={handleSelectedWidgetStateChanged}
          dashboardState={dashboardState}
          onDashboardChange={handleDashboardChanged}
          pageKey={activePageKey}
          leafKey={selectedLeafKey}
          layoutPath={layoutPath}
          queryId={queryId}
        />
      ) : (
        formatMessage({ id: "error.toolNotFound" }, { key: activeToolKey })
      )}
    </div>
  );
};

/**
 * Displays the currently selected tool.
 */
export const ActiveTool: FC<ActiveToolProps> = (props) => {
  const dashboardState = useSelector(getDashboardState);
  const activePageKey = useSelector(getActivePageKey);
  const selectedLeafKey = useSelector(getSelectedLeafKey);

  if (
    !dashboardState ||
    activePageKey === undefined ||
    selectedLeafKey === undefined
  ) {
    return <ToolPlaceholder />;
  }

  return (
    <ErrorBoundary
      resetKeys={[selectedLeafKey, activePageKey]}
      fallbackRender={({ error }) => <ToolPlaceholder />}
    >
      <ActiveToolWithoutPlaceholder
        {...props}
        dashboardState={dashboardState}
        activePageKey={activePageKey}
        selectedLeafKey={selectedLeafKey}
      />
    </ErrorBoundary>
  );
};
