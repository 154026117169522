// TODO https://activeviam.atlassian.net/browse/UI-8304
// Merge QueryError and MdxError into QueryError
/**
 * Thrown when a Mdx query yielded an unknown server error.
 */ export class MdxError extends Error {
    constructor(error){
        super(error.errorChain[0].message);
        this.error = error;
    }
}
