import { difference as _difference } from "lodash-es";
import { getMeasureName } from "./getMeasureName.js";
import { isACompoundIdentifierRepresentingAMeasure } from "./isACompoundIdentifierRepresentingAMeasure.js";
import { isMdxDrillthrough } from "./isMdxDrillthrough.js";
import { traverseMdx } from "./traverseMdx.js";
/**
 * Returns the names of the measures used in `mdx`.
 * Does not include formulas, but does include native measures used in formula definitions.
 */ export const getNativeMeasureNames = (mdx)=>{
    if (mdx === undefined || mdx === null) {
        return [];
    }
    if (isMdxDrillthrough(mdx)) {
        return getNativeMeasureNames(mdx.select);
    }
    const calculatedMeasureNames = mdx.withClause.filter((formula)=>isACompoundIdentifierRepresentingAMeasure(formula.name))// @ts-expect-error The filtering ensures that formula.name is of type MdxUnknownCompoundIdentifier | MdxMeasureCompoundIdentifier, but TypeScript fails to recognize it.
    .map((formula)=>getMeasureName(formula.name));
    const measureNamesOnAxesAndSlicer = new Set();
    traverseMdx(mdx, (node)=>{
        if (isACompoundIdentifierRepresentingAMeasure(node)) {
            measureNamesOnAxesAndSlicer.add(getMeasureName(node));
        }
    });
    return _difference(Array.from(measureNamesOnAxesAndSlicer), calculatedMeasureNames);
};
