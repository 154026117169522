import { produce } from "immer";
import { isEqual as _isEqual, keyBy as _keyBy, set as _set, sortBy as _sortBy, update as _update } from "lodash-es";
import { isCellSet } from "./utils/isCellSet.js";
function isSuccessMessageFromServer(message) {
    return message.status === "success";
}
/**
 * Manages state of data for a query over time.
 * Gets data and a message and updates the data.
 *
 */ export function dataReducer(state, message) {
    if (!isSuccessMessageFromServer(message)) {
        return undefined;
    }
    switch(message.type){
        case "cellData":
            if (state === undefined) {
                throw new Error("Should have existing data to update.");
            }
            if (!isCellSet(state)) {
                throw new Error("Received a Cellset update from ActivePivot about a query whose last result was a DrillthroughResult. This is not supported.");
            }
            return produce(state, (draftNextState)=>{
                draftNextState.epoch = message.data.epoch;
                const updatedCellsByOrdinal = _keyBy(message.data.cells, "ordinal");
                draftNextState.cells = draftNextState.cells.map((cell)=>updatedCellsByOrdinal[cell.ordinal] || cell);
            });
        case "drillthroughUpdateData":
            if (state === undefined) {
                throw new Error("Received a Drillthrough update from ActivePivot about a query without a prior full drillthrough result. This is not supported.");
            }
            if (isCellSet(state)) {
                throw new Error("Received a Drillthrough update from ActivePivot about a query whose last result was a Cellset. This is not supported.");
            }
            return produce(state, (draftNextState)=>{
                draftNextState.epoch = message.data.epoch;
                const { addedOrUpdatedRows , removedRows  } = message.data;
                if (addedOrUpdatedRows) {
                    Object.keys(addedOrUpdatedRows).forEach((rowIndexAsString)=>{
                        const rowIndex = parseInt(rowIndexAsString, 10);
                        draftNextState.result.rows[rowIndex] = addedOrUpdatedRows[rowIndex];
                    });
                }
                if (removedRows) {
                    removedRows.sort();
                    for(let i = removedRows.length - 1; i >= 0; i--){
                        draftNextState.result.rows.splice(removedRows[i], 1);
                    }
                }
            });
        case "cellSetData":
            return {
                ...message.data,
                // Atoti Server can send cells in a random order.
                // Some Atoti UI functions and components don't support it.
                cells: _sortBy(message.data.cells, "ordinal")
            };
        case "drillthroughData":
            // This is new data. We just return it.
            return message.data;
        default:
            throw new Error(// @ts-expect-error This case is impossible and TypeScript knows it. An error should still be thrown to inform the user if this happens during runtime.
            `Unexpected ActivePivot client message type: "${message.type}". Valid types are ["cellData", "drillthroughUpdateData", "cellSetData", "drillthroughData"]`);
    }
}
class UnknownQueryError extends Error {
    constructor(queryId){
        super(`No query exists for id: "${queryId}".`);
        this.queryId = queryId;
    }
}
export const clientInitialState = {
    connectionStatus: "disconnected",
    connectionStatusListeners: [],
    isDataModelLoading: false,
    calculatedMembers: {},
    drillthroughColumns: {},
    nextQueryId: 0,
    _map: {}
};
/**
 * Manages the state of the client.
 *
 * @param state - State of the client.
 * @param event - An event that may change the state of the client.
 */ export function activePivotClientReducer(state, event) {
    const { state: nextState , sideEffects  } = produce({
        state,
        sideEffects: []
    }, ({ state: draftNextState , sideEffects: draftSideEffects  })=>{
        switch(event.type){
            case "queryRegistered":
                {
                    const { id: queryId , mdx , context , ranges , updateMode ="once"  } = event;
                    if (draftNextState._map[queryId]?.query !== undefined) {
                        throw new Error(`Cannot register a query for id: "${queryId}", because a query is already registered with this id.`);
                    }
                    const queryVersion = draftNextState.nextQueryId.toString();
                    draftNextState.nextQueryId += 1;
                    const queryListeners = draftNextState._map[queryId]?.queryListeners ?? [];
                    const queryResultListeners = draftNextState._map[queryId]?.queryResultListeners ?? [];
                    const query = {
                        mdx,
                        context,
                        ranges,
                        updateMode
                    };
                    draftNextState._map[queryId] = {
                        queryVersion,
                        queryResult: {
                            isLoading: true
                        },
                        query,
                        queryListeners,
                        queryResultListeners
                    };
                    draftSideEffects.push({
                        type: "webSocketSendMessage",
                        message: {
                            action: "REGISTER",
                            data: {
                                initialState: updateMode === "once" ? "PAUSED" : "STARTED",
                                streamId: queryId,
                                queryId: queryVersion,
                                mdxQuery: {
                                    mdx,
                                    context
                                },
                                ranges
                            }
                        }
                    });
                    return;
                }
            case "queryUpdated":
                {
                    const { id: queryId , mdx , context , ranges , updateMode  } = event;
                    const prevQuery = state._map[queryId]?.query;
                    const query = draftNextState._map[queryId]?.query;
                    if (!prevQuery || !query) {
                        throw new UnknownQueryError(queryId);
                    }
                    const definitionChanged = mdx && /**
             * Using `_isEqual` to also check for deep equality as there is no guarantee for the reference to `query.context` to remain stable, even if memoized.
             * See https://reactjs.org/docs/hooks-reference.html#usememo
             * If incorrectly detecting a change of definition while the update mode changes, an incorrect sequence of websocket messages will be sent to the server
             * which will return an error.
             */ // eslint-disable-next-line atoti-ui/no-lodash-isequal
                    (prevQuery.mdx !== mdx || !_isEqual(prevQuery.context, context));
                    /**
           * Using `_isEqual` to also check for deep equality as there is no guarantee for the reference to `ranges` to remain stable, even if memoized.
           * See https://reactjs.org/docs/hooks-reference.html#usememo
           */ // eslint-disable-next-line atoti-ui/no-lodash-isequal
                    const rangesChanged = ranges && !_isEqual(prevQuery.ranges, ranges);
                    const updateModeChanged = updateMode && prevQuery.updateMode !== updateMode;
                    // If nothing changed don't do anything.
                    if (!definitionChanged && !rangesChanged && !updateModeChanged) {
                        return;
                    }
                    const wouldUpdatedQueryYieldTheCurrentResult = state._map[queryId].queryForCurrentResult?.mdx === mdx && // Not a performance threat: context is a small object.
                    // eslint-disable-next-line atoti-ui/no-lodash-isequal
                    _isEqual(state._map[queryId].queryForCurrentResult?.context, context) && // Not a performance threat: ranges is a small object.
                    // eslint-disable-next-line atoti-ui/no-lodash-isequal
                    _isEqual(state._map[queryId].queryForCurrentResult?.ranges, ranges);
                    if (definitionChanged || rangesChanged) {
                        if (wouldUpdatedQueryYieldTheCurrentResult) {
                            draftNextState._map[queryId].queryResult.isLoading = false;
                            draftNextState._map[queryId].query = state._map[queryId].queryForCurrentResult;
                            draftNextState._map[queryId].queryVersion = state._map[queryId].queryVersionForCurrentResult;
                        } else {
                            // Update the version if the query was changed.
                            const queryVersion = draftNextState.nextQueryId.toString();
                            draftNextState.nextQueryId += 1;
                            draftNextState._map[queryId].queryVersion = queryVersion;
                            draftNextState._map[queryId].queryResult = {
                                ...draftNextState._map[queryId].queryResult,
                                isLoading: true
                            };
                            if (mdx && definitionChanged) {
                                query.mdx = mdx;
                                query.context = context;
                                if (ranges && rangesChanged) {
                                    query.ranges = ranges;
                                }
                                draftSideEffects.push({
                                    type: "webSocketSendMessage",
                                    message: {
                                        action: "UPDATE",
                                        data: {
                                            streamId: queryId,
                                            queryId: queryVersion,
                                            mdxQuery: {
                                                mdx,
                                                context
                                            },
                                            ranges
                                        }
                                    }
                                });
                            } else if (ranges && rangesChanged) {
                                query.ranges = ranges;
                                draftSideEffects.push({
                                    type: "webSocketSendMessage",
                                    message: {
                                        action: "RANGE_UPDATE",
                                        data: {
                                            streamId: queryId,
                                            queryId: queryVersion,
                                            ranges
                                        }
                                    }
                                });
                            }
                        }
                    }
                    if (updateMode && updateModeChanged) {
                        draftNextState._map[queryId].query.updateMode = updateMode;
                        const isPausing = updateMode === "once";
                        draftSideEffects.push({
                            type: "webSocketSendMessage",
                            message: {
                                action: isPausing ? "PAUSE" : "RESUME",
                                data: queryId
                            }
                        });
                    }
                    return;
                }
            case "queryRefreshed":
                {
                    const queryId = event.id;
                    const query = draftNextState._map[queryId]?.query;
                    if (!query) {
                        throw new UnknownQueryError(queryId);
                    }
                    draftNextState._map[queryId].queryResult.isLoading = true;
                    draftSideEffects.push({
                        type: "webSocketSendMessage",
                        message: {
                            action: "REFRESH",
                            data: queryId
                        }
                    });
                    return;
                }
            case "queryUnregistered":
                {
                    if (draftNextState._map[event.id] !== undefined) {
                        const query = draftNextState._map[event.id].query;
                        const queryListeners = draftNextState._map[event.id].queryListeners;
                        const queryResultListeners = draftNextState._map[event.id].queryResultListeners;
                        if (queryListeners && queryListeners.length > 0 || queryResultListeners && queryResultListeners.length > 0) {
                            draftNextState._map[event.id] = {
                                queryListeners,
                                queryResultListeners
                            };
                        } else {
                            // Remove from queries map.
                            Reflect.deleteProperty(draftNextState._map, event.id);
                        }
                        // Check that the query is defined.
                        // This is necessary because listeners may have been registered for this queryId,
                        // while the query itself has not been set in the client yet, or has been unregistered.
                        if (query !== undefined) {
                            draftSideEffects.push({
                                type: "webSocketSendMessage",
                                message: {
                                    action: "UNREGISTER",
                                    data: event.id
                                }
                            });
                        }
                    }
                    return;
                }
            case "webSocketOpening":
                {
                    draftNextState.connectionStatus = "connecting";
                    return;
                }
            case "webSocketOpened":
                {
                    draftNextState.connectionStatus = "connected";
                    // The WebSocket has successfully been opened.
                    // Register all the queries that are defined in the state.
                    for(const queryId in draftNextState._map){
                        const query = draftNextState._map[queryId].query;
                        // Check that the query is defined.
                        // This is necessary because listeners may have been registered for this queryId,
                        // while the query itself has not been set in the client yet, or has been unregistered.
                        if (query) {
                            const queryVersion = draftNextState._map[queryId].queryVersion;
                            if (queryVersion === undefined) {
                                throw new Error(`Query with id ${queryId} is defined in the client state, but has no associated query version.`);
                            }
                            const { mdx , context , updateMode , ranges  } = query;
                            const registerMessage = {
                                action: "REGISTER",
                                data: {
                                    initialState: updateMode === "once" ? "PAUSED" : "STARTED",
                                    streamId: queryId,
                                    queryId: queryVersion,
                                    mdxQuery: {
                                        mdx,
                                        context
                                    },
                                    ranges
                                }
                            };
                            draftSideEffects.push({
                                type: "webSocketSendMessage",
                                message: registerMessage
                            });
                        }
                    }
                    return;
                }
            case "webSocketClosed":
                {
                    draftNextState.connectionStatus = "disconnected";
                    return;
                }
            case "webSocketErrored":
                {
                    draftNextState.connectionStatus = "disconnected";
                    return;
                }
            case "connectionStatusListenerAdded":
                {
                    draftNextState.connectionStatusListeners.push(event.listener);
                    return;
                }
            case "connectionStatusListenerRemoved":
                {
                    draftNextState.connectionStatusListeners.push(event.listener);
                    const listenerIndex = draftNextState.connectionStatusListeners.indexOf(event.listener);
                    if (listenerIndex !== -1) {
                        draftNextState.connectionStatusListeners.splice(listenerIndex, 1);
                    }
                    return;
                }
            case "queryListenerAdded":
                {
                    if (draftNextState._map[event.id] === undefined) {
                        draftNextState._map[event.id] = {
                            queryListeners: []
                        };
                    }
                    if (draftNextState._map[event.id]?.queryListeners === undefined) {
                        draftNextState._map[event.id].queryListeners = [];
                    }
                    draftNextState._map[event.id].queryListeners.push(event.listener);
                    return;
                }
            case "queryResultListenerAdded":
                {
                    if (draftNextState._map[event.id] === undefined) {
                        draftNextState._map[event.id] = {
                            queryResultListeners: []
                        };
                    }
                    if (draftNextState._map[event.id]?.queryResultListeners === undefined) {
                        draftNextState._map[event.id].queryResultListeners = [];
                    }
                    draftNextState._map[event.id].queryResultListeners.push(event.listener);
                    return;
                }
            case "queryListenerRemoved":
                {
                    const queryListenerIndex = draftNextState._map[event.id].queryListeners.indexOf(event.listener);
                    if (queryListenerIndex !== -1) {
                        draftNextState._map[event.id].queryListeners.splice(queryListenerIndex, 1);
                    }
                    return;
                }
            case "queryResultListenerRemoved":
                {
                    const queryResultListenerIndex = draftNextState._map[event.id].queryResultListeners.indexOf(event.listener);
                    if (queryResultListenerIndex !== -1) {
                        draftNextState._map[event.id].queryResultListeners.splice(queryResultListenerIndex, 1);
                    }
                    return;
                }
            case "webSocketMessageReceived":
                {
                    const { message  } = event;
                    if (state._map[message.streamId] === undefined || message.queryId !== state._map[message.streamId].queryVersion) {
                        // Not a relevant message. Ignore.
                        return;
                    }
                    draftNextState._map[message.streamId].queryForCurrentResult = state._map[message.streamId].query;
                    draftNextState._map[message.streamId].queryVersionForCurrentResult = state._map[message.streamId].queryVersion;
                    const queryResult = {
                        isLoading: false
                    };
                    if (!isSuccessMessageFromServer(message)) {
                        queryResult.error = message.error;
                    } else {
                        queryResult.data = dataReducer(state._map[message.streamId].queryResult.data, message);
                        queryResult.error = undefined;
                    }
                    draftNextState._map[message.streamId].queryResult = queryResult;
                    return;
                }
            case "dataModelLoadingStarted":
                {
                    draftNextState.isDataModelLoading = true;
                    break;
                }
            case "dataModelLoaded":
                {
                    draftNextState.dataModel = event.dataModel;
                    draftNextState.isDataModelLoading = false;
                    if (event.error) {
                        draftNextState.dataModelLoadingError = event.error;
                    }
                    break;
                }
            case "dataModelListenerAdded":
                {
                    draftNextState.dataModelListeners = [
                        ...draftNextState.dataModelListeners ?? [],
                        event.listener
                    ];
                    break;
                }
            case "dataModelListenerRemoved":
                {
                    if (draftNextState.dataModelListeners) {
                        const listenerIndex = draftNextState.dataModelListeners.indexOf(event.listener);
                        if (listenerIndex !== -1) {
                            draftNextState.dataModelListeners.splice(listenerIndex, 1);
                            if (draftNextState.dataModelListeners.length === 0) {
                                delete draftNextState.dataModelListeners;
                            }
                        }
                    }
                    break;
                }
            case "drillthroughColumnsLoading":
                {
                    _set(draftNextState, `drillthroughColumns.${event.cubeName}.isLoading`, true);
                    break;
                }
            case "drillthroughColumnsLoaded":
                {
                    _update(draftNextState, `drillthroughColumns.${event.cubeName}`, (drillthroughState)=>{
                        drillthroughState.isLoading = false;
                        drillthroughState.drillthroughColumns = event.drillthroughColumns;
                        if (event.error) {
                            drillthroughState.error = event.error;
                        }
                        return drillthroughState;
                    });
                    break;
                }
            case "drillthroughColumnsListenerAdded":
                {
                    _update(draftNextState, `drillthroughColumns.${event.cubeName}.listeners`, (listeners = [])=>{
                        listeners.push(event.listener);
                        return listeners;
                    });
                    break;
                }
            case "drillthroughColumnsListenerRemoved":
                {
                    _update(draftNextState, `drillthroughColumns.${event.cubeName}`, (drillthroughState)=>{
                        const listeners = drillthroughState?.listeners ?? [];
                        const listenerIndex = listeners.indexOf(event.listener);
                        if (listenerIndex !== -1) {
                            listeners.splice(listenerIndex, 1);
                            if (listeners.length === 0) {
                                delete drillthroughState.listeners;
                            }
                        }
                        return drillthroughState;
                    });
                    break;
                }
            case "calculatedMembersLoading":
                {
                    _set(draftNextState, [
                        "calculatedMembers",
                        event.cubeName,
                        "isLoading"
                    ], true);
                    break;
                }
            case "calculatedMembersLoaded":
                {
                    draftNextState.calculatedMembers[event.cubeName] = {
                        ...draftNextState.calculatedMembers[event.cubeName],
                        isLoading: false,
                        calculatedMembers: event.calculatedMembers,
                        error: event.error
                    };
                    break;
                }
            case "calculatedMembersListenerAdded":
                {
                    _update(draftNextState, [
                        "calculatedMembers",
                        event.cubeName,
                        "listeners"
                    ], (listeners = [])=>{
                        listeners.push(event.listener);
                        return listeners;
                    });
                    break;
                }
            case "calculatedMembersListenerRemoved":
                {
                    _update(draftNextState, [
                        "calculatedMembers",
                        event.cubeName
                    ], (calculatedMembersState)=>{
                        const listeners = calculatedMembersState?.listeners ?? [];
                        const listenerIndex = listeners.indexOf(event.listener);
                        if (listenerIndex !== -1) {
                            listeners.splice(listenerIndex, 1);
                            if (listeners.length === 0) {
                                delete calculatedMembersState.listeners;
                            }
                        }
                        return calculatedMembersState;
                    });
                    break;
                }
            default:
                throw new Error(// @ts-expect-error This case is impossible and TypeScript knows it. An error should still be thrown to inform the user if this happens during runtime.
                `Unexpected ActivePivot client reducer event type: "${event.type}". Valid types are ["queryRegistered", "queryUpdated", "queryRefreshed", "queryUnregistered", "webSocketOpening", "webSocketOpened", "webSocketClosed", "webSocketErrored", "queryListenerAdded", "queryListenerRemoved", "queryResultListenerAdded", "queryResultListenerRemoved", "connectionStatusListenerAdded", "connectionStatusListenerRemoved", "webSocketMessageReceived", "dataModelLoadingStarted", "dataModelLoaded", "dataModelListenerAdded", "dataModelListenerRemoved", "drillthroughColumnsLoaded", "drillthroughColumnsListenerAdded", "drillthroughColumnsListenerRemoved", "calculatedMembersLoaded", "calculatedMembersListenerAdded", "calculatedMembersListenerRemoved"]`);
        }
    });
    return [
        nextState,
        sideEffects
    ];
}
