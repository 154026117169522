import { uniqWith as _uniqWith } from "lodash-es";
import { areLevelsEqual, getHierarchy, getLevelIndex } from "@activeviam/data-model";
import { findDescendant } from "./findDescendant.js";
import { findLevels } from "./findLevels.js";
import { getMeasuresPositionOnAxis } from "./getMeasuresPositionOnAxis.js";
import { getSpecificCompoundIdentifier } from "./getSpecificCompoundIdentifier.js";
import { isMdxCompoundIdentifier } from "./isMdxCompoundIdentifier.js";
import { isMdxFunction } from "./isMdxFunction.js";
/**
 * Returns the path to the first occurrence of a `[Dim].[Hier].[Level].Members` expression in `mdx`, if such an expression exists.
 */ function findPathToLevelMembers(mdx, { cube , levelCoordinates  }) {
    return findDescendant(mdx, (mdx)=>{
        if (!isMdxFunction(mdx, "Members") || !isMdxCompoundIdentifier(mdx.arguments[0])) {
            return false;
        }
        const specificCompoundIdentifier = getSpecificCompoundIdentifier(mdx.arguments[0], {
            cube
        });
        return specificCompoundIdentifier.type === "level" && areLevelsEqual(specificCompoundIdentifier, levelCoordinates);
    })?.path;
}
/**
 * Returns metadata associated with totals on an MDX axis.
 */ export function getAxisTotalsMetaData(axis, cube) {
    const levels = findLevels(axis, cube);
    const levelsWithToggleableTotals = [];
    const levelsWithHiddenTotals = [];
    const deepLevelsExpressedWithMembers = [];
    const hasOnlyMeasuresOnAxis = levels.length === 0 && getMeasuresPositionOnAxis(axis) !== -1;
    if (hasOnlyMeasuresOnAxis) {
        return {
            levelsWithToggleableTotals,
            levelsWithHiddenTotals,
            deepLevelsExpressedWithMembers,
            isGrandTotalHidden: false,
            // If there are only measures on the axis, then each of its positions in the cellset represents the grand total for the corresponding measure.
            // Hiding the grand totals would yield no positions for this axis in the cellset.
            // The user would see an empty table, which would be a bad UX.
            // So prevent them from doing it.
            isGrandTotalToggleable: false
        };
    }
    let isGrandTotalToggleable = true;
    let isGrandTotalHidden = false;
    let isAboveADeepLevelExpressedWithMembers = false;
    for(let indexOnAxis = levels.length - 1; indexOnAxis >= 0; indexOnAxis--){
        const { path: pathToHighestNodeRepresentingTheLevel , match: highestNodeRepresentingTheLevel , ...levelCoordinates } = levels[indexOnAxis];
        if (indexOnAxis === levels.length - 1) {
        // By definition, the last level expressed on the axis holds leaf aggregates, not subtotals.
        } else {
            levelsWithToggleableTotals.unshift(levelCoordinates);
        }
        if (isAboveADeepLevelExpressedWithMembers) {
            // The code assumes that a hierarchy is expressed in the MDX either:
            // - with all its members down to a level (e.g. with the Descendants function)
            // - or with members from one level only, through [Dim].[Hier].[Level].Members
            // In the second case:
            // - subtotals on higher levels in the same hierarchy are by definition not yielded in the cellset
            // - since in particular AllMember is not yielded, subtotals on levels from hierarchies expressed above on the axis are not yielded either
            levelsWithHiddenTotals.unshift(levelCoordinates);
        }
        const hierarchy = getHierarchy(levelCoordinates, cube);
        const levelIndex = getLevelIndex({
            ...levelCoordinates,
            cube
        });
        if (levelIndex > 0) {
            const pathToMembersExpressionWithinHighestNodeRepresentingTheLevel = findPathToLevelMembers(highestNodeRepresentingTheLevel, {
                cube,
                levelCoordinates
            });
            const isExpressedWithMembers = pathToMembersExpressionWithinHighestNodeRepresentingTheLevel !== undefined;
            if (isExpressedWithMembers) {
                isGrandTotalHidden = true;
                const pathToMembersExpressionWithinMdx = [
                    ...pathToHighestNodeRepresentingTheLevel,
                    ...pathToMembersExpressionWithinHighestNodeRepresentingTheLevel
                ];
                deepLevelsExpressedWithMembers.unshift({
                    ...levelCoordinates,
                    pathWithinAxis: pathToMembersExpressionWithinMdx
                });
                isAboveADeepLevelExpressedWithMembers = true; // from the perspective of every level above the one from the current iteration (including those on the same hierarchy)
            }
        }
        // Higher levels within the same hierarchy are toggleable.
        const parentLevels = Object.values(hierarchy.levels).slice(hierarchy.slicing ? 0 : 1, levelIndex).reverse();
        for (const parentLevel of parentLevels){
            const parentLevelCoordinates = {
                ...levelCoordinates,
                levelName: parentLevel.name
            };
            levelsWithToggleableTotals.unshift(parentLevelCoordinates);
            if (isAboveADeepLevelExpressedWithMembers) {
                levelsWithHiddenTotals.unshift(parentLevelCoordinates);
            }
        }
        if (hierarchy.slicing) {
            // The server does not return any totals above slicing hierarchies.
            // You can see it in the flesh by adding CounterParty, City and Dates on rows in the UI.
            isGrandTotalToggleable = false;
            isGrandTotalHidden = true;
            break;
        }
    }
    return {
        levelsWithToggleableTotals: _uniqWith(levelsWithToggleableTotals, areLevelsEqual),
        levelsWithHiddenTotals: _uniqWith(// subset of levelsWithToggleableTotals
        levelsWithHiddenTotals, areLevelsEqual),
        deepLevelsExpressedWithMembers,
        isGrandTotalToggleable,
        isGrandTotalHidden
    };
}
