import { FolderFilled } from "@ant-design/icons";
import { groupBy as _groupBy, last as _last } from "lodash-es";
import { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useTheme, useTree } from "@activeviam/activeui-sdk";
import { getPathToParentFolder } from "@activeviam/content-client";
import { FileList } from "@activeviam/file-list";
import { Modal } from "@activeviam/utils-react";

import { getCaptionsOfFolders } from "../../utils/getCaptionsOfFolders.js";

const fullWidthStyle = { width: "100%" };

/**
 * Displays a modal that allows opening a dashboard from within a dashboard.
 */
export const OpenDashboardModal = ({ isVisible }: { isVisible: boolean }) => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [folderCaptions, setFolderCaptions] = useState<string[]>([]);
  const [idOfSelectedDashboard, setIdOfSelectedDashboard] = useState<
    string | null
  >(null);
  const [searchValue, setSearchValue] = useState("");

  const theme = useTheme();
  const dashboardsTree = useTree("dashboard");

  // Close the popup when unmounting.
  // Prevents the modal from still being open while navigating from a dashboard to the home page and back to a dashboard.
  useEffect(() => {
    return () => {
      dispatch({ type: "openDashboardPopupClosed" });
    };
  }, [dispatch]);

  const handleFileOpened = useCallback(
    (dashboardId: string) => {
      dispatch({ type: "openDashboardPopupClosed" });
      navigate(`/dashboard/${dashboardId}`);
    },
    [dispatch, navigate],
  );

  const handleDashboardSelected = useCallback(
    (pathToFileOrFolder: string[], { isFolder }: { isFolder: boolean }) => {
      // If the selected item is a file, then its path is necessarily not empty as it ends with its id.
      setIdOfSelectedDashboard(isFolder ? null : _last(pathToFileOrFolder)!);
    },
    [],
  );

  const handleActiveDashboardChanged = useCallback(
    (newActiveDashboardOrFolderId: string) => {
      if (!dashboardsTree) {
        return;
      }
      const pathToParentFolder = getPathToParentFolder(
        dashboardsTree,
        newActiveDashboardOrFolderId,
      );
      const folderCaptions =
        pathToParentFolder &&
        pathToParentFolder.length > 0 &&
        searchValue !== ""
          ? getCaptionsOfFolders(pathToParentFolder, dashboardsTree).map(
              ({ caption }) => caption ?? "",
            )
          : [];

      setFolderCaptions(folderCaptions);
    },
    [dashboardsTree, searchValue],
  );

  return (
    <Modal
      title={formatMessage({ id: "aui.openDashboard" })}
      okText={formatMessage({ id: "aui.open" })}
      open={isVisible}
      destroyOnClose={true}
      onOk={() => {
        if (idOfSelectedDashboard !== null) {
          handleFileOpened(idOfSelectedDashboard);
        }
      }}
      extra={
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          {folderCaptions.length > 0 && (
            <>
              <FolderFilled
                style={{
                  marginRight: 5,
                  color: theme.grayScale[8],
                  marginBottom: 2,
                }}
              />
              <div
                style={{
                  textOverflow: "ellipsis",
                  direction: "rtl",
                  overflow: "hidden",
                  display: "block",
                  width: 380,
                  whiteSpace: "nowrap",
                  color: theme.textColor,
                }}
              >
                {folderCaptions.join(" > ")}
              </div>
            </>
          )}
        </div>
      }
      okButtonProps={{
        disabled: idOfSelectedDashboard === null,
      }}
      onCancel={() => {
        dispatch({ type: "openDashboardPopupClosed" });
      }}
      bodyStyle={{
        paddingTop: 0,
      }}
      width={572}
    >
      <div style={{ height: 453 }} tabIndex={0}>
        <FileList
          hasFolderTitle={true}
          searchValue={searchValue}
          onSearchValueChanged={setSearchValue}
          style={fullWidthStyle}
          onFileOrFolderSelected={handleDashboardSelected}
          onActiveFileOrFolderChanged={handleActiveDashboardChanged}
          onFileOpened={handleFileOpened}
          contentType={"dashboard"}
          mode={"explorer"}
        />
      </div>
    </Modal>
  );
};
