// Handling the selection of both folders and dashboards within a single multi-selection.
// It allows a range selection to span across both sets.
// It makes the code to separate `selectedRanges` into files and folders a bit complicated here.
// But it simplifies the selection as `onItemSelected` handles the combinations of click and "Ctrl"/"Shift" keys.

/**
 * Returns the selected ranges of folders and files in the home page in grid mode.
 * Returns empty arrays when in list mode, as the list does not require the selected ranges of folders and files.
 */
export function getSelectedRangesOfFoldersAndFiles({
  selectedRanges,
  numberOfFolders,
  layout,
}: {
  layout: "grid" | "list";
  numberOfFolders: number;
  selectedRanges: { from: number; to: number }[];
}) {
  if (layout === "list") {
    return {
      selectedFolderRanges: [],
      selectedFileRanges: [],
    };
  }

  return {
    selectedFolderRanges: selectedRanges
      .filter((range) => range.from < numberOfFolders)
      .map((range) => ({
        from: range.from,
        to: Math.min(range.to, numberOfFolders - 1),
      })),
    selectedFileRanges: selectedRanges
      .filter(
        (range) => range.from >= numberOfFolders || range.to >= numberOfFolders,
      )
      .map((range) => ({
        from: Math.max(range.from - numberOfFolders, 0),
        to: range.to - numberOfFolders,
      })),
  };
}
