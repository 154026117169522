import { set as _set } from "lodash-es";
import { getLevelIndex } from "@activeviam/data-model";
import { _getDescendantsFunction } from "./internal/_getDescendantsFunction.js";
/**
 * Replaces the node at `path` in `mdx` with the Descendants function expressing the level at `levelCoordinates` and its parent levels.
 * Mutates `mdx`.
 */ export const replaceWithDescendantsFunction = ({ mdx , path , levelCoordinates , cube  })=>{
    const levelIndex = getLevelIndex({
        ...levelCoordinates,
        cube
    });
    const descendantsFunctionNode = _getDescendantsFunction({
        dimensionName: levelCoordinates.dimensionName,
        hierarchyName: levelCoordinates.hierarchyName,
        levelIndex,
        cube
    });
    _set(mdx, path, descendantsFunctionNode);
};
