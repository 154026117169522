import { LayoutOutlined } from "@ant-design/icons";
import { MenuProps } from "antd";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

import { ApplicationMenuItem, usePermission } from "@activeviam/activeui-sdk";

import { usePathToParentFolder } from "../../PathToParentFolderContext.js";
import { useIsDashboardSaved } from "../useIsDashboardSaved.js";
import { useUnsavedDashboardCounter } from "../useUnsavedDashboardCounter.js";

/**
 * {@link ApplicationMenuItem} to open a new dashboard.
 */
export const fileNewDashboardMenuItem: ApplicationMenuItem = {
  key: "new-dashboard",
  useMenuItem: () => {
    const navigate = useNavigate();
    const pathToParentFolder = usePathToParentFolder();
    const { formatMessage } = useIntl();
    const [canManageContent] = usePermission("canManageContent");
    const isSavedDashboard = useIsDashboardSaved();
    const counter = useUnsavedDashboardCounter();

    const handleNewDashboardButtonClicked: MenuProps["onClick"] = ({
      domEvent,
    }) => {
      const pathToNewUnsavedDashboard = counter
        ? `/${parseInt(counter) + 1}`
        : !isSavedDashboard
        ? // If no counter exists, the counter will begin at 2.
          "/2"
        : "";

      const newTabUrlHash = `/dashboard/${
        pathToParentFolder && pathToParentFolder.length > 0
          ? `${pathToParentFolder.join("/")}/`
          : ""
      }unsaved${pathToNewUnsavedDashboard}`;
      if (domEvent.ctrlKey || domEvent.metaKey) {
        // A new dashboard is opened in a new tab.
        const newURL = new URL(window.location.href);
        newURL.hash = newTabUrlHash;
        window.open(newURL);
      } else {
        // The current window navigates to a new dashboard.
        navigate(newTabUrlHash);
      }
    };

    return {
      icon: <LayoutOutlined />,
      disabled: !canManageContent,
      label: formatMessage({ id: "aui.defaultDashboardName" }),
      onClick: handleNewDashboardButtonClicked,
    };
  },
};
