import { Tool, Whitelist } from "@activeviam/activeui-sdk";

/**
 * Returns the subset of `tools` that the user is allowed to see.
 * Does not mutate `tools.`
 */
export function filterToolsBasedOnPermissions(
  tools: Tool[],
  allowedTools: Whitelist,
): Tool[] {
  return allowedTools === "*"
    ? tools
    : tools.filter(({ key }) => allowedTools.includes(key));
}
