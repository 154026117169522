import { getHierarchies } from "../getHierarchies.js";
import { _findCrossjoins } from "./_findCrossjoins.js";
/**
 * Returns the unique and ordered list of non-measure hierarchies expressed on `axis`
 */ export const _getHierarchiesOnAxis = (axis, { cube  })=>{
    const crossjoins = _findCrossjoins(axis, {
        cube
    });
    return getHierarchies(crossjoins[0]?.match || axis, {
        cube
    });
};
