import { useMemo } from "react";

import { Configuration, usePermission } from "@activeviam/activeui-sdk";

import { useConfiguration } from "../ConfigurationContext.js";
import { filterApplicationMenusBasedOnPermissions } from "../utils/filterApplicationMenusBasedOnPermissions.js";
import { filterPluginRegistryBasedOnPermissions } from "../utils/filterPluginRegistryBasedOnPermissions.js";
import { filterToolsBasedOnPermissions } from "../utils/filterToolsBasedOnPermissions.js";

/**
 * Returns the application's {@link Configuration}, filtered based on the user's {@link Permissions}.
 * It contains only those widgets, menus, tools, etc. that the user is allowed to see.
 */
export function useConfigurationFilteredBasedOnPermissions(): Configuration {
  const configuration = useConfiguration();

  const [
    allowedApplicationMenus,
    ,
    { isSet: areAllowedApplicationMenusSet },
  ] = usePermission("applicationMenus");
  const [canManageContent, , { isSet: isCanManageContentSet }] = usePermission(
    "canManageContent",
  );

  const [allowedWidgets] = usePermission("widgets");

  const [
    allowedWidgetMenuItems,
    ,
    { isSet: areAllowedWidgetMenuItemsSet },
  ] = usePermission("widgetMenuItems");

  const [
    allowedWidgetTitleBarButtons,
    ,
    { isSet: areAllowedTitleBarButtonsSet },
  ] = usePermission("widgetTitleBarButtons");

  const [allowedTools] = usePermission("tools");

  return useMemo(() => {
    const [leftApplicationMenu, rightApplicationMenu] = [
      configuration.leftApplicationMenu,
      configuration.rightApplicationMenu,
    ].map((applicationMenu) =>
      filterApplicationMenusBasedOnPermissions(applicationMenu, {
        allowedKeys: allowedApplicationMenus,
        areAllowedKeysSet: areAllowedApplicationMenusSet,
        canManageContent,
        isCanManageContentSet,
      }),
    );

    return {
      ...configuration,
      leftApplicationMenu,
      rightApplicationMenu,
      tools: filterToolsBasedOnPermissions(configuration.tools, allowedTools),
      pluginRegistry: filterPluginRegistryBasedOnPermissions(
        configuration.pluginRegistry,
        {
          canManageContent,
          isCanManageContentSet,
          allowedWidgets,
          allowedWidgetMenuItems,
          areAllowedWidgetMenuItemsSet,
          allowedWidgetTitleBarButtons,
          areAllowedTitleBarButtonsSet,
        },
      ),
    };
  }, [
    configuration,
    allowedApplicationMenus,
    areAllowedApplicationMenusSet,
    allowedWidgetMenuItems,
    areAllowedWidgetMenuItemsSet,
    canManageContent,
    isCanManageContentSet,
    allowedWidgetTitleBarButtons,
    areAllowedTitleBarButtonsSet,
    allowedWidgets,
    allowedTools,
  ]);
}
