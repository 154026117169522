import { css } from "@emotion/react";
import { ReactNode } from "react";
import { useIntl } from "react-intl";

import { IconSavedChanges, IconSavingChanges } from "@activeviam/activeui-sdk";

/**
 * Used to determine which icon and label should be used, if any, for a status notification.
 */
export const StatusNotificationIconAndLabel = (
  action: string,
  status: string,
): { icon: ReactNode | undefined; label: string | undefined } => {
  const { formatMessage } = useIntl();
  let icon: JSX.Element | undefined;
  let label: string | undefined;

  switch (action) {
    case "saving":
    case "loading":
      switch (status) {
        case "pending":
          icon = (
            <IconSavingChanges
              css={css`
                margin-right: 5px;
                margin-top: 3px;
                width: 1.4em;
                .spinner {
                  animation: spin 1s infinite;
                  transform-origin: center 67%;
                }
                @keyframes spin {
                  0% {
                    transform: rotate(0deg);
                  }
                  100% {
                    transform: rotate(360deg);
                  }
                }
              `}
            />
          );
          label = formatMessage({ id: `aui.${action}` });
          break;
        case "fulfilled":
        case "rejected":
          if (action === "saving") {
            // TODO: use specific icon and label when the action failed.
            icon = (
              <IconSavedChanges
                css={css`
                  margin-right: 5px;
                  margin-top: 3px;
                  width: 1.4em;
                `}
              />
            );
            label = formatMessage({ id: "aui.changesSaved" });
          }
          break;
      }
      break;
  }

  return { icon, label };
};
