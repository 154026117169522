import { get as _get } from "lodash-es";
import { addLevel } from "./addLevel.js";
import { _findCompoundIdentifiers } from "./internal/_findCompoundIdentifiers.js";
import { _getIndexOfAxisContainingHierarchy } from "./internal/_getIndexOfAxisContainingHierarchy.js";
import { isMdxFunction } from "./isMdxFunction.js";
import { removeLevel } from "./removeLevel.js";
/**
 * Returns a new {@link MdxSelect} corresponding to `mdx` where the given level is moved at `indexOnAxis` on the axis with `axisName`.
 * Does not mutate mdx.
 */ export function moveLevel(mdx, { cube , dimensionName , hierarchyName , levelName , axisName , indexOnAxis , shouldCreateNonEmptyAxis  }) {
    const originAxisIndex = _getIndexOfAxisContainingHierarchy(mdx, {
        dimensionName,
        hierarchyName,
        cube
    });
    const originAxis = mdx.axes[originAxisIndex];
    const foundLevels = _findCompoundIdentifiers(originAxis, {
        cube,
        hierarchyCoordinates: {
            dimensionName,
            hierarchyName
        }
    });
    // Looking at the level's ancestors to see if calculated members have been enabled.
    const areCalculatedMembersIncluded = foundLevels.some((level)=>{
        for(let i = level.path.length - 1; i >= 0; i--){
            if (isMdxFunction(// _get is needed to resolve an object property from a dynamic path
            // eslint-disable-next-line atoti-ui/no-lodash-get
            _get(originAxis, level.path.slice(0, i + 1)), "addcalculatedmembers")) {
                return true;
            }
        }
        return false;
    });
    const cleanMdx = removeLevel(mdx, {
        cube,
        dimensionName,
        hierarchyName,
        levelName
    });
    return addLevel(cleanMdx, {
        cube,
        dimensionName,
        hierarchyName,
        levelName,
        axisName,
        indexOnAxis,
        shouldCreateNonEmptyAxis,
        doesIncludeCalculatedMembers: areCalculatedMembersIncluded
    });
}
