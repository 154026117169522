import { useIntl } from "react-intl";

import type { ApplicationSubMenu } from "@activeviam/activeui-sdk";

import { useIsLocationDashboard } from "../../hooks/useIsLocationDashboard.js";
import { fileNewDashboardMenuItem } from "./fileNewDashboardMenuItem.js";
import { fileOpenDashboardPopupMenuItem } from "./fileOpenDashboardPopupMenuItem.js";
import { filePDFExportMenuItem } from "./filePDFExportMenuItem.js";
import { fileSaveAsMenuItem } from "./fileSaveAsMenuItem.js";
import { fileSaveMenuItem } from "./fileSaveMenuItem.js";
import { shareMenuItem } from "./shareMenuItem.js";

/**
 * {@link ApplicationSubMenu} containing menu items for file actions, such as save/save as.
 */
export const fileSubMenu: ApplicationSubMenu = {
  key: "file-submenu",
  useMenuItem: () => {
    const { formatMessage } = useIntl();
    const isLocationDashboard = useIsLocationDashboard();

    if (!isLocationDashboard) {
      return undefined;
    }

    return {
      label: formatMessage({ id: "file" }),
    };
  },
  children: [
    fileNewDashboardMenuItem,
    fileOpenDashboardPopupMenuItem,
    fileSaveMenuItem,
    fileSaveAsMenuItem,
    shareMenuItem,
    filePDFExportMenuItem,
  ],
};
