import { Whitelist } from "@activeviam/activeui-sdk";

/**
 * Returns whether the user is allowed to use the plugin identified by `key`.
 */
export function isPluginAllowedBasedOnContentManagementPermission(
  key: string,
  {
    allowedKeys,
    areAllowedKeysSet,
    canManageContent,
    isCanManageContentSet,
    keysGrantedByContentManagement,
  }: {
    allowedKeys: Whitelist;
    areAllowedKeysSet: boolean;
    canManageContent: boolean;
    isCanManageContentSet: boolean;
    keysGrantedByContentManagement: string[];
  },
) {
  if (isCanManageContentSet && keysGrantedByContentManagement.includes(key)) {
    return (
      // Allowed because `canManageContent` is set to true.
      canManageContent ||
      // Allowed because it is part of the allowed keys set by an admin user, even though `canManageContent` is set to false.
      (areAllowedKeysSet && (allowedKeys === "*" || allowedKeys.includes(key)))
    );
  }

  if (
    areAllowedKeysSet &&
    !(allowedKeys === "*" || allowedKeys.includes(key))
  ) {
    // Not allowed, because it is not part of the allowed keys set by an admin user.
    return false;
  }

  // By default, the user is allowed to see all menu items.
  return true;
}
