/**
 * Adds the row corresponding to `rowIndexOnScreen` and `rowIndexInData` to `rowIndexMapping`.
 * Mutates `rowIndexMapping`.
 */ export function addRowIndexToMapping(rowIndexMapping, { rowIndexOnScreen , rowIndexInData , numberOfMissingTotalRowsAbove  }) {
    if (!rowIndexMapping) {
        return;
    }
    for(let i = 0; i <= numberOfMissingTotalRowsAbove; i++){
        rowIndexMapping[rowIndexOnScreen + i] = rowIndexInData;
    }
}
