import { isString as _isString } from "lodash-es";
import { _isMdxSelect } from "./internal/_isMdxSelect.js";
import { isMdxCompoundIdentifier } from "./isMdxCompoundIdentifier.js";
import { isMdxFunction } from "./isMdxFunction.js";
import { quote } from "./quote.js";
import { interleave } from "./utils/interleave.js";
/**
 * Collect together pieces of text to produce one big string.
 * @param pieces - the pieces to glue together.
 * Returns the glued String.
 */ export const deepJoin = (...pieces)=>pieces.map((piece)=>_isString(piece) ? piece : deepJoin(...piece)).join("");
const todo = function() {
    throw new Error("TODO");
};
const shouldPrintAxisName = function(axisName) {
    return axisName !== "SLICER";
};
const INDENT_UNIT = "  ";
const INDENT_UNIT_LENGTH = INDENT_UNIT.length;
class Indenter {
    constructor(){
        this.indentString = "";
    }
    indent() {
        this.indentString += INDENT_UNIT;
        return this.newLine();
    }
    unindent() {
        this.indentString = this.indentString.substring(0, this.indentString.length - INDENT_UNIT_LENGTH);
    }
    newLine() {
        return `\n${this.indentString}`;
    }
}
class NoopIndenter {
    indent(needSpace = true) {
        return needSpace ? " " : "";
    }
    newLine(needSpace = true) {
        return needSpace ? " " : "";
    }
    unindent() {}
}
const join = (content, options)=>(options.glue || deepJoin)(...content);
const printArgList = ({ expression , fromIndex , openingChar , closingChar , options , indenter  })=>{
    const content = [
        openingChar,
        indenter.indent(false)
    ];
    const args = [];
    for(let i = fromIndex; i < expression.length; ++i){
        args.push(stringify(expression[i], options, indenter));
    }
    const interleaveArgs = `,${options.glueArgs ? "" : indenter.newLine()}`;
    content.push(...interleave(args, interleaveArgs));
    indenter.unindent();
    content.push(indenter.newLine(false));
    content.push(closingChar);
    return join(content, options);
};
const stringifyAxis = (axis, options, indenter)=>{
    const content = [
        axis.nonEmpty ? "NON EMPTY " : "",
        stringify(axis.expression, options, indenter)
    ];
    if (axis.properties && axis.properties.length > 0) {
        content.push(" PROPERTIES ");
        content.push(...interleave(axis.properties.map((node)=>stringify(node, options, indenter)), ", "));
    }
    if (shouldPrintAxisName(axis.name)) {
        content.push(" ON ", axis.name);
    }
    return join(content, options);
};
const stringifyCase = (caseNode, options, indenter)=>{
    const content = [
        "CASE"
    ];
    // TODO: We should throw if match is not empty but cases is.
    if (caseNode.match) {
        content.push(" ");
        content.push(stringify(caseNode.match, options, indenter));
    }
    if (caseNode.cases) {
        content.push(indenter.indent());
        content.push(...interleave(caseNode.cases.map((node)=>stringify(node, options, indenter)), indenter.newLine()));
        indenter.unindent();
    }
    if (caseNode.elseExp) {
        content.push(indenter.indent());
        content.push("ELSE ", stringify(caseNode.elseExp, options, indenter));
        indenter.unindent();
    }
    content.push(indenter.newLine());
    content.push("END");
    return join(content, options);
};
const stringifyCompoundIdentifier = (compoundIdentifier, options, indenter)=>{
    return join(interleave(compoundIdentifier.identifiers.map((node)=>stringify(node, options, indenter)), "."), options);
};
const stringifyDrillthrough = (drillthrough, options, indenter)=>{
    const content = [
        "DRILLTHROUGH"
    ];
    content.push(indenter.newLine());
    if (drillthrough.maxRows) {
        content.push(`MAXROWS ${drillthrough.maxRows}`);
        content.push(indenter.newLine());
    }
    if (drillthrough.firstRow) {
        content.push(`FIRSTROW ${drillthrough.firstRow}`);
        content.push(indenter.newLine());
    }
    content.push(stringifySelect(drillthrough.select, options, indenter));
    content.push(indenter.newLine());
    if (drillthrough.columns && drillthrough.columns.length) {
        content.push("RETURN");
        content.push(indenter.indent());
        content.push(drillthrough.columns.map((column)=>{
            if (isMdxCompoundIdentifier(column)) {
                return stringifyCompoundIdentifier(column, options, indenter);
            }
            if (isMdxFunction(column)) {
                if (column.arguments.length === 1) {
                    const cid = column.arguments[0];
                    if (isMdxCompoundIdentifier(cid)) {
                        return `${column.name}(${stringifyCompoundIdentifier(cid, options, indenter)})`;
                    }
                }
            }
            return todo();
        }).join(`,${indenter.newLine()}`));
    }
    return join(content, options);
};
const stringifyFormula = (formula, options, indenter)=>{
    let propertiesMdx = "";
    if (formula.properties && formula.properties.length > 0) {
        propertiesMdx = join([
            ", ",
            ...interleave(formula.properties.map((node)=>stringify(node, options, indenter)), ", ")
        ], options);
    }
    switch(formula.type){
        case "MEMBER":
            return join([
                indenter.newLine(),
                " Member ",
                stringify(formula.name, options, indenter),
                " AS ",
                stringify(formula.expression, options, indenter),
                propertiesMdx,
                " "
            ], options);
        case "SET":
            switch(formula.inlined){
                case false:
                    return join([
                        indenter.newLine(),
                        "Set ",
                        stringify(formula.name, options, indenter),
                        " AS ",
                        stringify(formula.expression, options, indenter),
                        propertiesMdx
                    ], options);
                case true:
                    return join([
                        stringify(formula.expression, options, indenter),
                        propertiesMdx,
                        " AS ",
                        stringify(formula.name, options, indenter)
                    ], options);
                default:
                    return todo();
            }
        default:
            return todo();
    }
};
const stringifyFrom = (fromNode, options)=>{
    return join([
        "FROM ",
        quote(fromNode.cubeName)
    ], options);
};
const stringifyFunction = (functionNode, options, indenter)=>{
    const name = functionNode.name;
    const args = functionNode.arguments;
    switch(functionNode.syntax){
        case "Function":
            return join([
                name,
                printArgList({
                    expression: args,
                    fromIndex: 0,
                    openingChar: "(",
                    closingChar: ")",
                    options,
                    indenter
                })
            ], options);
        case "Property":
            return join([
                stringify(args[0], options, indenter),
                ".",
                name
            ], options);
        case "Method":
            return join([
                stringify(args[0], options, indenter),
                ".",
                name,
                printArgList({
                    expression: args,
                    fromIndex: 1,
                    openingChar: "(",
                    closingChar: ")",
                    options,
                    indenter
                })
            ], options);
        case "Infix":
            return join(interleave(interleave(args.map((node)=>stringify(node, options, indenter)), name), " "), options);
        case "Prefix":
            return join([
                name,
                " ",
                stringify(args[0], options, indenter)
            ], options);
        case "Braces":
            return printArgList({
                expression: args,
                fromIndex: 0,
                openingChar: "{",
                closingChar: "}",
                options,
                indenter
            });
        case "Parentheses":
            return printArgList({
                expression: args,
                fromIndex: 0,
                openingChar: "(",
                closingChar: ")",
                options,
                indenter
            });
        default:
            return todo();
    }
};
const stringifyIdentifier = (identifier, options)=>{
    const value = identifier.value;
    switch(identifier.quoting){
        case "QUOTED":
            return quote(value);
        case "AMP_QUOTED":
            return join([
                "&",
                quote(value)
            ], options);
        case "UNQUOTED":
            return value;
        default:
            return todo();
    }
};
const stringifyLiteral = (literal, options)=>{
    const value = literal.value;
    switch(literal.type){
        case "PLACE_HOLDER":
            return value;
        case "SCALAR":
            return value;
        case "KEYWORD":
            return value;
        case "STRING":
            return join([
                '"',
                value,
                '"'
            ], options);
        default:
            return todo();
    }
};
const stringifyMemberPropertyDefinition = (memberPropertyDefinition, options, indenter)=>{
    return join([
        memberPropertyDefinition.name,
        " = ",
        stringify(memberPropertyDefinition.expression, options, indenter)
    ], options);
};
const stringifySelect = (select, options, indenter)=>{
    const content = [];
    if (_isMdxSelect(select) && select.withClause !== undefined && select.withClause.length > 0) {
        content.push("WITH", ...interleave(select.withClause.map((node)=>stringify(node, options, indenter)), " "));
        content.push(indenter.newLine());
    }
    content.push("SELECT");
    content.push(indenter.indent());
    if (select.axes !== undefined && select.axes.length > 0) {
        content.push(...interleave(select.axes.map((node)=>stringify(node, options, indenter)), `,${indenter.newLine()}`));
        content.push(indenter.newLine());
    }
    content.push(stringify(select.from, options, indenter));
    if (select.slicerAxis) {
        content.push(indenter.newLine());
        content.push("WHERE ", stringify(select.slicerAxis, options, indenter));
    }
    if (_isMdxSelect(select) && select.cellProps && select.cellProps.length > 0) {
        content.push(indenter.newLine());
        content.push("CELL PROPERTIES ", select.cellProps.join(", "));
    }
    indenter.unindent();
    return join(content, options);
};
const stringifySubSelect = (subSelect, options, indenter)=>{
    const select = stringifySelect(subSelect, options, indenter);
    const content = [
        "FROM "
    ];
    if (subSelect.nonVisual) {
        content.push("NON VISUAL ");
    }
    content.push("(");
    content.push(indenter.indent(false));
    content.push(select);
    indenter.unindent();
    content.push(indenter.newLine(false));
    content.push(")");
    return join(content, options);
};
const stringifyWhen = (whenNode, options, indenter)=>{
    const content = [
        "WHEN "
    ];
    content.push(stringify(whenNode.when, options, indenter));
    content.push(" THEN ");
    content.push(stringify(whenNode.then, options, indenter));
    return join(content, options);
};
/**
 * Returns the Mdx string corresponding to `mdx`
 */ export function stringify(mdx, options = {}, indenter) {
    if (mdx === undefined || mdx === null) {
        return "";
    }
    const safeIndenter = indenter || (options.indent ? new Indenter() : new NoopIndenter());
    switch(mdx.elementType){
        case "Axis":
            return stringifyAxis(mdx, options, safeIndenter);
        case "CaseExpression":
            return stringifyCase(mdx, options, safeIndenter);
        case "CompoundIdentifier":
            return stringifyCompoundIdentifier(mdx, options, safeIndenter);
        case "Drillthrough":
            return stringifyDrillthrough(mdx, options, safeIndenter);
        case "Formula":
            return stringifyFormula(mdx, options, safeIndenter);
        case "From":
            return stringifyFrom(mdx, options);
        case "Function":
            return stringifyFunction(mdx, options, safeIndenter);
        case "Identifier":
            return stringifyIdentifier(mdx, options);
        case "Literal":
            return stringifyLiteral(mdx, options);
        case "MemberPropertyDefinition":
            return stringifyMemberPropertyDefinition(mdx, options, safeIndenter);
        case "Select":
            return stringifySelect(mdx, options, safeIndenter);
        case "SubSelect":
            return stringifySubSelect(mdx, options, safeIndenter);
        case "When":
            return stringifyWhen(mdx, options, safeIndenter);
        default:
            throw new Error(`Encountered Mdx with unknown type: ${JSON.stringify(mdx)}, while stringifying an MDX AST object`);
    }
}
