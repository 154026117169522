import { createCompoundIdentifier, createMdxFunction } from "./nodeCreators.js";
/**
 * Checks to see if the value matches any keyword in property array
 * @param {string} value - search string
 */ const isAProperty = function(value) {
    // get all property functions lower case
    const propertyFunctions = [
        "allmembers",
        "children",
        "count",
        "current",
        "currentmember",
        "currentordinal",
        "datamember",
        "defaultmember",
        "dimension",
        "firstchild",
        "firstsibling",
        "hierarchy",
        "lastchild",
        "lastsibling",
        "level",
        "levels",
        "members",
        "membervalue",
        "name",
        "nextmember",
        "ordinal",
        "parent",
        "prevmember",
        "siblings",
        "uniquename",
        "unknownmember",
        "value"
    ];
    const testValue = value.toLowerCase();
    return propertyFunctions.some((element)=>element === testValue);
};
/**
 * determines the type of object to return for specific arrayNode
 * Returns either a fully parsed CompoundIdentifier or MdxFunction
 * example arrayNode to be reduced: a.[b].c().e
 */ // This function is used as `reduce` argument.
// We are not free to choose its signature.
// eslint-disable-next-line max-params
const reduceArrayNodes = function(previousValue, currentValue, index, array) {
    let result;
    switch(currentValue.elementType){
        case "Identifier":
            if (currentValue.quoting === "UNQUOTED" && isAProperty(currentValue.value)) {
                result = createMdxFunction(currentValue.value, "Property", [
                    // As an "Identifier" `previousValue` is also an MdxExpression.
                    // eslint-disable-next-line
                    previousValue
                ]);
            } else {
                if (previousValue.elementType === "CompoundIdentifier") {
                    previousValue.identifiers.push(currentValue);
                    result = previousValue;
                } else {
                    // it means we have a function and followed by an identifier ... probably an erroneous MDX
                    result = createMdxFunction(currentValue.value, "Property", [
                        // As an "Identifier" `previousValue` is also an MdxExpression.
                        // eslint-disable-next-line
                        previousValue
                    ]);
                }
            }
            break;
        case "Function":
            // As a "Function" `previousValue` is also an MdxExpression.
            // eslint-disable-next-line
            currentValue.arguments.unshift(previousValue);
            result = currentValue;
            result.syntax = "Method";
            break;
        default:
            result = array;
    }
    return result;
};
/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */ const transformArrayNodes = function(arrayNode) {
    let result;
    switch(arrayNode[0].elementType){
        // for Identifier and Mdx Function
        // first element is handled differently and is removed via arrayNode.shift()
        case "Identifier":
            result = arrayNode.reduce(reduceArrayNodes, createCompoundIdentifier(arrayNode.shift()));
            break;
        case "Function":
            result = arrayNode.reduce(reduceArrayNodes, arrayNode.shift());
            break;
        default:
            result = arrayNode;
            break;
    }
    return result;
};
/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */ export const transformTreeNodeForArgument = function(jisonNode) {
    let result;
    if (jisonNode === null) {
        return jisonNode;
    }
    if (jisonNode.constructor.name === "Array") {
        result = transformArrayNodes(jisonNode);
    } else if (jisonNode.elementType === "Identifier") {
        result = createCompoundIdentifier(jisonNode);
    } else {
        result = jisonNode;
    }
    return result;
};
/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */ export const extractIdentifierValue = (id)=>id.value;
