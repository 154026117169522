import { getDrillthroughColumns } from "./getDrillthroughColumns.js";
import { _getFunctionNodesOfDrillthroughColumn } from "./internal/_getFunctionNodesOfDrillthroughColumn.js";
/**
 * Returns a new {@link MdxDrillthrough} corresponding to `mdx` where the column
 * identified by `drillthroughColumnUniqueName` was added at `index` if provided
 * or at the last position otherwise.
 * Does not mutate `mdx`.
 */ export function addDrillthroughColumn(mdx, { drillthroughColumnUniqueName , index  }) {
    if (getDrillthroughColumns(mdx).includes(drillthroughColumnUniqueName)) {
        return mdx;
    }
    const columns = mdx.columns ?? [];
    // From the user's perspective, `index` refers to the position of a value/caption pair
    const indexForValueCaptionPairs = index !== undefined ? index * 2 : columns.length;
    return {
        ...mdx,
        columns: [
            ...columns.slice(0, indexForValueCaptionPairs),
            ..._getFunctionNodesOfDrillthroughColumn(drillthroughColumnUniqueName),
            ...columns.slice(indexForValueCaptionPairs)
        ]
    };
}
