import { ClassNames } from "@emotion/react";
import { Menu, MenuProps, theme as antdTheme } from "antd";
import { last as _last } from "lodash-es";
import { FC, memo, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import {
  ApplicationMenu,
  TitleInput,
  getContentRecordAtPath,
  getDashboardState,
  useIsPresenting,
  usePermission,
  useTheme,
  useTree,
} from "@activeviam/activeui-sdk";

import { useConfiguration } from "../../ConfigurationContext.js";
import { usePathToParentFolder } from "../../PathToParentFolderContext.js";
import { useIsLocationDashboard } from "../../hooks/useIsLocationDashboard.js";
import { useSaveDashboard } from "../../hooks/useSaveDashboard.js";
import { getAntdMenuItems } from "../menu/getAntdMenuItems.js";
import { useIsDashboardSaved } from "../useIsDashboardSaved.js";
import { HomeSearch } from "./HomeSearch.js";
import Logo from "./Logo.js";

const { useToken } = antdTheme;

const HeaderMenu: FC<
  Omit<MenuProps, "items"> & { menus: ApplicationMenu[] }
> = memo((props) => {
  const { menus, ...rest } = props;
  const { token: antdThemeTokens } = useToken();
  const [openKeys, setOpenKeys] = useState<string[]>([]);
  const trigger = openKeys.length === 0 ? "click" : "hover";
  /** The keys of submenus visible in the application header (for example "File", "Edit" or "Insert"). */
  const topLevelMenuKeys = menus.map((menu) => menu.key);

  const handleOpenChange = (newOpenKeys: string[]) => {
    // The header menu is opened.
    if (openKeys.length === 0 && newOpenKeys.length > 0) {
      const closeMenu = (event: MouseEvent) => {
        if (
          event.target instanceof Element &&
          // The event target is a submenu with children that is not a top level submenu.
          event.target.closest(`.ant-menu-submenu-popup li[role="none"]`)
        ) {
          // The submenu stays open.
          return;
        }
        // The submenu is closed.
        setOpenKeys([]);
        document.removeEventListener("click", closeMenu, { capture: true });
      };
      document.addEventListener("click", closeMenu, { capture: true });
    }

    if (newOpenKeys.length === 0 && openKeys.length === 1) {
      // The submenu stays open on mouseleave.
      return;
    }

    if (newOpenKeys.filter((el) => topLevelMenuKeys.includes(el)).length > 1) {
      // Only one top level submenu can be open at a time.
      setOpenKeys([_last(newOpenKeys)!]);
    } else {
      setOpenKeys(newOpenKeys);
    }
  };

  return (
    <ClassNames>
      {({ css }) => (
        <Menu
          triggerSubMenuAction={trigger}
          mode="horizontal"
          selectable={false}
          openKeys={openKeys}
          subMenuCloseDelay={openKeys.length > 1 ? 5 : 0.1}
          onOpenChange={handleOpenChange}
          css={{
            flex: 1,
            height: "100%",
            alignItems: "center",
            color: "inherit",
            backgroundColor: "inherit",
            border: "unset !important",
            li: {
              height: "100%",
              display: "flex !important",
              alignItems: "center",
            },
            ".ant-menu-item-active, .ant-menu-submenu-active > *": {
              color: `${antdThemeTokens.Menu?.colorItemTextSelected} !important`,
            },
            ".ant-menu-submenu-open": {
              color: antdThemeTokens.Menu?.colorItemTextSelected,
            },
            ".ant-menu-submenu": {
              paddingInline: "0 !important",
            },
            ".ant-menu-submenu-title": {
              padding: "0 12px",
            },
            ".ant-menu-submenu::after": {
              width: "100%",
              transform: "translateX(-12px)",
            },
          }}
          items={getAntdMenuItems(
            menus,
            css({
              // The wanted behavior is to:
              // - Have a fixed popup width
              // - Ellipsize the label of each menu item if it grows too big
              // - If the label consists of both a title and a shortcut, only ellipsize the title and always display the shortcut
              // This is ensured by the CSS below and the one from <MenuItemLabel />
              //
              // In particular, notice that:
              // - Each menu item is forced to be a flex container, instead of Ant Design's default inline blocks.
              // - Within it, .ant-menu-title-content (which represents the label) has `overflow: hidden`. This is necessary for the title to be ellipsized, whether there is a shortcut or not (see "Gotcha 3" in https://gaurav5430.medium.com/css-flex-positioning-gotchas-child-expands-to-more-than-the-width-allowed-by-the-parent-799c37428dd6)
              width: 215,
              ".ant-menu-item": {
                display: "flex !important",
                alignItems: "center",
              },
              ".ant-menu-title-content": {
                flexGrow: 1,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              },
            }),
          )}
          {...rest}
        />
      )}
    </ClassNames>
  );
});

export const headerHeight = 40;

const Header: FC = () => {
  const isLocationDashboard = useIsLocationDashboard();

  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const dashboardsTree = useTree("dashboard");
  const pathToParentFolder = usePathToParentFolder();
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const [canManageContent] = usePermission("canManageContent");
  const { leftApplicationMenu, rightApplicationMenu } = useConfiguration();

  const dashboardState = useSelector(getDashboardState);
  const isPresenting = useIsPresenting();
  const defaultDashboardName = formatMessage({
    id: "aui.defaultDashboardName",
  });

  const dashboardName = dashboardState?.name || defaultDashboardName;

  const [title, setTitle] = useState<string>(dashboardName);

  const saveDashboard = useSaveDashboard();

  const theme = useTheme();

  const dashboardContentRecord = useMemo(
    () =>
      pathToParentFolder && dashboardsTree && id
        ? getContentRecordAtPath({
            tree: dashboardsTree,
            path: [...pathToParentFolder, id],
          })
        : undefined,
    [pathToParentFolder, dashboardsTree, id],
  );

  useEffect(() => {
    setTitle(dashboardName);
  }, [dashboardName]);

  const configuration = useConfiguration();
  const goHome = () => {
    navigate(configuration.pathToHome);
  };

  const isPlaceholder = title === defaultDashboardName;
  const handleTitleBlurred = async () => {
    // do not save a title that is the default name
    if (isPlaceholder) {
      return;
    }

    if (!title) {
      setTitle(dashboardName);
    }

    if (dashboardState && title !== dashboardName) {
      const newDashboardState = {
        ...dashboardState,
        name: title,
      };
      dispatch({ type: "dashboardUpdated", dashboardState: newDashboardState });
      await saveDashboard(newDashboardState);
    }
  };

  const isDashboardSaved = useIsDashboardSaved();

  const isTitleEditingEnabled =
    canManageContent &&
    !isPresenting &&
    (!isDashboardSaved || dashboardContentRecord?.entry.canWrite);

  return (
    <div
      css={{
        background: theme.grayScale[1],
        color: theme.textColor,
        height: `${headerHeight}px`,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0px 4px",
        borderBottom: `1px solid ${theme.grayScale[5]}`,
      }}
    >
      <Logo onClick={goHome} />
      <HeaderMenu menus={leftApplicationMenu} />
      {!isLocationDashboard && <HomeSearch />}
      {isLocationDashboard && (
        <div
          css={{
            textAlign: "center",
            margin: "0 16px",
          }}
        >
          <TitleInput
            inputCss={{
              background: "transparent",
              boxShadow: "none",
              height: 24,
              marginTop: 8,
              marginBottom: 8,
              fontSize: 16,
              fontWeight: 400,
              maxWidth: 600,
              textOverflow: "ellipsis",
              color: isPlaceholder ? theme.placeholderColor : theme.textColor,
              ...(!isTitleEditingEnabled
                ? { ":hover": {}, focus: {} }
                : {
                    ":hover": {
                      borderColor: theme.isDark
                        ? theme.grayScale[7]
                        : theme.grayScale[5],
                    },
                    ":focus": {
                      borderColor: theme.isDark
                        ? theme.grayScale[7]
                        : theme.grayScale[5],
                    },
                  }),
            }}
            tooltipValue={formatMessage({ id: "aui.rename" })}
            value={title || ""}
            onChange={setTitle}
            onBlur={handleTitleBlurred}
            isPlaceholder={isPlaceholder}
            disabled={!isTitleEditingEnabled}
          />
        </div>
      )}
      <HeaderMenu
        css={{ justifyContent: "flex-end" }}
        menus={rightApplicationMenu}
      />
    </div>
  );
};

export default Header;
