import { axisIds } from "./axisIds.js";
import { getTuple } from "./getTuple.js";
/**
 * Executes the given callback for each cell in `data`
 */ export const forEachCell = (data, hierarchyIndicesInCellSet, callback)=>{
    const [columnsCount, rowsCount] = [
        axisIds.columns,
        axisIds.rows
    ].map((axisId)=>{
        const axis = data.axes.find(({ id  })=>id === axisId);
        return axis?.positions.length ?? 1;
    });
    let numberOfMatchedCells = 0;
    for(let rowIndex = 0; rowIndex < rowsCount; rowIndex++){
        for(let columnIndex = 0; columnIndex < columnsCount; columnIndex++){
            // Some positions can be empty: they do not have an associated cell in data.cells.
            let cell = null;
            const nextCell = data.cells[numberOfMatchedCells];
            const cellIndex = rowIndex * columnsCount + columnIndex;
            if (nextCell?.ordinal === cellIndex) {
                numberOfMatchedCells += 1;
                cell = nextCell;
            }
            const tuple = getTuple({
                columnIndex,
                data,
                hierarchyIndicesInCellSet,
                rowIndex
            });
            callback({
                cell,
                cellIndex: cell ? numberOfMatchedCells - 1 : null,
                columnIndex,
                tuple,
                rowIndex
            });
        }
    }
};
