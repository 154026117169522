import { produce } from "immer";
import { getCubeName } from "./getCubeName.js";
import { isMdxDrillthrough } from "./isMdxDrillthrough.js";
/**
 * Returns a new {@link MdxSelect} corresponding to `mdx` where all filter nodes were removed.
 * Does not mutate mdx.
 */ export function removeAllFilters(mdx) {
    if (isMdxDrillthrough(mdx)) {
        return {
            ...mdx,
            select: removeAllFilters(mdx.select)
        };
    }
    return produce(mdx, (draft)=>{
        Reflect.deleteProperty(draft, "slicerAxis");
        const cubeName = getCubeName(mdx);
        draft.from = {
            cubeName,
            elementType: "From"
        };
    });
}
