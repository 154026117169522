import { css } from "@emotion/react";
import { Card } from "antd";
import { fill as _fill } from "lodash-es";
import { CSSProperties } from "react";
import { useIntl } from "react-intl";

import { useTheme } from "@activeviam/activeui-sdk";

const folderCards = _fill(new Array(6), null);

interface DashboardFoldersSkeletonProps {
  cardStyle: CSSProperties;
  cardProps: {
    headStyle: CSSProperties;
    bordered: boolean;
    bodyStyle: CSSProperties;
  };
}

/**
 * Displays gray placeholder rectangles for folders while waiting for the home page to load.
 */
export const DashboardFoldersSkeleton = (
  props: DashboardFoldersSkeletonProps,
) => {
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const { cardStyle, cardProps } = props;
  return (
    <Card
      title={formatMessage({ id: "aui.contentTree.folders" })}
      {...cardProps}
      style={cardStyle}
    >
      <div
        css={css`
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          padding-bottom: 12px;
          -webkit-user-select: none;
          -moz-user-select: none;
        `}
      >
        {folderCards.map((item, index) => (
          <div
            key={index}
            css={css`
              border-radius: 2px;
              height: 45px;
              background: ${theme.grayScale[4]};
              width: 240px;
              margin: 12px 12px 0 0;
            `}
          />
        ))}
      </div>
    </Card>
  );
};
