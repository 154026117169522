import { produce } from "immer";
import { _getFilterClasses } from "./internal/_getFilterClasses.js";
import { isMdxDrillthrough } from "./isMdxDrillthrough.js";
import { removeAllFilters } from "./removeAllFilters.js";
function _createSlicerAxis(tuple) {
    if (tuple.length === 0) {
        return undefined;
    }
    return {
        elementType: "Axis",
        expression: tuple.length === 1 ? tuple[0] : {
            arguments: tuple,
            elementType: "Function",
            name: "()",
            syntax: "Parentheses"
        },
        name: "SLICER",
        nonEmpty: false,
        properties: []
    };
}
export function setFilters(mdx, { filters , cube  }) {
    if (isMdxDrillthrough(mdx)) {
        return {
            ...mdx,
            select: setFilters(mdx.select, {
                filters,
                cube
            })
        };
    }
    const filterClasses = _getFilterClasses({
        mdx,
        filters,
        cube
    });
    const cleanMdx = removeAllFilters(mdx);
    const slicerTupleAccumulator = [];
    return produce(cleanMdx, (draft)=>{
        filters.forEach((mdxFilter, i)=>{
            if (filterClasses[i] === "slicer") {
                // A slicer filter is necessarily an MdxCompoundIdentifier.
                // eslint-disable-next-line atoti-ui/no-as
                slicerTupleAccumulator.push(mdxFilter);
            } else {
                // Add the filter as a subselect.
                // This subselect must be sliced with the tuple corresponding to all slicer filters above.
                draft.from = {
                    axes: [
                        {
                            elementType: "Axis",
                            expression: mdxFilter,
                            name: "COLUMNS",
                            nonEmpty: false,
                            properties: []
                        }
                    ],
                    elementType: "SubSelect",
                    from: draft.from,
                    nonVisual: false,
                    slicerAxis: _createSlicerAxis(slicerTupleAccumulator.slice())
                };
            }
        });
        draft.slicerAxis = _createSlicerAxis(slicerTupleAccumulator);
    });
}
