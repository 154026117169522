import { isMdxFunction } from "../isMdxFunction.js";
import { _nullifyNodeAtPath } from "./_nullifyNodeAtPath.js";
/**
 * Nullify `functionNode` at `path` in `mdx` if all its arguments are default members.
 * Returns whether `functionNode` was nullified.
 * Mutates `mdx`.
 *
 * @example
 * Crossjoin([Currency].[Currency].DefaultMember, [Booking].[Desk].DefaultMember) => null
 */ export function _nullifyIfAllArgsAreDefaultMembers({ mdx , path , functionNode  }) {
    if (functionNode.arguments.every((arg)=>isMdxFunction(arg, "defaultmember"))) {
        _nullifyNodeAtPath(mdx, path);
        return true;
    }
    return false;
}
