import { getHierarchy } from "@activeviam/data-model";
import { createLevelCompoundIdentifier } from "../createLevelCompoundIdentifier.js";
import { createMemberCompoundIdentifier } from "../createMemberCompoundIdentifier.js";
/**
 * Returns the MDX AST representing the descendants of `hierarchyName`'s top level member down to the level at `levelIndex`.
 */ export const _getDescendantsFunction = ({ dimensionName , hierarchyName , levelIndex , cube , doesIncludeCalculatedMembers  })=>{
    if (levelIndex === 0) {
        throw new Error("Members of the first level of a hierarchy do not have ancestors. They cannot be expressed as descendants of another set of members.");
    }
    const hierarchy = getHierarchy({
        dimensionName,
        hierarchyName
    }, cube);
    const setOfAncestorMembers = hierarchy.slicing ? {
        elementType: "Function",
        name: "Members",
        syntax: "Property",
        arguments: [
            createLevelCompoundIdentifier({
                dimensionName,
                hierarchyName,
                levelName: Object.keys(hierarchy.levels)[0]
            })
        ]
    } : {
        elementType: "Function",
        name: "{}",
        syntax: "Braces",
        arguments: [
            createMemberCompoundIdentifier({
                dimensionName,
                hierarchyName,
                namePath: [
                    "AllMember"
                ]
            }, cube)
        ]
    };
    const descendantsFunction = {
        arguments: [
            setOfAncestorMembers,
            {
                elementType: "Literal",
                type: "SCALAR",
                value: `${levelIndex}`
            },
            {
                elementType: "Literal",
                type: "KEYWORD",
                value: "SELF_AND_BEFORE"
            }
        ],
        elementType: "Function",
        name: "Descendants",
        syntax: "Function"
    };
    return {
        arguments: doesIncludeCalculatedMembers ? [
            {
                elementType: "Function",
                syntax: "Function",
                name: "AddCalculatedMembers",
                arguments: [
                    descendantsFunction
                ]
            }
        ] : [
            descendantsFunction
        ],
        elementType: "Function",
        name: "Hierarchize",
        syntax: "Function"
    };
};
