import { ConfigProvider as AntdConfigProvider } from "antd";
import { ComponentType, useMemo } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { createRoot } from "react-dom/client";
import { RouterProvider, createHashRouter } from "react-router-dom";
import { compose } from "redux";

import {
  ConfigurationProvider,
  useConfiguration,
} from "./ConfigurationContext.js";
import App from "./components/App.js";
import { getOverlayRoot } from "./getOverlayRoot.js";
import { routes } from "./routes.js";
import { EnhancedReduxProvider } from "./state/EnhancedReduxProvider.js";
import { withStyle } from "./withStyle.js";
import { withTranslations } from "./withTranslations.js";

const WiredApp = () => {
  const configuration = useConfiguration();

  const AppWithHocs = useMemo(() => {
    const hocs = [
      withTranslations,
      ...configuration.higherOrderComponents,
      // `withStyle` relies on `useSetting` to get the theme key.
      // Since this relies on the ContentClient, it should be after `configuration.higherOrderComponents`, which are expected to provide the clients.
      withStyle,
    ];
    return compose<ComponentType>(...hocs)(App);
  }, [configuration]);

  const router = createHashRouter([
    {
      path: "/",
      element: <AppWithHocs />,
      children: [...routes, ...configuration.routes],
    },
  ]);

  return <RouterProvider router={router} />;
};

const render = () => {
  const container = document.getElementById("root");
  if (!container) {
    throw new Error("No element with id `root` was found in `index.html`");
  }
  const root = createRoot(container);

  root.render(
    <AntdConfigProvider getPopupContainer={getOverlayRoot}>
      <DndProvider backend={HTML5Backend}>
        <ConfigurationProvider>
          <EnhancedReduxProvider>
            <WiredApp />
          </EnhancedReduxProvider>
        </ConfigurationProvider>
      </DndProvider>
    </AntdConfigProvider>,
  );
};

render();

if (module.hot) {
  module.hot.accept("./components/App", render);
}
