import { FC } from "react";

import { folderCardStyle } from "./FolderCard.js";
import { NewFolderButton, NewFolderButtonProps } from "./NewFolderButton.js";

/**
 * Displays a `<NewFolderButton/>` shaped like a `<Card />`
 */
export const NewFolderCard: FC<NewFolderButtonProps> = (props) => {
  return (
    <NewFolderButton
      {...props}
      style={{ ...props.style, ...folderCardStyle }}
    />
  );
};
