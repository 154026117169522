import { flatten as _flatten, last as _last } from "lodash-es";
import { getHierarchy } from "@activeviam/data-model";
/**
 * Returns the caption corresponding to the input cellset members.
 */ export function getTupleCaption(tuple, { cube , captionForTotals , separator =" " , hasOnlyCaptionForLeafLevels =false  }) {
    if (tuple.length === 0) {
        // Edge case which can happen when using the default MDX query:
        // SELECT FROM [Cube]
        // In this case, this corresponds to the grand total.
        return captionForTotals;
    }
    let isTotalOrSubTotal = false;
    const allCaptions = _flatten(tuple.map(({ dimensionName , hierarchyName , ...member })=>{
        if (member === undefined) {
            return "";
        }
        const memberPath = // `member` is either of type 'Member' or 'DefaultMember' so it has a namePath or a path property.
        // eslint-disable-next-line
        member.namePath ?? member.path;
        const captionPath = member.captionPath;
        if (dimensionName === "Measures") {
            return captionPath;
        }
        const hierarchy = getHierarchy({
            dimensionName,
            hierarchyName
        }, cube);
        if (!hierarchy.slicing && memberPath.length === 1) {
            // In this situation namePath is [AllMember]
            // Flag that there is a total. It will be added at the end.
            isTotalOrSubTotal = true;
            return [];
        } else {
            if (hasOnlyCaptionForLeafLevels) {
                return _last(captionPath);
            } else {
                return hierarchy.slicing ? captionPath : captionPath.slice(1);
            }
        }
    }));
    const allCaptionsWithTotal = isTotalOrSubTotal ? [
        ...allCaptions,
        captionForTotals
    ] : allCaptions;
    return allCaptionsWithTotal.join(separator);
}
