import {
  ApplicationMenu,
  applicationSubMenuHelp,
} from "@activeviam/activeui-sdk";

import { editSubMenu } from "../menu/editSubMenu.js";
import { fileSubMenu } from "../menu/fileSubMenu.js";
import { insertSubMenu } from "../menu/insertSubMenu.js";
import { shareMenuItem } from "../menu/shareMenuItem.js";
import { statusNotificationMenuItem } from "../menu/statusNotificationMenuItem.js";
import { toggleIsPresentingMenuItem } from "../menu/toggleIsPresentingMenuItem.js";
import { userSubMenu } from "../menu/userSubMenu.js";

/**
 * Populated using the menus defined by API users under rightApplicationMenus in {@link Configuration}
 * These menus will be displayed at the right side of the header.
 */
export const rightApplicationMenu: ApplicationMenu[] = [
  shareMenuItem,
  toggleIsPresentingMenuItem,
  applicationSubMenuHelp,
  userSubMenu,
];

/**
 * Populated using the menus defined by API users under leftApplicationMenus in {@link Configuration}
 * These menus will be displayed at the left side of the header, to the right of the dashboard name.
 */
export const leftApplicationMenu: ApplicationMenu[] = [
  fileSubMenu,
  editSubMenu,
  insertSubMenu,
  statusNotificationMenuItem,
];
