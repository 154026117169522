import { FC, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  deserializeFilter,
  getActivePageKey,
  getDashboardState,
  getResourcesPanelKey,
  getSelectedLeafKey,
  updateWidget,
  useContentClient,
} from "@activeviam/activeui-sdk";
import { SavedFiltersPanel } from "@activeviam/filters";
import { WidgetsPanel } from "@activeviam/widget";

import { ApplicationDataModelTree } from "./ApplicationDataModelTree.js";

/**
 * Displays the panel located on the left of the application.
 * Either shows the data model tree, widgets panel or filters panel.
 */
export const ResourcesPanel: FC = () => {
  const resourcesPanelKey = useSelector(getResourcesPanelKey);
  const dispatch = useDispatch();
  const dashboardState = useSelector(getDashboardState);
  const activePageKey = useSelector(getActivePageKey);
  const selectedLeafKey = useSelector(getSelectedLeafKey);
  const contentClient = useContentClient();

  const handleSavedFilterAdded = async (id: string) => {
    if (dashboardState && activePageKey && selectedLeafKey) {
      const serializedFilter = await contentClient.fetchFile({
        id,
        withMetaData: false,
        type: "filter",
      });
      const deserializedFilter = deserializeFilter(serializedFilter);
      const updatedDashboardState = updateWidget(
        dashboardState,
        { pageKey: activePageKey, leafKey: selectedLeafKey },
        (draftWidgetState) => {
          draftWidgetState.filters = [
            ...(draftWidgetState.filters || []),
            deserializedFilter,
          ];
          return draftWidgetState;
        },
      );
      dispatch({
        type: "dashboardUpdated",
        dashboardState: updatedDashboardState,
      });
    }
  };

  return (
    <Fragment>
      {/* The data model is hidden rather than unmounted, in order to preserve its internal state */}
      <div
        css={{
          height: "100%",
          ...(resourcesPanelKey !== "data-model" && { display: "none" }),
        }}
      >
        <ApplicationDataModelTree />
      </div>
      {resourcesPanelKey === "widgets" && <WidgetsPanel />}
      {resourcesPanelKey === "filters" && (
        <SavedFiltersPanel onFilterOpened={handleSavedFilterAdded} />
      )}
    </Fragment>
  );
};
