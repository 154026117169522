import { produce } from "immer";
import { get as _get } from "lodash-es";
import { collapse } from "./collapse.js";
import { getSpecificCompoundIdentifier } from "./getSpecificCompoundIdentifier.js";
import { _cleanupMdx } from "./internal/_cleanupMdx.js";
import { _getPathsToMeasures } from "./internal/_getPathsToMeasures.js";
import { _nullifyNodeAtPath } from "./internal/_nullifyNodeAtPath.js";
import { isACompoundIdentifierRepresentingAMeasure } from "./isACompoundIdentifierRepresentingAMeasure.js";
import { isMdxCompoundIdentifier } from "./isMdxCompoundIdentifier.js";
import { isMdxFunction } from "./isMdxFunction.js";
import { traverseMdx } from "./traverseMdx.js";
/**
 * Returns a new {@link MdxSelect} corresponding to `mdx` where the given measure was removed.
 * Does not mutate mdx.
 */ export function removeMeasure(mdx, { cube , measureName , shouldRemoveCalculatedMeasureExpression  }) {
    const node = measureName ? {
        dimensionName: "Measures",
        hierarchyName: "Measures",
        namePath: [
            measureName
        ]
    } : undefined;
    const cleanMdx = node ? collapse(mdx, {
        cube,
        tupleCoordinates: [
            node
        ]
    }) : mdx;
    const pathsToMeasures = _getPathsToMeasures(cleanMdx);
    return produce(cleanMdx, (draft)=>{
        pathsToMeasures.forEach((path)=>{
            // By default, if the removed measure is a calculated measure, then its expression is removed from the withClause.
            if (shouldRemoveCalculatedMeasureExpression !== false && draft.withClause) {
                const isCalculatedMeasureUsedByAnotherCalculatedMeasure = draft.withClause.some(({ expression  })=>{
                    let isUsed = false;
                    traverseMdx(expression, (node)=>{
                        if (isMdxCompoundIdentifier(node)) {
                            const specificCompoundIdentifier = getSpecificCompoundIdentifier(node, {
                                cube
                            });
                            if (specificCompoundIdentifier.type === "measure" && specificCompoundIdentifier.measureName === measureName) {
                                // The removed measure is used in the expression of another calculated measure.
                                isUsed = true;
                                // Break.
                                return false;
                            }
                        }
                        // Keep going.
                        return true;
                    });
                    return isUsed;
                });
                if (!isCalculatedMeasureUsedByAnotherCalculatedMeasure) {
                    // The removed measure is not used in the expression of a different calculated measure.
                    // Its expression can safely be removed from the with clause.
                    draft.withClause = draft.withClause.filter(({ name  })=>{
                        const isMeasure = name.identifiers.length === 2 && name.identifiers[0].value === "Measures";
                        const isRemovedMeasure = isMeasure && name.identifiers[1].value === measureName;
                        return !isRemovedMeasure;
                    });
                }
            }
            let pathToMeasureToRemove;
            // Forced to use `get` because path is dynamic.
            // eslint-disable-next-line atoti-ui/no-lodash-get
            const nodeHoldingMeasures = _get(draft, path);
            if (isMdxFunction(nodeHoldingMeasures, "{}")) {
                const measureIndex = nodeHoldingMeasures.arguments.findIndex((argument)=>isACompoundIdentifierRepresentingAMeasure(argument, {
                        measureName
                    }));
                pathToMeasureToRemove = [
                    ...path,
                    "arguments",
                    measureIndex
                ];
            } else if (isACompoundIdentifierRepresentingAMeasure(nodeHoldingMeasures)) {
                pathToMeasureToRemove = path;
            }
            if (pathToMeasureToRemove) {
                _nullifyNodeAtPath(draft, pathToMeasureToRemove);
                _cleanupMdx(draft, pathToMeasureToRemove, cube);
            }
        });
    });
}
