import { RedoOutlined } from "@ant-design/icons";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { ActionCreators } from "redux-undo";

import type { ApplicationMenuItem, State } from "@activeviam/activeui-sdk";

import { MenuItemLabel } from "./MenuItemLabel.js";

const redoKey = "redo";

/**
 * {@link ApplicationMenuItem} used to revert the latest change made by a user.
 */
export const editRedoMenuItem: ApplicationMenuItem = {
  key: redoKey,
  useMenuItem: () => {
    const dispatch = useDispatch();
    const { formatMessage } = useIntl();

    const handleRedo = () => {
      dispatch(ActionCreators.redo());
    };

    const numberOfRedoableStates = useSelector(
      (state: State) => state.dashboard.future.length,
    );

    const disabled = numberOfRedoableStates === 0;

    return {
      icon: <RedoOutlined />,
      disabled,
      label: (
        <MenuItemLabel
          title={formatMessage({
            id: redoKey,
          })}
          shortcutKey={redoKey}
          disabled={disabled}
        />
      ),
      onClick: handleRedo,
    };
  },
};
