import { css } from "@emotion/react";
import { invert as _invert, set as _set } from "lodash-es";
import { FC, useEffect } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { unstable_usePrompt, useParams } from "react-router-dom";

import {
  getHasUnsavedChanges,
  getIsOpenDashboardPopupVisible,
  getResourcesPanelKey,
  usePermission,
  useSetting,
  useTheme,
} from "@activeviam/activeui-sdk";
import { isBrowserOnMacOS } from "@activeviam/utils";

import { useIsSavingDashboardAs } from "../IsSavingDashboardAsContext.js";
import { useSaveDashboard } from "../hooks/useSaveDashboard.js";
import { keyboardShortcuts } from "../keyboardShortcuts.js";
import { isElementTarget } from "../utils/isElementTarget.js";
import { DashboardBasedOnId } from "./DashboardBasedOnId.js";
import { ResourcesAndToolsPanels } from "./ResourcesAndToolsPanels.js";
import { OpenDashboardModal } from "./header/OpenDashboardModal.js";
import { useUnsavedDashboardCounter } from "./useUnsavedDashboardCounter.js";

/**
 * A dashboard based on the current URL.
 * With tools on the left, allowing to edit it.
 */
export const EditableDashboard: FC = () => {
  const theme = useTheme();
  const { formatMessage } = useIntl();

  const isOpenDashboardPopupVisible = useSelector(
    getIsOpenDashboardPopupVisible,
  );
  const dispatch = useDispatch();
  const { id } = useParams<{
    id?: string;
  }>();
  const counter = useUnsavedDashboardCounter();

  const saveDashboard = useSaveDashboard();
  const [canManageContent] = usePermission("canManageContent");

  const [isUnsavedChangesPopupEnabled] = useSetting(
    "unsavedChangesPopup.isEnabled",
  );
  const hasUnsavedChanges = useSelector(getHasUnsavedChanges);
  const resourcesPanelKey = useSelector(getResourcesPanelKey);
  const [isSavingDashboardAs] = useIsSavingDashboardAs();

  // The user is about to leave the application and lose unsaved changes.
  // Trigger a native browser alert to warn them and allow them to cancel.
  useEffect(() => {
    if (isUnsavedChangesPopupEnabled && canManageContent && hasUnsavedChanges) {
      const onBeforeUnload = (event: BeforeUnloadEvent) => {
        event.preventDefault();
        event.returnValue = "";
      };

      window.addEventListener("beforeunload", onBeforeUnload);
      return () => {
        window.removeEventListener("beforeunload", onBeforeUnload);
      };
    }

    return () => {};
  }, [hasUnsavedChanges, isUnsavedChangesPopupEnabled, canManageContent]);

  // The user is about to navigate away from their dashboard, within the application.
  // Trigger a prompt to warn them and allow them to cancel.
  unstable_usePrompt({
    when:
      hasUnsavedChanges &&
      isUnsavedChangesPopupEnabled &&
      canManageContent &&
      !isSavingDashboardAs,
    message: formatMessage({
      id: "confirmUnsavedChanges",
    }),
  });

  useEffect(() => {
    if (!canManageContent) {
      return () => {};
    }
    // Shortcuts to save and open a dashboard.
    const handleKeyDown = (event: KeyboardEvent) => {
      if (!isElementTarget(event.target)) {
        return;
      }
      if (event[isBrowserOnMacOS ? "metaKey" : "ctrlKey"]) {
        switch (event.key) {
          case "s":
            // Prevents the browser's default Save popup from opening.
            event.preventDefault();
            void saveDashboard();
            break;
          case "o":
            // Prevents the browser's default Save popup from opening.
            event.preventDefault();
            dispatch({ type: "openDashboardPopupOpened" });
            break;
        }
      }
    };
    document.body.addEventListener("keydown", handleKeyDown);
    return () => {
      document.body.removeEventListener("keydown", handleKeyDown);
    };
  }, [canManageContent, saveDashboard, dispatch]);

  useEffect(() => {
    if (canManageContent) {
      // Shortcuts to interact with the resource panel.
      const handleKeyUp = (event: KeyboardEvent) => {
        if (!isElementTarget(event.target)) {
          return;
        }
        if (event.key === "Escape") {
          // The user pressed Escape.
          if (event.target === document.body) {
            // No element is focused.
            if (resourcesPanelKey !== "data-model") {
              // Go back to the data model.
              dispatch({
                type: "resourcesPanelChanged",
                resourcesPanelKey: "data-model",
              });
            }
          } else {
            // An element is focused, blur it.
            // This way, the user will be able to go back to the data model by pressing Escape once more.
            event.target.blur();
          }
        } else if (event.target === document.body) {
          const panelKey = _invert(keyboardShortcuts)[event.key.toUpperCase()];
          if (panelKey) {
            event.preventDefault();
            // Open the resource panel corresponding to the pressed shortcut.
            dispatch({
              type: "resourcesPanelChanged",
              resourcesPanelKey: panelKey,
            });
          }
        }
      };
      document.body.addEventListener("keyup", handleKeyUp);
      return () => {
        document.body.removeEventListener("keyup", handleKeyUp);
      };
    }
    return () => {};
  }, [canManageContent, saveDashboard, dispatch, resourcesPanelKey]);

  return (
    <ResourcesAndToolsPanels>
      <div
        css={css`
          height: 100%;
          display: flex;
          flex-direction: column;
        `}
      >
        <div
          css={css`
            height: 100%;
            border-left: 1px solid;
            border-top: 1px solid;
            border-color: ${theme.grayScale[4]};
          `}
          id={"dashboard-thumbnail-root"}
        >
          <OpenDashboardModal isVisible={isOpenDashboardPopupVisible} />
          <DashboardBasedOnId id={id} unsavedDashboardCounter={counter} />
        </div>
      </div>
    </ResourcesAndToolsPanels>
  );
};
