import { produce } from "immer";
import { get as _get } from "lodash-es";
import { areHierarchiesEqual } from "@activeviam/data-model";
import { forEachExpandedTuple } from "./forEachExpandedTuple.js";
import { _cleanupMdx } from "./internal/_cleanupMdx.js";
import { _cleanupObsoleteDefaultMembers } from "./internal/_cleanupObsoleteDefaultMembers.js";
import { _getIndexOfAxisContainingHierarchiesExpressedInTuple } from "./internal/_getIndexOfAxisContainingLevelsExpressedInTuple.js";
import { _nullifyNodeAtPath } from "./internal/_nullifyNodeAtPath.js";
import { isMdxFunction } from "./isMdxFunction.js";
import { quote } from "./quote.js";
import { stringify } from "./stringify.js";
/**
 * Returns whether `tupleCoordinatesA` represents the same tuple as `tupleCoordinatesB`, or a descendant of it.
 */ const isSameTupleOrDescendant = (tupleCoordinatesA, tupleCoordinatesB)=>{
    if (tupleCoordinatesB.length > tupleCoordinatesA.length) {
        return false;
    }
    return tupleCoordinatesB.every((tupleCoordinateB, i)=>{
        const tupleCoordinateA = tupleCoordinatesA[i];
        return areHierarchiesEqual(tupleCoordinateA, tupleCoordinateB) && tupleCoordinateB.namePath.every((name, j)=>name === tupleCoordinateA.namePath[j]);
    });
};
/**
 * Returns the path to the first node representing `tupleCoordinates`, or a descendant of it.
 */ const findPathToTupleOrDescendant = (axis, tupleCoordinates, cube)=>{
    let path = undefined;
    forEachExpandedTuple(axis, cube, ({ path: _path , tupleCoordinates: _tupleCoordinates  })=>{
        if (isSameTupleOrDescendant(_tupleCoordinates, tupleCoordinates)) {
            path = _path;
            return false;
        }
        return;
    });
    return path;
};
/**
 * Returns a new {@link MdxAxis} corresponding to `axis` where the given tuple was collapsed.
 * Does not mutate mdx.
 */ export function _collapseInAxis(axis, { cube , tupleCoordinates  }) {
    return produce(axis, (draft)=>{
        let nextPathToRemove;
        while(nextPathToRemove = findPathToTupleOrDescendant(draft, tupleCoordinates, cube)){
            // Remove the node corresponding to the expanded tuple from the parent union.
            _nullifyNodeAtPath(draft, nextPathToRemove);
            const pathToUnion = nextPathToRemove.slice(0, -2);
            // Forced to use `get` because pathToUnion is dynamic.
            // eslint-disable-next-line atoti-ui/no-lodash-get
            const union = _get(draft, pathToUnion);
            if (!isMdxFunction(union, "union")) {
                function stringifyMember({ dimensionName , hierarchyName , namePath  }) {
                    return quote(dimensionName, hierarchyName, ...namePath);
                }
                const stringifiedTuple = tupleCoordinates.map(stringifyMember).join(",");
                throw new Error(`Cannot collapse tuple ${stringifiedTuple} in MDX ${stringify(axis, {
                    indent: true
                })}\n because a node representing this tuple or a descendant tuple was found outside a Union.`);
            }
            const indexOfRemovedTuple = nextPathToRemove[nextPathToRemove.length - 1];
            // Cleanup only up to the union in case other Union arguments need to be cleaned up before cleaning up the Union itself (*).
            // See _cleanupObsoleteDefaultMembers.
            _cleanupMdx(union, [
                "arguments",
                indexOfRemovedTuple
            ], cube);
            // Remove the obsolete default members in the case of an "Expand by", and cleanup the corresponding crossjoins.
            _cleanupObsoleteDefaultMembers(union, cube);
            // Cleanup the Union and its parents.
            _cleanupMdx(draft, pathToUnion, cube);
        // (*)
        // Example of why union arguments should not be cleaned up higher up than the union itself before they have all been cleaned up:
        //
        // Consider the following MDX where LegalEntityA is expanded down to Currency.
        // Hierarchize(
        //   Union(
        //     Crossjoin(
        //       Descendants(
        //         {
        //           [Booking].[Desk].[AllMember]
        //         },
        //         1,
        //         SELF_AND_BEFORE
        //       ),
        //       [Currency].[Currency].DefaultMember
        //     ),
        //     Crossjoin(
        //       [Booking].[Desk].[AllMember].[LegalEntityA],
        //       Hierarchize(
        //         Descendants(
        //           {
        //             [Currency].[Currency].[AllMember]
        //           },
        //           1,
        //           SELF_AND_BEFORE
        //         )
        //       )
        //     )
        //   )
        // )
        //
        // Suppose the user collapses LegalEntityA.
        // In a first step, the corresponding Crossjoin is removed:
        // Hierarchize(
        //   Union(
        //     Crossjoin(
        //       Descendants(
        //         {
        //           [Booking].[Desk].[AllMember]
        //         },
        //         1,
        //         SELF_AND_BEFORE
        //       ),
        //       [Currency].[Currency].DefaultMember
        //     )
        //   )
        // )
        //
        // If the cleanup was propagated higher up than the union at this stage, then the Union would now be replaced by its single argument:
        // Hierarchize(
        //   Crossjoin(
        //     Descendants(
        //       {
        //         [Booking].[Desk].[AllMember]
        //       },
        //       1,
        //       SELF_AND_BEFORE
        //     ),
        //     [Currency].[Currency].DefaultMember
        //   )
        // )
        //
        // Since the cleanup propagates bottom up, the process would stop here.
        // The MDX would be left with a useless default member in the crossjoin, and as a matter of fact, with a useless crossjoin, instead of the wanted:
        // Hierarchize(
        //   Descendants(
        //     {
        //       [Booking].[Desk].[AllMember]
        //     },
        //     1,
        //     SELF_AND_BEFORE
        //   )
        // )
        }
    });
}
/**
 * Returns a new {@link MdxSelect} corresponding to `mdx` where the given tuple was collapsed.
 * Does not mutate mdx.
 */ export function collapse(mdx, { cube , tupleCoordinates  }) {
    const axisIndex = _getIndexOfAxisContainingHierarchiesExpressedInTuple(mdx, {
        cube,
        tupleCoordinates
    });
    if (axisIndex === -1) {
        return mdx;
    }
    const axisWithCollapsedTuple = _collapseInAxis(mdx.axes[axisIndex], {
        cube,
        tupleCoordinates
    });
    return produce(mdx, (draft)=>{
        draft.axes[axisIndex] = axisWithCollapsedTuple;
        _cleanupMdx(draft, [
            "axes",
            axisIndex
        ], cube);
    });
}
