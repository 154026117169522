/**
 * Returns the {@link MdxLevelCompoundIdentifier} corresponding to the given level coordinates.
 */ export function createLevelCompoundIdentifier({ dimensionName , hierarchyName , levelName  }) {
    return {
        elementType: "CompoundIdentifier",
        type: "level",
        identifiers: [
            dimensionName,
            hierarchyName,
            levelName
        ].map((value)=>({
                elementType: "Identifier",
                quoting: "QUOTED",
                value
            })),
        dimensionName,
        hierarchyName,
        levelName
    };
}
