import domToImage from "dom-to-image";

export const fileCardCoverHeight = 140;
export const fileCardCoverWidth = 230;

/**
 * Asynchronously loads and returns an image.
 */
async function getLoadedImage(src: string): Promise<HTMLImageElement> {
  return new Promise(function (resolve, reject) {
    const image = new Image();
    image.onload = function () {
      resolve(image);
    };
    image.onerror = reject;
    image.src = src;
  });
}

/**
 * Converting a chart element with too many data points into a PNG image is a time consuming process.
 * Each chart data point takes around 1.04ms.
 */
function getChartsThatAreTooBigToBeExported(
  rootElement: HTMLElement,
  maxNumberOfCellsAcrossCharts: number,
): HTMLElement[] {
  let numberOfCellsCounter = 0;
  return Array.from(rootElement.querySelectorAll<HTMLElement>(".atoti-ui-plot"))
    .sort(
      (a, b) =>
        Number(a.getAttribute("data-number-of-cells")) -
        Number(b.getAttribute("data-number-of-cells")),
    )
    .filter((chart) => {
      numberOfCellsCounter += Number(
        chart.getAttribute("data-number-of-cells"),
      );
      return numberOfCellsCounter > maxNumberOfCellsAcrossCharts;
    });
}

/**
 * The scrollbars of some elements are hidden using the `::-webkit-scrollbar` style property.
 * E.g. a pivot table is made of 4 grids, while it has only one scrollbar, the other grids scrollbars are hidden.
 * The dom2image library does not understand this particular styling and will render a scrollbar.
 * To avoid this, this function mutate the elements to hide their scrollbars
 * @returns a function that mutate the elements back to their original state.
 */
function hideScrollBars(rootElement: HTMLElement) {
  const elementsWithHiddenScrollbars: {
    element: HTMLElement;
    originalOverflows: {
      overflow: string;
      overflowX: string;
      overflowY: string;
    };
  }[] = [];

  const elements = rootElement.querySelectorAll<HTMLElement>(
    ".aui-invisible-scrollbars",
  );

  for (const element of elements) {
    elementsWithHiddenScrollbars.push({
      element,
      originalOverflows: {
        overflow: element.style.overflow,
        overflowX: element.style.overflowX,
        overflowY: element.style.overflowY,
      },
    });
    element.style.overflow = "hidden";
  }

  return () => {
    // Restore the modified overflow style attributes to their original values.
    elementsWithHiddenScrollbars.forEach(({ element, originalOverflows }) => {
      element.style.overflow = originalOverflows.overflow;
      element.style.overflowX = originalOverflows.overflowX;
      element.style.overflowY = originalOverflows.overflowY;
    });
  };
}

/**
 * Asynchronously returns a string representing the source of a PNG thumbnail of the dashboard currently displayed.
 */
export async function getDashboardThumbnailDataUrl(): Promise<
  string | undefined
> {
  // Stripping tools and headers
  const rootElement = document.getElementById("dashboard-thumbnail-root");

  if (!rootElement) {
    // Happens if a state is saved without having been rendered (ie when creating a new dashboard).
    return undefined;
  }

  const restoreScrollbars = hideScrollBars(rootElement);

  const chartsThatAreTooBigToBeExported = getChartsThatAreTooBigToBeExported(
    rootElement,
    4000,
  );

  try {
    // @ts-expect-error: `@types/dom-to-image` is not accurate because in a module environment `dom-to-image` exports its API as a module rather than attaching it to the global scope.
    const screenshotAsPngData = await domToImage.toPng(rootElement, {
      filter: (node: HTMLElement) => {
        if (
          // Preventing the MathML semantic markup from being exported.
          // dom-to-image fails to process "styleless" DOM elements and these do not impact what's displayed.
          node.nodeName.toLocaleLowerCase() === "math" ||
          chartsThatAreTooBigToBeExported.includes(node)
        ) {
          return false;
        }
        return true;
      },
    });

    // dom-to-image does not allow to scale down after drawing.
    // Hence the manual operation.
    const canvas = document.createElement("canvas");
    canvas.width = fileCardCoverWidth;
    canvas.height = fileCardCoverHeight;
    canvas
      .getContext("2d")!
      .drawImage(
        await getLoadedImage(screenshotAsPngData),
        0,
        0,
        fileCardCoverWidth,
        fileCardCoverHeight,
      );

    return canvas.toDataURL();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn("The thumbnail could not be generated.", error);
    return undefined;
  } finally {
    restoreScrollbars();
  }
}
