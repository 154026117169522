import { useLocation } from "react-router-dom";

/**
 * React hook returning whether the user is on a saved dashboard.
 */
export function useIsDashboardSaved(): boolean {
  // A regex to match any unsaved dashboard path.
  // This matches "unsaved" and "unsaved/n", where n represents any number.
  const regex = /unsaved(\/\d+)?$/;
  return !regex.test(useLocation().pathname);
}
