import { produce } from "immer";
import { getMeasureName } from "../getMeasureName.js";
import { isACompoundIdentifierRepresentingAMeasure } from "../isACompoundIdentifierRepresentingAMeasure.js";
import { _findFirstMeasure } from "./_findFirstMeasure.js";
/**
 * Returns a new {@link MdxSelect} where the formulas defined in the withClause but not used in the rest of the statement are cleaned up.
 * Does not mutate mdx.
 */ export const _cleanUpUnusedFormulas = (mdx)=>{
    return produce(mdx, (draft)=>{
        let i = 0;
        while(i < draft.withClause.length){
            const formula = draft.withClause[i];
            if (isACompoundIdentifierRepresentingAMeasure(formula.name)) {
                const measureName = getMeasureName(formula.name);
                if (_findFirstMeasure(draft.axes, {
                    path: [
                        "axes"
                    ],
                    measureName
                }) === undefined) {
                    draft.withClause.splice(i, 1);
                    continue;
                }
            }
            i++;
        }
    });
};
