import { Alert, Button } from "antd";
import { filter as _filter } from "lodash-es";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";

import {
  useActivePivotClients,
  useConnectionStatuses,
} from "@activeviam/activeui-sdk";

/**
 * The minimum amount of time in milliseconds between two attempts to reconnect, when the connection is lost.
 */
const initialTimeUntilReconnection = 10000;

/**
 * A component that provides feedback when the connection is lost.
 * It automatically tries to reconnect every `reconnectionTimeout` seconds.
 * A countdown hints it.
 * It also renders a button that the user can click to try and reconnect right away.
 */
export const ConnectionLostBanner: FC = () => {
  const { formatMessage } = useIntl();

  const connectionStatuses = useConnectionStatuses();
  const activePivotClients = useActivePivotClients();
  /**
   * The serverKeys of the servers to which Atoti UI never managed to connect since the page loaded.
   */
  const deadServerKeys = useRef<Set<string>>(
    new Set(Object.keys(activePivotClients)),
  );

  const [isDismissed, setIsDismissed] = useState(false);
  const [timeUntilReconnection, setTimeUntilReconnection] = useState(
    initialTimeUntilReconnection,
  );
  const [failuresCounter, setFailuresCounter] = useState(0);

  for (const serverKey in activePivotClients) {
    if (activePivotClients[serverKey].connectionStatus === "connected") {
      deadServerKeys.current.delete(serverKey);
    }
  }

  const overallConnectionStatus = useMemo(() => {
    if (
      Object.keys(connectionStatuses).some(
        (serverKey) =>
          connectionStatuses[serverKey] === "disconnected" &&
          // Disregard dead servers.
          // This makes sure the banner does not show up if one of the target servers is down.
          !deadServerKeys.current.has(serverKey),
      )
    ) {
      return "disconnected";
    } else if (Object.values(connectionStatuses).includes("connecting")) {
      return "connecting";
    }
    return "connected";
  }, [connectionStatuses]);

  const tryToReconnect = useCallback(async () => {
    try {
      await Promise.all(
        _filter(
          activePivotClients,
          (activePivotClient) =>
            activePivotClient.connectionStatus === "disconnected",
        ).map((activePivotClient) => activePivotClient.connect()),
      );
      setTimeUntilReconnection(initialTimeUntilReconnection);
      setFailuresCounter(0);
    } catch (e) {
      setFailuresCounter((counter) => counter + 1);
    } finally {
    }
  }, [activePivotClients]);

  // Every 1s, decrement the time until next reconnection attempt.
  useEffect(() => {
    if (overallConnectionStatus === "disconnected") {
      if (timeUntilReconnection === 0) {
        void tryToReconnect();
      } else {
        const timeoutId = setTimeout(() => {
          setTimeUntilReconnection(timeUntilReconnection - 1000);
        }, 1000);
        return () => {
          clearTimeout(timeoutId);
        };
      }
    }
    return;
  }, [timeUntilReconnection, tryToReconnect, overallConnectionStatus]);

  // If a reconnection attempt just failed, then reset the time until next reconnection attempt to its initial value.
  useEffect(() => {
    setTimeUntilReconnection(initialTimeUntilReconnection);
  }, [failuresCounter]);

  const isTryingToReconnect = timeUntilReconnection === 0;
  if (
    !isDismissed &&
    (overallConnectionStatus === "disconnected" || isTryingToReconnect)
  ) {
    return (
      <Alert
        banner={true}
        message={formatMessage(
          {
            id: "connectionLost",
          },
          {
            connectionStatus: overallConnectionStatus,
            timeUntilReconnection: timeUntilReconnection / 1000,
          },
        )}
        closable={true}
        afterClose={() => {
          setIsDismissed(true);
        }}
        action={
          overallConnectionStatus === "disconnected" && (
            <Button
              size="small"
              type="text"
              onClick={() => {
                setTimeUntilReconnection(0);
              }}
            >
              <u>{formatMessage({ id: "retryNow" })}</u>
            </Button>
          )
        }
      />
    );
  }

  return null;
};
