import { produce } from "immer";
import { get as _get, update as _update } from "lodash-es";
import { areHierarchiesEqual } from "@activeviam/data-model";
import { getLevelExpression } from "../addLevel.js";
import { createHierarchyCompoundIdentifier } from "../createHierarchyCompoundIdentifier.js";
import { createMemberOrMeasureCompoundIdentifier } from "../createMemberOrMeasureCompoundIdentifier.js";
import { getHierarchies } from "../getHierarchies.js";
import { isMdxCompoundIdentifier } from "../isMdxCompoundIdentifier.js";
import { isMdxFunction } from "../isMdxFunction.js";
import { getNodesToExpand } from "./_expandTupleOntoDeeperLevelOfTheSameHierarchy.js";
import { _findCompoundIdentifiers } from "./_findCompoundIdentifiers.js";
import { _findCrossjoins } from "./_findCrossjoins.js";
/**
 * Returns the "DefaultMember" functions present in the crossjoin below the one representing `toLevel`.
 * This can happen in case of 2 recursive "expand by" and then a new "expand by" at the same level of the first one.
 * For example:
 *  - expand "USD" by Cities
 *  - expand "USD / New York" by Desks
 *  - expand "EUR" by Cities
 * In this case, there will be a "DefaultMember" for Desks in the first Crossjoin in the Union, below the DefaultMember for Cities.
 */ const getDefaultMembersBelowHierarchyExpandedOnto = (union, toLevel, cube)=>{
    const firstCrossjoin = union.arguments[0];
    if (!isMdxFunction(firstCrossjoin, "crossjoin")) {
        // Should never happen.
        return [];
    }
    const defaultMembers = firstCrossjoin.arguments.filter((argument)=>isMdxFunction(argument, "defaultmember"));
    const defaultMembersBelowExpansion = [];
    let didFindHierarchyExpandedOnto = false;
    defaultMembers.forEach((defaultMember)=>{
        if (!isMdxFunction(defaultMember, "defaultmember") || defaultMember.arguments.length !== 1) {
            return;
        }
        const compoundIdentifier = defaultMember.arguments[0];
        if (!isMdxCompoundIdentifier(compoundIdentifier)) {
            return;
        }
        if (didFindHierarchyExpandedOnto) {
            defaultMembersBelowExpansion.push(defaultMember);
        }
        const [hierarchyCoordinates] = getHierarchies(compoundIdentifier, {
            cube
        });
        if (areHierarchiesEqual(hierarchyCoordinates, toLevel)) {
            didFindHierarchyExpandedOnto = true;
        }
    });
    return defaultMembersBelowExpansion;
};
/**
 * Returns the already existing Union node representing the expansion, if it exists.
 */ const getAlreadyExpandedNode = (mdx, { tupleCoordinates , cube  })=>{
    // The already expanded node is a Union, containing a Crossjoin
    // whose dimensionality is the same as `tupleCoordinates`.
    const crossjoins = _findCrossjoins(mdx, {
        hierarchyCoordinates: tupleCoordinates,
        cube
    });
    if (crossjoins.length === 0) {
        return;
    }
    const { path  } = crossjoins[0];
    // Forced to use `get` because the path is dynamic.
    // eslint-disable-next-line atoti-ui/no-lodash-get
    const parent = _get(mdx, path.slice(0, -2));
    if (isMdxFunction(parent, "union")) {
        return parent;
    }
    return;
};
/**
 * Returns a node representing the defaultMember of the hierarchy of `toLevel`.
 */ const createDefaultMemberNode = (hierarchyCoordinates)=>({
        arguments: [
            createHierarchyCompoundIdentifier(hierarchyCoordinates)
        ],
        elementType: "Function",
        name: "DefaultMember",
        syntax: "Property"
    });
/**
 * Returns the Crossjoin containing `tupleCoordinates` along with the members
 * of `toLevel`, which should be used as a 2nd argument of the Union function
 * representing the expansion.
 */ const createCrossjoinWithMembersOfChosenLevel = ({ cube , defaultMembersBelowHierarchyExpandedOnto , toLevel , tupleCoordinates , doesIncludeCalculatedMembers  })=>({
        arguments: [
            ...tupleCoordinates.map((memberCoordinates)=>createMemberOrMeasureCompoundIdentifier(memberCoordinates, cube)),
            getLevelExpression(toLevel, cube, doesIncludeCalculatedMembers),
            ...defaultMembersBelowHierarchyExpandedOnto
        ],
        elementType: "Function",
        name: "Crossjoin",
        syntax: "Function"
    });
/**
 * Expands `tupleCoordinates` down onto `toLevel`.
 * Assumes that `toLevel` belongs to a different hierarchy than the last member in `tupleCoordinates` does.
 * Does not mutate `mdx`.
 */ export const _expandTupleOntoDifferentHierarchy = (mdx, { cube , tupleCoordinates , toLevel , doesIncludeCalculatedMembers  })=>{
    // - find the expressions with the same dimensionality as `tupleCoordinates`.
    // - surround them in an `Union` function.
    // - add as 2nd argument of the `Union` the descendants of `tupleCoordinates` onto `toLevel`.
    return produce(mdx, (draft)=>{
        const union = getAlreadyExpandedNode(draft, {
            tupleCoordinates,
            cube
        });
        if (!union) {
            // Expanding the first tuple, when none is currently expanded.
            const nodesToExpand = getNodesToExpand(draft, {
                hierarchyCoordinates: tupleCoordinates,
                cube
            });
            nodesToExpand.forEach(({ match , path , isAlreadyHierarchized  })=>{
                if (!isMdxFunction(match, "crossjoin")) {
                    // Surround the node to expand in a crossjoin
                    _update(draft, path, (node)=>({
                            arguments: [
                                node
                            ],
                            elementType: "Function",
                            name: "Crossjoin",
                            syntax: "Function"
                        }));
                }
                // add the defaultMember of the hierarchy of `toLevel` into the crossjoin.
                // Forced to use `get` because the path is dynamic.
                // eslint-disable-next-line atoti-ui/no-lodash-get
                const crossjoin = _get(draft, path);
                crossjoin.arguments.push(createDefaultMemberNode(toLevel));
                // Surround the crossjoin in a union
                _update(draft, path, (node)=>({
                        arguments: [
                            node
                        ],
                        elementType: "Function",
                        name: "Union",
                        syntax: "Function"
                    }));
                // Add the crossjoin with the members of toLevel as a second argument of the Union.
                // Forced to use `get` because the path is dynamic.
                // eslint-disable-next-line atoti-ui/no-lodash-get
                const union = _get(draft, path);
                union.arguments.push(createCrossjoinWithMembersOfChosenLevel({
                    cube,
                    defaultMembersBelowHierarchyExpandedOnto: [],
                    toLevel,
                    tupleCoordinates,
                    doesIncludeCalculatedMembers
                }));
                if (!isAlreadyHierarchized) {
                    _update(draft, path, (_node)=>({
                            arguments: [
                                _node
                            ],
                            elementType: "Function",
                            name: "Hierarchize",
                            syntax: "Function"
                        }));
                }
            });
        } else {
            const defaultMembersBelowHierarchyExpandedOnto = getDefaultMembersBelowHierarchyExpandedOnto(union, toLevel, cube);
            // Add the DefaultMember of the hierarchy expanded onto into existing Crossjoins if they don't yet contain this hierarchy.
            union.arguments.forEach((crossjoin)=>{
                if (!isMdxFunction(crossjoin, "crossjoin")) {
                    // Should never happen.
                    return;
                }
                const compoundIdentifiers = _findCompoundIdentifiers(crossjoin, {
                    cube
                });
                const doesAlreadyContainHierarchyExpandedOnto = compoundIdentifiers.some(({ match  })=>{
                    const [hierarchyCoordinates] = getHierarchies(match, {
                        cube
                    });
                    return areHierarchiesEqual(hierarchyCoordinates, toLevel);
                });
                if (!doesAlreadyContainHierarchyExpandedOnto) {
                    crossjoin.arguments.push(createDefaultMemberNode(toLevel));
                }
            });
            // Expanding a nth tuple, when some are already expanded.
            union.arguments.push(createCrossjoinWithMembersOfChosenLevel({
                cube,
                defaultMembersBelowHierarchyExpandedOnto,
                toLevel,
                tupleCoordinates,
                doesIncludeCalculatedMembers
            }));
        }
    });
};
