import { Button, Result } from "antd";
import { FC } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

import { useConfiguration } from "../ConfigurationContext.js";

interface ContentNotFoundProps {
  message?: string;
}

/**
 * Displays a placeholder message indicating that the requested content was not found.
 */
export const ContentNotFound: FC<ContentNotFoundProps> = ({ message }) => {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const configuration = useConfiguration();
  const handleClicked = () => {
    navigate(configuration.pathToHome);
  };

  return (
    <div
      style={{
        display: "flex",
        height: "100%",
        width: "100%",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Result
        status="info"
        subTitle={
          message !== undefined
            ? message
            : formatMessage({ id: "error.urlNotFound" })
        }
        extra={
          <Button onClick={handleClicked} type="primary">
            {formatMessage({ id: "goToHomePage" })}
          </Button>
        }
      />
    </div>
  );
};
