export const separator = "].[";
export const characterToEscape = "]";
// In MDX, the character to escape is escaped by duplicating it.
export const escapedCharacter = "]]";
const characterToEscapeRegExp = new RegExp(characterToEscape, "g");
/**
 * Converts an array of strings into the corresponding stringified Mdx compound identifier.
 *
 * @example
 * ```tsx
 * quote('a', 'b') // === '[a].[b]'
 * ```
 */ export function quote(...path) {
    return `[${path.map((s)=>s.replace(characterToEscapeRegExp, escapedCharacter)).join(separator)}]`;
}
