/* generated by jison-lex 0.3.4 */
var lexer = (function(){
var lexer = ({

EOF:1,

parseError:function parseError(str, hash) {
        if (this.yy.parser) {
            this.yy.parser.parseError(str, hash);
        } else {
            throw new Error(str);
        }
    },

// resets the lexer, sets new input
setInput:function (input, yy) {
        this.yy = yy || this.yy || {};
        this._input = input;
        this._more = this._backtrack = this.done = false;
        this.yylineno = this.yyleng = 0;
        this.yytext = this.matched = this.match = '';
        this.conditionStack = ['INITIAL'];
        this.yylloc = {
            first_line: 1,
            first_column: 0,
            last_line: 1,
            last_column: 0
        };
        if (this.options.ranges) {
            this.yylloc.range = [0,0];
        }
        this.offset = 0;
        return this;
    },

// consumes and returns one char from the input
input:function () {
        var ch = this._input[0];
        this.yytext += ch;
        this.yyleng++;
        this.offset++;
        this.match += ch;
        this.matched += ch;
        var lines = ch.match(/(?:\r\n?|\n).*/g);
        if (lines) {
            this.yylineno++;
            this.yylloc.last_line++;
        } else {
            this.yylloc.last_column++;
        }
        if (this.options.ranges) {
            this.yylloc.range[1]++;
        }

        this._input = this._input.slice(1);
        return ch;
    },

// unshifts one char (or a string) into the input
unput:function (ch) {
        var len = ch.length;
        var lines = ch.split(/(?:\r\n?|\n)/g);

        this._input = ch + this._input;
        this.yytext = this.yytext.substr(0, this.yytext.length - len);
        //this.yyleng -= len;
        this.offset -= len;
        var oldLines = this.match.split(/(?:\r\n?|\n)/g);
        this.match = this.match.substr(0, this.match.length - 1);
        this.matched = this.matched.substr(0, this.matched.length - 1);

        if (lines.length - 1) {
            this.yylineno -= lines.length - 1;
        }
        var r = this.yylloc.range;

        this.yylloc = {
            first_line: this.yylloc.first_line,
            last_line: this.yylineno + 1,
            first_column: this.yylloc.first_column,
            last_column: lines ?
                (lines.length === oldLines.length ? this.yylloc.first_column : 0)
                 + oldLines[oldLines.length - lines.length].length - lines[0].length :
              this.yylloc.first_column - len
        };

        if (this.options.ranges) {
            this.yylloc.range = [r[0], r[0] + this.yyleng - len];
        }
        this.yyleng = this.yytext.length;
        return this;
    },

// When called from action, caches matched text and appends it on next action
more:function () {
        this._more = true;
        return this;
    },

// When called from action, signals the lexer that this rule fails to match the input, so the next matching rule (regex) should be tested instead.
reject:function () {
        if (this.options.backtrack_lexer) {
            this._backtrack = true;
        } else {
            return this.parseError('Lexical error on line ' + (this.yylineno + 1) + '. You can only invoke reject() in the lexer when the lexer is of the backtracking persuasion (options.backtrack_lexer = true).\n' + this.showPosition(), {
                text: "",
                token: null,
                line: this.yylineno
            });

        }
        return this;
    },

// retain first n characters of the match
less:function (n) {
        this.unput(this.match.slice(n));
    },

// displays already matched input, i.e. for error messages
pastInput:function () {
        var past = this.matched.substr(0, this.matched.length - this.match.length);
        return (past.length > 20 ? '...':'') + past.substr(-20).replace(/\n/g, "");
    },

// displays upcoming input, i.e. for error messages
upcomingInput:function () {
        var next = this.match;
        if (next.length < 20) {
            next += this._input.substr(0, 20-next.length);
        }
        return (next.substr(0,20) + (next.length > 20 ? '...' : '')).replace(/\n/g, "");
    },

// displays the character position where the lexing error occurred, i.e. for error messages
showPosition:function () {
        var pre = this.pastInput();
        var c = new Array(pre.length + 1).join("-");
        return pre + this.upcomingInput() + "\n" + c + "^";
    },

// test the lexed token: return FALSE when not a match, otherwise return token
test_match:function(match, indexed_rule) {
        var token,
            lines,
            backup;

        if (this.options.backtrack_lexer) {
            // save context
            backup = {
                yylineno: this.yylineno,
                yylloc: {
                    first_line: this.yylloc.first_line,
                    last_line: this.last_line,
                    first_column: this.yylloc.first_column,
                    last_column: this.yylloc.last_column
                },
                yytext: this.yytext,
                match: this.match,
                matches: this.matches,
                matched: this.matched,
                yyleng: this.yyleng,
                offset: this.offset,
                _more: this._more,
                _input: this._input,
                yy: this.yy,
                conditionStack: this.conditionStack.slice(0),
                done: this.done
            };
            if (this.options.ranges) {
                backup.yylloc.range = this.yylloc.range.slice(0);
            }
        }

        lines = match[0].match(/(?:\r\n?|\n).*/g);
        if (lines) {
            this.yylineno += lines.length;
        }
        this.yylloc = {
            first_line: this.yylloc.last_line,
            last_line: this.yylineno + 1,
            first_column: this.yylloc.last_column,
            last_column: lines ?
                         lines[lines.length - 1].length - lines[lines.length - 1].match(/\r?\n?/)[0].length :
                         this.yylloc.last_column + match[0].length
        };
        this.yytext += match[0];
        this.match += match[0];
        this.matches = match;
        this.yyleng = this.yytext.length;
        if (this.options.ranges) {
            this.yylloc.range = [this.offset, this.offset += this.yyleng];
        }
        this._more = false;
        this._backtrack = false;
        this._input = this._input.slice(match[0].length);
        this.matched += match[0];
        token = this.performAction.call(this, this.yy, this, indexed_rule, this.conditionStack[this.conditionStack.length - 1]);
        if (this.done && this._input) {
            this.done = false;
        }
        if (token) {
            return token;
        } else if (this._backtrack) {
            // recover context
            for (var k in backup) {
                this[k] = backup[k];
            }
            return false; // rule action called reject() implying the next rule should be tested instead.
        }
        return false;
    },

// return next match in input
next:function () {
        if (this.done) {
            return this.EOF;
        }
        if (!this._input) {
            this.done = true;
        }

        var token,
            match,
            tempMatch,
            index;
        if (!this._more) {
            this.yytext = '';
            this.match = '';
        }
        var rules = this._currentRules();
        for (var i = 0; i < rules.length; i++) {
            tempMatch = this._input.match(this.rules[rules[i]]);
            if (tempMatch && (!match || tempMatch[0].length > match[0].length)) {
                match = tempMatch;
                index = i;
                if (this.options.backtrack_lexer) {
                    token = this.test_match(tempMatch, rules[i]);
                    if (token !== false) {
                        return token;
                    } else if (this._backtrack) {
                        match = false;
                        continue; // rule action called reject() implying a rule MISmatch.
                    } else {
                        // else: this is a lexer rule which consumes input without producing a token (e.g. whitespace)
                        return false;
                    }
                } else if (!this.options.flex) {
                    break;
                }
            }
        }
        if (match) {
            token = this.test_match(match, rules[index]);
            if (token !== false) {
                return token;
            }
            // else: this is a lexer rule which consumes input without producing a token (e.g. whitespace)
            return false;
        }
        if (this._input === "") {
            return this.EOF;
        } else {
            return this.parseError('Lexical error on line ' + (this.yylineno + 1) + '. Unrecognized text.\n' + this.showPosition(), {
                text: "",
                token: null,
                line: this.yylineno
            });
        }
    },

// return next match that has a token
lex:function lex () {
        var r = this.next();
        if (r) {
            return r;
        } else {
            return this.lex();
        }
    },

// activates a new lexer condition state (pushes the new lexer condition state onto the condition stack)
begin:function begin (condition) {
        this.conditionStack.push(condition);
    },

// pop the previously active lexer condition state off the condition stack
popState:function popState () {
        var n = this.conditionStack.length - 1;
        if (n > 0) {
            return this.conditionStack.pop();
        } else {
            return this.conditionStack[0];
        }
    },

// produce the lexer rule set which is active for the currently active lexer condition state
_currentRules:function _currentRules () {
        if (this.conditionStack.length && this.conditionStack[this.conditionStack.length - 1]) {
            return this.conditions[this.conditionStack[this.conditionStack.length - 1]].rules;
        } else {
            return this.conditions["INITIAL"].rules;
        }
    },

// return the currently active lexer condition state; when an index argument is provided it produces the N-th previous condition state, if available
topState:function topState (n) {
        n = this.conditionStack.length - 1 - Math.abs(n || 0);
        if (n >= 0) {
            return this.conditionStack[n];
        } else {
            return "INITIAL";
        }
    },

// alias for begin(condition)
pushState:function pushState (condition) {
        this.begin(condition);
    },

// return the number of states currently on the stack
stateStackSize:function stateStackSize() {
        return this.conditionStack.length;
    },
options: {"case-insensitive":true},
performAction: function anonymous(yy,yy_,$avoiding_name_collisions,YY_START) {
var YYSTATE=YY_START;
switch($avoiding_name_collisions) {
case 0:this.popState(); return 'sub_STRING'
break;
case 1:this.popState(); return 'sub_SIMPLEQUOTEDSTRING'
break;
case 2:this.popState(); return 'sub_QUOTED_ID'
break;
case 3:this.popState(); return 'sub_NUMBER';
break;
case 4:this.popState(); return 'sub_DECIMAL';
break;
case 5:return 'AXIS'
break;
case 6:return 'AND'
break;
case 7:return 'AS'
break;
case 8:return 'CASE'
break;
case 9:return 'CELL'
break;
case 10:return 'CHAPTERS'
break;
case 11:return 'COLUMNS'
break;
case 12:return 'DIMENSION'
break;
case 13:return 'DRILLTHROUGH'
break;
case 14:return 'ELSE'
break;
case 15:return 'END'
break;
case 16:return 'EXISTING'
break;
case 17:return 'FALSE'
break;
case 18:return 'FIRSTROW'
break;
case 19:return 'FROM'
break;
case 20:return 'IS'
break;
case 21:return 'MAXROWS'
break;
case 22:return 'MEMBER'
break;
case 23:return 'NON_EMPTY'
break;
case 24:return 'NON_VISUAL'
break;
case 25:return 'NOT'
break;
case 26:return 'NULL'
break;
case 27:return 'ON'
break;
case 28:return 'OR'
break;
case 29:return 'PAGES'
break;
case 30:return 'PROPERTIES'
break;
case 31:return 'RETURN'
break;
case 32:return 'ROWS'
break;
case 33:return 'SECTIONS'
break;
case 34:return 'SELECT'
break;
case 35:return 'SET'
break;
case 36:return 'THEN'
break;
case 37:return 'TRUE'
break;
case 38:return 'XOR'
break;
case 39:return 'WHEN'
break;
case 40:return 'WHERE'
break;
case 41:return 'WITH'
break;
case 42:return 'EOF'
break;
case 43:// COMMENT:   '/*' (options {greedy=false;} : .)*   '*/' {$channel=HIDDEN;}
break;
case 44:// LINE_COMMENT   : ('//'|'--') ~('\n'|'\r')* '\r'? ('\n'|EOF) {$channel=HIDDEN;}
break;
case 45:return 'ID'  //ID		:  ('a'..'z'|'A'..'Z'|'_'|'$') ('a'..'z'|'A'..'Z'|'_'|'0'..'9'|'$')*
break;
case 46:return 'DBL_QUOTE'
break;
case 47:return 'AMP'
break;
case 48:return 'DIGIT'  //NUMBER  : ('0'..'9')+
break;
case 49:return 'DECIMAL'
break;
case 50:return 'DOT'
break;
case 51:// WS : (' ' | '\t' | '\r' | '\f' | '\n' | '\u000B')+ {$channel=HIDDEN;}
break;
case 52:return 'QUOTE'
break;
case 53:return 'ASTERISK'
break;
case 54:return 'COLON'
break;
case 55:return 'SEMICOLON'
break;
case 56:return 'COMMA'
break;
case 57:return 'POW'
break;
case 58:return 'EQ'
break;
case 59:return 'NE'
break;
case 60:return 'GE'
break;
case 61:return 'GT'
break;
case 62:return 'LBRACE'
break;
case 63:return 'LE'
break;
case 64:return 'LPAREN'
break;
case 65:return 'LT'
break;
case 66:return 'MINUS'
break;
case 67:return 'PLUS'
break;
case 68:return 'RBRACE'
break;
case 69:return 'RPAREN'
break;
case 70:return 'SOLIDUS'
break;
case 71:return 'OBRACKET'
break;
case 72:return 'CBRACKET'
break;
case 73:return 'INVALID'
break;
case 74:start_statement
break;
}
},
rules: [/^(?:[^"]*)/i,/^(?:[^']*)/i,/^(?:([\]]{2}|[^\]]+)+)/i,/^(?:[0-9]*(\.[0-9]*)?(E(\+|-)?[0-9]+)?)/i,/^(?:[0-9]*(E(\+|-)?[0-9]+)?)/i,/^(?:AXIS\b)/i,/^(?:AND\b)/i,/^(?:AS\b)/i,/^(?:CASE\b)/i,/^(?:CELL\b)/i,/^(?:CHAPTERS\b)/i,/^(?:COLUMNS\b)/i,/^(?:DIMENSION\b)/i,/^(?:DRILLTHROUGH\b)/i,/^(?:ELSE\b)/i,/^(?:END\b)/i,/^(?:EXISTING\b)/i,/^(?:FALSE\b)/i,/^(?:FIRSTROW\b)/i,/^(?:FROM\b)/i,/^(?:IS\b)/i,/^(?:MAXROWS\b)/i,/^(?:MEMBER\b)/i,/^(?:NON EMPTY\b)/i,/^(?:NON VISUAL\b)/i,/^(?:NOT\b)/i,/^(?:NULL\b)/i,/^(?:ON\b)/i,/^(?:OR\b)/i,/^(?:PAGES\b)/i,/^(?:PROPERTIES\b)/i,/^(?:RETURN\b)/i,/^(?:ROWS\b)/i,/^(?:SECTIONS\b)/i,/^(?:SELECT\b)/i,/^(?:SET\b)/i,/^(?:THEN\b)/i,/^(?:TRUE\b)/i,/^(?:XOR\b)/i,/^(?:WHEN\b)/i,/^(?:WHERE\b)/i,/^(?:WITH\b)/i,/^(?:$)/i,/^(?:\/\*[^\*\/]*\*\/)/i,/^(?:([/]{2}|[-]{2}).*)/i,/^(?:[a-zA-Z_\$][a-zA-Z_\$0-9]*)/i,/^(?:["])/i,/^(?:&)/i,/^(?:[0-9])/i,/^(?:\.[0-9])/i,/^(?:\.)/i,/^(?:\s+)/i,/^(?:')/i,/^(?:\*)/i,/^(?::)/i,/^(?:;)/i,/^(?:,)/i,/^(?:\^)/i,/^(?:=)/i,/^(?:<>)/i,/^(?:>=)/i,/^(?:>)/i,/^(?:\{)/i,/^(?:<=)/i,/^(?:\()/i,/^(?:<)/i,/^(?:-)/i,/^(?:\+)/i,/^(?:\})/i,/^(?:\))/i,/^(?:\/)/i,/^(?:\[)/i,/^(?:\])/i,/^(?:.)/i,/^(?:(?=lex)start\b)/i],
conditions: {"quotedid":{"rules":[2],"inclusive":false},"simplequotedstring":{"rules":[1],"inclusive":false},"string":{"rules":[0],"inclusive":false},"decimal":{"rules":[4],"inclusive":false},"number":{"rules":[3],"inclusive":false},"INITIAL":{"rules":[5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74],"inclusive":true}}
});
return lexer;
})();

export default lexer;