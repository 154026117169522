import { flatMap as _flatMap } from "lodash-es";
import { forEachExpandedTuple } from "./forEachExpandedTuple.js";
import { _isMdxSelect } from "./internal/_isMdxSelect.js";
/**
 * Returns the expanded tuples in `mdx`
 */ export function getExpandedTuples(mdx, cube) {
    if (_isMdxSelect(mdx)) {
        return _flatMap(mdx.axes, (axis)=>getExpandedTuples(axis, cube));
    }
    const accumulator = [];
    forEachExpandedTuple(mdx, cube, ({ tupleCoordinates , toLevel  })=>{
        accumulator.push({
            tupleCoordinates,
            toLevel
        });
    });
    return accumulator;
}
