import { areHierarchiesEqual, areLevelsEqual } from "@activeviam/data-model";
import { getSpecificCompoundIdentifier } from "../getSpecificCompoundIdentifier.js";
import { _isSameMember } from "./_isSameMember.js";
/** Returns whether `compoundIdentifier1` and `compoundIdentifier2` represent the same Mdx compound. */ export function _areCompoundIdentifiersEqual(compoundIdentifier1, compoundIdentifier2, cube) {
    const [specificCompoundIdentifier1, specificCompoundIdentifier2] = [
        compoundIdentifier1,
        compoundIdentifier2
    ].map((compoundIdentifier)=>getSpecificCompoundIdentifier(compoundIdentifier, {
            cube
        }));
    if (specificCompoundIdentifier1.type !== specificCompoundIdentifier2.type) {
        return false;
    }
    switch(specificCompoundIdentifier1.type){
        case "namedSet":
            return specificCompoundIdentifier1.setName === // @ts-expect-error TypeScript fails to recognize that at this stage `specificCompoundIdentifier2` has the same type as `specificCompoundIdentifier1`.
            specificCompoundIdentifier2.setName;
        case "dimension":
            return specificCompoundIdentifier1.dimensionName === // @ts-expect-error TypeScript fails to recognize that at this stage `specificCompoundIdentifier2` has the same type as `specificCompoundIdentifier1`.
            specificCompoundIdentifier2.dimensionName;
        case "hierarchy":
            return areHierarchiesEqual(specificCompoundIdentifier1, // @ts-expect-error TypeScript fails to recognize that at this stage `specificCompoundIdentifier2` has the same type as `specificCompoundIdentifier1`.
            specificCompoundIdentifier2);
        case "level":
            return areLevelsEqual(specificCompoundIdentifier1, // @ts-expect-error TypeScript fails to recognize that at this stage `specificCompoundIdentifier2` has the same type as `specificCompoundIdentifier1`.
            specificCompoundIdentifier2);
        case "measure":
        case "member":
            return _isSameMember(specificCompoundIdentifier1, specificCompoundIdentifier2, cube);
    }
}
