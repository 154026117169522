import { css } from "@emotion/react";
import { Menu, theme as antdTheme } from "antd";
import {
  isEqual as _isEqual,
  last as _last,
  unionWith as _unionWith,
} from "lodash-es";
import { FC, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  FileListMenuItemProps,
  getContentNodes,
  getContentRecordAtPath,
  getExpandedFolderPathsInHomePage,
  sortTree,
  useContentClient,
  useMenuItemCreateFolder,
  useMenuItemMoveFilesAndFolders,
  useMenuItemRenameFileOrFolder,
  useMenuItemShareFileOrFolder,
  useTree,
} from "@activeviam/activeui-sdk";
import {
  useMenuItemDeleteFolder,
  useOnAfterSubmit,
} from "@activeviam/file-list";
import { Dropdown } from "@activeviam/utils-react";

import { getDashboardFoldersTreeNodes } from "./getDashboardFoldersTreeNodes.js";

const { useToken } = antdTheme;

interface ContextMenuProps {
  contextMenuPath?: string[];
  onPathToSelectedFolderChanged: (pathToSelectedFolder: string[]) => void;
  onPathToEditedFolderChanged: (path: string[] | undefined) => void;
}

function useContextMenuItems(props: ContextMenuProps) {
  const { contextMenuPath, onPathToEditedFolderChanged } = props;

  const dashboardsTree = useTree("dashboard");
  const contentClient = useContentClient();

  const id = _last(contextMenuPath);
  const folder = getContentRecordAtPath({
    tree: dashboardsTree,
    path: contextMenuPath,
  });
  const pathToParentFolder = contextMenuPath?.slice(0, -1) ?? [];

  const onAfterSubmit = useOnAfterSubmit({
    pathToParentFolder,
  });

  const menuItemProps: FileListMenuItemProps = {
    contentType: "dashboard",
    contentTree: dashboardsTree ?? undefined,
    pathToParentFolder,
    selectedFilesAndFolders:
      id && folder
        ? [
            {
              id,
              record: folder,
            },
          ]
        : [],
    onEditStarted: () => onPathToEditedFolderChanged(contextMenuPath),
    onAfterSubmit: async () => {
      await contentClient.loadTree("dashboard");
    },
  };

  const menuItemCreateFolder = useMenuItemCreateFolder(menuItemProps);
  const menuItemRenameFileOrFolder = useMenuItemRenameFileOrFolder(
    menuItemProps,
  );
  const menuItemShareFileOrFolder = useMenuItemShareFileOrFolder(menuItemProps);
  const menuItemMoveFilesAndFolders = useMenuItemMoveFilesAndFolders({
    ...menuItemProps,
    onAfterSubmit,
  });
  const menuItemDeleteFolder = useMenuItemDeleteFolder({
    ...menuItemProps,
    onAfterSubmit,
  });

  return [
    menuItemCreateFolder,
    menuItemRenameFileOrFolder,
    menuItemShareFileOrFolder,
    menuItemMoveFilesAndFolders,
    menuItemDeleteFolder,
  ];
}

interface DashboardsTreeProps {
  pathToSelectedFolder?: string[];
  onPathToSelectedFolderChanged: (pathToSelectedFolder: string[]) => void;
}

const defaultPathToSelectedFolder: string[] = [];

/**
 * Displays the tree of dashboard folders, on the left of the homepage.
 */
export const DashboardFoldersTree: FC<DashboardsTreeProps> = ({
  pathToSelectedFolder = defaultPathToSelectedFolder,
  onPathToSelectedFolderChanged,
}) => {
  const { token: antdThemeTokens } = useToken();
  const dispatch = useDispatch();
  const contentClient = useContentClient();
  const expandedPaths = useSelector(getExpandedFolderPathsInHomePage);
  const [isDropdownMenuOpen, setIsDropdownMenuOpen] = useState(false);
  const [contextMenuPath, setContextMenuPath] = useState<string[]>();
  const [pathToEditedFolder, setPathToEditedFolder] = useState<string[]>();
  const dashboardsTree = useTree("dashboard");

  useEffect(() => {
    dispatch({
      type: "expandedFolderPathsInHomePageUpdated",
      expandedFolderPathsInHomePage: [
        ...expandedPaths,
        ...pathToSelectedFolder
          .slice(0, -1)
          .reduce<string[][]>((acc, folderKey) => {
            const parentFolderPath = _last(acc);
            if (parentFolderPath) {
              return [...acc, [...parentFolderPath, folderKey]];
            }
            return [[folderKey]];
          }, []),
      ],
    });
    // Adding `expandedPaths` would cause this hook to unnecessarily rerun since this hook updates `expandedPaths`.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathToSelectedFolder, dispatch]);

  const items = useMemo(() => {
    if (!dashboardsTree) {
      return null;
    }

    const handleEditionEnded = async (
      pathToParentFolder: string[],
      caption: string,
    ) => {
      await contentClient.updateFolder({
        metaData: { isFolder: true, name: caption },
        pathToParentFolder,
        type: "dashboard",
      });
      await contentClient.loadTree("dashboard");
      setPathToEditedFolder(undefined);
    };

    const folders = getContentNodes(dashboardsTree, {
      rootNodeMetaData: {
        isFolder: true,
        name: "dashboards",
      },
      areFilesVisible: false,
    });
    const sortedFolders = sortTree(folders, (nodeA, nodeB) => {
      return (nodeA.caption ?? "").toLowerCase() <
        (nodeB.caption ?? "").toLowerCase()
        ? -1
        : 1;
    });

    return getDashboardFoldersTreeNodes(sortedFolders, {
      pathToSelectedFolder,
      pathToEditedFolder,
      onSelect: onPathToSelectedFolderChanged,
      onContextMenu: setContextMenuPath,
      onEditionEnded: handleEditionEnded,
    });
  }, [
    contentClient,
    dashboardsTree,
    pathToEditedFolder,
    pathToSelectedFolder,
    onPathToSelectedFolderChanged,
  ]);

  const contextMenuItems = useContextMenuItems({
    contextMenuPath,
    onPathToSelectedFolderChanged,
    onPathToEditedFolderChanged: setPathToEditedFolder,
  });

  if (!items) {
    return null;
  }

  return (
    <Dropdown
      trigger={["contextMenu"]}
      open={isDropdownMenuOpen}
      onOpenChange={setIsDropdownMenuOpen}
      menu={{
        items: contextMenuItems,
        onClick: ({ domEvent }) => {
          // A menu item can decide to opt out from the default behavior by calling `event.domEvent.preventDefault()`.
          // The default behavior is to close the menu on a click event.
          if (!domEvent.isDefaultPrevented()) {
            setIsDropdownMenuOpen(false);
          }
        },
      }}
    >
      <Menu
        mode="inline"
        css={css`
          .ant-menu-item,
          .ant-menu-submenu-title {
            width: 100%;
            margin-left: 0;
            margin-block: 0;
            &:focus-visible {
              outline-offset: -2px !important;
              outline-color: ${antdThemeTokens.colorPrimary} !important;
            }
          }
          .ant-menu-submenu:not(.folder-selected, .ant-menu-submenu-active)
            > div {
            color: ${antdThemeTokens.colorText}!important;
          }
          .ant-menu-submenu > div:after {
            opacity: 0;
            content: "";
            position: absolute;
            inset-block: 0;
            inset-inline-end: 0;
            border-inline-end: ${antdThemeTokens.Menu?.colorActiveBarWidth}px
              ${antdThemeTokens.lineType} ${antdThemeTokens.colorPrimary};
            transform: scaleY(0.0001);
            transition: transform ${antdThemeTokens.motionDurationMid}
                ${// `motionEaseOut` controls the menu item right border animation function.
                antdThemeTokens.motionEaseOut},
              opacity ${antdThemeTokens.motionDurationMid}
                ${// `motionEaseOut` controls the menu item right border animation function.
                antdThemeTokens.motionEaseOut};
          }
          .folder-selected > div[role="menuitem"] {
            background-color: ${antdThemeTokens.colorPrimaryBg}!important;
            &:after {
              opacity: 1;
              transition: transform ${antdThemeTokens.motionDurationMid}
                  ${// `motionEaseInQuint` controls the menu item right border animation function.
                  antdThemeTokens.motionEaseInQuint},
                opacity ${antdThemeTokens.motionDurationMid}
                  ${// `motionEaseInQuint` controls the menu item right border animation function.
                  antdThemeTokens.motionEaseInQuint};
              transform: scaleY(1);
            }
          }
        `}
        selectedKeys={[pathToSelectedFolder.join("/")]}
        openKeys={expandedPaths.map((folderPath) => folderPath.join("/"))}
        onOpenChange={(openKeys) => {
          dispatch({
            type: "expandedFolderPathsInHomePageUpdated",
            expandedFolderPathsInHomePage: openKeys.map((openKey) =>
              openKey.split("/"),
            ),
          });
        }}
        onClick={(event) => {
          onPathToSelectedFolderChanged(event.key.split("/"));
        }}
        items={items}
      />
    </Dropdown>
  );
};
