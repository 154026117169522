import { AppstoreOutlined, UnorderedListOutlined } from "@ant-design/icons";
import { Fragment } from "react";

import { getPrefixedLocalStorageKey, useTheme } from "@activeviam/activeui-sdk";
import { FileListSkeleton } from "@activeviam/file-list";

import { DashboardFoldersSkeleton } from "./DashboardFoldersSkeleton.js";
import { DashboardsSkeleton } from "./DashboardsSkeleton.js";

/**
 * Displays gray rectangles for both the folders and dashboards while waiting for the home page to load.
 */
export const LoadingHomePageSkeleton = () => {
  const theme = useTheme();
  const layout = window.localStorage.getItem(
    getPrefixedLocalStorageKey("settings-homePageLayout"),
  );

  const cardStyle = {
    marginTop: 12,
    boxShadow: `0px 2px 8px 0px ${theme.black}1A`,
  };

  const titleStyle = {
    fontSize: 16,
    fontWeight: 500,
    color: theme.textColor,
  };

  const cardProps = {
    headStyle: { ...titleStyle, backgroundColor: theme.grayScale[1] },
    bordered: false,
    bodyStyle: { paddingTop: 0, paddingBottom: 0 },
  };

  return (
    <div
      style={{
        backgroundColor: theme.backgroundColor,
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          marginTop: 8,
          marginBottom: 8,
          height: 28,
          paddingRight: 24,
        }}
      >
        {layout === "list" ? (
          <Fragment>
            <div
              style={{
                marginRight: 16,
                width: 130,
                height: 24,
                backgroundColor: theme.grayScale[2],
                borderRadius: 2,
              }}
            />
            <div
              style={{
                marginRight: 16,
                width: 160,
                height: 24,
                backgroundColor: theme.selectionColor,
                borderRadius: 2,
              }}
            />
            <AppstoreOutlined style={{ fontSize: 18 }} />
          </Fragment>
        ) : (
          <UnorderedListOutlined style={{ fontSize: 18 }} />
        )}
      </div>
      <div
        style={{
          overflow: "hidden",
          height: "calc(100% - 44px)",
          width: "100%",
          backgroundColor: theme.grayScale[2],
          paddingLeft: 12,
          paddingRight: 12,
        }}
      >
        {layout === "list" ? (
          <FileListSkeleton />
        ) : (
          <Fragment>
            <DashboardFoldersSkeleton
              cardStyle={cardStyle}
              cardProps={cardProps}
            />
            <DashboardsSkeleton cardStyle={cardStyle} cardProps={cardProps} />
          </Fragment>
        )}
      </div>
    </div>
  );
};
