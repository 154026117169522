import { DesktopOutlined } from "@ant-design/icons";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import type { ApplicationMenuItem } from "@activeviam/activeui-sdk";
import {
  getDashboardState,
  useIsPresenting,
  useTheme,
} from "@activeviam/activeui-sdk";

import { useIsDashboardReadOnly } from "../../hooks/useIsDashboardReadOnly.js";

/**
 * {@link ApplicationMenuItem} allowing users to toggle whether they are presenting or editing a dashboard.
 */
export const toggleIsPresentingMenuItem: ApplicationMenuItem = {
  key: "toggle-is-presenting",
  useMenuItem: () => {
    const dashboard = useSelector(getDashboardState);
    const dispatch = useDispatch();
    const theme = useTheme();
    const { formatMessage } = useIntl();
    const isPresenting = useIsPresenting();
    const isDashboardReadOnly = useIsDashboardReadOnly();

    if (!dashboard || isDashboardReadOnly) {
      return undefined;
    }

    const handleIsPresentingToggled = () => {
      dispatch({ type: "isPresentingChanged", isPresenting: !isPresenting });
    };

    const color = isPresenting ? theme.headerActiveColor : undefined;

    return {
      icon: <DesktopOutlined style={{ color }} />,
      label: <span style={{ color }}>{formatMessage({ id: "present" })}</span>,
      onClick: handleIsPresentingToggled,
    };
  },
};
