import { _nullifyNodeAtPath } from "./_nullifyNodeAtPath.js";
/**
 * Nullify the function node at `path` in `mdx` if one or more of its mandatory arguments have been removed.
 * Returns whether the function node was nullified.
 * Mutates `mdx`.
 *
 * @example
 * Descendants(null, 3, SELF_AND_BEFORE) => null
 */ export function _nullifyIfMandatoryArgRemoved({ mdx , path , minNumberOfArgs , indexOfFirstRemovedArg  }) {
    if (indexOfFirstRemovedArg !== -1 && indexOfFirstRemovedArg < minNumberOfArgs) {
        _nullifyNodeAtPath(mdx, path);
        return true;
    }
    return false;
}
