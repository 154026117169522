import { addCalculatedMeasureExpressionToWithClause } from "./addCalculatedMeasureExpressionToWithClause.js";
import { createMeasureCompoundIdentifier } from "./createMeasureCompoundIdentifier.js";
import { smartFilter } from "./smartFilter.js";
const measureNameForMembersMatchingCaption = "MembersMatchingCaption";
/**
 * Returns the {@link MdxSelect} that searches the members that match `searchValue` in the given level of a virtual hierarchy.
 */ export function getMdxToFetchMembersOfVirtualHierarchyMatchingCaption({ dimensionName , hierarchyName , levelName , searchValue , cube , filters , isSmartFilteringEnabled , hierarchiesToIgnoreInSmartFilteringContext  }) {
    /**
   * The filtering is done through a calculated measure evaluated on the grand total, that returns the list of members whose caption matches the search value.
   * It uses the dedicated VIRTUAL-MEMBER-SEARCH post-processor through an ActiveMeasure.
   *
   * @see
   * - ActiveMeasure documentation: https://support.activeviam.com/confluence/display/AP5/Active+Measures+and+MDX+compilation
   * - VIRTUAL-MEMBER-SEARCH post-processor code: https://github.com/activeviam/activepivot/blob/6.0/pivot/core/ext/src/main/java/com/quartetfs/biz/pivot/postprocessing/impl/VirtualHierarchySearchPostProcessor.java
   */ const measureExpressionForMembersMatchingCaption = {
        elementType: "Function",
        name: "ActiveMeasure",
        syntax: "Function",
        arguments: [
            "VIRTUAL-MEMBER-SEARCH",
            "pattern",
            searchValue,
            "filteredLevel",
            `${levelName}@${hierarchyName}@${dimensionName}`
        ].map((literalValue)=>({
                elementType: "Literal",
                type: "STRING",
                value: literalValue
            }))
    };
    const mdxSelect = {
        axes: [
            {
                elementType: "Axis",
                name: "ROWS",
                nonEmpty: false,
                properties: [],
                expression: createMeasureCompoundIdentifier(measureNameForMembersMatchingCaption)
            }
        ],
        elementType: "Select",
        from: {
            cubeName: cube.name,
            elementType: "From"
        },
        withClause: []
    };
    const mdxWithCalculatedMeasureExpression = addCalculatedMeasureExpressionToWithClause(mdxSelect, {
        calculatedMeasureName: measureNameForMembersMatchingCaption,
        expression: measureExpressionForMembersMatchingCaption
    });
    // Notice that `nonEmptyEvaluationMeasureName` is not passed.
    // Indeed, it is not supported for virtual hierarchies.
    return smartFilter(mdxWithCalculatedMeasureExpression, {
        cube,
        targetHierarchyCoordinates: {
            dimensionName,
            hierarchyName
        },
        filters,
        isSmartFilteringEnabled,
        ignoredHierarchies: hierarchiesToIgnoreInSmartFilteringContext
    });
}
