import { getHierarchy } from "@activeviam/data-model";
import { axisIds, getAxisLength } from "./index.js";
/**
 * Returns the {@link CellSetMetaData} corresponding to `data`.
 * In particular, allows to know which measures and levels are numeric, based on the information present in `data`.
 */ export function getCellSetMetaData(data, cube) {
    const levels = [];
    const measureTypes = {};
    /**
   * The axis of `data` containing measures.
   * Usually the columns axis.
   */ let measuresAxis = undefined;
    /**
   * The index of the measures among the other hierarchies on their axis.
   */ let measuresIndexOnAxis = 0;
    for (const axis of data.axes){
        /**
     * The coordinates of all levels contained on the axis.
     */ const axisLevels = [];
        const hierarchies = Object.values(axis.hierarchies);
        for(let hierarchyIndex = 0; hierarchyIndex < hierarchies.length; hierarchyIndex++){
            const { dimension: dimensionName , hierarchy: hierarchyName  } = hierarchies[hierarchyIndex];
            if (dimensionName === "Measures") {
                measuresAxis = axis;
                measuresIndexOnAxis = hierarchyIndex;
            } else {
                const hierarchy = getHierarchy({
                    dimensionName,
                    hierarchyName
                }, cube);
                const levels = Object.values(hierarchy.levels)// The "ALL" level of a non-slicing hierarchy represents its grand total.
                // It is not included in the metaData returned by getCellSetMetaData.
                // Indeed, its only member is "ALL" (captioned "Total" in Atoti UI).
                // It is never numeric.
                .slice(hierarchy.slicing ? 0 : 1, axis.maxLevelPerHierarchy[hierarchyIndex]).map(({ name , index  })=>({
                        dimensionName,
                        hierarchyName,
                        levelName: name,
                        hierarchyIndex,
                        levelIndex: index
                    }));
                axisLevels.push(...levels);
            }
        }
        for (const { hierarchyIndex , levelIndex , ...levelCoordinates } of axisLevels){
            const isNumeric = axis.positions.every((position)=>{
                if (levelIndex < position[hierarchyIndex].namePath.length) {
                    const memberName = position[hierarchyIndex].namePath[levelIndex];
                    // @ts-expect-error although typed as if it only accepted numbers, isNaN also accepts strings.
                    return !isNaN(memberName);
                }
                return true;
            });
            levels.push({
                ...levelCoordinates,
                isNumeric
            });
        }
    }
    const columnsAxis = data.axes.find(({ id  })=>id === axisIds.columns);
    if (measuresAxis && columnsAxis && [
        axisIds.rows,
        axisIds.columns
    ].includes(measuresAxis.id)) {
        const numberOfColumns = getAxisLength(columnsAxis);
        for (const cell of data.cells){
            const rowIndex = Math.floor(cell.ordinal / numberOfColumns);
            const columnIndex = cell.ordinal % numberOfColumns;
            const positionIndexOnFullAxis = measuresAxis.id === axisIds.columns ? columnIndex : rowIndex;
            const chunkOffset = measuresAxis.range?.from ?? 0;
            const positionIndex = positionIndexOnFullAxis - chunkOffset;
            const position = measuresAxis.positions[positionIndex];
            const measureName = position[measuresIndexOnAxis].namePath[0];
            const isNumeric = // @ts-expect-error although typed as if it only accepted numbers, isNaN also accepts strings.
            measureTypes[measureName]?.isNumeric !== false && !isNaN(cell.value);
            measureTypes[measureName] = {
                isNumeric
            };
        }
    }
    return {
        levels,
        measures: Object.entries(measureTypes).map(([measureName, { isNumeric  }])=>({
                measureName,
                isNumeric
            }))
    };
}
