import { Fragment, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import {
  ApplicationMenuItem,
  IconSaveAs,
  Permission,
  SaveAsPopup,
  getContentRecordAtPath,
  getDashboardState,
  getOwnersAndReaders,
  useIsModalMounted,
  useTree,
  useUser,
} from "@activeviam/activeui-sdk";

import { usePathToParentFolder } from "../../PathToParentFolderContext.js";
import { useSaveDashboardAs } from "../../hooks/useSaveDashboardAs.js";
import { useIsDashboardSaved } from "../useIsDashboardSaved.js";

/**
 * {@link ApplicationMenuItem} to save a dashboard with a different name.
 */
export const fileSaveAsMenuItem: ApplicationMenuItem = {
  key: "save-dashboard-as",
  useMenuItem: () => {
    const dashboardsTree = useTree("dashboard");
    const saveDashboardAs = useSaveDashboardAs();
    const { formatMessage } = useIntl();

    const { username } = useUser();

    const pathToParentFolderOfCurrentDashboard = usePathToParentFolder() ?? [];
    const isDashboardSaved = useIsDashboardSaved();
    const dashboard = useSelector(getDashboardState);

    const [isPopupVisible, setIsPopupVisible] = useState<boolean>(false);
    const isPopupMounted = useIsModalMounted(isPopupVisible);

    const handleSaveAs = async (
      name: string,
      pathToParentFolder: string[],
      permissions: Permission[],
    ) => {
      if (dashboard) {
        // Saving a dashboard for the first time means a file inherits its parent folder's permissions.
        const folderRecord = pathToParentFolder
          ? getContentRecordAtPath({
              tree: dashboardsTree,
              path: pathToParentFolder,
            })
          : dashboardsTree;

        const { owners, readers } =
          isDashboardSaved || permissions.length > 0 || !folderRecord
            ? getOwnersAndReaders(permissions, username)
            : folderRecord.entry;

        await saveDashboardAs({
          dashboard,
          name,
          pathToParentFolder,
          owners,
          readers,
        });
      }
      setIsPopupVisible(false);
    };

    const handleCancel = () => {
      setIsPopupVisible(false);
    };

    const handleClicked = () => {
      setIsPopupVisible(true);
    };

    return {
      icon: <IconSaveAs />,
      label: (
        <Fragment>
          {isPopupMounted && (
            <SaveAsPopup
              contentTree={dashboardsTree}
              contentType={"dashboard"}
              isVisible={isPopupVisible}
              onClose={handleCancel}
              onSubmit={handleSaveAs}
              pathToParentFolder={pathToParentFolderOfCurrentDashboard}
              defaultName={formatMessage(
                { id: "aui.copyOf" },
                {
                  name:
                    dashboard?.name ??
                    formatMessage({ id: "aui.defaultDashboardName" }),
                },
              )}
            />
          )}
          <span>{formatMessage({ id: "saveAs" })}</span>
        </Fragment>
      ),
      onClick: () => {
        if (!isPopupVisible) {
          handleClicked();
        }
      },
    };
  },
};
