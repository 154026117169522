import { defaultCleanup } from "./_functionCleanup.js";
import { _mdxFunctionsMetaData } from "./_mdxFunctionsMetaData.js";
/**
 * Cleans up the function node at `path` in `mdx`.
 * It is most often called right after one of its arguments has been nullified.
 * Mutates `mdx`: this function is meant to be called from within a call to { produce } from "immer".
 */ export function _cleanupFunctionNode({ mdx , path , functionNode , cube  }) {
    const lowerCaseFunctionName = functionNode.name.toLowerCase();
    const functionMetaData = _mdxFunctionsMetaData[lowerCaseFunctionName];
    if (!functionMetaData) {
        throw new Error(`Unknown MDX function "${functionNode.name}"`);
    }
    // Remove nullified args.
    const remainingArgs = [];
    const indicesOfRemovedArgs = [];
    functionNode.arguments.forEach((arg, argIndex)=>{
        if (arg === null) {
            indicesOfRemovedArgs.push(argIndex);
        } else {
            remainingArgs.push(arg);
        }
    });
    functionNode.arguments = remainingArgs;
    const indexOfFirstRemovedArg = indicesOfRemovedArgs[0] ?? -1;
    // Each function defines how it should be cleaned up.
    const { minNumberOfArgs , cleanup =defaultCleanup  } = functionMetaData;
    cleanup({
        mdx,
        path,
        functionNode,
        minNumberOfArgs,
        indexOfFirstRemovedArg,
        cube
    });
}
