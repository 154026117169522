import { get as _get, initial as _initial } from "lodash-es";
import { isMdxFunction } from "../isMdxFunction.js";
import { _getHighestParentRepresentingTheSameHierarchies } from "./_getHighestParentRepresentingTheSameHierarchies.js";
import { _isMdxAxis } from "./_isMdxAxis.js";
/**
 * Returns whether the mdx at `path` in `node`, contributes to the dimensionality of `node`.
 */ export function _isContributingToNodeDimensionality(node, path) {
    const { match , path: pathToHighestParentRepresentingTheSameHierarchy  } = _getHighestParentRepresentingTheSameHierarchies(node, path);
    if (match === node || _isMdxAxis(node) && match === node.expression) {
        return true;
    }
    const parentPath = _initial(pathToHighestParentRepresentingTheSameHierarchy);
    const grandParentPath = _initial(parentPath);
    const parent = _get(node, parentPath);
    const grandParent = grandParentPath.length > 0 ? _get(node, grandParentPath) : node;
    if (parent && _isMdxAxis(parent)) {
        return true;
    }
    if (isMdxFunction(grandParent, "crossjoin") || isMdxFunction(grandParent, "()")) {
        return _isContributingToNodeDimensionality(node, grandParentPath);
    }
    return false;
}
