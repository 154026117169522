import { ConfigProvider as AntdConfigProvider } from "antd";
import type { Locale } from "antd/es/locale-provider/index.js";
import { ComponentType, useEffect, useMemo, useState } from "react";
import { IntlConfig, IntlProvider } from "react-intl";
import { treeToFlatMap } from "tree-to-flat-map";

import {
  LoadingBackground,
  fetchTranslations,
  getPluginsTranslations,
  getToolsTranslations,
} from "@activeviam/activeui-sdk";

import { useConfiguration } from "./ConfigurationContext.js";

const locale = "en-US";

/**
 * Ant Design components get their locale from the Ant Design context.
 * The context must be initialized for locales other than 'en'.
 * */
const fetchAntdLocale = async (locale: string): Promise<Locale | undefined> => {
  switch (locale) {
    case "fr-FR":
      await import("dayjs/locale/fr");
      return (await import("antd/es/locale/fr_FR.js")).default;
    default:
      return undefined;
  }
};

/**
 * Provides the translations to `Component`.
 */
export function withTranslations<P extends JSX.IntrinsicAttributes>(
  Component: ComponentType<P>,
): ComponentType<P> {
  const Wrapped = (props: P) => {
    const { pluginRegistry, tools } = useConfiguration();

    /**
     * The app and core translations.
     * On top of these translations, some additional ones come from plugins.
     **/
    const [baseTranslations, setBaseTranslations] = useState<
      IntlConfig["messages"] | undefined
    >(undefined);

    const [antdLocale, setAntdLocale] = useState<Locale>();

    useEffect(() => {
      const initTranslations = async (locale: string) => {
        const [
          sdkTranslations,
          { default: appTranslations },
        ] = await Promise.all([
          fetchTranslations(locale),
          import(`./translations/${locale}.ts`),
        ]);

        const flatAppTranslations = treeToFlatMap(appTranslations);
        setBaseTranslations({
          ...sdkTranslations,
          ...flatAppTranslations,
        });
      };

      const initAntdLocale = async (locale: string) => {
        setAntdLocale(await fetchAntdLocale(locale));
      };

      void initTranslations(locale);
      void initAntdLocale(locale);
    }, []);

    const translations = useMemo(() => {
      return {
        ...baseTranslations,
        ...getPluginsTranslations(pluginRegistry, locale),
        ...getToolsTranslations(tools, locale),
      };
    }, [baseTranslations, pluginRegistry, tools]);

    if (baseTranslations === undefined) {
      return <LoadingBackground />;
    }

    return (
      <IntlProvider locale={locale} messages={translations}>
        <AntdConfigProvider locale={antdLocale}>
          <Component {...props} />
        </AntdConfigProvider>
      </IntlProvider>
    );
  };

  const name = Component.displayName || Component.name || "Unknown";
  Wrapped.displayName = `withTranslations(${name})`;
  return Wrapped;
}
