import { Breadcrumb } from "antd";
import { FC, useMemo } from "react";

import { useTree } from "@activeviam/activeui-sdk";

import { getCaptionsOfFolders } from "../../utils/getCaptionsOfFolders.js";

interface FolderBreadcrumbProps {
  pathToParentFolder: string[];
  onPathToFolderChanged: (newPath: string[]) => void;
}

/**
 * Displays the full path to the current folder as a clickable breadcrumb component.
 */
export const FolderBreadcrumb: FC<FolderBreadcrumbProps> = (props) => {
  const dashboardsTree = useTree("dashboard");

  const crumbs = useMemo(
    () => getCaptionsOfFolders(props.pathToParentFolder, dashboardsTree),
    [dashboardsTree, props.pathToParentFolder],
  );

  return (
    <Breadcrumb
      separator="/"
      css={{
        ".breadcrumb-item": {
          cursor: "pointer",
          fontSize: 14,
          lineHeight: `22px`,
          fontWeight: 500,
        },
      }}
    >
      {crumbs.map(({ caption, id }, index) => (
        <Breadcrumb.Item
          key={id}
          className="breadcrumb-item"
          onClick={() => {
            const itemPath = props.pathToParentFolder.slice(0, index + 1);
            props.onPathToFolderChanged(itemPath);
          }}
        >
          {caption}
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
};
