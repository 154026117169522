import { getServiceVersionIdAsNumber } from "@activeviam/versions";
import { fetchCalculatedMembersV6 } from "./fetchCalculatedMembersV6.js";
import { fetchCalculatedMembersV8 } from "./fetchCalculatedMembersV8.js";
/**
 * Asynchronously returns the calculated members available for the cube identified by `cubeName` on the server at `serverUrl`.
 */ export async function fetchCalculatedMembers(args) {
    const serviceVersionIdAsNumber = getServiceVersionIdAsNumber(args.pivotServiceVersion.id);
    switch(serviceVersionIdAsNumber){
        case 8:
            return fetchCalculatedMembersV8(args);
        default:
            return fetchCalculatedMembersV6(args);
    }
}
