import { FileFilled, FolderOutlined, SearchOutlined } from "@ant-design/icons";
import { Input } from "antd";
import { debounce as _debounce } from "lodash-es";
import {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

import {
  ContentRecord,
  DashboardMetaData,
  getContentRecordAtPath,
  getMetaDataRecord,
  search,
  useTree,
} from "@activeviam/activeui-sdk";
import { Dropdown } from "@activeviam/utils-react";

import { HomeSearchResult } from "./HomeSearchResult.js";

const maxNumberOfResults = 7;

/**
 * Returns all dashboards and dashboard folders found under `node` that the user can read, recursively.
 * This includes readable files/folders within non-readable ancestors.
 */
const getDashboardsAndFolders = (
  node: ContentRecord<DashboardMetaData>,
  path: string[] = [],
  acc: { metaData: DashboardMetaData; path: string[] }[] = [],
): { metaData: DashboardMetaData; path: string[] }[] => {
  // Entries corresponding to dashboards or dashboard folders are all represented as directories in the dashboard structure tree.
  // For dashboards, this directory contains only a metadata file.
  // For dashboards folders, it also contains children dashboards/dashboard folders.
  if (path.length > 0 && node.entry.canRead && node.entry.isDirectory) {
    const metaData = node?.children?.[`${path[path.length - 1]}_metadata`].entry
      .content!;
    acc.push({
      metaData,
      path,
    });
  }

  const children = node.children || {};
  Object.keys(children).forEach((childId) => {
    getDashboardsAndFolders(children[childId], [...path, childId], acc);
  });

  return acc;
};

/**
 * The search input used in the header of the home page to search and navigate to dashboards or folders
 */
export const HomeSearch: FC = () => {
  const [searchValue, setSearchValue] = useState<string>("");
  const [searchResults, setSearchResults] = useState<
    ReturnType<typeof getDashboardsAndFolders>
  >([]);
  const dashboardsTree = useTree("dashboard");
  const navigate = useNavigate();
  const { formatMessage } = useIntl();

  const allDashboardsAndFolders = useMemo(() => {
    if (dashboardsTree === null) {
      return [];
    }
    return getDashboardsAndFolders(dashboardsTree);
  }, [dashboardsTree]);

  // ESLint does not trust `_debounce` to return a function that does not have other dependencies than the one specified in the `useCallback` dependency array.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    _debounce((searchValue: string) => {
      setSearchResults(
        search(allDashboardsAndFolders, searchValue, {
          keys: ["metaData.name"],
          limit: maxNumberOfResults,
        }),
      );
    }, 500),
    [allDashboardsAndFolders],
  );

  useEffect(() => {
    if (searchValue.length === 0) {
      debouncedSearch.cancel();
      setSearchResults([]);
    } else {
      debouncedSearch(searchValue);
    }
  }, [searchValue, debouncedSearch]);

  const handleItemSelected = (item: {
    metaData: DashboardMetaData;
    path: string[];
  }) => {
    if (item.metaData.isFolder) {
      navigate(`/folder/${item.path.join("/")}`);
    } else {
      navigate(`/dashboard/${item.path[item.path.length - 1]}`);
    }
    setSearchValue("");
  };

  const handleSearchValueChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  return (
    <Dropdown
      menu={{
        items: searchResults.map((searchResult) => {
          let pathName = "";

          for (let i = 1; i < searchResult.path.length; i++) {
            const folder = getContentRecordAtPath({
              tree: dashboardsTree,
              path: searchResult.path.slice(0, i),
            });

            if (folder && folder.children && folder.entry.canRead) {
              const folderName = getMetaDataRecord(
                folder,
                searchResult.path[i - 1],
              ).entry.content.name;
              pathName =
                i === 1 ? `${folderName}` : `${pathName}/${folderName}`;
            }
          }

          return {
            key: searchResult.path.join("/"),
            icon: searchResult.metaData.isFolder ? (
              <FolderOutlined />
            ) : (
              <FileFilled />
            ),
            label: (
              <HomeSearchResult
                path={searchResult.path}
                dashboardName={searchResult.metaData.name}
                searchValue={searchValue}
              />
            ),
            onClick: () => handleItemSelected(searchResult),
          };
        }),
      }}
    >
      <Input
        prefix={<SearchOutlined />}
        value={searchValue}
        onChange={handleSearchValueChanged}
        placeholder={formatMessage(
          { id: "aui.searchFilesAndFolders" },
          { contentType: "dashboard" },
        )}
        style={{
          height: 28,
          width: 600,
        }}
      />
    </Dropdown>
  );
};
