import { findDescendant } from "../findDescendant.js";
import { isACompoundIdentifierRepresentingAMeasure } from "../isACompoundIdentifierRepresentingAMeasure.js";
/**
 * Returns a reference to the first measure encountered in `mdx`.
 * Only matches the given `measureName`, if any.
 */ export const _findFirstMeasure = (mdx, { path , measureName  } = {})=>// `match` is an MdxCompoundIdentifier or an MdxFormula because findDescendant is called with matchFn = `isACompoundIdentifierRepresentingAMeasure`.
    // eslint-disable-next-line
    findDescendant(mdx, (descendant)=>isACompoundIdentifierRepresentingAMeasure(descendant, {
            measureName
        }), path);
