import { get as _get, isEmpty as _isEmpty, set as _set } from "lodash-es";
import { isMdxFunction } from "../isMdxFunction.js";
import { _cleanupFunctionNode } from "./_cleanupFunctionNode.js";
import { _isMdxAxis } from "./_isMdxAxis.js";
import { _isMdxFormula } from "./_isMdxFormula.js";
import { _isMdxSelect } from "./_isMdxSelect.js";
import { _isMdxSubSelect } from "./_isMdxSubSelect.js";
import { _nullifyNodeAtPath } from "./_nullifyNodeAtPath.js";
/**
 * Cleanup the `mdx` from the bottom up, starting from `node` at `path`.
 * In most cases, this is called right after a measure, level, hierarchy or filter was nullified at `path` in `mdx`.
 * Mutates `mdx`: this function is made to be called from within a call to { produce } from "immer".
 */ export const _cleanupMdx = (mdx, path, cube)=>{
    const isRoot = path.length === 0;
    // Forced to use `get` because path is dynamic.
    // eslint-disable-next-line atoti-ui/no-lodash-get
    const node = isRoot ? mdx : _get(mdx, path);
    if (node !== null) {
        if (_isMdxSelect(node)) {
            const select = node;
            // Remove empty axes.
            select.axes = select.axes.filter((axis)=>axis !== null);
            if (select.slicerAxis === null) {
                Reflect.deleteProperty(select, "slicerAxis");
            }
            select.withClause = select.withClause.filter((formula)=>formula !== null);
        } else if (_isMdxSubSelect(node)) {
            // Remove the subSelect if it is empty.
            const subSelect = node;
            subSelect.axes = subSelect.axes.filter((axis)=>axis !== null);
            if (_isEmpty(subSelect.axes)) {
                _set(mdx, path, subSelect.from);
            }
        } else if (_isMdxAxis(node) && !node.expression) {
            _nullifyNodeAtPath(mdx, path);
        } else if (isMdxFunction(node)) {
            _cleanupFunctionNode({
                mdx,
                path,
                functionNode: node,
                cube
            });
        } else if (_isMdxFormula(node) && (node.name === null || node.expression === null)) {
            _nullifyNodeAtPath(mdx, path);
        }
    }
    if (!isRoot) {
        const parentPath = path.slice(0, -1);
        _cleanupMdx(mdx, parentPath, cube);
    }
};
