import { isArray as _isArray } from "lodash-es";
import { stringify } from "./stringify.js";
/**
 * Walks through `mdx` recursively and calls `callback` on each node.
 * If `callback` explicitly returns `false` when evaluated on a node, the function execution is stopped, and `false` is returned.
 */ export function traverseMdx(mdx, callback, path = []) {
    if (!mdx) {
        return;
    }
    if (_isArray(mdx)) {
        return !mdx.some((_mdx, i)=>traverseMdx(_mdx, callback, [
                ...path,
                i
            ]) === false);
    }
    const shouldBreak = callback(mdx, path) === false;
    if (shouldBreak) {
        return false;
    }
    const walkThroughChild = (key)=>{
        // @ts-expect-error: Doesn't allow to find attribute in mdx when it should
        const child = mdx[key];
        return traverseMdx(child, callback, [
            ...path,
            key
        ]);
    };
    const walkThroughChildren = (...keys)=>{
        return !keys.some((key)=>{
            return walkThroughChild(key) === false;
        });
    };
    switch(mdx.elementType){
        case "MemberPropertyDefinition":
        case "Axis":
            return walkThroughChildren("expression");
        case "CaseExpression":
            return walkThroughChildren("cases", "elseExp", "match");
        case "Formula":
            return walkThroughChildren("name", "expression", "properties");
        case "Function":
            return walkThroughChildren("arguments");
        case "Select":
            return walkThroughChildren("withClause", "axes", "slicerAxis", "from");
        case "Drillthrough":
            return walkThroughChildren("columns", "select");
        case "SubSelect":
            return walkThroughChildren("axes", "slicerAxis", "from");
        case "When":
            return walkThroughChildren("when", "then");
        case "CompoundIdentifier":
        case "From":
        case "Identifier":
        case "Literal":
            break;
        default:
            throw new Error(`Encountered Node with unknown type: "${// If the elementType is not one of the above, then the type is wrong.
            // eslint-disable-next-line
            mdx.elementType}" while walking through the following mdx:\n${stringify(mdx, {
                indent: true
            })}`);
    }
}
