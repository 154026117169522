import { css } from "@emotion/react";
import { Card } from "antd";
import { map as _map } from "lodash-es";
import { FC, Fragment, useMemo } from "react";
import { useAsyncAbortable } from "react-async-hook";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

import {
  AccessLog,
  ContentEntry,
  DashboardMetaData,
  IconEmptyFolder,
  Title,
  findContentRecords,
  getMetaData,
  useActivity,
  useContentClient,
  useTheme,
  useTree,
} from "@activeviam/activeui-sdk";
import { getDurationAndTimeUnit } from "@activeviam/file-list";

import { BrandSignatureFooter } from "./BrandSignatureFooter.js";
import { DashboardsSkeleton } from "./DashboardsSkeleton.js";
import { FileCard } from "./FileCard.js";

/**
 * Component showing the list of recently opened dashboards.
 */
export const RecentlyOpened: FC = () => {
  const dashboardsTree = useTree("dashboard");
  const { formatMessage, formatRelativeTime } = useIntl();
  const contentClient = useContentClient();
  const theme = useTheme();
  const navigate = useNavigate();
  const [recentlyOpenedDashboards] = useActivity("recentlyOpenedDashboards");
  const recentlyOpenedDashboardsMetaData = useMemo(() => {
    if (dashboardsTree === null || !recentlyOpenedDashboards) {
      return null;
    }

    const metaData: (DashboardMetaData &
      AccessLog & {
        entry: ContentEntry<DashboardMetaData>;
        pathToParentFolder: string[];
      })[] = [];

    const dashboardContentRecords = findContentRecords<DashboardMetaData>(
      dashboardsTree,
      _map(recentlyOpenedDashboards, "id"),
    );

    recentlyOpenedDashboards.forEach(({ id, lastOpened }) => {
      const dashboardContentRecord = dashboardContentRecords[id];
      if (dashboardContentRecord) {
        const { node, pathToParentFolder } = dashboardContentRecord;
        // Only show dashboards that are still accessible to the current user
        if (node.entry.canRead) {
          metaData.push({
            entry: node.entry,
            id,
            lastOpened,
            pathToParentFolder,
            ...getMetaData(node, id),
          });
        }
      }
    });

    return metaData;
  }, [dashboardsTree, recentlyOpenedDashboards]);

  const { result: thumbnails } = useAsyncAbortable(
    async (signal) => {
      if (!recentlyOpenedDashboardsMetaData) {
        return;
      }

      const thumbnailsSettledPromises = await Promise.allSettled(
        recentlyOpenedDashboardsMetaData.map(({ id, pathToParentFolder }) =>
          contentClient.fetchThumbnail(
            {
              type: "dashboard",
              id,
              pathToParentFolder,
            },
            { signal },
          ),
        ),
      );

      return thumbnailsSettledPromises.map((promiseSettledResult) => {
        if (promiseSettledResult.status === "fulfilled") {
          return promiseSettledResult.value;
        } else {
          // Even though errors can be anything in theory, in practice they are always instances of Error.
          // eslint-disable-next-line atoti-ui/no-as
          const error = promiseSettledResult.reason as Error;
          // eslint-disable-next-line no-console
          console.error(error.message, error.stack);

          return undefined;
        }
      });
    },
    [recentlyOpenedDashboardsMetaData, contentClient],
  );

  if (recentlyOpenedDashboardsMetaData === null) {
    // Recently opened dashboards are always displayed in grid mode.
    return (
      <DashboardsSkeleton
        cardStyle={{
          marginTop: 12,
          boxShadow: `0px 2px 8px 0px ${theme.black}1A`,
        }}
        cardProps={{
          headStyle: {
            fontSize: 16,
            fontWeight: 500,
            color: theme.textColor,
            backgroundColor: theme.grayScale[1],
          },
          bordered: false,
          bodyStyle: { paddingTop: 0, paddingBottom: 0 },
        }}
      />
    );
  }

  return (
    <div
      css={{
        height: "100%",
      }}
    >
      <div
        css={{
          fontSize: 14,
          lineHeight: `24px`,
          fontWeight: 500,
          display: "flex",
          alignItems: "center",
          height: 44,
          paddingLeft: 12,
          color: theme.textColor,
        }}
      >
        {formatMessage({ id: "recentlyOpened" })}
      </div>
      <div
        style={
          // The height of the space allocated to the content of the folder is 100% minus the height of the breadcrumb and the height of the footer.
          {
            overflow: "auto",
            height: "calc(100% - 44px - 28px)",
            width: "100%",
            paddingLeft: 12,
            paddingRight: 12,
            backgroundColor: theme.grayScale[2],
          }
        }
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            ...(recentlyOpenedDashboardsMetaData.length === 0
              ? {
                  backgroundColor: theme.backgroundColor,
                  marginTop: 12,
                  height: "calc(100% - 12px)",
                  boxShadow: `0px 2px 8px 0px ${theme.black}1A`,
                  flexGrow: 1,
                  justifyContent: "center",
                  alignItems: "center",
                }
              : {}),
          }}
        >
          {recentlyOpenedDashboardsMetaData.length === 0 ? (
            <Fragment>
              <IconEmptyFolder
                style={{ fontSize: 164, filter: "grayscale(100%)" }}
              />
              <div style={{ marginTop: 20 }}>
                <Title level={2}>
                  {formatMessage({ id: "noRecentlyOpenedDashboard" })}
                </Title>
              </div>
            </Fragment>
          ) : (
            <Card
              style={{
                marginTop: 12,
                boxShadow: `0px 2px 8px 0px ${theme.black}1A`,
                flexGrow: 1,
              }}
              bordered={false}
              bodyStyle={{ paddingTop: 0, paddingBottom: 0 }}
            >
              <div
                css={css`
                  display: flex;
                  flex-wrap: wrap;
                  margin-bottom: 12px;
                  width: 100%;
                `}
              >
                {recentlyOpenedDashboardsMetaData.map(
                  (
                    { id, lastOpened, pathToParentFolder, ...metaData },
                    index,
                  ) => {
                    const { duration, timeUnit } = getDurationAndTimeUnit(
                      lastOpened,
                    );
                    return (
                      <FileCard
                        key={id}
                        id={id}
                        metaData={metaData}
                        description={`${formatMessage({
                          id: "opened",
                        })} ${
                          duration &&
                          formatRelativeTime(-duration, timeUnit, {
                            numeric: "auto",
                            style: "long",
                          })
                        }`}
                        pathToParentFolder={pathToParentFolder}
                        thumbnail={thumbnails?.[index]}
                        onFileDoubleClicked={() => navigate(`/dashboard/${id}`)}
                        onAfterSubmit={async () => {
                          await contentClient.loadTree("dashboard");
                        }}
                      />
                    );
                  },
                )}
              </div>
            </Card>
          )}
        </div>
      </div>
      <BrandSignatureFooter />
    </div>
  );
};
