import { getHierarchies } from "./getHierarchies.js";
import { parse } from "./parse.js";
/**
 * Returns the first hierarchy used to define a named set.
 */ export const getNamedSetHierarchy = (namedSet, cube)=>{
    const setMdx = parse(namedSet.expression);
    // Only sets with a dimensionality of 1 are supported.
    // Several hierarchies may still be used to define the set (e.g. a TopCount also involves a measure), but in that case the first expressed hierarchy is expected to be the one constituting the dimensionality.
    return getHierarchies(setMdx, {
        cube
    })[0];
};
