import { flatMap as _flatMap } from "lodash-es";
import { isMdxFunction } from "../isMdxFunction.js";
/**
 * Flattens a nested call of `functionNode` at `path` in `mdx`.
 * Mutates `functionNode`.
 *
 * @example
 * Union(EUR, Union(GBP, USD)) => Union(EUR, GBP, USD)
 */ export function _flattenNestedCall({ functionNode  }) {
    functionNode.arguments = _flatMap(functionNode.arguments, (arg)=>isMdxFunction(arg, functionNode.name.toLowerCase()) ? arg.arguments : [
            arg
        ]);
}
