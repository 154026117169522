import { produce } from "immer";
import { isEmpty as _isEmpty } from "lodash-es";
import { FC } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useDispatch, useSelector } from "react-redux";

import {
  AWidgetState,
  DataModelTree,
  DrillthroughTableWidgetState,
  getActivePageKey,
  getCubeName,
  getDashboardState,
  getSelectedLeafKey,
  getWidget,
  isWidgetWithQueryState,
  setCubeName,
  useActivity,
  useDataModels,
  useSwitchedWidgetState,
} from "@activeviam/activeui-sdk";
import { DataModelTreeFallback } from "@activeviam/data-model-tree";
import {
  getDefaultMdxDrillthrough,
  getDefaultMdxSelect,
} from "@activeviam/widget";

function noop() {}

interface InnerApplicationDataModelTreeProps {
  widgetState: AWidgetState;
  onCubeSelected: (serverKey: string, cubeName: string) => void;
}

/**
 * Displays a data model tree, provided the state of the selected widget.
 */
const InnerApplicationDataModelTree: FC<InnerApplicationDataModelTreeProps> = (
  props,
) => {
  const widgetState = useSwitchedWidgetState(props.widgetState);
  const [lastUsedCube] = useActivity("lastUsedCube");

  const dataModels = useDataModels();
  const { serverKey: unsafeServerKey, query } = isWidgetWithQueryState(
    widgetState,
  )
    ? widgetState
    : {
        query: undefined,
        serverKey: undefined,
      };

  // If there is no serverKey in the widgetState, we first use the `lastUsedCube` serverKey before using the first server by default.
  // This enables to have the DataModeTree pointing to the `lastUsedCube` when a new widget is created as its widgetState does not have a query.
  const serverKey =
    unsafeServerKey ?? lastUsedCube?.serverKey ?? Object.keys(dataModels)[0];

  const dataModel = dataModels[serverKey];
  if (!dataModel) {
    // Loading data models.
    return (
      <DataModelTreeFallback
        serverKey={serverKey}
        inputStyle={{
          marginLeft: 8,
          width: `calc(100% - 16px)`,
        }}
        onCubeSelected={noop}
      />
    );
  }

  const mdx = query?.mdx;
  const cubeName = mdx
    ? getCubeName(mdx)
    : lastUsedCube?.cubeName ??
      Object.keys(Object.values(dataModel.catalogs)[0]?.cubes)[0];

  if (!cubeName) {
    return (
      <DataModelTreeFallback
        serverKey={serverKey}
        inputStyle={{
          marginLeft: 8,
          width: `calc(100% - 16px)`,
        }}
        cubeName={cubeName}
        onCubeSelected={props.onCubeSelected}
      />
    );
  }

  return (
    <DataModelTree
      marginLeft={8}
      serverKey={serverKey}
      cubeName={cubeName}
      mdx={mdx}
      onCubeSelected={props.onCubeSelected}
    />
  );
};

/**
 * Displays a data model tree.
 * Allows users to switch cubes, and add measures, KPIs and hierarchies to their widgets and filters.
 */
export const ApplicationDataModelTree: FC = () => {
  const dashboardState = useSelector(getDashboardState);
  const activePageKey = useSelector(getActivePageKey);
  const selectedLeafKey = useSelector(getSelectedLeafKey);
  const dispatch = useDispatch();

  const [lastUsedCube] = useActivity("lastUsedCube");

  const dataModels = useDataModels();

  if (
    !dashboardState ||
    activePageKey === undefined ||
    selectedLeafKey === undefined ||
    _isEmpty(dataModels)
  ) {
    return null;
  }

  const widgetState = getWidget(dashboardState, activePageKey, selectedLeafKey);

  const { serverKey: unsafeServerKey, query } =
    widgetState && isWidgetWithQueryState(widgetState)
      ? widgetState
      : {
          query: undefined,
          serverKey: undefined,
        };
  // If there is no serverKey in the widgetState, we first use the `lastUsedCube` serverKey before using the first server by default.
  // This enables to have the DataModelTree pointing to the `lastUsedCube` when a new widget is created as its widgetState does not have a query.
  const serverKey =
    unsafeServerKey ?? lastUsedCube?.serverKey ?? Object.keys(dataModels)[0];

  const dataModel = dataModels[serverKey];

  const mdx = query?.mdx;
  const cubeName = mdx
    ? getCubeName(mdx)
    : lastUsedCube?.cubeName ??
      Object.keys(Object.values(dataModel.catalogs)[0]?.cubes)[0];

  if (widgetState === undefined) {
    // TODO when https://activeviam.atlassian.net/browse/UI-7880 is done.
    // Keep returning the data model tree in this case.
    // Also merge ApplicationDataModelTree and InnerApplicationDataModelTree.
    return null;
  }

  const handleChange = (updatedWidgetState: AWidgetState) => {
    const updatedDashboardState = produce(dashboardState, (draft) => {
      draft.pages[activePageKey].content[selectedLeafKey] = updatedWidgetState;
    });
    dispatch({
      type: "dashboardUpdated",
      dashboardState: updatedDashboardState,
    });
  };

  const handleCubeSelected = (serverKey: string, cubeName: string) => {
    if (widgetState.widgetKey.startsWith("drillthrough")) {
      // Find alternatives in https://activeviam.atlassian.net/browse/UI-7881.
      // By convention, widgets whose key start with "drillthrough" have a `widgetState.query.mdx`.
      // This attribute is of type MdxDrillthrough.
      // eslint-disable-next-line atoti-ui/no-as
      const _widgetState = widgetState as DrillthroughTableWidgetState;
      const { mdx } = _widgetState.query;
      const updatedMdx = mdx
        ? setCubeName(mdx, { cubeName })
        : getDefaultMdxDrillthrough(cubeName);
      const updatedWidgetState = produce(_widgetState, (draft) => {
        draft.query.mdx = updatedMdx;
        draft.serverKey = serverKey;
        delete draft.initialCubeName;
      });
      handleChange(updatedWidgetState);
    }

    if (!isWidgetWithQueryState(widgetState)) {
      return;
    }

    const { mdx } = widgetState.query;
    const updatedMdx = mdx
      ? setCubeName(mdx, { cubeName })
      : getDefaultMdxSelect(cubeName);
    const updatedWidgetState = produce(widgetState, (draft) => {
      draft.query.mdx = updatedMdx;
      draft.serverKey = serverKey;
    });
    handleChange(updatedWidgetState);
  };

  return (
    <ErrorBoundary
      resetKeys={[widgetState]}
      fallbackRender={({ error }) => (
        <DataModelTreeFallback
          serverKey={serverKey}
          inputStyle={{
            marginLeft: 8,
            width: `calc(100% - 16px)`,
          }}
          cubeName={cubeName}
          onCubeSelected={handleCubeSelected}
        />
      )}
    >
      <InnerApplicationDataModelTree
        widgetState={widgetState}
        onCubeSelected={handleCubeSelected}
      />
    </ErrorBoundary>
  );
};
