import { useLocation } from "react-router-dom";

const dashboardRegex = /\/dashboard\/.+/;

/**
 * React hook that returns whether the current location is a dashboard.
 */
export function useIsLocationDashboard(): boolean {
  const { pathname } = useLocation();
  return dashboardRegex.test(pathname);
}
