import { getServiceVersionIdAsNumber } from "@activeviam/versions";
import { fetchDataModelV6 } from "./fetchDataModelV6.js";
import { fetchDataModelV8 } from "./fetchDataModelV8.js";
/**
 * Asynchronously returns the {@link DataModel} available on the server at `serverUrl`.
 */ export async function fetchDataModel(args) {
    const serviceVersionIdAsNumber = getServiceVersionIdAsNumber(args.pivotServiceVersion.id);
    switch(serviceVersionIdAsNumber){
        case 8:
            return fetchDataModelV8(args);
        default:
            return fetchDataModelV6(args);
    }
}
