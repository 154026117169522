import { set as _set } from "lodash-es";
import { isMdxFunction } from "../isMdxFunction.js";
/**
 * Replaces `functionNode` at `path` in `mdx` by its first argument if it is an unnecessary call of an idempotent function.
 * Mutates `mdx`.
 *
 * @example
 * AddCalculatedMembers(AddCalculatedMembers([MySet])) => AddCalculatedMembers([MySet])
 */ export function _flattenIdempotentCall({ mdx , path , functionNode  }) {
    if (functionNode.arguments.length === 1 && isMdxFunction(functionNode.arguments[0], functionNode.name.toLowerCase())) {
        _set(mdx, path, functionNode.arguments[0]);
    }
}
