import { getMemberCoordinates } from "./getMemberCoordinates.js";
import { isMdxFunction } from "./isMdxFunction.js";
/**
 * Returns the {@link TupleCoordinates} corresponding to the given {@link MdxExpression}.
 * Throws if the expression does not represent a member or a tuple.
 */ export const getTupleCoordinates = (expression, cube)=>{
    const members = isMdxFunction(expression, "()") ? expression.arguments : [
        expression
    ];
    return members.map((member)=>getMemberCoordinates(member, {
            cube
        }));
};
