import type { ItemType } from "antd/es/menu/hooks/useItems.js";

import { ApplicationMenu, ApplicationSubMenu } from "@activeviam/activeui-sdk";

const isApplicationSubMenu = (
  menu: ApplicationMenu,
): menu is ApplicationSubMenu => "children" in menu;

/**
 * Returns an Antd menu items from the {@link ApplicationMenu} menus.
 */
export function getAntdMenuItems(
  menus: ApplicationMenu[],
  popupClassName: string,
): ItemType[] {
  const items: ItemType[] = [];
  menus.forEach((menu) => {
    if (isApplicationSubMenu(menu)) {
      const antdSubMenuItem = menu.useMenuItem();
      if (antdSubMenuItem) {
        items.push({
          ...antdSubMenuItem,
          key: menu.key,
          children: getAntdMenuItems(menu.children, popupClassName),
          popupClassName,
        });
      }
    } else {
      const antdMenuItem = menu.useMenuItem();
      if (antdMenuItem) {
        items.push({ ...antdMenuItem, key: menu.key });
      }
    }
  });
  return items;
}
